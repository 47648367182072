import React, { useState, useRef, useEffect } from 'react';
import { 
  PlusIcon, 
  XMarkIcon, 
  TrashIcon, 
  PencilIcon,
  CheckIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  ArrowPathIcon
} from '@heroicons/react/24/outline';
import { db } from '../firebase';
import { 
  collection, 
  query, 
  where, 
  addDoc, 
  getDocs,
  updateDoc,
  doc,
  serverTimestamp,
  orderBy,
  deleteDoc,
  writeBatch
} from 'firebase/firestore';
import { useAuth } from '../contexts/AuthContext';
import { motion, AnimatePresence } from 'framer-motion';

const EditIngredientPopup = ({ ingredient, onClose, onSave, onDelete }) => {
  const [name, setName] = useState(ingredient.name);
  const [amount, setAmount] = useState(ingredient.amount);
  const popupRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [onClose]);

  const handleSubmit = (e) => {
    e.preventDefault();
    onSave({ ...ingredient, name: name.trim(), amount: amount.trim() });
    onClose();
  };

  return (
    <motion.div 
      initial={{ opacity: 0 }} 
      animate={{ opacity: 1 }} 
      exit={{ opacity: 0 }}
      className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50 p-4"
    >
      <motion.div
        initial={{ scale: 0.95, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        exit={{ scale: 0.95, opacity: 0 }}
        ref={popupRef}
        className="w-full max-w-md bg-white rounded-2xl shadow-xl p-6 relative"
      >
        <div className="absolute top-4 right-4 flex items-center gap-2">
          <button
            onClick={() => {
              onDelete(ingredient);
              onClose();
            }}
            className="p-2 text-gray-400 hover:text-red-500 transition-colors rounded-full hover:bg-gray-100"
            aria-label="Delete ingredient"
          >
            <TrashIcon className="w-5 h-5" />
          </button>
          <button
            onClick={onClose}
            className="p-2 text-gray-400 hover:text-gray-600 transition-colors rounded-full hover:bg-gray-100"
            aria-label="Close popup"
          >
            <XMarkIcon className="w-5 h-5" />
          </button>
        </div>

        <h2 className="text-2xl font-light text-gray-900 mb-6">Edit Item</h2>

        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label htmlFor="name" className="block text-sm font-medium text-gray-700 mb-1">
              Name
            </label>
            <input
              id="name"
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="w-full p-3 rounded-xl border border-gray-200 
                focus:outline-none focus:ring-2 focus:ring-primary/20 focus:border-primary
                bg-white text-gray-900 placeholder-gray-400"
              required
            />
          </div>

          <div>
            <label htmlFor="amount" className="block text-sm font-medium text-gray-700 mb-1">
              Amount
            </label>
            <input
              id="amount"
              type="text"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              className="w-full p-3 rounded-xl border border-gray-200 
                focus:outline-none focus:ring-2 focus:ring-primary/20 focus:border-primary
                bg-white text-gray-900 placeholder-gray-400"
              placeholder="e.g., 2 cups, 500g"
            />
          </div>

          <button
            type="submit"
            className="w-full p-3 bg-primary text-white rounded-xl font-medium
              hover:bg-primary/90 transition-colors duration-200
              focus:outline-none focus:ring-2 focus:ring-primary/50 focus:ring-offset-2"
          >
            Save Changes
          </button>
        </form>
      </motion.div>
    </motion.div>
  );
};

const IngredientItem = ({ ingredient, isActive, onToggle, onEdit }) => {
  return (
    <motion.div 
      layout
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      className={`
        group relative p-4 rounded-xl border transition-all duration-200
        ${isActive 
          ? 'bg-white border-gray-200 shadow-sm hover:shadow-md' 
          : 'bg-gray-50 border-gray-100'
        }
      `}
    >
      <div className="flex items-center gap-3">
        <button
          onClick={() => onToggle(ingredient)}
          className={`
            flex-shrink-0 w-6 h-6 rounded-full border-2 transition-all duration-200
            flex items-center justify-center
            ${isActive 
              ? 'border-gray-300 hover:border-primary hover:bg-primary/5' 
              : 'border-gray-200 bg-white'
            }
          `}
        >
          {!isActive && <CheckIcon className="w-4 h-4 text-gray-400" />}
        </button>

        <div className="flex-grow">
          <p className={`font-medium ${isActive ? 'text-gray-900' : 'text-gray-500 line-through'}`}>
            {ingredient.name}
          </p>
          {ingredient.amount && (
            <p className={`text-sm ${isActive ? 'text-gray-500' : 'text-gray-400'}`}>
              {ingredient.amount}
            </p>
          )}
        </div>

        <button
          onClick={() => onEdit(ingredient)}
          className="p-2 text-gray-400 hover:text-gray-600 transition-colors rounded-full 
            opacity-0 group-hover:opacity-100 hover:bg-gray-100"
          aria-label="Edit ingredient"
        >
          <PencilIcon className="w-5 h-5" />
        </button>
      </div>
    </motion.div>
  );
};

const ShoppingList = () => {
  const { user } = useAuth();
  const [newIngredient, setNewIngredient] = useState('');
  const [ingredients, setIngredients] = useState([]);
  const [activeIngredients, setActiveIngredients] = useState(new Set());
  const [editingIngredient, setEditingIngredient] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isResetting, setIsResetting] = useState(false);
  const [showCompleted, setShowCompleted] = useState(true);

  useEffect(() => {
    const fetchIngredients = async () => {
      if (!user) {
        setIsLoading(false);
        return;
      }

      try {
        const q = query(
          collection(db, 'shopping_lists'),
          where('userId', '==', user.uid),
          orderBy('createdAt', 'desc')
        );

        const querySnapshot = await getDocs(q);
        const fetchedIngredients = [];
        const activeIngs = new Set();

        querySnapshot.forEach((doc) => {
          const data = doc.data();
          fetchedIngredients.push({
            id: doc.id,
            name: data.name,
            amount: data.amount || '',
            isActive: data.isActive ?? true
          });
          if (data.isActive ?? true) {
            activeIngs.add(data.name);
          }
        });

        setIngredients(fetchedIngredients);
        setActiveIngredients(activeIngs);
      } catch (error) {
        console.error('Error fetching shopping list:', error);
        setIngredients([]);
        setActiveIngredients(new Set());
      } finally {
        setIsLoading(false);
      }
    };

    setIsLoading(true);
    fetchIngredients();
  }, [user]);

  const handleAddIngredient = async (e) => {
    e.preventDefault();
    if (!newIngredient.trim() || !user) return;

    try {
      const docRef = await addDoc(collection(db, 'shopping_lists'), {
        userId: user.uid,
        name: newIngredient.trim(),
        amount: '',
        isActive: true,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp()
      });

      const newIng = {
        id: docRef.id,
        name: newIngredient.trim(),
        amount: '',
        isActive: true
      };

      setIngredients([newIng, ...ingredients]);
      setActiveIngredients(new Set(activeIngredients).add(newIng.name));
      setNewIngredient('');
    } catch (error) {
      console.error('Error adding ingredient:', error);
    }
  };

  const handleEditIngredient = async (updatedIngredient) => {
    try {
      const docRef = doc(db, 'shopping_lists', updatedIngredient.id);
      await updateDoc(docRef, {
        name: updatedIngredient.name,
        amount: updatedIngredient.amount,
        updatedAt: serverTimestamp()
      });

      setIngredients(ingredients.map(ing => 
        ing.id === updatedIngredient.id ? updatedIngredient : ing
      ));
    } catch (error) {
      console.error('Error updating ingredient:', error);
    }
  };

  const handleDeleteIngredient = async (ingredient) => {
    try {
      const docRef = doc(db, 'shopping_lists', ingredient.id);
      await deleteDoc(docRef);
      
      setIngredients(ingredients.filter(item => item.id !== ingredient.id));
    } catch (error) {
      console.error('Error deleting ingredient:', error);
    }
  };

  const toggleIngredient = async (ingredient) => {
    try {
      const newActiveIngredients = new Set(activeIngredients);
      const isActive = !newActiveIngredients.has(ingredient.name);

      const docRef = doc(db, 'shopping_lists', ingredient.id);
      await updateDoc(docRef, {
        isActive: isActive,
        updatedAt: serverTimestamp()
      });

      if (isActive) {
        newActiveIngredients.add(ingredient.name);
      } else {
        newActiveIngredients.delete(ingredient.name);
      }
      
      setActiveIngredients(newActiveIngredients);
    } catch (error) {
      console.error('Error toggling ingredient:', error);
    }
  };

  const handleResetDone = async () => {
    if (!user || isResetting || inactiveItems.length === 0) return;

    try {
      setIsResetting(true);
      const batch = writeBatch(db);
      
      inactiveItems.forEach((ingredient) => {
        const docRef = doc(db, 'shopping_lists', ingredient.id);
        batch.delete(docRef);
      });

      await batch.commit();
      setIngredients(ingredients.filter(ing => activeIngredients.has(ing.name)));
    } catch (error) {
      console.error('Error resetting done items:', error);
    } finally {
      setIsResetting(false);
    }
  };

  const activeItems = ingredients.filter(ing => activeIngredients.has(ing.name));
  const inactiveItems = ingredients.filter(ing => !activeIngredients.has(ing.name));

  if (isLoading) {
    return (
      <div className="min-h-screen pt-24 bg-gray-50">
        <div className="max-w-2xl mx-auto px-4">
          <div className="flex justify-center items-center min-h-[400px]">
            <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-primary"></div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50">
      <div className="max-w-2xl mx-auto px-4 pb-24 pt-page-top">
        <div className="mb-8">
          <h1 className="text-2xl sm:text-3xl font-thin text-text-dark mb-6">Your Shopping List</h1>
          <p className="text-lg text-text-light">Keep track of your grocery needs</p>
        </div>

        {/* Add new ingredient form */}
        <form onSubmit={handleAddIngredient} className="mb-8">
          <div className="flex gap-2">
            <input
              type="text"
              value={newIngredient}
              onChange={(e) => setNewIngredient(e.target.value)}
              placeholder="Add new item..."
              className="flex-1 p-4 rounded-xl border border-gray-200 
                focus:outline-none focus:ring-2 focus:ring-primary/20 focus:border-primary
                bg-white text-gray-900 placeholder-gray-400"
            />
            <button
              type="submit"
              disabled={!newIngredient.trim()}
              className="px-6 bg-primary text-white rounded-xl font-medium
                hover:bg-primary/90 transition-colors duration-200
                focus:outline-none focus:ring-2 focus:ring-primary/50 focus:ring-offset-2
                disabled:opacity-50 disabled:cursor-not-allowed
                flex items-center gap-2"
            >
              <PlusIcon className="w-5 h-5" />
              <span className="hidden sm:inline">Add</span>
            </button>
          </div>
        </form>

        {/* Active items */}
        <div className="mb-6">
          <div className="flex items-center justify-between mb-4">
            <h2 className="text-lg font-medium text-gray-900">To Buy ({activeItems.length})</h2>
          </div>
          <div className="space-y-2">
            <AnimatePresence>
              {activeItems.map((ingredient) => (
                <IngredientItem
                  key={ingredient.id}
                  ingredient={ingredient}
                  isActive={true}
                  onToggle={toggleIngredient}
                  onEdit={() => setEditingIngredient(ingredient)}
                />
              ))}
            </AnimatePresence>
            {activeItems.length === 0 && (
              <p className="text-gray-500 text-center py-8">
                Your shopping list is empty. Add some items above!
              </p>
            )}
          </div>
        </div>

        {/* Completed items */}
        {inactiveItems.length > 0 && (
          <div className="border-t border-gray-200 pt-6">
            <div className="flex items-center justify-between mb-4">
              <button
                onClick={() => setShowCompleted(!showCompleted)}
                className="flex items-center gap-2 text-gray-500 hover:text-gray-700"
              >
                <h2 className="text-lg font-medium">Completed ({inactiveItems.length})</h2>
                {showCompleted ? (
                  <ChevronUpIcon className="w-5 h-5" />
                ) : (
                  <ChevronDownIcon className="w-5 h-5" />
                )}
              </button>
              <button
                onClick={handleResetDone}
                disabled={isResetting}
                className="p-2 text-gray-400 hover:text-red-500 transition-colors rounded-full hover:bg-gray-100
                  disabled:opacity-50 disabled:cursor-not-allowed"
                aria-label="Clear completed items"
              >
                {isResetting ? (
                  <ArrowPathIcon className="w-5 h-5 animate-spin" />
                ) : (
                  <TrashIcon className="w-5 h-5" />
                )}
              </button>
            </div>
            {showCompleted && (
              <div className="space-y-2">
                <AnimatePresence>
                  {inactiveItems.map((ingredient) => (
                    <IngredientItem
                      key={ingredient.id}
                      ingredient={ingredient}
                      isActive={false}
                      onToggle={toggleIngredient}
                      onEdit={() => setEditingIngredient(ingredient)}
                    />
                  ))}
                </AnimatePresence>
              </div>
            )}
          </div>
        )}

        {/* Edit Ingredient Popup */}
        <AnimatePresence>
          {editingIngredient && (
            <EditIngredientPopup
              ingredient={editingIngredient}
              onClose={() => setEditingIngredient(null)}
              onSave={handleEditIngredient}
              onDelete={handleDeleteIngredient}
            />
          )}
        </AnimatePresence>
      </div>
    </div>
  );
};

export default ShoppingList;

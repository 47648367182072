import React, { useState } from 'react';
import { motion } from 'framer-motion';

// Mock data for recipes
const mockRecipes = [
  {
    id: 1,
    title: 'Quinoa Buddha Bowl',
    description: 'A healthy bowl packed with protein-rich quinoa, roasted vegetables, and tahini dressing',
    image: 'https://images.unsplash.com/photo-1546069901-ba9599a7e63c',
    time: '25 mins',
    difficulty: 'Easy',
    calories: 450,
    tags: ['Vegetarian', 'High-Protein', 'Gluten-Free']
  },
  {
    id: 2,
    title: 'Grilled Salmon with Asparagus',
    description: 'Fresh Atlantic salmon with grilled asparagus and lemon butter sauce',
    image: 'https://images.unsplash.com/photo-1467003909585-2f8a72700288',
    time: '30 mins',
    difficulty: 'Medium',
    calories: 380,
    tags: ['Pescatarian', 'Low-Carb', 'High-Protein']
  },
  {
    id: 3,
    title: 'Mediterranean Pasta',
    description: 'Whole grain pasta with cherry tomatoes, olives, and fresh herbs',
    image: 'https://images.unsplash.com/photo-1473093226795-af9932fe5856',
    time: '20 mins',
    difficulty: 'Easy',
    calories: 420,
    tags: ['Vegetarian', 'Mediterranean']
  },
  {
    id: 4,
    title: 'Chicken Stir-Fry',
    description: 'Quick and healthy chicken stir-fry with mixed vegetables',
    image: 'https://images.unsplash.com/photo-1512058564366-18510be2db19',
    time: '25 mins',
    difficulty: 'Easy',
    calories: 350,
    tags: ['High-Protein', 'Low-Carb']
  },
  // Add more mock recipes as needed
];

const Recipes = () => {
  const [selectedTags, setSelectedTags] = useState([]);
  const allTags = [...new Set(mockRecipes.flatMap(recipe => recipe.tags))];

  const filteredRecipes = selectedTags.length > 0
    ? mockRecipes.filter(recipe => 
        selectedTags.some(tag => recipe.tags.includes(tag))
      )
    : mockRecipes;

  const toggleTag = (tag) => {
    setSelectedTags(prev =>
      prev.includes(tag)
        ? prev.filter(t => t !== tag)
        : [...prev, tag]
    );
  };

  return (
    <div className="container mx-auto px-4 py-8 pb-36">
      {/* Header */}
      <div className="mb-8">
        <h1 className="text-3xl font-semibold text-gray-900 mb-4">Recipes</h1>
        <p className="text-gray-600">Discover healthy and delicious meals curated just for you</p>
      </div>

      {/* Tags filter */}
      <div className="mb-8">
        <div className="flex flex-wrap gap-2">
          {allTags.map(tag => (
            <button
              key={tag}
              onClick={() => toggleTag(tag)}
              className={`px-3 py-1 rounded-full text-sm font-medium transition-colors
                ${selectedTags.includes(tag)
                  ? 'bg-primary text-white'
                  : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                }`}
            >
              {tag}
            </button>
          ))}
        </div>
      </div>

      {/* Recipe Grid */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {filteredRecipes.map(recipe => (
          <motion.div
            key={recipe.id}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="bg-white rounded-lg shadow-card overflow-hidden hover:shadow-lg transition-shadow"
          >
            <div className="relative h-48">
              <img
                src={recipe.image}
                alt={recipe.title}
                className="w-full h-full object-cover"
              />
            </div>
            <div className="p-4">
              <h3 className="text-xl font-semibold text-gray-900 mb-2">{recipe.title}</h3>
              <p className="text-gray-600 text-sm mb-4">{recipe.description}</p>
              
              <div className="flex items-center justify-between mb-4">
                <div className="flex items-center text-sm text-gray-500">
                  <span className="mr-4">{recipe.time}</span>
                  <span>{recipe.difficulty}</span>
                </div>
                <span className="text-sm font-medium text-primary">{recipe.calories} cal</span>
              </div>

              <div className="flex flex-wrap gap-2">
                {recipe.tags.map(tag => (
                  <span
                    key={tag}
                    className="px-2 py-1 bg-gray-100 text-gray-600 text-xs rounded-full"
                  >
                    {tag}
                  </span>
                ))}
              </div>
            </div>
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default Recipes; 
import { db } from '../firebase';
import { 
  collection, 
  doc, 
  setDoc, 
  getDocs, 
  getDoc,
  query, 
  where, 
  orderBy 
} from 'firebase/firestore';

// ============================================================
// CONFIGURATION
// ============================================================
const AI_CONFIG = {
  USE_DEEPSEEK: false,  // Set to false to use OpenAI, true for DeepSeek
  TEMPERATURE: 1,      // 1 for creative responses, lower for more consistent ones
  MAX_TOKENS: 8192     // 8192 for DeepSeek, 2048 for OpenAI
};

export const saveMealPlan = async (userId, mealPlan) => {
  console.log('Saving meal plan:', mealPlan);
  
  // Use userId as the document ID to ensure one meal plan per user
  const mealPlanRef = doc(db, 'mealplans', userId);
  
  // Normalize dates to ensure consistent format
  const normalizedMealPlan = {
    ...mealPlan,
    days: mealPlan.days.map(day => ({
      ...day,
      date: new Date(day.date).toISOString().split('T')[0],
      totalCalories: day.totalCalories || 0,
      macros: {
        protein: day.macros?.protein || 0,
        carbs: day.macros?.carbs || 0,
        fat: day.macros?.fat || 0,
        fiber: day.macros?.fiber || 0
      },
      meals: day.meals.map(meal => ({
        name: meal.name || '',
        description: meal.description || '',
        time: meal.time || '',
        ingredients: Array.isArray(meal.ingredients) ? meal.ingredients : [],
        instructions: Array.isArray(meal.instructions) ? meal.instructions : [],
        tags: Array.isArray(meal.tags) ? meal.tags : [],
        enhancedRecipe: !!meal.enhancedRecipe,
        calories: meal.calories || 0,
        macros: {
          protein: meal.macros?.protein || 0,
          carbs: meal.macros?.carbs || 0,
          fat: meal.macros?.fat || 0,
          fiber: meal.macros?.fiber || 0
        }
      }))
    }))
  };
  
  console.log('Normalized meal plan for saving:', normalizedMealPlan);
  
  await setDoc(mealPlanRef, {
    userId,
    ...normalizedMealPlan,
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString()
  }, { merge: true }); // Use merge: true to update existing document

  // Save each day and its meals
  for (const day of normalizedMealPlan.days) {
    const dayRef = doc(collection(db, `mealplans/${mealPlanRef.id}/days`));
    await setDoc(dayRef, {
      date: day.date,
      summary: day.summary || '',
      totalCalories: day.totalCalories || 0,
      macros: day.macros || { protein: 0, carbs: 0, fat: 0, fiber: 0 }
    });

    // Save meals for the day
    for (const meal of day.meals) {
      const mealRef = doc(collection(db, `mealplans/${mealPlanRef.id}/days/${dayRef.id}/meals`));
      await setDoc(mealRef, {
        ...meal,
        enhancedRecipe: !!meal.enhancedRecipe,
        calories: meal.calories || 0,
        macros: meal.macros || { protein: 0, carbs: 0, fat: 0, fiber: 0 }
      });
    }
  }

  // Dispatch event to notify calendar of update
  window.dispatchEvent(new CustomEvent('mealPlanUpdated'));
  console.log('Meal plan saved and event dispatched');

  return mealPlanRef.id;
};

export const getMealPlan = async (userId) => {
  const mealPlanRef = doc(db, 'mealplans', userId);
  const docSnap = await getDoc(mealPlanRef);
  
  if (!docSnap.exists()) {
    console.log('No meal plan found for user:', userId);
    return null;
  }
  
  const data = docSnap.data();
  console.log('Raw meal plan data:', data);
  
  // Ensure the data has the correct structure
  if (!data.days) {
    console.log('Meal plan has no days array');
    return null;
  }
  
  // Normalize the data structure
  const normalizedDays = data.days.map(day => ({
    date: day.date,
    summary: day.summary || '',
    totalCalories: day.totalCalories || 0,
    macros: {
      protein: day.macros?.protein || 0,
      carbs: day.macros?.carbs || 0,
      fat: day.macros?.fat || 0,
      fiber: day.macros?.fiber || 0
    },
    meals: Array.isArray(day.meals) ? day.meals.map(meal => ({
      name: meal.name || '',
      description: meal.description || '',
      time: meal.time || '',
      ingredients: Array.isArray(meal.ingredients) ? meal.ingredients : [],
      instructions: Array.isArray(meal.instructions) ? meal.instructions : [],
      tags: Array.isArray(meal.tags) ? meal.tags : [],
      enhancedRecipe: !!meal.enhancedRecipe,
      calories: meal.calories || 0,
      macros: {
        protein: meal.macros?.protein || 0,
        carbs: meal.macros?.carbs || 0,
        fat: meal.macros?.fat || 0,
        fiber: meal.macros?.fiber || 0
      }
    })) : []
  }));
  
  console.log('Normalized meal plan:', { days: normalizedDays });
  return { days: normalizedDays };
};

// ============================================================
// DEEPSEEK MEAL PLAN GENERATION
// ============================================================
const generateMealPlanWithDeepseek = async (mealsToGenerate, userPreferences, chatSynthesis) => {
  console.log('Generating meal plan with DeepSeek');
  
  // System prompt with strict output rules and JSON example
  const messages = [
    {
      role: 'system',
      content: `You are a meal planning assistant that outputs ONLY valid JSON. Generate simple meals (around 5 ingredients) for specific time slots with the following STRICT requirements:

1. USER CONTEXT:
${userPreferences ? `- User Food Preferences: ${userPreferences}` : '- Default Preferences: healthy and balanced meals'}
${chatSynthesis ? `- User Dietary Profile: ${chatSynthesis}` : ''}

2. JSON OUTPUT FORMAT:
You must return a valid JSON object matching this exact structure. Here's an example:

{
  "days": [
    {
      "date": "2024-01-20",
      "summary": "A balanced day with protein-rich breakfast, light lunch, healthy snack, and nutritious dinner",
      "totalCalories": 2100,
      "macros": {
        "protein": 125,
        "carbs": 230,
        "fat": 70,
        "fiber": 35
      },
      "meals": [
        {
          "name": "Scrambled Eggs with Spinach",
          "description": "Fluffy scrambled eggs with fresh spinach and feta cheese",
          "tags": ["lc", "gf"],
          "calories": 420,
          "macros": {
            "protein": 22,
            "carbs": 8,
            "fat": 18,
            "fiber": 3
          },
          "ingredients": [
            {
              "name": "eggs",
              "quantity": 2,
              "unit": "large"
            },
            {
              "name": "spinach",
              "quantity": 30,
              "unit": "g"
            },
            {
              "name": "feta cheese",
              "quantity": 30,
              "unit": "g"
            }
          ],
          "time": "08:00"
        }
      ]
    }
  ]
}

3. STRICT RULES:
- Output must be a valid JSON object
- Use ONLY double quotes (") for strings
- All dates must be in "YYYY-MM-DD" format
- All times must be in "HH:MM" 24-hour format
- Quantities must be numbers (not strings)
- Generate ONLY the following meals:
${mealsToGenerate.map(meal => 
  `  - Date: ${meal.date}, Type: ${meal.type}, Time Range: ${meal.timeRange}`
).join('\n')}
- Each meal must have at least 3 ingredients (snacks can have fewer)
- IMPORTANT: Include accurate calorie and macro information for each meal and daily totals
- Snacks should be healthy and nutritious
- IMPORTANT: Follow user's food preferences strictly when provided
- IMPORTANT: Align meals with the user's dietary profile and goals
- IMPORTANT: Include relevant tags for each meal:
  * "k" for keto-friendly meals
  * "lc" for low-carb meals
  * "gf" for gluten-free meals
  * "v" for vegan & vegetarian meals
- Consider recent food context to avoid repetition when available

DO NOT include any explanatory text or markdown formatting.
ONLY return the JSON object.`
    }
  ];

  try {
    console.log('User Context:', {
      preferences: userPreferences,
      chatSynthesis
    });

    // Make request to DeepSeek
    const response = await fetch('https://dscompletions-boc5emstrq-uc.a.run.app', {
      method: 'POST',
      headers: { 
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      body: JSON.stringify({
        messages,
        model: 'deepseek-chat',
        temperature: 1,
        max_tokens: 8192,  // Allow for longer responses
        stream: false,
        response_format: {
          type: 'json_object'  // Force JSON output
        }
      })
    });

    if (!response.ok) {
      const errorText = await response.text();
      console.error('DeepSeek response error:', errorText);
      
      // Parse the error response
      const errorData = JSON.parse(errorText);
      
      // If it's an insufficient balance error and we got a fallback response
      if (errorData.details?.includes('402') && errorData.note?.includes('OpenAI fallback')) {
        console.log('Using OpenAI fallback response');
        return JSON.parse(errorData.response.content);
      }
      
      throw new Error(`DeepSeek service error: ${response.status} ${errorText}`);
    }

    const data = await response.json();
    console.log('Raw DeepSeek response:', data);

    if (!data.response?.content) {
      throw new Error('No content in response');
    }

    // Parse JSON directly
    const parsedContent = JSON.parse(data.response.content);

    // Validate the structure
    if (!Array.isArray(parsedContent.days)) {
      throw new Error('Missing days array');
    }

    return parsedContent;
  } catch (error) {
    console.error('Error in DeepSeek meal plan generation:', error);
    throw error;
  }
};

// ============================================================
// OPENAI MEAL PLAN GENERATION
// ============================================================
const generateMealPlanWithOpenAI = async (mealsToGenerate, userPreferences, chatSynthesis) => {
  console.log('Generating meal plan with OpenAI');
  
  const messages = [
    {
      role: 'system',
      content: `You are a meal planning assistant that outputs ONLY valid JSON. Generate sophisticated, nutritionally balanced meals (4-7 ingredients) for specific time slots with the following STRICT requirements:

1. USER CONTEXT:
${userPreferences ? `- User Food Preferences: ${userPreferences}` : '- Default Preferences: healthy and balanced meals'}
${chatSynthesis ? `- User Dietary Profile: ${chatSynthesis}` : ''}

2. JSON OUTPUT FORMAT:
You must return a valid JSON object matching this exact structure. Here's an example:

{
  "days": [
    {
      "date": "2024-01-20",
      "summary": "A balanced day with protein-rich breakfast, light lunch, healthy snack, and nutritious dinner",
      "totalCalories": 2100,
      "macros": {
        "protein": 125,
        "carbs": 230,
        "fat": 70,
        "fiber": 35
      },
      "meals": [
        {
          "name": "Mediterranean Scrambled Eggs with Feta",
          "description": "Fluffy scrambled eggs with fresh spinach, cherry tomatoes, and crumbled feta cheese",
          "tags": ["lc", "gf", "vegetarian"],
          "calories": 420,
          "macros": {
            "protein": 22,
            "carbs": 8,
            "fat": 18,
            "fiber": 3
          },
          "ingredients": [
            {
              "name": "eggs",
              "quantity": 2,
              "unit": "large"
            },
            {
              "name": "baby spinach",
              "quantity": 30,
              "unit": "g"
            },
            {
              "name": "cherry tomatoes",
              "quantity": 50,
              "unit": "g"
            },
            {
              "name": "feta cheese",
              "quantity": 30,
              "unit": "g"
            },
            {
              "name": "olive oil",
              "quantity": 1,
              "unit": "tsp"
            }
          ],
          "instructions": [
            "Heat olive oil in a non-stick pan over medium heat",
            "Halve cherry tomatoes and add to pan, cook until slightly softened",
            "Add spinach and cook until wilted",
            "Whisk eggs in a bowl, season with salt and pepper",
            "Pour eggs into pan and gently fold until almost set",
            "Crumble feta over eggs and finish cooking"
          ],
          "time": "08:00",
          "prepTime": "10 mins",
          "cookTime": "8 mins",
          "difficulty": "easy"
        }
      ]
    }
  ]
}

3. STRICT RULES:
- Output must be a valid JSON object
- Use ONLY double quotes (") for strings
- All dates must be in "YYYY-MM-DD" format
- All times must be in "HH:MM" 24-hour format
- Quantities must be numbers (not strings)
- Generate ONLY the following meals:
${mealsToGenerate.map(meal => 
  `  - Date: ${meal.date}, Type: ${meal.type}, Time Range: ${meal.timeRange}`
).join('\n')}
- Each meal must have 4-7 ingredients for more sophisticated recipes
- Include accurate calorie and macro information for each meal and daily totals
- Include detailed, step-by-step cooking instructions
- Add prep time, cook time, and difficulty level for each recipe
- Snacks should be nutritious and balanced
- IMPORTANT: Follow user's food preferences strictly when provided
- IMPORTANT: Align meals with the user's dietary profile and goals
- IMPORTANT: Include relevant tags for each meal:
  * "k" for keto-friendly meals
  * "lc" for low-carb meals
  * "gf" for gluten-free meals
  * "v" for vegan & vegetarian meals
  * "df" for dairy-free meals
  * "quick" for meals under 20 mins total time
- Consider recent food context to avoid repetition when available

DO NOT include any explanatory text or markdown formatting.
ONLY return the JSON object.`
    }
  ];

  try {
    const response = await fetch('https://generateairesponse-boc5emstrq-uc.a.run.app/general-chat', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        messages,
        temperature: AI_CONFIG.TEMPERATURE,
        max_tokens: 2048,  // OpenAI has lower token limit
        response_format: {
          type: 'json_object'  // Force JSON output
        }
      })
    });

    if (!response.ok) {
      const errorText = await response.text();
      console.error('OpenAI response error:', errorText);
      throw new Error(`OpenAI service error: ${response.status} ${errorText}`);
    }

    const data = await response.json();
    console.log('Raw OpenAI response:', data);

    if (!data.response?.content) {
      throw new Error('No content in response');
    }

    // Parse JSON directly
    const parsedContent = JSON.parse(data.response.content);

    // Validate the structure
    if (!Array.isArray(parsedContent.days)) {
      throw new Error('Missing days array');
    }

    return parsedContent;
  } catch (error) {
    console.error('Error in OpenAI meal plan generation:', error);
    throw error;
  }
};

// Modify the existing generateMealPlan function to allow switching between OpenAI and DeepSeek
export const generateMealPlan = async (existingMeals = [], userId, options = {}) => {
  const { targetDate, preferences } = options;
  
  console.log('Starting meal plan generation with options:', { targetDate, preferences });
  console.log('Existing meals:', existingMeals);
  
  // Normalize target date to YYYY-MM-DD format
  const normalizedTargetDate = targetDate ? new Date(targetDate).toISOString().split('T')[0] : null;
  
  // If targetDate is provided, we'll only generate meals for that specific day
  let dates;
  if (normalizedTargetDate) {
    dates = [normalizedTargetDate];
    console.log('Generating for specific date:', normalizedTargetDate);
  } else {
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0); // Reset time part for consistent comparison
    
    // Include today and next 3 days
    dates = Array.from({ length: 4 }, (_, i) => {
      const date = new Date(currentDate);
      date.setDate(currentDate.getDate() + i);
      return date.toISOString().split('T')[0];
    });
    console.log('Generating for date range:', dates);
  }

  // Create a map of existing meals with normalized dates
  const existingMealMap = {};
  existingMeals.forEach(day => {
    // Ensure date is in YYYY-MM-DD format
    const dateKey = new Date(day.date).toISOString().split('T')[0];
    console.log(`Processing existing meals for ${dateKey}:`, day.meals?.length || 0);
    
    if (!existingMealMap[dateKey]) {
      existingMealMap[dateKey] = new Set();
    }
    
    if (Array.isArray(day.meals)) {
      day.meals.forEach(m => {
        const timeRange = m.time.split(':')[0];
        let mealType;
        if (timeRange >= '07' && timeRange <= '09') mealType = 'breakfast';
        else if (timeRange >= '12' && timeRange <= '14') mealType = 'lunch';
        else if (timeRange >= '15' && timeRange <= '16') mealType = 'snack';
        else if (timeRange >= '18' && timeRange <= '20') mealType = 'dinner';
        existingMealMap[dateKey].add(mealType);
      });
    }
  });
  
  console.log('Existing meal map:', existingMealMap);

  // Create a list of meals we need to generate
  const mealsToGenerate = [];
  dates.forEach(date => {
    const existingMealsForDay = existingMealMap[date] || new Set();
    console.log(`Checking meals needed for ${date}:`, {
      existingMeals: Array.from(existingMealsForDay),
    });
    
    // For each date, check which meal types we need to generate
    [
      { type: 'breakfast', timeRange: '07:00-09:00' },
      { type: 'lunch', timeRange: '12:00-14:00' },
      { type: 'snack', timeRange: '15:00-16:00' },
      { type: 'dinner', timeRange: '18:00-20:00' }
    ].forEach(({ type, timeRange }) => {
      if (!existingMealsForDay.has(type)) {
        mealsToGenerate.push({ date, type, timeRange });
        console.log(`Adding meal to generate: ${date} - ${type}`);
      } else {
        console.log(`Meal already exists: ${date} - ${type}`);
      }
    });
  });

  console.log('Meals to generate:', mealsToGenerate);

  // If we have no meals to generate, return the existing meal plan
  if (mealsToGenerate.length === 0) {
    console.log('No new meals needed, returning existing plan');
    return { days: existingMeals };
  }

  // Get user preferences and chat synthesis if available
  let userPreferences = preferences || '';
  let chatSynthesis = '';

  try {
    // Generate the meal plan using DeepSeek or fallback to OpenAI
    console.log('Starting AI meal generation with:', {
      provider: AI_CONFIG.USE_DEEPSEEK ? 'DeepSeek' : 'OpenAI',
      mealsCount: mealsToGenerate.length,
      preferences: userPreferences
    });

    const generatedPlan = AI_CONFIG.USE_DEEPSEEK
      ? await generateMealPlanWithDeepseek(mealsToGenerate, userPreferences, chatSynthesis)
      : await generateMealPlanWithOpenAI(mealsToGenerate, userPreferences, chatSynthesis);

    console.log('Generated plan:', generatedPlan);

    // Create a map of existing days for easier merging
    const existingDaysMap = new Map(
      existingMeals.map(day => [new Date(day.date).toISOString().split('T')[0], day])
    );
    console.log('Existing days map:', Object.fromEntries(existingDaysMap));

    // Merge the generated plan with existing meals
    const mergedDays = [];
    
    // First, add all generated days
    generatedPlan.days.forEach(generatedDay => {
      const normalizedDate = new Date(generatedDay.date).toISOString().split('T')[0];
      console.log(`Processing generated day: ${normalizedDate}`, {
        mealCount: generatedDay.meals.length,
        summary: generatedDay.summary
      });

      const existingDay = existingDaysMap.get(normalizedDate);
      
      if (existingDay) {
        console.log(`Merging with existing day: ${normalizedDate}`);
        // Merge the new meals with existing ones
        const existingMealTypes = new Set(
          existingDay.meals.map(m => {
            const hour = parseInt(m.time.split(':')[0]);
            if (hour >= 7 && hour <= 9) return 'breakfast';
            if (hour >= 12 && hour <= 14) return 'lunch';
            if (hour >= 15 && hour <= 16) return 'snack';
            if (hour >= 18 && hour <= 20) return 'dinner';
            return null;
          })
        );

        const mergedMeals = [...existingDay.meals];
        
        generatedDay.meals.forEach(newMeal => {
          const timeHour = parseInt(newMeal.time.split(':')[0]);
          let mealType;
          
          if (timeHour >= 7 && timeHour <= 9) mealType = 'breakfast';
          else if (timeHour >= 12 && timeHour <= 14) mealType = 'lunch';
          else if (timeHour >= 15 && timeHour <= 16) mealType = 'snack';
          else if (timeHour >= 18 && timeHour <= 20) mealType = 'dinner';
          
          if (!existingMealTypes.has(mealType)) {
            console.log(`Adding new meal for ${mealType}: ${newMeal.name || 'Unnamed meal'}`);
            mergedMeals.push(newMeal);
          }
        });

        mergedDays.push({
          ...existingDay,
          date: normalizedDate,
          meals: mergedMeals,
          summary: generatedDay.summary || existingDay.summary
        });
      } else {
        console.log(`Adding new day: ${normalizedDate}`);
        mergedDays.push({
          ...generatedDay,
          date: normalizedDate
        });
      }
    });

    // Add any remaining existing days that weren't in the generated plan
    existingMeals.forEach(day => {
      const normalizedDate = new Date(day.date).toISOString().split('T')[0];
      if (!mergedDays.some(d => d.date === normalizedDate)) {
        console.log(`Adding existing day not in generated plan: ${normalizedDate}`);
        mergedDays.push({
          ...day,
          date: normalizedDate
        });
      }
    });

    // Sort days by date
    mergedDays.sort((a, b) => new Date(a.date) - new Date(b.date));
    
    console.log('Final merged plan:', {
      totalDays: mergedDays.length,
      totalMeals: mergedDays.reduce((sum, day) => sum + day.meals.length, 0),
      days: mergedDays.map(d => ({
        date: d.date,
        mealCount: d.meals.length
      }))
    });

    return { days: mergedDays };
  } catch (error) {
    console.error('Error generating meal plan:', error);
    throw error;
  }
};

export const generateAllMeals = async (userId) => {
  const currentDate = new Date();
  const dates = Array.from({ length: 4 }, (_, i) => {
    const date = new Date(currentDate);
    date.setDate(currentDate.getDate() + i);
    return date.toISOString().split('T')[0];
  });

  // Generate all meals from scratch
  const mealsToGenerate = [];
  dates.forEach(date => {
    mealsToGenerate.push(
      { date, type: 'breakfast', timeRange: '07:00-09:00' },
      { date, type: 'lunch', timeRange: '12:00-14:00' },
      { date, type: 'snack', timeRange: '15:00-16:00' },
      { date, type: 'dinner', timeRange: '18:00-20:00' }
    );
  });

  return generateMealPlan([], userId); // Pass userId to generateMealPlan
};

export const getLoggedMeals = async (userId, startDate, endDate) => {
  const mealsRef = collection(db, 'meals');
  const q = query(
    mealsRef,
    where('userId', '==', userId),
    where('timestamp', '>=', startDate),
    where('timestamp', '<=', endDate)
  );

  const querySnapshot = await getDocs(q);
  return querySnapshot.docs.map(doc => ({
    id: doc.id,
    ...doc.data()
  }));
};

export const getMealPlans = async (userId) => {
  const q = query(
    collection(db, 'mealplans'),
    where('userId', '==', userId),
    orderBy('createdAt', 'desc')
  );

  const snapshot = await getDocs(q);
  return snapshot.docs.map(doc => ({
    id: doc.id,
    ...doc.data()
  }));
};
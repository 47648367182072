import React, { useEffect, useRef, useState } from "react";
import { db, auth } from '../../firebase';
import { collection, addDoc, serverTimestamp, doc, getDoc } from 'firebase/firestore';
import { FaSpinner } from 'react-icons/fa';
import CheckmarkAnimation from './CheckmarkAnimation';

/**
 * AudioRecordingPopup Component
 * 
 * Flow:
 * 1. Opens a popup with audio recording interface
 * 2. Starts recording audio immediately upon opening
 * 3. Shows animated audio visualization bars while recording
 * 4. When user stops recording:
 *    - Converts audio data to blob
 *    - Sends audio blob to transcription endpoint
 *    - Receives text transcription
 *    - Sends transcription to AI endpoint for processing
 *    - Receives AI response
 * 5. Closes on click outside or after processing
 * 
 * API Endpoints Used:
 * - POST /transcribeaudio-boc5emstrq-uc.a.run.app/ (Audio to text)
 * - POST /generateairesponse-boc5emstrq-uc.a.run.app/ (AI processing)
 */
const AudioRecordingPopup = ({ 
    onClose, 
    mediaRecorderRef, 
    streamRef, 
    isProcessing, 
    setIsProcessing 
  }) => {
    const popupRef = useRef(null);
    const [isRecording, setIsRecording] = useState(true);
    const [transcribedText, setTranscribedText] = useState('');
    const [analysisResult, setAnalysisResult] = useState(null);
    const [analysisType, setAnalysisType] = useState(null); // 'mood' or 'meal'
    const [error, setError] = useState(null);
    const [showCheckmark, setShowCheckmark] = useState(false);
    const [userPreferences, setUserPreferences] = useState({ showMealConfirmation: true });
  
    const isMobile = () => {
      return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    };
  
    const getMimeType = () => {
      const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
      const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

      if (isIOS || isSafari) {
        // For Safari, we only try the supported formats
        const formats = [
          'audio/mp4;codecs=mp4a.40.2',
          'audio/mp4'
        ];
        
        for (const format of formats) {
          if (MediaRecorder.isTypeSupported(format)) {
            console.log('Safari using format:', format);
            return format;
          }
        }
        
        return 'audio/mp4';
      }

      // For other browsers
      return MediaRecorder.isTypeSupported('audio/webm') ? 'audio/webm' : 'audio/mp4';
    };
  
    const saveToFirebase = async (analysis, type, transcribedText) => {
      try {
        const user = auth.currentUser;
        if (!user) {
          console.error("No user logged in");
          return;
        }

        const baseData = {
          userId: user.uid,
          timestamp: serverTimestamp(),
          analyzedAt: new Date().toISOString(),
          transcribedText: transcribedText,
          title: analysis.title || '', // Add title to base data
        };

        if (type === 'mood') {
          const moodData = {
            ...baseData,
            feelings: analysis.feelings,
          };

          const moodsRef = collection(db, 'moods');
          const docRef = await addDoc(moodsRef, moodData);
          console.log("Mood saved with ID:", docRef.id);
          return docRef.id;
        } else if (type === 'meal') {
          const mealData = {
            ...baseData,
            ingredients: analysis.ingredients,
            mealType: analysis.mealType
          };

          const mealsRef = collection(db, 'meals');
          const docRef = await addDoc(mealsRef, mealData);
          console.log("Meal saved with ID:", docRef.id);
          return docRef.id;
        }
      } catch (error) {
        console.error("Error saving to Firebase:", error);
        throw error;
      }
    };
  
    const handleAudioData = async (audioBlob) => {
      try {
        setIsProcessing(true);
        console.log("Sending audio data...");
        console.log("Audio Blob Type:", audioBlob.type);
        
        // First get the transcription
        const transcribeResponse = await fetch("https://transcribeaudio-boc5emstrq-uc.a.run.app/", {
          method: "POST",
          headers: {
            'Content-Type': audioBlob.type || 'audio/webm'
          },
          body: audioBlob
        });

        if (!transcribeResponse.ok) {
          const errorText = await transcribeResponse.text();
          throw new Error(`HTTP error! status: ${transcribeResponse.status}, message: ${errorText}`);
        }

        const { transcription } = await transcribeResponse.json();
        console.log("Transcribed Text:", transcription);
        setTranscribedText(transcription);

        // Send to AI endpoint with modified system prompt
        const currentTime = new Date().toISOString();
        const aiResponse = await fetch("https://generateairesponse-boc5emstrq-uc.a.run.app/chat", {
          method: "POST",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            messages: [
              {
                role: "system",
                content: `Analyze the user's message. 
                If it's about their mood or feelings, respond with: {"type": "mood", "feelings": ["feeling1", "feeling2", "feeling3"]} (only distinct feelings, maximum 3 different feelings).
                If it's about food or a meal, respond with: {
                  "type": "meal",
                  "title": "A concise, descriptive title for the meal (3-5 words)",
                  "description": "A brief, informative description of the meal (1-2 sentences)",
                  "mealType": "breakfast|lunch|dinner|snack", // First check if user explicitly mentions meal type (e.g., "for breakfast", "had lunch"). If not mentioned, determine based on time or ingredients: 5-11=breakfast, 11.45-14=lunch, 18-22=dinner, else=snack
                  "ingredients": [
                    {
                      "name": "ingredient",
                      "estimated_grams": number,
                      "category": "category",
                      "kcal_per_100g": number,
                      "protein_g": number,
                      "carbs_g": number,
                      "fat_g": number
                    }
                  ]
                }. 
                Important rules:
                - Title should be catchy and descriptive (e.g., "Grilled Salmon with Quinoa")
                - Description should highlight key ingredients and preparation methods
                - Always use English names for ingredients
                - Capitalize the first letter of each ingredient name
                - Use singular form for ingredient names
                - Standardize common ingredients to their basic form
                - For each ingredient, provide accurate nutritional values per 100g
                - Calculate total kcal based on estimated_grams
                If it's neither about feelings nor about food/meals, respond with: {"type": "unsupported"}.
                For food categories, use only: meat, fish, vegetables, fruits, grains, dairy, legumes, nuts, processed_food, beverages, condiments.`
              },
              {
                role: "user",
                content: `Current time is ${currentTime}. Here's my meal: ${transcription}`
              }
            ]
          }),
        });

        if (!aiResponse.ok) {
          throw new Error(`HTTP error! status: ${aiResponse.status}`);
        }

        const rawResponse = await aiResponse.json();
        let analysis;
        
        try {
          if (typeof rawResponse.response === 'string') {
            const jsonString = rawResponse.response.replace(/```json\n|\n```/g, '');
            analysis = JSON.parse(jsonString);
          } else {
            analysis = rawResponse;
          }

          setAnalysisResult(analysis);
          setAnalysisType(analysis.type);

          // Save to Firebase after successful analysis, passing the transcription directly
          await saveToFirebase(analysis, analysis.type, transcription);

          if (userPreferences.showMealConfirmation) {
            setAnalysisResult(analysis); // Show regular results
          }

        } catch (error) {
          console.error("Error parsing response:", error);
          setAnalysisResult({ error: "Sorry, we couldn't process that message." });
        }

      } catch (error) {
        console.error("Error processing audio:", error);
        if (userPreferences.showMealConfirmation) {
          setAnalysisResult({ error: "An error occurred while processing your message." });
        }
      } finally {
        setIsProcessing(false);
      }
    };
  
    const stopRecording = () => {
      if (mediaRecorderRef.current && mediaRecorderRef.current.state === "recording") {
        try {
          // If meal confirmation is disabled, show checkmark immediately
          if (!userPreferences.showMealConfirmation) {
            setShowCheckmark(true);
          }

          // Continue with processing in the background
          const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
          const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
          const chunks = mediaRecorderRef.current.chunks || [];
          
          mediaRecorderRef.current.onstop = async () => {
            try {
              const mimeType = (isIOS || isSafari) ? 'audio/mp4' : 'audio/webm';
              let audioBlob = new Blob(chunks, { type: mimeType });
              
              if (audioBlob.size < 1000) {
                console.warn('Audio blob seems too small, might be empty');
                return;
              }

              // Process in background
              handleAudioData(audioBlob);
            } catch (error) {
              console.error('Error processing audio:', error);
            }
          };

          if (isIOS || isSafari) {
            mediaRecorderRef.current.requestData();
          }
          mediaRecorderRef.current.stop();
          streamRef.current.getTracks().forEach(track => track.stop());
          
          mediaRecorderRef.current = null;
          streamRef.current = null;
          setIsRecording(false);
        } catch (error) {
          console.error('Error stopping recording:', error);
        }
      }
    };
  
    const startMobileRecording = async () => {
      try {
        const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
        
        // More specific constraints for iOS
        const constraints = {
          audio: {
            echoCancellation: {ideal: true},
            noiseSuppression: {ideal: true},
            autoGainControl: {ideal: true},
            sampleRate: isIOS ? 44100 : undefined,
            channelCount: isIOS ? 1 : undefined,
            // Add these for iOS
            sampleSize: isIOS ? 16 : undefined,
            volume: 1.0,
          }
        };

        console.log('Requesting media with constraints:', constraints);
        const stream = await navigator.mediaDevices.getUserMedia(constraints);
        
        // For iOS Safari, we'll explicitly use mp4
        const mimeType = isIOS ? 'audio/mp4' : getMimeType();
        console.log('Starting mobile recording with mime type:', mimeType);
        
        const options = {
          mimeType,
          audioBitsPerSecond: isIOS ? 128000 : 128000, // Increased for better quality
        };

        console.log('MediaRecorder options:', options);

        // Verify stream is active and has audio tracks
        const audioTracks = stream.getAudioTracks();
        console.log('Audio tracks:', audioTracks.length);
        console.log('Audio track settings:', audioTracks[0].getSettings());
        
        let mediaRecorder;
        try {
          mediaRecorder = new MediaRecorder(stream, options);
        } catch (e) {
          console.warn('Failed to create MediaRecorder with options, trying without mime type');
          mediaRecorder = new MediaRecorder(stream);
        }
        
        mediaRecorderRef.current = mediaRecorder;
        streamRef.current = stream;
        
        // Add data available handler immediately
        mediaRecorder.ondataavailable = (event) => {
          if (event.data?.size > 0) {
            console.log('Valid audio data received');
          }
        };

        // Add error handler
        mediaRecorder.onerror = (event) => {
          console.error('MediaRecorder error:', event);
          setError('Recording error occurred. Please try again.');
        };

        // Request data more frequently on iOS
        if (isIOS) {
          mediaRecorder.start(100); // Get data every 100ms
        } else {
          mediaRecorder.start();
        }
        
        setIsRecording(true);
        setError(null);
      } catch (error) {
        console.error('Error starting mobile recording:', error);
        setError(
          error.name === 'NotAllowedError' 
            ? 'Please grant microphone permission to use this feature.'
            : `Could not start audio recording: ${error.message}`
        );
      }
    };
  
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (popupRef.current && !popupRef.current.contains(event.target)) {
          onClose();
        }
      };
  
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [onClose]);

    useEffect(() => {
      const checkCompatibility = async () => {
        if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
          setError('Audio recording is not supported in this browser.');
          return false;
        }

        // Check if we're on mobile
        if (isMobile()) {
          try {
            // Define getMobileAudioConstraints inside the effect
            const getMobileAudioConstraints = () => ({
              audio: {
                echoCancellation: true,
                noiseSuppression: true,
                autoGainControl: true,
                sampleRate: 44100,
                channelCount: 1
              }
            });

            // Request permission explicitly on mobile
            const stream = await navigator.mediaDevices.getUserMedia(getMobileAudioConstraints());
            stream.getTracks().forEach(track => track.stop()); // Stop the test stream
            return true;
          } catch (err) {
            console.error('Mobile permission error:', err);
            setError('Please grant microphone permission to use this feature.');
            return false;
          }
        }
        return true;
      };

      checkCompatibility();
    }, []); // Now the dependency array is empty as all functions are defined inside

    useEffect(() => {
      const fetchUserPreferences = async () => {
        if (auth.currentUser) {
          const userDoc = await getDoc(doc(db, 'users', auth.currentUser.uid));
          if (userDoc.exists()) {
            setUserPreferences(userDoc.data());
          }
        }
      };
      fetchUserPreferences();
    }, []);

    if (showCheckmark) {
      return <CheckmarkAnimation onComplete={onClose} />;
    }

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50 p-4 overflow-y-auto">
      <div 
        ref={popupRef}
        className={`
          w-[280px] sm:w-80
          min-h-[280px]
          max-h-[90vh]
          bg-white 
          rounded-3xl
          p-6
          relative
          flex flex-col items-center
          my-auto
          before:absolute
          before:inset-[-3px]
          before:-z-10
          before:rounded-3xl
          before:bg-[conic-gradient(from_0deg,#00ffff,#7000ff,#00ff66,#00ffff)]
          after:absolute
          after:inset-[-3px]
          after:-z-20
          after:rounded-3xl
          after:blur-md
          after:bg-[conic-gradient(from_0deg,#00ffff80,#7000ff80,#00ff6680,#00ffff80)]
        `}
      >
        {error ? (
          <div className="text-center p-4">
            <p className="text-red-500 mb-2">{error}</p>
            {isMobile() && (
              <button
                onClick={() => {
                  setError(null);
                  startMobileRecording();
                }}
                className="mt-2 px-4 py-2 bg-gray-800 text-white rounded-lg hover:bg-gray-700 transition-colors"
              >
                Try Again
              </button>
            )}
            <p className="text-sm text-gray-600 mt-2">
              {isMobile() 
                ? 'Please ensure microphone permissions are enabled in your browser settings.'
                : 'Please try using a modern browser like Chrome, Firefox, or Safari.'}
            </p>
          </div>
        ) : (
          <>
            {/* Title and Description */}
            <div className="text-center mb-6">
              <h3 className="text-lg font-semibold text-gray-900 mb-2">
                Track Your Meal
              </h3>
              <p className="text-sm text-gray-600">
                Describe your meal naturally - include ingredients, portions, and how it was prepared.
              </p>
            </div>

            {/* Audio Visualization - made more compact */}
            <div className="flex items-center justify-center gap-1 h-24">
              {[...Array(20)].map((_, i) => (
                <div
                  key={i}
                  className={`w-1 bg-gradient-to-t from-gray-300 via-gray-500 to-gray-600 rounded-full ${
                    isRecording ? "audio-bar-animated" : ""
                  }`}
                  style={{
                    height: isRecording ? `${Math.random() * 100}%` : "2px",
                    animationDelay: isRecording ? `${i * 0.05}s` : undefined
                  }}
                />
              ))}
            </div>

            {/* Record/Stop Button - improved for mobile */}
            <button
              onClick={isMobile() ? stopRecording : stopRecording}
              onTouchEnd={isMobile() ? stopRecording : undefined}
              disabled={isProcessing}
              className={`
                mt-4
                w-14 h-14 
                rounded-full 
                bg-white
                flex items-center justify-center
                transition-opacity duration-200
                active:scale-95
                ${isProcessing ? 'opacity-50 cursor-not-allowed' : 'hover:opacity-80'}
              `}
            >
              {isProcessing ? (
                <FaSpinner className="w-6 h-6 text-gray-600 animate-spin" />
              ) : (
                <div className="w-6 h-6 bg-gray-600 rounded-lg" />
              )}
            </button>

            {/* Recording Status */}
            <div className="mt-2 text-sm text-gray-500">
              {isProcessing ? 'Processing your message...' : (isRecording ? 'Tap to stop recording' : 'Recording stopped')}
            </div>

            {/* Transcribed Text - only show for mood or before analysis */}
            {transcribedText && (!analysisResult || analysisType === 'mood') && (
              <div className="
                mt-4
                max-h-[200px]
                overflow-y-auto
                p-3
                bg-gray-100
                rounded-lg
                w-full
                text-gray-700
                text-sm
              ">
                {transcribedText}
              </div>
            )}

            {/* Analysis Results - with titles */}
            {analysisResult && !isProcessing && (
              <div className="w-full mt-4">
                {analysisResult.error ? (
                  <div className="bg-gray-50 p-4 rounded-lg">
                    <p className="text-gray-800 text-center">{analysisResult.error}</p>
                  </div>
                ) : analysisType === 'unsupported' ? (
                  <div className="bg-gray-50 p-4 rounded-lg text-center">
                    <p className="text-gray-800 font-medium mb-1">Unsupported Message Type</p>
                    <p className="text-gray-600 text-sm">We currently only support messages about feelings and meals.</p>
                  </div>
                ) : analysisType === 'mood' ? (
                  <div className="space-y-2">
                    <h4 className="text-sm font-medium text-gray-600">Your Feelings</h4>
                    <div className="flex flex-wrap gap-2">
                      {analysisResult.feelings.map((feeling, index) => (
                        <span
                          key={index}
                          className="px-3 py-1 bg-gradient-to-r from-purple-500 to-pink-500 text-white rounded-full text-sm"
                        >
                          {feeling}
                        </span>
                      ))}
                    </div>
                  </div>
                ) : analysisType === 'meal' && (
                  <div className="space-y-3">
                    <div>
                      <h4 className="text-sm font-medium text-gray-600 mb-2">Your Message</h4>
                      <div className="bg-gray-50 p-3 rounded-lg">
                        <p className="text-gray-800">{transcribedText}</p>
                      </div>
                    </div>

                    <div>
                      <h4 className="text-sm font-medium text-gray-600 mb-2">Meal Title</h4>
                      <div className="bg-gray-50 p-3 rounded-lg">
                        <p className="text-gray-800">{analysisResult.title}</p>
                      </div>
                    </div>

                    <div>
                      <h4 className="text-sm font-medium text-gray-600 mb-2">Meal Description</h4>
                      <div className="bg-gray-50 p-3 rounded-lg">
                        <p className="text-gray-800">{analysisResult.description}</p>
                      </div>
                    </div>

                    <div>
                      <h4 className="text-sm font-medium text-gray-600 mb-2">Meal Type</h4>
                      <div className="bg-gray-50 p-3 rounded-lg">
                        <p className="text-gray-800">{analysisResult.mealType}</p>
                      </div>
                    </div>

                    <div>
                      <h4 className="text-sm font-medium text-gray-600 mb-2">Ingredients</h4>
                      <div className="bg-gray-50 p-3 rounded-lg">
                        <ul className="space-y-2">
                          {analysisResult.ingredients.map((ing, index) => (
                            <li key={index} className="flex justify-between items-center">
                              <span className="text-gray-800">{ing.name}</span>
                              <div className="text-right">
                                <span className="text-sm text-gray-500">{ing.category}</span>
                                <span className="ml-2 text-gray-800">{ing.estimated_grams}g</span>
                              </div>
                            </li>
                          ))}
                        </ul>
                        <div className="mt-4 pt-3 border-t border-gray-200 space-y-2">
                          <div className="flex justify-between items-center font-medium">
                            <span>Total Calories:</span>
                            <span>
                              {analysisResult.ingredients.reduce((total, ing) => 
                                total + Math.round(ing.kcal_per_100g * ing.estimated_grams / 100), 0)
                              } kcal
                            </span>
                          </div>
                          <div className="flex justify-between items-center text-sm text-gray-600">
                            <span>Total Macros:</span>
                            <div className="flex gap-3">
                              <span>P: {Math.round(analysisResult.ingredients.reduce((total, ing) => 
                                total + (ing.protein_g * ing.estimated_grams / 100), 0))}g</span>
                              <span>C: {Math.round(analysisResult.ingredients.reduce((total, ing) => 
                                total + (ing.carbs_g * ing.estimated_grams / 100), 0))}g</span>
                              <span>F: {Math.round(analysisResult.ingredients.reduce((total, ing) => 
                                total + (ing.fat_g * ing.estimated_grams / 100), 0))}g</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default AudioRecordingPopup; 
import React from 'react';
import { XMarkIcon, ClockIcon, FireIcon, SparklesIcon } from '@heroicons/react/24/outline';

const HomeMealPopup = ({ meal, onClose }) => {
  if (!meal) return null;

  // Helper function to get ingredients from either meal.recipe or meal directly
  const getIngredients = () => {
    if (meal.recipe?.ingredients) return meal.recipe.ingredients;
    if (meal.ingredients) return meal.ingredients;
    return null;
  };

  // Helper function to get instructions from either meal.recipe or meal directly
  const getInstructions = () => {
    if (meal.recipe?.instructions) return meal.recipe.instructions;
    if (meal.instructions) return meal.instructions;
    return null;
  };

  const ingredients = getIngredients();
  const instructions = getInstructions();

  return (
    // Overlay with backdrop blur
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black/50 backdrop-blur-sm">
      {/* Popup container */}
      <div className="relative w-full max-w-3xl max-h-[90vh] overflow-y-auto bg-white dark:bg-gray-800 rounded-lg shadow-xl m-4">
        {/* Close button */}
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
        >
          <XMarkIcon className="w-6 h-6" />
        </button>

        {/* Content */}
        <div className="p-6">
          {/* Header with meal type and time */}
          <div className="mb-6">
            <div className="flex items-center gap-2 mb-2">
              <h2 className="text-2xl font-semibold text-gray-900 dark:text-white">
                {meal.mealType.charAt(0).toUpperCase() + meal.mealType.slice(1)}
              </h2>
              {meal.enhancedRecipe && (
                <span 
                  className="inline-flex items-center justify-center w-6 h-6 bg-gradient-to-r from-violet-600 via-fuchsia-500 to-pink-500 rounded-full"
                  title="AI Enhanced Recipe"
                >
                  <SparklesIcon className="w-4 h-4 text-white" />
                </span>
              )}
            </div>
            <p className="text-sm text-gray-500 dark:text-gray-400">
              Scheduled for {meal.time}
            </p>
          </div>

          {/* Image if available */}
          {meal.imageUrl && (
            <div className="mb-6 rounded-lg overflow-hidden">
              <img
                src={meal.imageUrl}
                alt={meal.description}
                className="w-full h-64 object-cover"
              />
            </div>
          )}

          {/* Description */}
          {meal.description && (
            <div className="mb-6">
              <h3 className="text-xl font-medium text-gray-900 dark:text-white mb-2">
                {meal.description}
              </h3>
            </div>
          )}

          {/* Quick info */}
          {(meal.recipe || meal.prepTime || meal.calories) && (
            <div className="grid grid-cols-2 gap-4 mb-6">
              <div className="flex items-center gap-2 text-gray-600 dark:text-gray-300">
                <ClockIcon className="w-5 h-5" />
                <span>{meal.recipe?.prepTime || meal.prepTime || '30 mins'}</span>
              </div>
              <div className="flex items-center gap-2 text-gray-600 dark:text-gray-300">
                <FireIcon className="w-5 h-5" />
                <span>{meal.recipe?.calories || meal.calories || '400 kcal'}</span>
              </div>
            </div>
          )}

          {/* Tags */}
          {meal.tags && meal.tags.length > 0 && (
            <div className="flex flex-wrap gap-2 mb-6">
              {meal.tags.map((tag) => (
                <span
                  key={tag}
                  className="px-3 py-1 bg-gray-100 dark:bg-gray-700 text-gray-600 dark:text-gray-300 rounded-full text-sm"
                >
                  {tag === 'k' ? 'Keto' :
                   tag === 'lc' ? 'Low Carb' :
                   tag === 'gf' ? 'Gluten Free' :
                   tag === 'v' ? 'Vegan' :
                   tag === 'vg' ? 'Vegetarian' : tag}
                </span>
              ))}
            </div>
          )}

          {/* Ingredients */}
          {ingredients && (
            <div className="mb-8">
              <h3 className="text-lg font-semibold mb-4 text-gray-900 dark:text-white">
                Ingredients
              </h3>
              <ul className="grid grid-cols-1 md:grid-cols-2 gap-3">
                {ingredients.map((ingredient, index) => {
                  // Handle both string and object formats
                  const ingredientText = typeof ingredient === 'string' 
                    ? ingredient 
                    : `${ingredient.quantity || ''} ${ingredient.unit || ''} ${ingredient.name}`.trim();
                  
                  return (
                    <li
                      key={index}
                      className="flex items-center gap-2 text-gray-600 dark:text-gray-300"
                    >
                      <span className="w-1.5 h-1.5 bg-primary rounded-full"></span>
                      {ingredientText}
                    </li>
                  );
                })}
              </ul>
            </div>
          )}

          {/* Instructions */}
          {instructions && (
            <div className="mb-8">
              <h3 className="text-lg font-semibold mb-4 text-gray-900 dark:text-white">
                Instructions
              </h3>
              <ol className="space-y-4">
                {instructions.map((instruction, index) => (
                  <li
                    key={index}
                    className="flex gap-4 text-gray-600 dark:text-gray-300"
                  >
                    <span className="flex-shrink-0 w-6 h-6 bg-primary/10 rounded-full flex items-center justify-center text-primary font-medium">
                      {index + 1}
                    </span>
                    <span>{instruction}</span>
                  </li>
                ))}
              </ol>
            </div>
          )}

          {/* Nutritional Information */}
          {meal.nutritionalInfo && (
            <div className="rounded-lg bg-gray-50 dark:bg-gray-700 p-6">
              <h3 className="text-lg font-semibold mb-4 text-gray-900 dark:text-white">
                Nutritional Information
              </h3>
              <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
                <div>
                  <p className="text-sm text-gray-500 dark:text-gray-400">Calories</p>
                  <p className="font-medium text-gray-900 dark:text-white">{meal.nutritionalInfo.calories}</p>
                </div>
                <div>
                  <p className="text-sm text-gray-500 dark:text-gray-400">Protein</p>
                  <p className="font-medium text-gray-900 dark:text-white">{meal.nutritionalInfo.protein}</p>
                </div>
                <div>
                  <p className="text-sm text-gray-500 dark:text-gray-400">Carbs</p>
                  <p className="font-medium text-gray-900 dark:text-white">{meal.nutritionalInfo.carbs}</p>
                </div>
                <div>
                  <p className="text-sm text-gray-500 dark:text-gray-400">Fat</p>
                  <p className="font-medium text-gray-900 dark:text-white">{meal.nutritionalInfo.fat}</p>
                </div>
              </div>
            </div>
          )}

          {/* Nutritional Benefits */}
          {(meal.recipe?.nutritionalBenefits || meal.nutritionalBenefits) && (
            <div className="mt-8">
              <h3 className="text-lg font-semibold mb-4 text-gray-900 dark:text-white">
                Health Benefits
              </h3>
              <ul className="space-y-2">
                {(meal.recipe?.nutritionalBenefits || meal.nutritionalBenefits).map((benefit, index) => (
                  <li
                    key={index}
                    className="flex items-start gap-2 text-gray-600 dark:text-gray-300"
                  >
                    <span className="text-primary">•</span>
                    {benefit}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default HomeMealPopup; 
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import NavigationManager from './components/Layout/NavigationManager';
import Footer from './components/Layout/Footer';
import Home from './pages/Home';
import MyMeals from './pages/MyMeals';
import Account from './pages/Account';
import Login from './pages/account/Login';
import Signup from './pages/account/Signup';
import Statistics from './pages/Statistics';
import Chatbot from './pages/Chatbot';
import Landing from './pages/Landing';
import Landing1 from './pages/Landing1';
import Landing2 from './pages/Landing2';
import Landing3 from './pages/Landing3';
import OnboardingFlow from './components/onboarding/OnboardingFlow';
import { AuthProvider } from './contexts/AuthContext';
import { PWAProvider } from './contexts/PWAContext';
import ProtectedRoute from './components/ProtectedRoute';
import AdminRoute from './components/AdminRoute';
import PublicRoute from './components/PublicRoute';
import LandingRoute from './components/LandingRoute';
import ShoppingList from './pages/ShoppingList';
import Recipes from './pages/Recipes';
import WaitlistAdmin from './pages/admin/Waitlist';
import Cookies from 'js-cookie';
import FloatingActionButtonWrapper from './components/common/FloatingActionButtonWrapper';
import InstallPWA from './components/common/InstallPWA';

const App = () => {
  useEffect(() => {
    // Check for landing page preference cookie on initial load
    const landingPageType = Cookies.get('landingPageType');
    if (landingPageType && window.location.pathname === '/') {
      // Only redirect if we're on the root path
      switch(landingPageType) {
        case 'landing1':
          window.location.href = '/lp-cycle-nutrition';
          break;
        case 'landing2':
          window.location.href = '/lp-microbiome';
          break;
        case 'landing3':
          window.location.href = '/lp-longevity-nutrition';
          break;
        default:
          // Default landing page
          window.location.href = '/landing';
      }
    }
  }, []);

  return (
    <AuthProvider>
      <PWAProvider>
        <Router>
          <div className="font-sans font-light bg-background flex flex-col min-h-screen">
            <Toaster 
              position="top-center"
              toastOptions={{
                duration: 3000,
                style: {
                  background: '#363636',
                  color: '#fff',
                },
                success: {
                  duration: 3000,
                  iconTheme: {
                    primary: '#68D391',
                    secondary: '#fff',
                  },
                },
                error: {
                  duration: 4000,
                  iconTheme: {
                    primary: '#F56565',
                    secondary: '#fff',
                  },
                },
              }}
            />
            <InstallPWA />
            <Routes>
              {/* Public Routes - redirect to /home if logged in */}
              <Route path="/login" element={
                <PublicRoute>
                  <Login />
                </PublicRoute>
              } />
              <Route path="/signup" element={
                <PublicRoute>
                  <Signup />
                </PublicRoute>
              } />
              <Route path="/" element={
                <PublicRoute>
                  <Landing />
                </PublicRoute>
              } />
              <Route path="/lp-cycle-nutrition" element={
                <LandingRoute>
                  <Landing1 />
                </LandingRoute>
              } />
              <Route path="/lp-microbiome" element={
                <LandingRoute>
                  <Landing2 />
                </LandingRoute>
              } />
              <Route path="/lp-longevity-nutrition" element={
                <LandingRoute>
                  <Landing3 />
                </LandingRoute>
              } />
              
              {/* Protected Routes - wrapped in ProtectedRoute */}
              <Route
                path="/home"
                element={
                  <ProtectedRoute>
                    <div className="flex flex-col h-screen">
                      <NavigationManager />
                      <main className="flex-grow mb-24 md:mb-0">
                        <Home />
                      </main>
                      <Footer className="hidden md:block" />
                    </div>
                  </ProtectedRoute>
                }
              />
              <Route
                path="/statistics"
                element={
                  <ProtectedRoute>
                    <div className="flex flex-col h-screen">
                      <NavigationManager />
                      <main className="flex-grow mb-24 md:mb-0">
                        <Statistics />
                      </main>
                      <Footer className="hidden md:block" />
                    </div>
                  </ProtectedRoute>
                }
              />
              <Route
                path="/shopping-list"
                element={
                  <ProtectedRoute>
                    <div className="flex flex-col h-screen">
                      <NavigationManager />
                      <main className="flex-grow mb-24 md:mb-0">
                        <ShoppingList />
                      </main>
                      <Footer className="hidden md:block" />
                    </div>
                  </ProtectedRoute>
                }
              />
              <Route
                path="/recipes"
                element={
                  <ProtectedRoute>
                    <div className="flex flex-col h-screen">
                      <NavigationManager />
                      <main className="flex-grow mb-24 md:mb-0">
                        <Recipes />
                      </main>
                      <Footer className="hidden md:block" />
                    </div>
                  </ProtectedRoute>
                }
              />
              <Route
                path="/meals"
                element={
                  <ProtectedRoute>
                    <div className="flex flex-col h-screen">
                      <NavigationManager />
                      <main className="flex-grow mb-24 md:mb-0">
                        <MyMeals />
                      </main>
                      <Footer className="hidden md:block" />
                    </div>
                  </ProtectedRoute>
                }
              />
              <Route
                path="/account"
                element={
                  <ProtectedRoute>
                    <div className="flex flex-col h-screen">
                      <NavigationManager />
                      <main className="flex-grow mb-24 md:mb-0">
                        <Account />
                      </main>
                      <Footer className="hidden md:block" />
                    </div>
                  </ProtectedRoute>
                }
              />
              <Route
                path="/chat"
                element={
                  <ProtectedRoute>
                    <div className="flex flex-col h-screen">
                      <main className="flex-grow">
                        <Chatbot />
                      </main>
                    </div>
                  </ProtectedRoute>
                }
              />

              {/* Admin Routes */}
              <Route
                path="/admin/waitlist"
                element={
                  <ProtectedRoute>
                    <AdminRoute>
                      <div className="flex flex-col h-screen">
                        <NavigationManager />
                        <main className="flex-grow mb-16 md:mb-0">
                          <WaitlistAdmin />
                        </main>
                        <Footer className="hidden md:block" />
                      </div>
                    </AdminRoute>
                  </ProtectedRoute>
                }
              />

              {/* Onboarding Route */}
              <Route
                path="/onboarding"
                element={
                  <ProtectedRoute>
                    <OnboardingFlow />
                  </ProtectedRoute>
                }
              />

              {/* Catch all other routes and redirect to home if logged in, or landing if not */}
              <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
            <FloatingActionButtonWrapper />
          </div>
        </Router>
      </PWAProvider>
    </AuthProvider>
  );
};

export default App;

import React, { useEffect } from 'react';
import { FiCheck } from 'react-icons/fi';

const CheckmarkAnimation = ({ onComplete }) => {
  useEffect(() => {
    // Wait for animation to complete before triggering onComplete
    const timer = setTimeout(() => {
      onComplete();
    }, 1000); // Match this with the CSS animation duration

    return () => clearTimeout(timer);
  }, [onComplete]);

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
      <div className="animate-checkmark-popup">
        <FiCheck className="w-20 h-20 text-green-500" />
      </div>
    </div>
  );
};

export default CheckmarkAnimation; 
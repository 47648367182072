import React from 'react';
import FloatingActionButton from './FloatingActionButton';
import { usePWA } from '../../contexts/PWAContext';

const FloatingActionButtonWrapper = () => {
  const { isMobile } = usePWA();

  // Don't render on mobile devices
  if (isMobile) return null;

  return <FloatingActionButton />;
};

export default FloatingActionButtonWrapper; 
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { db } from '../firebase';

/**
 * Consolidate tags using GPT to ensure semantic uniqueness
 * For example: a person cannot have two different heights at the same time
 */
const consolidateTagsWithGPT = async (existingTags, newContent, category) => {
  console.log(' Consolidating tags:', { existingTags, newContent, category });
  
  try {
    const messages = [
      {
        role: 'system',
        content: `You are a tag consolidation assistant. Your task is to combine existing tags with new content.
Rules:
1. Return ONLY a JSON array of strings
2. Keep all existing tags unless they conflict
3. Format should be ["tag1: value1", "tag2: value2"]
4. Do not add explanatory text
5. Maintain the exact format of existing tags`
      },
      {
        role: 'user',
        content: `Existing tags: ${JSON.stringify(existingTags)}
New content: ${newContent}
Category: ${category}

Return a JSON array of consolidated tags. ONLY return the JSON array, no other text.`
      }
    ];

    console.log(' Sending to GPT:', messages);
    
    const response = await fetch('https://generateairesponse-boc5emstrq-uc.a.run.app/foodwise-chatbot', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      body: JSON.stringify({ messages })
    });

    const data = await response.json();
    console.log(' Full GPT response data:', data);
    console.log(' Response success:', data.success);
    console.log(' Response content:', data.response);

    if (!data.success || !data.response?.content) {
      throw new Error('Invalid GPT response');
    }

    // Extract JSON array from response
    let responseText = data.response.content;
    console.log(' Raw response text:', responseText);

    // Clean up the response if it contains markdown or explanatory text
    if (responseText.includes('[')) {
      const match = responseText.match(/\[(.*?)\]/s);
      if (match) {
        responseText = `[${match[1]}]`;
      }
    }

    // Parse the JSON array
    const consolidatedTags = JSON.parse(responseText);
    console.log(' Parsed consolidated tags:', consolidatedTags);

    if (!Array.isArray(consolidatedTags)) {
      throw new Error('GPT response is not an array');
    }

    return consolidatedTags;
  } catch (error) {
    console.error(' Failed to parse GPT response:', error);
    // Fallback: keep existing tags and add new content
    const newTags = [...existingTags];
    if (!newTags.includes(newContent)) {
      newTags.push(newContent);
    }
    return newTags;
  }
};

/**
 * Parse a timed preference using GPT to extract both content and end date
 */
const parseTimedPreference = async (content, duration, endDate) => {
  console.log(' Parsing timed preference:', { content, duration, endDate });
  
  try {
    const timestamp = new Date().toISOString();
    const messages = [
      {
        role: 'system',
        content: 'You are a natural language parser for user preferences and durations. Parse the input and return a JSON object with content and endDate fields.',
        timestamp
      },
      {
        role: 'user',
        content: `Parse this preference: "${content}". 
${duration ? `Duration: ${duration}` : `End date: ${endDate}`}

Current date: 2025-01-06
Example outputs:
{"content": "eat low carb", "endDate": "2025-08-15T00:00:00.000Z"}  // "eat low carb until mid August"
{"content": "gluten free", "endDate": "2025-01-15T00:00:00.000Z"}   // "gluten free until January 15th"
{"content": "keto diet", "endDate": "2025-01-27T00:00:00.000Z"}     // "keto diet for 3 weeks"

Rules:
1. Clean up content: remove unnecessary words, standardize terms
2. Parse date format:
   - If duration given (e.g., "3 weeks"): add to current date
   - If end date given: parse into ISO format
3. Return ISO date string for endDate
4. IMPORTANT: Use 2025-01-06 as the current date`,
        timestamp
      }
    ];

    console.log(' Sending to GPT:', messages);

    const response = await fetch('https://generateairesponse-boc5emstrq-uc.a.run.app/foodwise-chatbot', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      body: JSON.stringify({
        messages: messages.map(msg => ({
          role: msg.role,
          content: [{
            type: "text",
            text: msg.content
          }],
          timestamp: msg.timestamp
        })),
        currentMessage: {
          role: 'user',
          content: [{
            type: "text",
            text: messages[messages.length - 1].content
          }],
          timestamp
        }
      })
    });

    if (!response.ok) {
      throw new Error(`GPT request failed: ${response.status}`);
    }

    const data = await response.json();
    console.log(' Raw GPT response:', data);
    
    try {
      // The response might be wrapped in markdown code blocks, so we need to clean it
      const responseText = data.response?.content || '{}';
      console.log(' GPT response content:', responseText);
      
      // Clean up markdown formatting if present
      let jsonText = responseText;
      if (responseText.includes('```')) {
        // Extract JSON from markdown code block
        const match = responseText.match(/```(?:json)?\s*(\{[\s\S]*?\})\s*```/);
        if (match) {
          jsonText = match[1];
        }
      }
      
      console.log(' Cleaned JSON text:', jsonText);
      const parsed = JSON.parse(jsonText);
      console.log(' Parsed response:', parsed);
      
      if (!parsed.content || !parsed.endDate) {
        throw new Error('Invalid GPT response format');
      }
      
      // Clean up the endDate format - ensure we just get YYYY-MM-DD
      const endDate = new Date(parsed.endDate).toISOString().split('T')[0];
      
      return {
        content: parsed.content,
        endDate
      };
    } catch (error) {
      console.error(' Failed to parse GPT response:', error);
      // Fallback: use original values and default duration
      const fallbackDate = new Date('2025-01-08T21:10:28+01:00');
      fallbackDate.setDate(fallbackDate.getDate() + 14); // 2 weeks default
      return {
        content,
        endDate: fallbackDate.toISOString().split('T')[0]
      };
    }
  } catch (error) {
    console.error(' Failed to parse timed preference:', error);
    // Fallback: use original values and default duration
    const fallbackDate = new Date('2025-01-08T21:10:28+01:00');
    fallbackDate.setDate(fallbackDate.getDate() + 14); // 2 weeks default
    return {
      content,
      endDate: fallbackDate.toISOString().split('T')[0]
    };
  }
};

/**
 * Update permanent tags (stored in assistantMemory collection)
 */
export const updatePermanentTags = async (userId, category, content) => {
  console.log(' Starting permanent tag update:', { category, content });
  
  try {
    const memoryDocRef = doc(db, 'assistantMemory', userId);
    const memoryDoc = await getDoc(memoryDocRef);
    const currentTags = memoryDoc.exists() ? memoryDoc.data().tags || [] : [];
    
    console.log(' Current tags:', currentTags);

    // Special handling for health metrics
    if (category === 'health_conditions') {
      const userDocRef = doc(db, 'users', userId);
      if (content.includes('height:') || content.includes('weight:')) {
        const metric = content.includes('height:') ? 'height' : 'weight';
        const value = content.split(':')[1].trim();
        
        console.log(' Updating health metric:', { metric, value });
        
        // Update user profile
        await setDoc(userDocRef, {
          healthMetrics: { [metric]: value }
        }, { merge: true });
      }
    }

    // Format content based on category
    let formattedContent = content;
    if (category === 'goals') {
      formattedContent = `goal: ${content}`;
    }
    
    console.log(' Formatted content:', formattedContent);

    // Consolidate tags with GPT
    const consolidatedTags = await consolidateTagsWithGPT(currentTags, formattedContent, category);
    console.log(' Consolidated tags:', consolidatedTags);

    // Update Firestore
    await setDoc(memoryDocRef, {
      tags: consolidatedTags,
      updatedAt: new Date().toISOString()
    }, { merge: true });

    // Return only the new tags (ones that weren't in currentTags)
    const newTags = consolidatedTags.filter(tag => !currentTags.includes(tag));
    console.log(' New tags to return:', newTags);
    
    return newTags;
  } catch (error) {
    console.error(' Failed to update permanent tags:', error);
    throw error;
  }
};

/**
 * Update timed preferences (stored in timedPreferences collection)
 */
export const updateTimedPreference = async (userId, category, content, duration, endDate) => {
  console.log(' Starting timed preference update:', { category, content, duration, endDate });
  
  try {
    const prefsDocRef = doc(db, 'timedPreferences', userId);
    const prefsDoc = await getDoc(prefsDocRef);
    const currentPrefs = prefsDoc.exists() ? prefsDoc.data().preferences || [] : [];
    
    console.log(' Current preferences:', currentPrefs);

    // Parse the preference and duration using GPT
    const { content: parsedContent, endDate: parsedEndDate } = await parseTimedPreference(content, duration, endDate);
    console.log(' Parsed preference:', { parsedContent, parsedEndDate });

    // Create new preference object
    const newPref = {
      category,
      content: parsedContent,
      endDate: parsedEndDate,
      createdAt: new Date('2025-01-06').toISOString()
    };

    // First, remove expired preferences
    const activePrefs = currentPrefs.filter(pref => new Date(pref.endDate) > new Date('2025-01-06'));

    // Then, remove semantically duplicate preferences
    const uniquePrefs = activePrefs.filter(pref => {
      // Direct duplicates
      if (pref.content === parsedContent) return false;

      // Similar dietary preferences
      const isDietaryPref = (text) => /eat|carb|gluten|dairy|vegan|vegetarian|protein/i.test(text);
      if (isDietaryPref(pref.content) && isDietaryPref(parsedContent)) {
        // Extract the key concept (e.g., "low carb" from "eat low carb")
        const normalize = (text) => text.toLowerCase().replace(/^(eat|follow|do|have)\s+/, '');
        const prefConcept = normalize(pref.content);
        const newConcept = normalize(parsedContent);
        
        // If they're talking about the same thing (e.g., carbs, gluten, etc.)
        const sameType = (a, b) => {
          const types = [
            /carb/i,
            /gluten/i,
            /dairy|lactose/i,
            /vegan|vegetarian/i,
            /protein/i
          ];
          return types.some(type => type.test(a) && type.test(b));
        };
        
        return !sameType(prefConcept, newConcept);
      }

      // Keep preferences that are semantically different
      return true;
    });

    // Add the new preference
    uniquePrefs.push(newPref);
    
    console.log(' Final preferences:', uniquePrefs);

    // Update Firestore
    await setDoc(prefsDocRef, {
      preferences: uniquePrefs,
      updatedAt: new Date('2025-01-06').toISOString()
    }, { merge: true });
  } catch (error) {
    console.error(' Failed to update timed preference:', error);
    throw error;
  }
};

import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { HiOutlineChartBar, HiOutlineCamera, HiChevronDown, HiChevronUp } from 'react-icons/hi';
import { MdOutlineRestaurantMenu } from 'react-icons/md';
import { RiHealthBookLine, RiTimeLine } from 'react-icons/ri';
import { GiBrain } from 'react-icons/gi';
import { useAuth } from '../contexts/AuthContext';

console.log("landing.js");

const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="border border-border rounded-lg overflow-hidden">
      <button
        className="w-full px-6 py-4 text-left bg-background hover:bg-background/50 flex justify-between items-center"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="text-lg font-semibold">{question}</span>
        {isOpen ? (
          <HiChevronUp className="w-6 h-6 text-primary" />
        ) : (
          <HiChevronDown className="w-6 h-6 text-primary" />
        )}
      </button>
      {isOpen && (
        <div className="px-6 py-4 bg-white">
          <p className="text-text-light">{answer}</p>
        </div>
      )}
    </div>
  );
};

const Landing = () => {
  const navigate = useNavigate();
  const { currentUser } = useAuth();

  useEffect(() => {
    if (currentUser) {
      navigate('/home');
    }
  }, [currentUser, navigate]);

  const faqs = [
    {
      question: "How is Foodwise different from other nutrition apps?",
      answer: "Foodwise combines cutting-edge AI technology with evidence-based nutrition science to provide personalized recommendations. Unlike traditional apps that just count calories, we focus on optimizing your nutrition for longevity, cognitive performance, and overall wellbeing."
    },
    {
      question: "Will this work with my busy lifestyle?",
      answer: "Absolutely! Foodwise is designed for busy professionals. Our AI-powered image recognition makes tracking meals effortless, and our recommendations adapt to your schedule and preferences."
    },
    {
      question: "How quickly will I see results?",
      answer: "Many users report improved energy levels and mental clarity within the first week. Physical changes typically become noticeable within 2-4 weeks of consistent use, but the long-term benefits for longevity and health continue to accumulate over time."
    },
    {
      question: "Is the nutrition advice based on scientific research?",
      answer: "Yes! Our recommendations are based on peer-reviewed research in nutrition science and longevity studies. We continuously update our knowledge base with the latest scientific findings to ensure you're getting the most current, evidence-based guidance."
    }
  ];

  return (
    <div className="w-full overflow-x-hidden">
      {/* Hero Section */}
      <div className="relative bg-gradient-to-b from-primary/5 to-transparent">
        <div className="max-w-7xl mx-auto px-6 sm:px-8 lg:px-12">
          <div className="flex flex-col lg:flex-row items-center gap-12 pt-20 pb-28">
            <div className="lg:w-1/2 text-left">
              <h1 className="text-4xl sm:text-5xl font-bold text-text-dark mb-6 leading-tight">
                Foodwise - Intelligent Nutrition for your best Life
              </h1>
              <p className="text-lg sm:text-xl text-text-light mb-8">
                Unlock your full potential with Foodwise - where cutting-edge nutrition science meets 
                longevity research to help you achieve peak performance, optimal health, and increased lifespan.
              </p>
              {/* Auth Buttons */}
              <div className="flex flex-col sm:flex-row gap-4 w-full max-w-md">
                <Link
                  to="/login"
                  className="flex-1 bg-primary text-white px-6 py-3 rounded-lg hover:bg-primary/90 transition-colors text-center font-medium"
                >
                  Sign In
                </Link>
                <Link
                  to="/signup"
                  className="flex-1 bg-white text-primary border-2 border-primary px-6 py-3 rounded-lg hover:bg-primary/5 transition-colors text-center font-medium"
                >
                  Sign Up
                </Link>
              </div>
            </div>
            <div className="lg:w-1/2">
              <div className="relative w-[240px] sm:w-[320px] lg:w-full aspect-square rounded-2xl overflow-hidden shadow-2xl mx-auto">
                <img 
                  src="/images/landing/hero-healthy-meal.jpg" 
                  alt="Healthy meal preparation with fresh ingredients" 
                  className="object-cover w-full h-full"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Key Benefits Section */}
      <div className="bg-white py-24">
        <div className="max-w-7xl mx-auto px-6 sm:px-8 lg:px-12">
          <div className="text-center mb-16">
            <h2 className="text-3xl sm:text-4xl font-bold mb-4">Optimize Your Life</h2>
            <p className="text-base sm:text-xl text-text-light max-w-2xl mx-auto">
              Experience the power of AI-driven nutrition optimization for enhanced performance and longevity.
            </p>
          </div>
          <div className="grid md:grid-cols-3 gap-8 sm:gap-12">
            <div className="group">
              <div className="aspect-square mb-6 rounded-2xl overflow-hidden max-w-[200px] sm:max-w-[240px] mx-auto">
                <img 
                  src="/images/landing/cognitive-brain-food.jpg" 
                  alt="Brain-boosting foods and supplements" 
                  className="object-cover w-full h-full group-hover:scale-105 transition-transform duration-300"
                />
              </div>
              <div className="text-center">
                <div className="w-16 h-16 shrink-0 bg-primary/10 rounded-full flex items-center justify-center backdrop-blur-sm">
                  <GiBrain className="w-8 h-8 text-primary" />
                </div>
                <h3 className="text-lg sm:text-xl font-semibold mb-2">Enhanced Cognitive Function</h3>
                <p className="text-text-light">
                  Optimize your brain performance through scientifically-backed nutrition protocols.
                </p>
              </div>
            </div>
            <div className="group">
              <div className="aspect-square mb-6 rounded-2xl overflow-hidden max-w-[200px] sm:max-w-[240px] mx-auto">
                <img 
                  src="/images/landing/longevity-lifestyle.jpg" 
                  alt="Healthy lifestyle and longevity practices" 
                  className="object-cover w-full h-full group-hover:scale-105 transition-transform duration-300"
                />
              </div>
              <div className="text-center">
                <div className="w-16 h-16 shrink-0 bg-primary/10 rounded-full flex items-center justify-center backdrop-blur-sm">
                  <RiHealthBookLine className="w-8 h-8 text-primary" />
                </div>
                <h3 className="text-lg sm:text-xl font-semibold mb-2">Longevity Optimization</h3>
                <p className="text-text-light">
                  Extend your healthspan with nutrition strategies based on the latest longevity research.
                </p>
              </div>
            </div>
            <div className="group">
              <div className="aspect-square mb-6 rounded-2xl overflow-hidden max-w-[200px] sm:max-w-[240px] mx-auto">
                <img 
                  src="/images/landing/personalized-nutrition.jpg" 
                  alt="Personalized nutrition planning" 
                  className="object-cover w-full h-full group-hover:scale-105 transition-transform duration-300"
                />
              </div>
              <div className="text-center">
                <div className="w-16 h-16 shrink-0 bg-primary/10 rounded-full flex items-center justify-center backdrop-blur-sm">
                  <RiTimeLine className="w-8 h-8 text-primary" />
                </div>
                <h3 className="text-lg sm:text-xl font-semibold mb-2">Sustainable Results</h3>
                <p className="text-text-light">
                  Build lasting habits that fit your lifestyle while achieving your health goals.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Features Section with App Screenshots */}
      <div className="bg-primary/5 py-24">
        <div className="max-w-7xl mx-auto px-6 sm:px-8 lg:px-12">
          <h2 className="text-3xl sm:text-4xl font-bold text-center mb-16">How Foodwise Empowers You</h2>
          <div className="grid lg:grid-cols-2 gap-16 items-center">
            <div className="space-y-12">
              <div className="flex gap-6">
                <div className="w-16 h-16 shrink-0 bg-primary/10 rounded-full flex items-center justify-center">
                  <HiOutlineCamera className="w-8 h-8 text-primary" />
                </div>
                <div>
                  <h3 className="text-lg sm:text-xl font-semibold mb-2">Smart Tracking</h3>
                  <p className="text-base sm:text-lg text-text-light">
                    Effortlessly log your meals with AI-powered image recognition and get real-time insights.
                  </p>
                </div>
              </div>
              <div className="flex gap-6">
                <div className="w-16 h-16 shrink-0 bg-primary/10 rounded-full flex items-center justify-center">
                  <HiOutlineChartBar className="w-8 h-8 text-primary" />
                </div>
                <div>
                  <h3 className="text-lg sm:text-xl font-semibold mb-2">Personalized Analytics</h3>
                  <p className="text-base sm:text-lg text-text-light">
                    Get deep insights into your nutrition patterns with advanced AI analysis.
                  </p>
                </div>
              </div>
              <div className="flex gap-6">
                <div className="w-16 h-16 shrink-0 bg-primary/10 rounded-full flex items-center justify-center">
                  <MdOutlineRestaurantMenu className="w-8 h-8 text-primary" />
                </div>
                <div>
                  <h3 className="text-lg sm:text-xl font-semibold mb-2">AI Meal Planning</h3>
                  <p className="text-base sm:text-lg text-text-light">
                    Receive customized meal suggestions that align with your goals and preferences.
                  </p>
                </div>
              </div>
            </div>
            <div className="relative">
              <div className="aspect-[9/16] w-[200px] sm:w-[280px] rounded-[2.5rem] overflow-hidden border-8 border-white shadow-2xl mx-auto -translate-x-5 sm:-translate-x-8">
                <img 
                  src="/images/landing/app-main-interface.jpg" 
                  alt="Foodwise app main interface showing meal tracking and analysis" 
                  className="object-cover w-full h-full"
                />
              </div>
              <div className="absolute -bottom-6 -right-6 aspect-[9/16] w-32 sm:w-40 rounded-[2rem] overflow-hidden border-8 border-white shadow-2xl -translate-x-4 sm:-translate-x-6 lg:-translate-x-16">
                <img 
                  src="/images/landing/app-analytics-interface.jpg" 
                  alt="Foodwise analytics dashboard showing nutrition insights" 
                  className="object-cover w-full h-full"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* FAQ Section */}
      <div className="bg-white py-24">
        <div className="max-w-3xl mx-auto px-6 sm:px-8 lg:px-12">
          <h2 className="text-3xl sm:text-4xl font-bold text-center mb-16">Frequently Asked Questions</h2>
          <div className="space-y-4">
            {faqs.map((faq, index) => (
              <FAQItem key={index} question={faq.question} answer={faq.answer} />
            ))}
          </div>
        </div>
      </div>

      {/* CTA Section */}
      <div className="bg-primary/5 py-24">
        <div className="max-w-7xl mx-auto px-6 sm:px-8 lg:px-12 text-center">
          <h2 className="text-3xl sm:text-4xl font-bold mb-6">Get Started Today</h2>
          <p className="text-base sm:text-xl text-text-light mb-8 max-w-2xl mx-auto">
            Join thousands of users who are already optimizing their nutrition and health with Foodwise.
          </p>
          <div className="flex flex-col sm:flex-row gap-4 max-w-md mx-auto px-4">
            <Link
              to="/login"
              className="flex-1 bg-primary text-white px-6 py-3 rounded-lg hover:bg-primary/90 transition-colors text-base sm:text-lg font-medium"
            >
              Sign In
            </Link>
            <Link
              to="/signup"
              className="flex-1 bg-white text-primary border-2 border-primary px-6 py-3 rounded-lg hover:bg-primary/5 transition-colors text-base sm:text-lg font-medium"
            >
              Sign Up
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Landing;

import React from 'react';
import { Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#ffffff',
    padding: 50,
    fontFamily: 'Helvetica',
  },
  header: {
    marginBottom: 40,
    paddingBottom: 15,
    borderBottom: 3,
    borderBottomColor: '#E5E7EB',
    position: 'relative',
  },
  headerAccent: {
    position: 'absolute',
    bottom: -3,
    left: 0,
    width: 100,
    height: 3,
    backgroundColor: '#10B981',
  },
  title: {
    fontSize: 32,
    letterSpacing: -1,
    color: '#111827',
    fontFamily: 'Helvetica-Bold',
  },
  subtitle: {
    fontSize: 11,
    color: '#6B7280',
    marginTop: 8,
    textTransform: 'uppercase',
    letterSpacing: 0.5,
  },
  section: {
    marginBottom: 30,
  },
  dateTitle: {
    fontSize: 16,
    marginBottom: 15,
    color: '#111827',
    backgroundColor: '#F9FAFB',
    padding: '10 15',
    borderRadius: 6,
    fontFamily: 'Helvetica-Bold',
    textTransform: 'uppercase',
    letterSpacing: 0.5,
  },
  meal: {
    marginBottom: 15,
    paddingLeft: 15,
    borderLeft: 2,
    borderLeftColor: '#10B981',
    position: 'relative',
  },
  mealTime: {
    fontSize: 11,
    color: '#6B7280',
    marginBottom: 4,
    letterSpacing: 0.5,
    textTransform: 'uppercase',
  },
  mealTitle: {
    fontSize: 14,
    color: '#111827',
    marginBottom: 6,
    fontFamily: 'Helvetica-Bold',
  },
  ingredients: {
    fontSize: 11,
    color: '#4B5563',
    paddingLeft: 0,
    lineHeight: 1.6,
  },
  footer: {
    position: 'absolute',
    bottom: 30,
    left: 50,
    right: 50,
    fontSize: 9,
    color: '#9CA3AF',
    textAlign: 'center',
    paddingTop: 10,
    borderTop: 1,
    borderTopColor: '#E5E7EB',
  },
  emptyState: {
    marginTop: 40,
    padding: 20,
    backgroundColor: '#F9FAFB',
    borderRadius: 6,
    textAlign: 'center',
  },
  emptyStateText: {
    color: '#6B7280',
    fontSize: 14,
  }
});

// Format date to a more readable format
const formatDate = (dateString) => {
  const date = new Date(dateString);
  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(today.getDate() + 1);
  
  // Reset hours to compare just the dates
  today.setHours(0, 0, 0, 0);
  tomorrow.setHours(0, 0, 0, 0);
  date.setHours(0, 0, 0, 0);

  if (date.getTime() === today.getTime()) {
    return 'Today';
  } else if (date.getTime() === tomorrow.getTime()) {
    return 'Tomorrow';
  } else {
    return date.toLocaleDateString('en-US', { 
      weekday: 'long',
      month: 'long',
      day: 'numeric'
    });
  }
};

const MealPlanPDF = ({ meals }) => {
  const today = new Date().toLocaleDateString('en-US', { 
    weekday: 'long', 
    year: 'numeric', 
    month: 'long', 
    day: 'numeric' 
  });

  const hasAnyMeals = Object.keys(meals).length > 0;

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.header}>
          <Text style={styles.title}>3-Day Meal Plan</Text>
          <Text style={styles.subtitle}>Generated on {today}</Text>
          <View style={styles.headerAccent} />
        </View>
        
        {hasAnyMeals ? (
          Object.entries(meals)
            .sort(([dateA], [dateB]) => dateA.localeCompare(dateB))
            .map(([date, dayMeals]) => (
              <View key={date} style={styles.section}>
                <Text style={styles.dateTitle}>{formatDate(date)}</Text>
                {dayMeals
                  .sort((a, b) => a.time.localeCompare(b.time))
                  .map((meal, index) => (
                    <View key={index} style={styles.meal}>
                      <Text style={styles.mealTime}>{meal.time}</Text>
                      <Text style={styles.mealTitle}>{meal.name}</Text>
                      <Text style={styles.ingredients}>
                        {meal.ingredients
                          .map(ing => `${ing.quantity}${ing.unit} ${ing.name}`)
                          .join('  •  ')}
                      </Text>
                    </View>
                  ))}
              </View>
            ))
        ) : (
          <View style={styles.emptyState}>
            <Text style={styles.emptyStateText}>
              No meals planned for the next three days.
            </Text>
          </View>
        )}
        
        <Text style={styles.footer}>
          Generated by FoodWise • {today}
        </Text>
      </Page>
    </Document>
  );
};

export default MealPlanPDF; 
import React, { useEffect, useState, useCallback } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { scheduleNotifications } from '../../services/notificationService';

// Move constants outside component since they're static
const STORAGE_KEY = 'pwa_prompt_last_shown';
const PROMPT_INTERVAL = 2 * 24 * 60 * 60 * 1000; // 2 days in milliseconds

const InstallPWA = () => {
  const [supportsPWA, setSupportsPWA] = useState(false);
  const [promptInstall, setPromptInstall] = useState(null);
  const [isInstalled, setIsInstalled] = useState(false);
  const [showPrompt, setShowPrompt] = useState(false);
  const { user } = useAuth();

  const shouldShowPrompt = useCallback(() => {
    // Check if running as PWA
    if (window.matchMedia('(display-mode: standalone)').matches || 
        window.navigator.standalone === true) {
      return false;
    }

    const lastPrompt = localStorage.getItem(STORAGE_KEY);
    if (!lastPrompt) return true;

    const lastPromptTime = parseInt(lastPrompt, 10);
    const now = Date.now();

    return now - lastPromptTime >= PROMPT_INTERVAL;
  }, []); // Empty dependency array is now correct since constants are module-level

  useEffect(() => {
    // Check if the app is already installed
    if (window.matchMedia('(display-mode: standalone)').matches || 
        window.navigator.standalone === true) {
      setIsInstalled(true);
      return;
    }

    // Check if it's a mobile device
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    
    if (!isMobile) {
      return; // Don't show prompt on desktop
    }

    // Check if we should show the prompt based on time interval
    if (!shouldShowPrompt()) {
      return;
    }

    const handler = (e) => {
      e.preventDefault();
      setSupportsPWA(true);
      setPromptInstall(e);
      // Show prompt after a short delay to ensure it's not too intrusive
      setTimeout(() => setShowPrompt(true), 2000);
    };

    window.addEventListener('beforeinstallprompt', handler);

    // For iOS, show prompt after a short delay
    if (isMobile && /iPhone|iPad|iPod/.test(navigator.userAgent)) {
      setTimeout(() => setShowPrompt(true), 2000);
    }

    return () => window.removeEventListener('beforeinstallprompt', handler);
  }, [shouldShowPrompt]);

  const handleInstall = async () => {
    if (!promptInstall) {
      return;
    }

    promptInstall.prompt();
    
    const { outcome } = await promptInstall.userChoice;
    if (outcome === 'accepted') {
      // After successful installation, request notification permissions
      if (user) {
        await scheduleNotifications(user.uid);
      }
      setIsInstalled(true);
      // Store the installation time
      localStorage.setItem(STORAGE_KEY, Date.now().toString());
    }
    
    setPromptInstall(null);
    setShowPrompt(false);
  };

  const handleClose = () => {
    // Store the time when user clicked "Maybe Later"
    localStorage.setItem(STORAGE_KEY, Date.now().toString());
    setShowPrompt(false);
    setPromptInstall(null);
  };

  if (!showPrompt || isInstalled) {
    return null;
  }

  // Show iOS instructions
  const isIOS = /iPhone|iPad|iPod/.test(navigator.userAgent);
  const isAndroid = /Android/.test(navigator.userAgent);

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
      <div className="bg-white rounded-xl shadow-xl max-w-sm w-full p-6 m-4 relative">
        <div className="text-center mb-6">
          <h3 className="text-lg font-semibold text-gray-900 mb-2">
            Install FoodWise App
          </h3>
          <p className="text-sm text-gray-600 mb-4">
            Get the best experience by installing FoodWise on your device
          </p>
        </div>

        {isIOS && (
          <div className="mb-6">
            <ol className="text-sm space-y-3 list-decimal pl-4">
              <li>Tap the <span className="inline-flex items-center px-2 py-0.5 bg-gray-100 text-gray-800 rounded">
                <svg className="w-4 h-4 mr-1" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                  <path d="M3 15v4c0 1.1.9 2 2 2h14a2 2 0 0 0 2-2v-4M12 3v12M7 8l5-5 5 5"/>
                </svg>
                Share</span> button
              </li>
              <li>Scroll down and tap <span className="font-medium">'Add to Home Screen'</span></li>
              <li>Tap <span className="font-medium">'Add'</span> in the top right</li>
            </ol>
          </div>
        )}

        {isAndroid && (
          <div className="mb-6">
            <p className="text-sm text-gray-600 mb-4">
              Click the Install button below to add FoodWise to your home screen
            </p>
          </div>
        )}

        <div className="flex flex-col gap-3">
          {!isIOS && supportsPWA && (
            <button
              onClick={handleInstall}
              className="w-full bg-primary-600 text-white py-3 px-4 rounded-lg font-medium hover:bg-primary-700 transition-colors"
            >
              Install App
            </button>
          )}
          <button
            onClick={handleClose}
            className="w-full border border-gray-300 text-gray-700 py-3 px-4 rounded-lg font-medium hover:bg-gray-50 transition-colors"
          >
            Maybe Later
          </button>
        </div>
      </div>
    </div>
  );
};

export default InstallPWA; 
import React, { useRef, useEffect } from 'react';
import { formatMessage } from '../../utils/messageFormatter';

export const MessageList = ({ messages, isLoading }) => {
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  return (
    <div className="flex-1 overflow-y-auto px-2 py-4 space-y-4">
      {messages.length === 0 ? (
        <div className="flex flex-col items-center justify-center h-full text-center text-text-light">
          <p className="text-lg mb-2">👋 Welcome to Foodwise Chat!</p>
          <p className="text-sm">Ask me anything about nutrition, recipes, or meal planning.</p>
        </div>
      ) : (
        messages.map((message, index) => (
          <div
            key={index}
            className={`flex ${message.role === 'user' ? 'justify-end' : 'justify-start'}`}
          >
            <div
              className={`max-w-[80%] rounded-2xl px-4 py-2 shadow-md ${
                message.role === 'user'
                  ? 'bg-red-50 text-text-dark rounded-br-none border border-red-100'
                  : 'bg-white text-text-dark rounded-bl-none border border-gray-100'
              }`}
            >
              <div 
                className="text-base space-y-1"
                dangerouslySetInnerHTML={{ __html: formatMessage(message.content) }}
              />
              <span className="text-xs text-gray-500 mt-1 block">
                {new Date(message.timestamp).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
              </span>
            </div>
          </div>
        ))
      )}
      {isLoading && (
        <div className="flex justify-start">
          <div className="bg-white text-gray-800 rounded-2xl p-3 shadow-md border border-gray-100">
            Thinking...
          </div>
        </div>
      )}
      <div ref={messagesEndRef} />
    </div>
  );
};




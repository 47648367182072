import React, { useState } from 'react';
import { FiUser, FiCalendar, FiUsers } from 'react-icons/fi';

const PersonalInfo = ({ userData, updateUserData, onNext }) => {
  const [errors, setErrors] = useState({});

  const validateForm = () => {
    const newErrors = {};
    if (!userData.firstName?.trim()) newErrors.firstName = 'First name is required';
    if (!userData.lastName?.trim()) newErrors.lastName = 'Last name is required';
    if (!userData.gender) newErrors.gender = 'Gender is required';
    if (!userData.dateOfBirth) newErrors.dateOfBirth = 'Date of birth is required';
    
    // Validate age (must be at least 13 years old)
    if (userData.dateOfBirth) {
      const age = new Date().getFullYear() - new Date(userData.dateOfBirth).getFullYear();
      if (age < 13) newErrors.dateOfBirth = 'You must be at least 13 years old';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      onNext();
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    updateUserData({ [name]: value });
    // Clear error when user starts typing
    if (errors[name]) {
      setErrors(prev => ({ ...prev, [name]: undefined }));
    }
  };

  return (
    <div className="space-y-6">
      <div className="text-center">
        <h2 className="text-2xl font-bold text-gray-900">Welcome to FoodWise!</h2>
        <p className="mt-2 text-sm text-gray-600">
          Let's start by getting to know you better. This information helps us provide
          personalized nutrition advice tailored just for you.
        </p>
      </div>

      <form onSubmit={handleSubmit} className="space-y-6">
        {/* Name Fields */}
        <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
          <div>
            <label htmlFor="firstName" className="block text-sm font-medium text-gray-700">
              First Name
            </label>
            <div className="mt-1 relative">
              <div className="absolute inset-y-0 left-0 pl-4 flex items-center pointer-events-none">
                <FiUser className="h-5 w-5 text-gray-400" />
              </div>
              <input
                type="text"
                name="firstName"
                id="firstName"
                value={userData.firstName || ''}
                onChange={handleChange}
                className={`block w-full pl-12 pr-3 py-2 border ${
                  errors.firstName ? 'border-red-300' : 'border-gray-300'
                } rounded-md shadow-sm focus:ring-primary-500 focus:border-primary-500`}
                placeholder="John"
              />
            </div>
            {errors.firstName && (
              <p className="mt-1 text-sm text-red-600">{errors.firstName}</p>
            )}
          </div>

          <div>
            <label htmlFor="lastName" className="block text-sm font-medium text-gray-700">
              Last Name
            </label>
            <div className="mt-1 relative">
              <div className="absolute inset-y-0 left-0 pl-4 flex items-center pointer-events-none">
                <FiUser className="h-5 w-5 text-gray-400" />
              </div>
              <input
                type="text"
                name="lastName"
                id="lastName"
                value={userData.lastName || ''}
                onChange={handleChange}
                className={`block w-full pl-12 pr-3 py-2 border ${
                  errors.lastName ? 'border-red-300' : 'border-gray-300'
                } rounded-md shadow-sm focus:ring-primary-500 focus:border-primary-500`}
                placeholder="Doe"
              />
            </div>
            {errors.lastName && (
              <p className="mt-1 text-sm text-red-600">{errors.lastName}</p>
            )}
          </div>
        </div>

        {/* Gender Selection */}
        <div>
          <label className="block text-sm font-medium text-gray-700">Gender</label>
          <div className="mt-1 relative">
            <div className="absolute inset-y-0 left-0 pl-4 flex items-center pointer-events-none">
              <FiUsers className="h-5 w-5 text-gray-400" />
            </div>
            <select
              name="gender"
              value={userData.gender || ''}
              onChange={handleChange}
              className={`block w-full pl-12 pr-3 py-2 border ${
                errors.gender ? 'border-red-300' : 'border-gray-300'
              } rounded-md shadow-sm focus:ring-primary-500 focus:border-primary-500`}
            >
              <option value="">Select gender</option>
              <option value="male">Male</option>
              <option value="female">Female</option>
              <option value="other">Other</option>
              <option value="prefer-not-to-say">Prefer not to say</option>
            </select>
          </div>
          {errors.gender && (
            <p className="mt-1 text-sm text-red-600">{errors.gender}</p>
          )}
        </div>

        {/* Date of Birth */}
        <div>
          <label htmlFor="dateOfBirth" className="block text-sm font-medium text-gray-700">
            Date of Birth
          </label>
          <div className="mt-1 relative">
            <div className="absolute inset-y-0 left-0 pl-4 flex items-center pointer-events-none">
              <FiCalendar className="h-5 w-5 text-gray-400" />
            </div>
            <input
              type="date"
              name="dateOfBirth"
              id="dateOfBirth"
              value={userData.dateOfBirth || ''}
              onChange={handleChange}
              max={new Date().toISOString().split('T')[0]}
              className={`block w-full pl-12 pr-3 py-2 border ${
                errors.dateOfBirth ? 'border-red-300' : 'border-gray-300'
              } rounded-md shadow-sm focus:ring-primary-500 focus:border-primary-500`}
            />
          </div>
          {errors.dateOfBirth && (
            <p className="mt-1 text-sm text-red-600">{errors.dateOfBirth}</p>
          )}
        </div>

        {/* Next Button */}
        <div className="flex justify-between px-4 py-2 bg-white border-t border-gray-100">
          <div></div> {/* Empty div for spacing since this is the first step */}
          <button
            type="submit"
            className="text-sm text-primary hover:text-primary-dark"
          >
            Continue
          </button>
        </div>
      </form>
    </div>
  );
};

export default PersonalInfo; 
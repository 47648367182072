import React from 'react';
import { 
  DocumentTextIcon as DocumentTextIconOutline,
  ArrowTopRightOnSquareIcon
} from '@heroicons/react/24/outline';
import { DocumentTextIcon, ChevronDownIcon, ShoppingCartIcon, PlusIcon } from '@heroicons/react/24/solid';

const FeaturedRecipe = ({ 
  recipe,
  nutritionalContext,
  userPreferences,
  addToShoppingList,
  logMeal,
  isLogged,
  showLogSuccess,
  isAdviceExpanded,
  setIsAdviceExpanded,
  isPersonalizedExpanded,
  setIsPersonalizedExpanded,
  isCycleSupportExpanded,
  setIsCycleSupportExpanded,
  isInstructionsExpanded,
  setIsInstructionsExpanded
}) => {
  if (typeof recipe === 'string') {
    return <div className="prose prose-sm max-w-none">{recipe}</div>;
  }

  const openGoogleSearch = () => {
    const searchQuery = `${recipe.title} recipe ${recipe.ingredients.slice(0, 3).join(' ')}`;
    const searchUrl = `https://www.google.com/search?q=${encodeURIComponent(searchQuery)}`;
    window.open(searchUrl, '_blank', 'noopener,noreferrer');
  };

  return (
    <div className="prose prose-sm max-w-none">
      <div className="flex justify-between items-center mb-2">
        <h3 className="text-xl font-medium text-text-dark">{recipe.title}</h3>
        <div className="flex gap-2">
          <button
            onClick={openGoogleSearch}
            className="inline-flex items-center justify-center p-2 rounded-full hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition-colors duration-200"
            aria-label="Search recipe on Google"
          >
            <ArrowTopRightOnSquareIcon className="h-5 w-5 text-gray-400 hover:text-indigo-500" />
          </button>
          <button
            onClick={() => logMeal(recipe)}
            className="inline-flex items-center justify-center p-2 rounded-full hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition-colors duration-200"
            aria-label="Log meal to diary"
          >
            {isLogged || showLogSuccess ? (
              <DocumentTextIcon className="h-6 w-6 text-indigo-600" />
            ) : (
              <DocumentTextIconOutline className="h-6 w-6 text-gray-400 hover:text-indigo-500" />
            )}
          </button>
        </div>
      </div>
      <p className="text-sm text-text-dark mb-2">{recipe.description}</p>
      
      {/* Recipe Image */}
      {recipe.imageUrl && (
        <div className="mb-4 rounded-lg overflow-hidden shadow-md">
          <img 
            src={recipe.imageUrl} 
            alt={recipe.title}
            className="w-full h-64 object-cover"
            loading="lazy"
          />
        </div>
      )}
      
      {recipe.tags && (
        <div className="flex flex-wrap gap-2 mb-4">
          {recipe.tags.map((tag, index) => (
            <span key={index} className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800">
              {tag}
            </span>
          ))}
        </div>
      )}
      
      <div className="flex gap-4 text-sm text-text-dark mb-6">
        <span>⏱️ {recipe.prepTime}</span>
        <span>🔥 {recipe.calories}</span>
        <span>👤 {recipe.servings && `${recipe.servings} servings`}</span>
      </div>

      {false && nutritionalContext && (
        <div className="mb-6 space-y-4">
          {nutritionalContext.holisticAdvice ? (
            <div className="bg-purple-50 p-4 rounded-lg">
              <button 
                onClick={() => setIsAdviceExpanded(!isAdviceExpanded)}
                className="w-full flex items-center justify-between font-medium mb-2"
              >
                <span>💡 Holistic Wellness Advice</span>
                <ChevronDownIcon 
                  className={`w-5 h-5 text-gray-500 transition-transform duration-200 ease-in-out ${
                    isAdviceExpanded ? 'transform rotate-180' : ''
                  }`}
                />
              </button>
              <div className={`transition-all duration-200 ease-in-out overflow-hidden ${
                isAdviceExpanded ? 'max-h-[1000px] opacity-100' : 'max-h-0 opacity-0'
              }`}>
                <p className="text-sm text-text-dark mb-3">{nutritionalContext.holisticAdvice}</p>
                {nutritionalContext.macros && (
                  <div className="mt-4 pt-3 border-t border-purple-100">
                    <p className="text-sm font-medium mb-2">Nutrition per serving:</p>
                    <div className="grid grid-cols-4 gap-2 text-sm">
                      <div className="bg-purple-100/50 rounded p-2 text-center">
                        <div className="font-medium">Protein</div>
                        <div>{nutritionalContext.macros.protein}</div>
                      </div>
                      <div className="bg-purple-100/50 rounded p-2 text-center">
                        <div className="font-medium">Carbs</div>
                        <div>{nutritionalContext.macros.carbs}</div>
                      </div>
                      <div className="bg-purple-100/50 rounded p-2 text-center">
                        <div className="font-medium">Fats</div>
                        <div>{nutritionalContext.macros.fats}</div>
                      </div>
                      <div className="bg-purple-100/50 rounded p-2 text-center">
                        <div className="font-medium">Calories</div>
                        <div>{nutritionalContext.macros.calories}</div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div className="bg-green-50 p-4 rounded-lg">
              <button 
                onClick={() => setIsAdviceExpanded(!isAdviceExpanded)}
                className="w-full flex items-center justify-between font-medium mb-2"
              >
                <span>🌿 Nutritional Benefits</span>
                <ChevronDownIcon 
                  className={`w-5 h-5 text-gray-500 transition-transform duration-200 ease-in-out ${
                    isAdviceExpanded ? 'transform rotate-180' : ''
                  }`}
                />
              </button>
              <div className={`transition-all duration-200 ease-in-out overflow-hidden ${
                isAdviceExpanded ? 'max-h-[1000px] opacity-100' : 'max-h-0 opacity-0'
              }`}>
                <ul className="list-disc pl-5 text-sm text-text-dark space-y-1 mb-3">
                  {Array.isArray(nutritionalContext.nutritionalBenefits) 
                    ? nutritionalContext.nutritionalBenefits.map((benefit, index) => (
                        <li key={index}>{benefit}</li>
                      ))
                    : typeof nutritionalContext.nutritionalBenefits === 'string'
                      ? nutritionalContext.nutritionalBenefits.split('\n').map((benefit, index) => (
                          <li key={index}>{benefit.replace(/^[•-]\s*/, '')}</li>
                        ))
                    : <li>{String(nutritionalContext.nutritionalBenefits)}</li>
                  }
                </ul>
                {nutritionalContext.macros && (
                  <div className="mt-4 pt-3 border-t border-green-100">
                    <p className="text-sm font-medium mb-2">Nutrition per serving:</p>
                    <div className="grid grid-cols-4 gap-2 text-sm">
                      <div className="bg-green-100/50 rounded p-2 text-center">
                        <div className="font-medium">Protein</div>
                        <div>{nutritionalContext.macros.protein}</div>
                      </div>
                      <div className="bg-green-100/50 rounded p-2 text-center">
                        <div className="font-medium">Carbs</div>
                        <div>{nutritionalContext.macros.carbs}</div>
                      </div>
                      <div className="bg-green-100/50 rounded p-2 text-center">
                        <div className="font-medium">Fats</div>
                        <div>{nutritionalContext.macros.fats}</div>
                      </div>
                      <div className="bg-green-100/50 rounded p-2 text-center">
                        <div className="font-medium">Calories</div>
                        <div>{nutritionalContext.macros.calories}</div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
          
          <div className="bg-blue-50 p-4 rounded-lg">
            <button 
              onClick={() => setIsPersonalizedExpanded(!isPersonalizedExpanded)}
              className="w-full flex items-center justify-between font-medium mb-2"
            >
              <span>🎯 Why This Meal Is Perfect for You</span>
              <ChevronDownIcon 
                className={`w-5 h-5 text-gray-500 transition-transform duration-200 ease-in-out ${
                  isPersonalizedExpanded ? 'transform rotate-180' : ''
                }`}
              />
            </button>
            <div className={`transition-all duration-200 ease-in-out overflow-hidden ${
              isPersonalizedExpanded ? 'max-h-[1000px] opacity-100' : 'max-h-0 opacity-0'
            }`}>
              <p className="text-sm text-text-dark">{nutritionalContext.personalizedContext}</p>
            </div>
          </div>
          
          {userPreferences?.menstrualCycle?.enabled && userPreferences.gender === 'female' && nutritionalContext?.cyclePhaseSupport && (
            <div className="bg-pink-50 p-4 rounded-lg">
              <button 
                onClick={() => setIsCycleSupportExpanded(!isCycleSupportExpanded)}
                className="w-full flex items-center justify-between font-medium mb-2"
              >
                <span>🌸 Cycle Phase Support</span>
                <ChevronDownIcon 
                  className={`w-5 h-5 text-gray-500 transition-transform duration-200 ease-in-out ${
                    isCycleSupportExpanded ? 'transform rotate-180' : ''
                  }`}
                />
              </button>
              <div className={`transition-all duration-200 ease-in-out overflow-hidden ${
                isCycleSupportExpanded ? 'max-h-[1000px] opacity-100' : 'max-h-0 opacity-0'
              }`}>
                <div className="space-y-3">
                  <div>
                    <h4 className="font-medium text-sm mb-1">Current Phase: {nutritionalContext.cyclePhaseSupport.phase}</h4>
                    <p className="text-sm text-text-dark">{nutritionalContext.cyclePhaseSupport.nutritionalNeeds}</p>
                  </div>
                  <div>
                    <h4 className="font-medium text-sm mb-1">How This Meal Helps</h4>
                    <p className="text-sm text-text-dark">{nutritionalContext.cyclePhaseSupport.mealBenefits}</p>
                  </div>
                  <div>
                    <h4 className="font-medium text-sm mb-1">Tips for This Phase</h4>
                    <p className="text-sm text-text-dark">{nutritionalContext.cyclePhaseSupport.additionalTips}</p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}

      <div className="mb-4">
        <div className="flex items-center justify-between mb-2">
          <h4 className="font-medium">Ingredients:</h4>
          <button
            onClick={() => addToShoppingList(recipe)}
            className="p-1.5 hover:bg-gray-100 rounded-full transition-colors duration-200 relative group"
            aria-label="Add ingredients to shopping list"
          >
            <ShoppingCartIcon className="h-5 w-5 text-gray-600" />
            <PlusIcon className="h-3 w-3 text-gray-600 absolute -top-1 -right-1 bg-white rounded-full" />
          </button>
        </div>
        <ul className="list-disc pl-4">
          {recipe.ingredients.map((ingredient, index) => (
            <li key={index} className="text-sm text-text-dark">
              {typeof ingredient === 'string' ? ingredient : (
                <>
                  {ingredient.quantity && `${ingredient.quantity} `}
                  {ingredient.unit && `${ingredient.unit} `}
                  {ingredient.name}
                  {ingredient.grams && ` (${ingredient.grams}g)`}
                </>
              )}
            </li>
          ))}
        </ul>
      </div>

      <div>
        <button 
          onClick={() => setIsInstructionsExpanded(!isInstructionsExpanded)}
          className="w-full flex items-center justify-between font-medium mb-2"
        >
          <span>📝 Cooking Instructions</span>
          <ChevronDownIcon 
            className={`w-5 h-5 text-gray-500 transition-transform duration-200 ease-in-out ${
              isInstructionsExpanded ? 'transform rotate-180' : ''
            }`}
          />
        </button>
        <div className={`transition-all duration-200 ease-in-out overflow-hidden ${
          isInstructionsExpanded ? 'max-h-[1000px] opacity-100' : 'max-h-0 opacity-0'
        }`}>
          <ol className="list-decimal pl-4">
            {recipe.instructions.map((step, index) => (
              <li key={index} className="text-sm text-text-dark">{step}</li>
            ))}
          </ol>
        </div>
      </div>
    </div>
  );
};

export default FeaturedRecipe;

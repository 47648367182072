// src/services/recipeService.js
// sending the api prompts for the calendar popup to generate a recipe

import { getAuth } from 'firebase/auth';
import { collection, query, where, getDocs, limit, orderBy, doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { loadMemory, loadTimedPreferences } from './memoryService';
import { loadPreviousSynthesis } from './chatService';

// The 'type' parameter can be either 'quick' or 'detailed':
// - 'quick': Used for simple recipe generation (like in Home.js)
// - 'detailed': Used when enhancing an existing meal (like in MealPopup.js)
// handleRecipeGeneration makes two API calls to generate a detailed recipe
// It receives a context parameter from MealPopup.js which contains the original meal details
export const handleRecipeGeneration = async (type = 'quick', context = '') => {
  const user = getAuth().currentUser;
  if (!user) throw new Error('User not authenticated');

  try {
    // Load user preferences - fixed to use direct doc reference
    const userDoc = await getDoc(doc(db, 'users', user.uid));
    const userData = userDoc.exists() ? userDoc.data() : {};
    
    // Calculate cycle phase if applicable
    const cyclePhase = userData.menstrualCycle?.enabled && 
                      userData.gender === 'female' && 
                      userData.menstrualCycle.lastPeriodStart
      ? calculateCyclePhase(userData.menstrualCycle.lastPeriodStart, userData.menstrualCycle.cycleLength)
      : null;

    const userPreferences = {
      foodPreferences: userData.foodPreferences || 'healthy and balanced',
      allergies: userData.allergies || [],
      gender: userData.gender,
      menstrualCycle: userData.menstrualCycle,
      currentCyclePhase: cyclePhase,
      generateRecipeImages: userData.generateRecipeImages ?? true
    };
    
    // Load dietary context
    const memory = await loadMemory(user.uid);
    const dietaryTags = memory.filter(tag => tag.category === 'dietary');
    
    // Load temporary preferences
    const activePrefs = await loadTimedPreferences(user.uid);
    
    // Load past meals from the last 7 days
    const sevenDaysAgo = new Date();
    sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);
    
    const mealsRef = collection(db, 'meals');
    const mealsQuery = query(
      mealsRef,
      where('userId', '==', user.uid),
      orderBy('createdAt', 'desc'),
      limit(20)
    );
    
    const mealsSnapshot = await getDocs(mealsQuery);
    const ingredients = [];
    const ingredientCounts = {};
    
    mealsSnapshot.forEach(doc => {
      const meal = doc.data();
      const mealDate = meal.createdAt?.toDate?.() || new Date(meal.createdAt);
      
      if (mealDate >= sevenDaysAgo) {
        meal.ingredients?.forEach(ing => {
          const key = `${ing.name}|${ing.category || 'unknown'}`;
          ingredientCounts[key] = (ingredientCounts[key] || 0) + 1;
        });
      }
    });
    
    Object.entries(ingredientCounts).forEach(([key, count]) => {
      const [name, category] = key.split('|');
      ingredients.push({ name, category, count });
    });

    // Load chat synthesis
    const synthesis = await loadPreviousSynthesis(user.uid);

    // ===== PROMPT #1: NUTRITIONIST PROMPT =====
    // Uses the context from MealPopup.js as part of the user profile analysis
    const nutritionistPrompt = `You are a professional nutritionist and healthcare expert. Analyze the user's profile and provide nutritional recommendations.
IMPORTANT: Your response must be a valid JSON object. Do not include any markdown formatting or additional text.

USER PROFILE:
- Dietary preferences: ${userPreferences.foodPreferences}
${context ? `- Current context/needs: "${context}"` : ''}  // <-- Here we use the prompt from MealPopup
- Dietary context: ${dietaryTags?.length > 0 ? dietaryTags.map(tag => tag.content).join(', ') : 'None specified'}
- Temporary preferences: ${activePrefs?.length > 0 ? activePrefs.map(pref => pref.content).join(', ') : 'None specified'}
${userPreferences.menstrualCycle?.enabled && userPreferences.gender === 'female' 
  ? `- Menstrual cycle phase: ${userPreferences.currentCyclePhase}` : ''}
- Recent meals (7 days): ${ingredients.length > 0 
  ? ingredients.map(ing => `${ing.name} (${ing.category}) - ${ing.count} times`).join(', ') 
  : 'No past meals recorded'}
${synthesis?.synthesis ? `- Previous chat insights: ${synthesis.synthesis}` : ''}

RESPONSE FORMAT:
{
  "fixed_requirements": ["requirement1", "requirement2"],
  "recommendations": ["recommendation1", "recommendation2"],
  "recommended_ingredients": ["ingredient1", "ingredient2"]
}`;

    const recommendationsResponse = await fetch('https://generateairesponse-boc5emstrq-uc.a.run.app/chat', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        messages: [{ role: 'system', content: nutritionistPrompt }],
        temperature: 0.7,
        max_tokens: 500
      })
    });

    const recommendationsData = await recommendationsResponse.json();
    let nutritionRecommendations;
    
    // Validate response structure
    if (!recommendationsData || !recommendationsData.response) {
      console.error('Invalid recommendations response:', recommendationsData);
      nutritionRecommendations = {
        fixed_requirements: [],
        recommendations: [],
        recommended_ingredients: []
      };
    } else {
      try {
        // Clean the response to ensure it's valid JSON
        let cleanedResponse = recommendationsData.response
          .replace(/^[\s\S]*?{/, '{') // Remove any text before the first {
          .replace(/}[\s\S]*$/, '}') // Remove any text after the last }
          .trim();
        
        nutritionRecommendations = JSON.parse(cleanedResponse);
        
        // Validate the structure
        nutritionRecommendations = {
          fixed_requirements: Array.isArray(nutritionRecommendations.fixed_requirements) 
            ? nutritionRecommendations.fixed_requirements 
            : [],
          recommendations: Array.isArray(nutritionRecommendations.recommendations) 
            ? nutritionRecommendations.recommendations 
            : [],
          recommended_ingredients: Array.isArray(nutritionRecommendations.recommended_ingredients) 
            ? nutritionRecommendations.recommended_ingredients 
            : []
        };
      } catch (error) {
        console.error('Failed to parse recommendations:', error);
        nutritionRecommendations = {
          fixed_requirements: [],
          recommendations: [],
          recommended_ingredients: []
        };
      }
    }

    // ===== PROMPT #2: CHEF PROMPT =====
    // Uses both the nutritional recommendations AND the context from MealPopup.js
    const recipePrompt = `You are a professional chef. Create a detailed recipe based on these requirements and recommendations.
IMPORTANT: Your response must be a valid JSON object. Do not include any markdown formatting or additional text.

NUTRITIONAL REQUIREMENTS:
${JSON.stringify(nutritionRecommendations, null, 2)}

MEAL DETAILS:
${context}  // <-- Here we use the prompt from MealPopup again

RESPONSE FORMAT:
{
  "title": "Recipe Name",
  "ingredients": ["ingredient1 with quantity", "ingredient2 with quantity"],
  "instructions": ["step1", "step2"],
  "nutritionalBenefits": ["benefit1", "benefit2"],
  "prepTime": "30 minutes",
  "calories": "400 kcal",
  "difficulty": "Easy",
  "servings": "4"
}`;

    const recipeResponse = await fetch('https://generateairesponse-boc5emstrq-uc.a.run.app/chat', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        messages: [{ role: 'system', content: recipePrompt }],
        temperature: 0.7,
        max_tokens: 800
      })
    });

    const recipeData = await recipeResponse.json();
    let recipe;
    
    // Validate response structure
    if (!recipeData || !recipeData.response) {
      console.error('Invalid recipe response:', recipeData);
      recipe = {
        title: "Recipe",
        ingredients: [],
        instructions: [],
        nutritionalBenefits: [],
        prepTime: "30 minutes",
        calories: "400 kcal",
        difficulty: "Medium",
        servings: "2"
      };
    } else {
      try {
        // Clean the response to ensure it's valid JSON
        let cleanedResponse = recipeData.response
          .replace(/^[\s\S]*?{/, '{') // Remove any text before the first {
          .replace(/}[\s\S]*$/, '}') // Remove any text after the last }
          .trim();
        
        recipe = JSON.parse(cleanedResponse);
        
        // Validate the structure and ensure all required fields exist
        recipe = {
          title: recipe.title || "Recipe",
          ingredients: Array.isArray(recipe.ingredients) ? recipe.ingredients : [],
          instructions: Array.isArray(recipe.instructions) ? recipe.instructions : [],
          nutritionalBenefits: Array.isArray(recipe.nutritionalBenefits) ? recipe.nutritionalBenefits : [],
          prepTime: recipe.prepTime,
          calories: recipe.calories,
          difficulty: ["Easy", "Medium", "Hard"].includes(recipe.difficulty) ? recipe.difficulty : "Medium",
          servings: recipe.servings
        };
      } catch (error) {
        console.error('Failed to parse recipe:', error);
        recipe = {
          title: "Recipe",
          ingredients: [],
          instructions: [],
          nutritionalBenefits: [],
          prepTime: "30 minutes",
          calories: "400 kcal",
          difficulty: "Medium",
          servings: "2"
        };
      }
    }

    return {
      recipe,
      nutritionalContext: {
        benefits: nutritionRecommendations.recommendations,
        warnings: [],
        recommendations: nutritionRecommendations.fixed_requirements
      }
    };
  } catch (error) {
    console.error("Error in recipe generation:", error);
    throw error;
  }
};

export const updateMealPlan = async (userId, date, mealId, updatedMealData) => {
  try {
    const mealPlanRef = doc(db, 'mealplans', userId);  
    const mealPlanDoc = await getDoc(mealPlanRef);
    
    if (!mealPlanDoc.exists()) {
      throw new Error('Meal plan not found');
    }

    const currentMealPlan = mealPlanDoc.data();
    const dayToUpdate = currentMealPlan.days.find(d => d.date === date);
    
    if (!dayToUpdate) {
      throw new Error(`Could not find day with date: ${date}`);
    }

    const mealToUpdate = dayToUpdate.meals.find(m => m.id === mealId);
    if (!mealToUpdate) {
      throw new Error(`Could not find meal with id: ${mealId}`);
    }

    // Update the meal with new data
    Object.assign(mealToUpdate, updatedMealData);

    // Update the document in Firestore
    await updateDoc(mealPlanRef, {
      days: currentMealPlan.days,
      updatedAt: new Date().toISOString()
    });

  } catch (error) {
    console.error('Error updating meal plan:', error);
    throw error;
  }
};

const calculateCyclePhase = (lastPeriodStart, cycleLength = 28) => {
  if (!lastPeriodStart) return null;
  
  const today = new Date();
  const lastPeriod = new Date(lastPeriodStart);
  const daysSinceLastPeriod = Math.floor((today - lastPeriod) / (1000 * 60 * 60 * 24));
  const currentCycleDay = daysSinceLastPeriod % cycleLength;
  
  if (currentCycleDay <= 5) return 'menstrual';
  if (currentCycleDay <= 13) return 'follicular';
  if (currentCycleDay <= 17) return 'ovulatory';
  return 'luteal';
};

// Purpose: Redirects authenticated users to the home page when they try to access public routes like login, signup, or landing.

import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

const PublicRoute = ({ children }) => {
  const { currentUser } = useAuth();

  if (currentUser) {
    return <Navigate to="/home" />;
  }

  return children;
};

export default PublicRoute;

// Purpose: Route component for landing pages that are truly public (no redirects)

import { useEffect } from 'react';
import Cookies from 'js-cookie';
import { useLocation } from 'react-router-dom';

const LandingRoute = ({ children }) => {
  const location = useLocation();

  useEffect(() => {
    // Extract the landing page type from the path
    const pathSegments = location.pathname.split('/');
    const landingType = pathSegments[1] || 'default';

    // Set cookie for the landing page type
    Cookies.set('landingPageType', landingType, { expires: 7 });
  }, [location]);

  return children;
};

export default LandingRoute;

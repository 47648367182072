import React, { useState, useRef, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { MessageList } from '../components/chat/MessageList';
import { ChatInput } from '../components/chat/ChatInput';
import * as chatService from '../services/chatService';
import * as memoryService from '../services/memoryService';
import { db } from '../firebase';
import { doc, getDoc } from 'firebase/firestore';

const MESSAGE_LIMIT = 50;

const Chatbot = () => {
  const { user, loading } = useAuth();
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [userProfile, setUserProfile] = useState(null);
  const [setLastSyncedMessageCount] = useState(0);
  const [error] = useState(null);
  const messagesEndRef = useRef(null);
  const hasGeneratedSynthesis = useRef(false);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  // Debug auth state
  useEffect(() => {
    console.log('🔐 Auth Check:', { 
      user: user?.uid, 
      loading,
      isAuthenticated: user ? '✅ Logged in' : '❌ Not logged in' 
    });
  }, [user, loading]);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  // Load existing thread and messages
  useEffect(() => {
    const loadExistingChat = async () => {
      if (!user) {
        console.log('👻 Ghost user detected! Please log in to chat');
        return;
      }

      try {
        console.log('🔍 Detective mode: Looking for chat history for user:', user.uid);
        const chatHistory = await chatService.loadChatHistory(user.uid);
        
        if (chatHistory) {
          console.log('📚 Found the ancient scrolls (chat history)!');
          setMessages(chatHistory.messages || []);
        } else {
          console.log('📝 Fresh start! Creating new chat history');
          const welcomeMessage = {
            role: 'assistant',
            content: "Hi! I'm your Foodwise assistant. Feel free to ask me anything about nutrition or your diet preferences!",
            timestamp: new Date().toISOString(),
            userId: user.uid
          };
          setMessages([welcomeMessage]);
          await chatService.updateChatHistory(user.uid, [welcomeMessage]);
        }
      } catch (error) {
        console.error('💥 Oops! Chat loading error:', error);
        setMessages([{
          role: 'assistant',
          content: 'Sorry, I encountered an error loading the chat. Please refresh the page.',
          timestamp: new Date().toISOString(),
          userId: user.uid
        }]);
      }
    };

    loadExistingChat();
  }, [user]);

  // Load user profile and memory
  useEffect(() => {
    const loadUserData = async () => {
      if (!user) return;
      try {
        const [memory, timedPrefs, userDoc] = await Promise.all([
          memoryService.loadMemory(user.uid),
          memoryService.loadTimedPreferences(user.uid),
          getDoc(doc(db, 'users', user.uid))
        ]);
        
        // Combine user data with memory
        setUserProfile({
          ...userDoc.data(),
          memory,
          timedPreferences: timedPrefs
        });
      } catch (error) {
        console.error('Failed to load user data:', error);
      }
    };
    loadUserData();
  }, [user]);

  useEffect(() => {
    const generateSynthesis = async () => {
      if (!user || !messages.length || hasGeneratedSynthesis.current) return;
      
      try {
        console.log('🔄 Generating chat synthesis on unmount...');
        const synthesis = await chatService.synthesizeChat(messages);
        await chatService.saveChatSynthesis(user.uid, synthesis);
        hasGeneratedSynthesis.current = true;
        setLastSyncedMessageCount(messages.length);
        console.log('✅ Chat synthesis saved successfully');
      } catch (error) {
        console.error('💥 Failed to generate chat synthesis:', error);
      }
    };

    return () => {
      generateSynthesis();
    };
  }, [user, messages, setLastSyncedMessageCount]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('✉️ New message incoming!', { inputMessage, user });
    
    if (!inputMessage.trim() || !user) return;
    
    setIsLoading(true);
    const timestamp = new Date().toISOString();
    
    const userMessage = {
      role: 'user',
      content: inputMessage,
      timestamp,
      userId: user.uid
    };

    try {
      const newMessages = [...messages, userMessage].slice(-MESSAGE_LIMIT);
      setMessages(newMessages);
      setInputMessage('');

      const data = await chatService.sendChatMessage(newMessages, userProfile);
      
      let functionResponse = null;
      // Check if there are tool calls in the response
      if (data.response.tool_calls && data.response.tool_calls.length > 0) {
        const toolCall = data.response.tool_calls[0];
        if (toolCall.function) {
          console.log('🔧 Function call received:', {
            name: toolCall.function.name,
            args: JSON.parse(toolCall.function.arguments)
          });
          
          const args = JSON.parse(toolCall.function.arguments);
          
          if (toolCall.function.name === 'update_timed_preference') {
            console.log('⏱️ Updating timed preference:', args);
            await memoryService.updateTimedPreference(user.uid, args.category, args.content, args.duration, args.endDate);
            functionResponse = `I'll remember that preference and help you stay on track with your goal!`;
          } else if (toolCall.function.name === 'update_memory') {
            console.log('📝 Updating memory:', args);
            functionResponse = "I've noted down your preference. Is there anything specific you'd like to know about your diet?";
          }
        }
      }
      
      // Get the response content
      const content = functionResponse || (data.response.content?.[0]?.text || data.response.content || "I couldn't process that message.");
      
      const assistantMessage = {
        role: 'assistant',
        content: content,
        timestamp: new Date().toISOString(),
        userId: user.uid
      };

      const updatedMessages = [...newMessages, assistantMessage];
      setMessages(updatedMessages);
      await chatService.updateChatHistory(user.uid, updatedMessages);

    } catch (error) {
      console.error('💥 Message sending failed:', error);
      setMessages(prev => [...prev, {
        role: 'assistant',
        content: 'Sorry, I encountered an error. Please try again.',
        timestamp: new Date().toISOString(),
        userId: user.uid
      }]);
    } finally {
      setIsLoading(false);
    }
  };

  // Early return if not authenticated
  if (!user && !loading) {
    return (
      <div className="flex flex-col items-center justify-center h-screen bg-gray-50">
        <div className="text-center p-8 bg-white rounded-lg shadow-md">
          <h2 className="text-2xl font-semibold mb-4">Authentication Required</h2>
          <p className="text-gray-600 mb-4">Please log in to access the chat feature.</p>
          <button
            onClick={() => window.location.href = '/login'}
            className="bg-primary text-white px-6 py-2 rounded-md hover:bg-primary-dark transition-colors"
          >
            Go to Login
          </button>
        </div>
      </div>
    );
  }

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-primary"></div>
      </div>
    );
  }

  return (
    <div className="fixed inset-0 bg-background flex flex-col">
      {/* AI Warning Banner */}
      {!localStorage.getItem('hideAIWarning') && (
        <div className="bg-amber-50 border-b border-amber-200">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-3">
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <div className="flex-shrink-0">
                  <svg className="h-5 w-5 text-amber-400" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
                  </svg>
                </div>
                <div className="ml-3">
                  <p className="text-sm text-amber-700">
                    This is an AI-powered chat that may make mistakes. It remembers information about your preferences to provide personalized recommendations.
                  </p>
                </div>
              </div>
              <div className="ml-4 flex-shrink-0">
                <button
                  onClick={() => {
                    localStorage.setItem('hideAIWarning', 'true');
                    // Force a re-render
                    window.location.reload();
                  }}
                  className="inline-flex text-amber-700 hover:text-amber-600"
                >
                  <span className="sr-only">Dismiss</span>
                  <svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      
      {/* Chat Header */}
      <div className="flex items-center justify-between px-4 py-3 border-b border-gray-200 bg-white">
        <div className="flex items-center">
          <h1 className="text-lg font-medium text-gray-900">Chat with AI Nutritionist</h1>
        </div>
        <button
          onClick={() => window.history.back()}
          className="p-2 text-gray-400 hover:text-gray-600 rounded-full hover:bg-gray-100 transition-colors"
          aria-label="Close chat"
        >
          <svg className="w-5 h-5" viewBox="0 0 20 20" fill="currentColor">
            <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
          </svg>
        </button>
      </div>
      
      {/* Chat Container */}
      <div className="flex-1 flex flex-col overflow-hidden">
        {error && (
          <div className="bg-red-50 p-4">
            <p className="text-red-800">{error}</p>
          </div>
        )}
        
        {/* Messages */}
        <MessageList messages={messages} isLoading={isLoading} />
        
        {/* Input - Now sticky */}
        <div className="sticky bottom-0 bg-white border-t border-gray-200 shadow-lg">
          <ChatInput
            inputMessage={inputMessage}
            setInputMessage={setInputMessage}
            onSubmit={handleSubmit}
            isLoading={isLoading}
          />
        </div>
      </div>
    </div>
  );
};

export default Chatbot;

import React from 'react';

const ProgressBar = ({ currentStep, totalSteps }) => {
  const steps = [
    { number: 1, title: 'Personal Info' },
    { number: 2, title: 'Health Metrics' },
    { number: 3, title: 'Goals' },
    { number: 4, title: 'Chat' },
  ];

  return (
    <div className="w-full">
      {/* Progress percentage */}
      <div className="mb-4">
        <div className="text-small font-medium text-primary">
          Step {currentStep} of {totalSteps}
        </div>
        <div className="text-tiny text-text-light">
          {Math.round((currentStep / totalSteps) * 100)}% Complete
        </div>
      </div>

      {/* Progress bar */}
      <div className="relative">
        <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-white-pure border border-primary/20">
          <div
            style={{ width: `${(currentStep / totalSteps) * 100}%` }}
            className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-primary transition-all duration-500"
          />
        </div>

        {/* Step indicators */}
        <div className="grid grid-cols-4 gap-2 -mt-2">
          {steps.map((step) => (
            <div
              key={step.number}
              className={`flex flex-col items-center ${
                step.number === currentStep ? 'text-primary' :
                step.number < currentStep ? 'text-text-light' : 'text-text-dark'
              }`}
            >
              <div
                className={`w-6 h-6 flex items-center justify-center rounded-full transition-all duration-500 ${
                  step.number < currentStep
                    ? 'bg-text-light text-white-pure'
                    : step.number === currentStep
                    ? 'bg-primary text-white-pure ring-4 ring-primary/20'
                    : 'bg-white-pure text-text-dark border border-text-light/30'
                }`}
              >
                {step.number < currentStep ? (
                  <svg className="w-4 h-4" fill="currentColor" viewBox="0 0 20 20">
                    <path
                      fillRule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clipRule="evenodd"
                    />
                  </svg>
                ) : (
                  step.number
                )}
              </div>
              <div className={`text-tiny mt-2 font-medium text-center w-full px-1 ${
                step.number === currentStep ? 'text-primary' :
                step.number < currentStep ? 'text-text-light' : 'text-text-dark'
              }`}>
                {step.title}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProgressBar; 
import { db } from '../firebase';
import { doc, setDoc, getDoc } from 'firebase/firestore';

const NOTIFICATION_CHECK_INTERVAL = 5 * 60 * 1000; // Check every 5 minutes

const getMealTypeForHour = (hour) => {
  if (hour === 8) return 'breakfast';
  if (hour === 12) return 'lunch';
  if (hour === 19) return 'dinner';
  return 'meal';
};

const shouldShowNotification = (hour) => {
  const now = new Date();
  const currentHour = now.getHours();
  const currentMinute = now.getMinutes();
  
  // Show notification if we're within the first 5 minutes of the target hour
  return currentHour === hour && currentMinute < 5;
};

export const scheduleNotifications = async (userId) => {
  try {
    // Check if the browser supports notifications
    if (!('Notification' in window)) {
      console.log('This browser does not support notifications');
      return;
    }

    // Request permission
    const permission = await Notification.requestPermission();
    if (permission !== 'granted') {
      console.log('Notification permission denied');
      return;
    }

    // Get service worker registration
    const registration = await navigator.serviceWorker.ready;

    // Save notification preferences to Firestore
    await setDoc(doc(db, 'users', userId), {
      notificationsEnabled: true,
      notificationTimes: [8, 12, 19] // breakfast, lunch, dinner
    }, { merge: true });

    // Set up periodic checks for notifications
    const checkAndSendNotifications = async () => {
      const userDoc = await getDoc(doc(db, 'users', userId));
      if (!userDoc.exists() || !userDoc.data().notificationsEnabled) {
        return;
      }

      const notificationTimes = [
        { hour: 8, message: "Time to track your breakfast!" },
        { hour: 12, message: "Don't forget to track your lunch!" },
        { hour: 19, message: "Remember to track your dinner!" }
      ];

      for (const { hour, message } of notificationTimes) {
        if (shouldShowNotification(hour)) {
          const mealType = getMealTypeForHour(hour);
          
          try {
            await registration.showNotification('Meal Tracking Reminder', {
              body: message,
              icon: '/logo192.png',
              badge: '/logo192.png',
              tag: `meal-reminder-${mealType}`,
              vibrate: [100, 50, 100],
              actions: [
                {
                  action: 'track',
                  title: 'Track Now'
                }
              ]
            });
          } catch (error) {
            console.error(`Error showing ${mealType} notification:`, error);
          }
        }
      }
    };

    // Initial check
    await checkAndSendNotifications();

    // Set up periodic checks
    const intervalId = setInterval(checkAndSendNotifications, NOTIFICATION_CHECK_INTERVAL);

    // Store the interval ID in localStorage to manage it later
    localStorage.setItem('notificationIntervalId', intervalId.toString());

    return true;
  } catch (error) {
    console.error('Error setting up notifications:', error);
    return false;
  }
};

export const checkNotificationStatus = async (userId) => {
  try {
    const userDoc = await getDoc(doc(db, 'users', userId));
    if (userDoc.exists()) {
      return userDoc.data().notificationsEnabled || false;
    }
    return false;
  } catch (error) {
    console.error('Error checking notification status:', error);
    return false;
  }
};

export const toggleNotifications = async (userId, enabled) => {
  try {
    if (enabled) {
      return await scheduleNotifications(userId);
    } else {
      // Disable notifications
      await setDoc(doc(db, 'users', userId), {
        notificationsEnabled: false
      }, { merge: true });
      
      // Clear the notification check interval
      const intervalId = localStorage.getItem('notificationIntervalId');
      if (intervalId) {
        clearInterval(parseInt(intervalId));
        localStorage.removeItem('notificationIntervalId');
      }

      // Cancel existing notifications if possible
      const registration = await navigator.serviceWorker.ready;
      const notifications = await registration.getNotifications();
      notifications.forEach(notification => notification.close());
      
      return true;
    }
  } catch (error) {
    console.error('Error toggling notifications:', error);
    return false;
  }
}; 
// MealCalendar.js
// here we generate the weekly plan (and api call for the weekly plan)

import React, { useState, useRef, useEffect, useCallback } from 'react';
import { ChevronLeftIcon, ChevronRightIcon, SparklesIcon, ChatBubbleLeftIcon } from '@heroicons/react/24/solid';
import { motion, useMotionValue } from 'framer-motion';
import MealPopup from './MealPopup';
import NutritionistChatPopup from '../chat/NutritionistChatPopup';
import * as chatService from '../../services/chatService';

import { getAuth } from 'firebase/auth';
import { generateMealPlan, saveMealPlan, getMealPlan } from '../../services/mealPlanService';
import { toast } from 'react-hot-toast';

const MealCalendar = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [days, setDays] = useState([]); // Will hold our calendar data
  const x = useMotionValue(0);
  const containerRef = useRef(null);
  const [containerWidth, setContainerWidth] = useState(0);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [selectedMeal, setSelectedMeal] = useState(null);
  const [selectedMealType, setSelectedMealType] = useState(null);
  const [generatingDays, setGeneratingDays] = useState(new Set()); // Track which days are generating
  const [setIsGenerating] = useState(false);
  const [setHasAllMeals] = useState(false);
  const [mealPlanPreferences] = useState('');
  const [isChatPopupOpen, setIsChatPopupOpen] = useState(false);
  const [showGenerateConfirm, setShowGenerateConfirm] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [generationStep, setGenerationStep] = useState(0);
  const [generationFeedback, setGenerationFeedback] = useState('');

  // Helper function to get meal icon
  const getMealIcon = (mealType) => {
    switch (mealType) {
      case 'breakfast':
        return '🍳';
      case 'lunch':
        return '🍜';
      case 'snack':
      case 'snacks':
        return '🥪';
      case 'dinner':
        return '🍽️';
      default:
        return '🍴';
    }
  };

  // Function to check if all meal slots are filled
  const checkAllMealsGenerated = (mealPlan) => {
    if (!mealPlan || !mealPlan.days) return false;
    
    // Get current date in local timezone
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    
    console.log('Checking meals starting from:', currentDate.toLocaleString());
    
    // Get the next 4 days in local timezone
    const dates = Array.from({ length: 4 }, (_, i) => {
      const date = new Date(currentDate);
      date.setDate(currentDate.getDate() + i);
      const dateString = date.toLocaleDateString('en-CA');
      //console.log(`Checking date ${i + 1}:`, dateString);
      return dateString;
    });

    // Check if each date has all meal types
    return dates.every(date => {
      const dayMeals = mealPlan.days.find(d => d.date === date);
      if (!dayMeals) {
        console.log('Missing meals for date:', date);
        return false;
      }

      const mealTimes = dayMeals.meals.map(m => {
        const hour = parseInt(m.time.split(':')[0]);
        if (hour >= 7 && hour <= 9) return 'breakfast';
        if (hour >= 12 && hour <= 14) return 'lunch';
        if (hour >= 15 && hour <= 16) return 'snack';
        if (hour >= 18 && hour <= 20) return 'dinner';
        return null;
      });

      const hasAllMealTypes = ['breakfast', 'lunch', 'snack', 'dinner'].every(type => 
        mealTimes.includes(type)
      );
      
      if (!hasAllMealTypes) {
        console.log('Missing meal types for date:', date, 'Found types:', mealTimes);
      }
      
      return hasAllMealTypes;
    });
  };

  // Function to fetch meals and create calendar
  const fetchMealsAndCreateCalendar = useCallback(async () => {
    const auth = getAuth();
    if (!auth.currentUser) return;

    try {
      // Get the user's meal plan
      const mealPlan = await getMealPlan(auth.currentUser.uid);
      console.log('Fetched meal plan:', mealPlan);
      
      // Update button state
      setHasAllMeals(checkAllMealsGenerated(mealPlan));

      // 1. Set up our date range using local timezone
      const TODAY = new Date();
      // Ensure we're at the start of today in the local timezone
      TODAY.setHours(0, 0, 0, 0);
      
      // Log the date we're starting from
      console.log('Creating calendar starting from:', TODAY.toLocaleString());

      // 2. Create empty calendar structure
      const calendarDays = [];
      for (let i = 0; i < 6; i++) {
        const date = new Date(TODAY);
        date.setDate(TODAY.getDate() + i);
        // Use consistent date format in local timezone
        const dateString = date.toISOString().split('T')[0];
        
        console.log(`Creating calendar day ${i + 1}:`, dateString);
        
        // Initialize with empty meals array
        calendarDays.push({
          date,
          dateString,
          meals: []
        });
      }

      // Process meal plans
      if (mealPlan && mealPlan.days) {
        console.log('Processing meal plan days:', mealPlan.days.length);
        
        mealPlan.days.forEach(planDay => {
          // Normalize the plan day date to match our format
          const planDateString = new Date(planDay.date).toISOString().split('T')[0];
          
          // Find the matching calendar day using the normalized date string
          const calendarDay = calendarDays.find(day => day.dateString === planDateString);
          console.log(`Processing plan day ${planDateString}:`, {
            found: !!calendarDay,
            mealCount: planDay.meals?.length
          });

          if (calendarDay) {
            // Simply use the meals array from the plan
            calendarDay.meals = planDay.meals.map(meal => ({
              ...meal,
              description: meal.description || '',
              title: meal.name || '',
              ingredients: meal.ingredients || [],
              instructions: meal.instructions || [],
              enhancedRecipe: meal.enhancedRecipe || false,
              tags: meal.tags || [],
              fromMealPlan: true
            }));
            
            // Sort meals by time
            calendarDay.meals.sort((a, b) => {
              const timeA = parseInt(a.time.split(':')[0]);
              const timeB = parseInt(b.time.split(':')[0]);
              return timeA - timeB;
            });
          }
        });
      }

      console.log('Final calendar days:', calendarDays.map(day => ({
        date: day.dateString,
        mealCount: day.meals.length
      })));
      setDays(calendarDays);
    } catch (error) {
      console.error('Error fetching meals:', error);
      setDays([]); // Use empty calendar on error
    }
  }, [setHasAllMeals]);

  useEffect(() => {
    fetchMealsAndCreateCalendar();
  }, [fetchMealsAndCreateCalendar]);

  // Listen for meal plan updates
  useEffect(() => {
    const handleMealPlanUpdate = () => {
      fetchMealsAndCreateCalendar();
    };

    window.addEventListener('mealPlanUpdated', handleMealPlanUpdate);
    return () => window.removeEventListener('mealPlanUpdated', handleMealPlanUpdate);
  }, [fetchMealsAndCreateCalendar]);

  useEffect(() => {
    if (containerRef.current) {
      setContainerWidth(containerRef.current.offsetWidth);
      const resizeObserver = new ResizeObserver((entries) => {
        setContainerWidth(entries[0].contentRect.width);
      });
      resizeObserver.observe(containerRef.current);
      return () => resizeObserver.disconnect();
    }
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleDragEnd = (event, info) => {
    const threshold = containerWidth / 4;
    const dragDistance = info.offset.x;
    
    if (dragDistance > threshold && currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    } else if (dragDistance < -threshold && currentIndex < days.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const handleNext = () => {
    if (currentIndex < days.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const handlePrevious = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const formatDate = (date) => {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);

    // Check if date is today or tomorrow
    if (date.toDateString() === today.toDateString()) {
      return 'Today';
    } else if (date.toDateString() === tomorrow.toDateString()) {
      return 'Tomorrow';
    }

    // For mobile, show shorter format
    if (window.innerWidth < 768) {
      return date.toLocaleDateString('en-US', { 
        day: 'numeric',
        weekday: 'short',
        month: 'short'
      });
    }
    
    // For desktop, show full format
    return date.toLocaleDateString('en-US', {
      day: 'numeric',
      weekday: 'long',
      month: 'long'
    });
  };

  const handleChatComplete = async (synthesis) => {
    try {
      setIsGenerating(true);
      const auth = getAuth();
      if (!auth.currentUser) return;

      // Save the synthesis without generating new meals
      await chatService.saveChatSynthesis(auth.currentUser.uid, synthesis);
      
      // Refresh the calendar to reflect any UI updates
      await fetchMealsAndCreateCalendar();
    } catch (error) {
      console.error('Error saving chat synthesis:', error);
    } finally {
      setIsGenerating(false);
    }
  };

  // New function to simulate step-by-step generation feedback
  const updateGenerationProgress = async () => {
    const steps = [
      "Analyzing your dietary preferences and restrictions...",
      "Calculating nutritional requirements for the day...",
      "Considering your health goals and activity level...",
      "Crafting personalized meal suggestions...",
      "Finalizing your personal meal plan with balanced nutrition..."
    ];

    for (let i = 0; i < steps.length; i++) {
      setGenerationStep(i);
      setGenerationFeedback(steps[i]);
      await new Promise(resolve => setTimeout(resolve, 1000)); // Simulate API processing time
    }
  };

  // Modified handleGenerateDayMeals function
  const handleGenerateDayMeals = async (date, preferences = '') => {
    const auth = getAuth();
    if (!auth.currentUser) return;

    try {
      setShowGenerateConfirm(false);
      setIsProcessing(true);
      setGeneratingDays(prev => new Set([...prev, date.toISOString()]));

      // Start the progress updates in parallel
      updateGenerationProgress();

      // Get existing meals to provide context
      const existingPlan = await getMealPlan(auth.currentUser.uid);
      const existingMeals = existingPlan?.days || [];

      // Normalize the target date
      const targetDate = date.toISOString().split('T')[0];
      console.log('Generating meals for date:', targetDate);

      // Generate meals just for this day
      const result = await generateMealPlan(existingMeals, auth.currentUser.uid, {
        targetDate,
        preferences
      });

      console.log('Generated result:', result);

      // Update the calendar with new meals
      await saveMealPlan(auth.currentUser.uid, result);
      
      // Add a small delay to ensure the progress animation completes
      await new Promise(resolve => setTimeout(resolve, 500));
      
      // Refresh the calendar
      await fetchMealsAndCreateCalendar();
    } catch (error) {
      console.error('Error generating meals for day:', error);
      toast.error('Failed to generate meals');
    } finally {
      setIsProcessing(false);
      setGenerationStep(0);
      setGenerationFeedback('');
      setGeneratingDays(prev => {
        const next = new Set(prev);
        next.delete(date.toISOString());
        return next;
      });
    }
  };

  // Modify the renderDay function to handle the new meal structure
  const renderDay = (day) => {
    const mealTypes = ['breakfast', 'lunch', 'snack', 'dinner'];
    const date = new Date(day.date);

    // Check if all meal types are present
    const hasAllMealTypes = mealTypes.every(type => {
      return day.meals.some(m => {
        const hour = parseInt(m.time.split(':')[0]);
        if (type === 'breakfast' && hour >= 7 && hour <= 9) return true;
        if (type === 'lunch' && hour >= 12 && hour <= 14) return true;
        if (type === 'snack' && hour >= 15 && hour <= 16) return true;
        if (type === 'dinner' && hour >= 18 && hour <= 20) return true;
        return false;
      });
    });

    // Calculate daily total calories
    const dailyTotalCalories = day.meals.reduce((total, meal) => total + (meal.calories || 0), 0);

    // Check if this day is currently generating
    const isGenerating = generatingDays.has(date.toISOString());

    return (
      <div className="bg-white rounded-lg shadow-md p-4">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-lg font-semibold">{formatDate(date)}</h3>
          <div className="flex items-center gap-2">
            <div className="text-sm text-gray-600 font-medium">
              {dailyTotalCalories > 0 ? `${dailyTotalCalories} kcal` : ''}
            </div>
            {/* Generate Meals Button - Only show if not all meals are set */}
            {!hasAllMealTypes && (
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  setSelectedDate(date);
                  setShowGenerateConfirm(true);
                }}
                disabled={isGenerating}
                className={`
                  py-1.5 px-3 rounded-lg text-sm font-medium
                  transition-all duration-200 flex items-center gap-1.5
                  ${isGenerating
                    ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
                    : 'bg-primary text-white hover:bg-primary/90 shadow-sm hover:shadow'}
                `}
              >
                {isGenerating ? (
                  <div className="flex items-center gap-1.5">
                    <div className="w-3.5 h-3.5 border-2 border-white/30 border-t-white rounded-full animate-spin" />
                    <span>Generating...</span>
                  </div>
                ) : (
                  <div className="flex items-center gap-1.5">
                    <SparklesIcon className="h-3.5 w-3.5" />
                    <span>Generate</span>
                  </div>
                )}
              </button>
            )}
          </div>
        </div>
        <div className="space-y-3">
          {mealTypes.map((type) => {
            const meal = day.meals.find(m => {
              const hour = parseInt(m.time.split(':')[0]);
              if (type === 'breakfast' && hour >= 7 && hour <= 9) return true;
              if (type === 'lunch' && hour >= 12 && hour <= 14) return true;
              if (type === 'snack' && hour >= 15 && hour <= 16) return true;
              if (type === 'dinner' && hour >= 18 && hour <= 20) return true;
              return false;
            });

            return (
              <div 
                key={type}
                onClick={() => {
                  if (meal) {
                    setSelectedMeal(meal);
                    setSelectedMealType(type);
                  }
                }}
                className={`p-3 rounded-lg transition-all duration-200 ${
                  meal ? 'bg-white border border-gray-200 hover:border-primary-500 cursor-pointer' 
                       : 'bg-gray-50 border border-dashed border-gray-300'
                }`}
              >
                <div className="flex justify-between items-center">
                  <div className="flex items-center space-x-2">
                    <span className="text-xl">{getMealIcon(type)}</span>
                    <span className="font-medium capitalize">{type}</span>
                  </div>
                  {meal && meal.calories > 0 && (
                    <span className="text-sm text-gray-600">{meal.calories} kcal</span>
                  )}
                </div>
                {meal ? (
                  <div className="mt-1">
                    <p className="text-sm text-gray-600 line-clamp-2">{meal.name || meal.description}</p>
                  </div>
                ) : (
                  <p className="text-sm text-gray-400 mt-1">No meal planned</p>
                )}
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  return (
    <div className="space-y-6 mt-8">
      {/* Chat with AI Section */}
      <div className="bg-white rounded-lg shadow-lg p-4">
        <div className="flex items-center justify-between">
          <div className="flex-1">
            <h2 className="text-lg font-medium text-gray-900">
              Need help planning your meals?
            </h2>
            <p className="text-sm text-gray-500 mt-1">
              Get personalized suggestions from our AI nutritionist
            </p>
          </div>
          <button
            onClick={() => setIsChatPopupOpen(true)}
            className="flex items-center text-primary-dark hover:text-primary-darker font-medium transition-colors ml-4"
          >
            <ChatBubbleLeftIcon className="h-5 w-5 mr-1.5" />
            <span className="text-sm">Chat with AI</span>
          </button>
        </div>
      </div>

      {/* Meal Planner Section */}
      <div className="bg-white rounded-lg shadow-lg p-6 mb-8">
        {/* Desktop View */}
        <div className="hidden md:flex justify-between items-center mb-6">
          <h2 className="text-2xl font-bold text-gray-900">
            Meal Planner
          </h2>
          <div className="flex items-center space-x-4">
            <button
              onClick={handlePrevious}
              disabled={currentIndex === 0}
              className={`p-2 rounded-full ${
                currentIndex === 0
                  ? 'text-gray-400 bg-gray-100'
                  : 'text-gray-600 hover:bg-gray-100'
              }`}
            >
              <ChevronLeftIcon className="h-5 w-5" />
            </button>
            <button
              onClick={handleNext}
              disabled={currentIndex >= days.length - 1}
              className={`p-2 rounded-full ${
                currentIndex >= days.length - 1
                  ? 'text-gray-400 bg-gray-100'
                  : 'text-gray-600 hover:bg-gray-100'
              }`}
            >
              <ChevronRightIcon className="h-5 w-5" />
            </button>
          </div>
        </div>

        {/* Mobile View */}
        <div className="flex md:hidden justify-between items-center mb-6">
          <div className="flex items-center space-x-3">
            <h2 className="text-xl font-bold text-gray-900">Meal Planner</h2>
          </div>
          <div className="flex items-center space-x-2">
            <button
              onClick={handlePrevious}
              disabled={currentIndex === 0}
              className={`p-1.5 rounded-full ${
                currentIndex === 0
                  ? 'text-gray-400 bg-gray-100'
                  : 'text-gray-600 hover:bg-gray-100'
              }`}
            >
              <ChevronLeftIcon className="h-4 w-4" />
            </button>
            <button
              onClick={handleNext}
              disabled={currentIndex >= days.length - 1}
              className={`p-1.5 rounded-full ${
                currentIndex >= days.length - 1
                  ? 'text-gray-400 bg-gray-100'
                  : 'text-gray-600 hover:bg-gray-100'
              }`}
            >
              <ChevronRightIcon className="h-4 w-4" />
            </button>
          </div>
        </div>

        <div 
          ref={containerRef}
          className="relative overflow-hidden"
          style={{ touchAction: 'pan-y pinch-zoom' }}
        >
          <motion.div
            drag="x"
            dragConstraints={{ 
              left: -containerWidth * (days.length - (isMobile ? 1 : 3)), 
              right: 0 
            }}
            dragElastic={0.1}
            dragMomentum={false}
            onDragEnd={handleDragEnd}
            animate={{ 
              x: -currentIndex * (containerWidth / (isMobile ? 1 : 3)) 
            }}
            transition={{ type: "spring", stiffness: 300, damping: 30 }}
            className="flex"
            style={{ x }}
          >
            {days.map((day, index) => {
              const isVisible = index >= currentIndex && index < currentIndex + (isMobile ? 1 : 3);
              return (
                <div
                  key={index}
                  className="w-full md:w-1/3 flex-shrink-0"
                  style={{ 
                    paddingRight: index === days.length - 1 ? 0 : '1rem',
                    opacity: isVisible ? 1 : 0.5,
                    transition: 'opacity 0.3s ease'
                  }}
                >
                  {renderDay(day)}
                </div>
              );
            })}
          </motion.div>
        </div>

        {/* Generate Confirmation Popup */}
        {showGenerateConfirm && selectedDate && (
          <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50" onClick={() => setShowGenerateConfirm(false)}>
            <div className="bg-white rounded-lg p-6 max-w-md w-full mx-4 shadow-xl" onClick={e => e.stopPropagation()}>
              <div className="flex flex-col items-center text-center mb-6">
                <div className="w-12 h-12 bg-primary/10 rounded-full flex items-center justify-center mb-4">
                  <SparklesIcon className="h-6 w-6 text-primary" />
                </div>
                <h3 className="text-lg font-semibold text-gray-900 mb-2">
                  Generate Meals for {formatDate(selectedDate)}
                </h3>
                <p className="text-sm text-gray-500">
                  This will create a balanced meal plan for this day based on your preferences and nutritional needs.
                </p>
              </div>
              
              <div className="flex justify-end gap-3">
                <button
                  onClick={() => setShowGenerateConfirm(false)}
                  className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-lg hover:bg-gray-50"
                >
                  Cancel
                </button>
                <button
                  onClick={() => {
                    handleGenerateDayMeals(selectedDate, mealPlanPreferences);
                    setShowGenerateConfirm(false);
                  }}
                  className="px-6 py-2 text-sm font-medium text-white bg-primary rounded-lg hover:bg-primary/90"
                >
                  Generate
                </button>
              </div>
            </div>
          </div>
        )}

        {selectedMeal && (
          <div className="fixed inset-0 bg-black bg-opacity-30 z-50" onClick={() => {
            setSelectedMeal(null);
            setSelectedMealType(null);
          }}>
            <MealPopup
              meal={selectedMeal}
              mealType={selectedMealType}
              onClose={() => {
                setSelectedMeal(null);
                setSelectedMealType(null);
              }}
            />
          </div>
        )}

        <NutritionistChatPopup
          isOpen={isChatPopupOpen}
          onClose={() => setIsChatPopupOpen(false)}
          onGenerateMealPlan={handleChatComplete}
        />

        {/* Processing Popup */}
        {isProcessing && (
          <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
            <div className="bg-white rounded-lg p-8 max-w-md w-full mx-4 shadow-xl">
              <div className="flex flex-col items-center justify-center space-y-6">
                <div className="relative w-24 h-24">
                  <div className="absolute inset-0 rounded-full border-4 border-t-primary border-r-primary/30 border-b-primary/10 border-l-primary/70 animate-spin"></div>
                  <div className="absolute inset-4 bg-gradient-to-br from-primary/20 to-primary/40 rounded-full animate-pulse"></div>
                  <div className="absolute inset-0 flex items-center justify-center text-2xl">
                    🧑‍🍳
                  </div>
                </div>
                <div className="flex flex-col items-center space-y-3">
                  <div className="text-lg font-medium text-gray-900">
                    Crafting Your Meal Plan
                  </div>
                  <div className="w-full bg-gray-200 rounded-full h-2 max-w-[200px]">
                    <div 
                      className="bg-primary h-2 rounded-full transition-all duration-300"
                      style={{ width: `${(generationStep + 1) * 20}%` }}
                    ></div>
                  </div>
                  <div className="text-sm text-gray-600 text-center min-h-[40px] animate-fade-in">
                    {generationFeedback}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MealCalendar;
import React, { useState, useEffect } from 'react';
import { signOut } from "firebase/auth";
import { auth, db } from '../firebase';
import { Link, useNavigate } from 'react-router-dom';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { FiEdit2, FiCheck, FiInfo } from 'react-icons/fi';
import { toast } from 'react-hot-toast';
import { calculateCalories, calculateBMI } from '../services/calorieCalculator';

const calculateAge = (dateOfBirth) => {
  if (!dateOfBirth) return null;
  const birthDate = new Date(dateOfBirth);
  const today = new Date();
  let age = today.getFullYear() - birthDate.getFullYear();
  const monthDiff = today.getMonth() - birthDate.getMonth();
  
  if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
};

const SectionHeader = ({ title, description, isEditing, onEdit, onSave, isSaving, showEditButton = true }) => (
  <div className="flex justify-between items-start mb-4">
    <div>
      <h2 className="text-lg font-medium text-text-dark">{title}</h2>
      {description && <p className="text-sm text-text-light mt-1">{description}</p>}
    </div>
    {showEditButton && (
      isEditing ? (
        <button
          onClick={onSave}
          disabled={isSaving}
          className="save-button px-4 py-1.5 rounded-full bg-white text-gray-900 border border-gray-200 flex items-center gap-2 text-sm hover:bg-gray-50 transition-colors disabled:opacity-50"
          aria-label="Save changes"
        >
          <FiCheck className="w-4 h-4" />
          <span>{isSaving ? 'Saving...' : 'Save'}</span>
        </button>
      ) : (
        <button
          onClick={onEdit}
          className="p-1.5 rounded-full hover:bg-gray-100 transition-colors text-gray-600"
          aria-label="Edit information"
        >
          <FiEdit2 className="w-4 h-4" />
        </button>
      )
    )}
  </div>
);

const Account = () => {
  const navigate = useNavigate();
  const [setIsEditing] = useState(false);
  const [userData, setUserData] = useState({
    firstName: '',
    lastName: '',
    gender: '',
    dateOfBirth: '',
    weight: '', // in kg
    height: '', // in cm
    activityLevel: '', // sedentary, lightly active, moderately active, very active
    sportsPerWeek: 0, // number of sports sessions per week
    goal: '', // weight loss, muscle gain, maintenance, general health
    foodPreferences: '',
    allergies: [],
    showMealConfirmation: false,
    // generateRecipeImages: false, // temporarily disabled
    desiredCalories: '',
    calculatedCalories: {
      bmr: 0,
      tdee: 0,
      bmi: 0,
      bmiCategory: ''
    },
    menstrualCycle: {
      enabled: false,
      cycleLength: 28,
      lastPeriodStart: '',
    },
    healthFocus: '', // gut microbiome, longevity, or fitness
  });
  const [dietaryContext, setDietaryContext] = useState([]);
  const [timedPreferences, setTimedPreferences] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const [isHealthFocusExpanded, setIsHealthFocusExpanded] = useState(false);
  const [editingSections, setEditingSections] = useState({
    personalInfo: false,
    healthFocus: false,
    menstrualCycle: false,
    healthMetrics: false,
    settings: false
  });

  const healthFocusOptions = [
    {
      id: 'gut-microbiome',
      title: 'Gut Microbiome',
      description: 'Focus on foods that promote digestive health and a balanced microbiome',
      icon: '🦠',
      benefits: ['Improved digestion', 'Enhanced immune system', 'Better nutrient absorption']
    },
    {
      id: 'longevity',
      title: 'Longevity',
      description: 'Optimize your diet for healthy aging and cellular health',
      icon: '🌱',
      benefits: ['Anti-aging benefits', 'Cell repair support', 'Reduced inflammation']
    },
    {
      id: 'fitness',
      title: 'Fitness',
      description: 'Nutrition tailored for athletic performance and muscle recovery',
      icon: '💪',
      benefits: ['Muscle recovery', 'Enhanced performance', 'Energy optimization']
    }
  ];

  useEffect(() => {
    const fetchUserData = async () => {
      if (!auth.currentUser) return;
      
      try {
        const userDoc = await getDoc(doc(db, 'users', auth.currentUser.uid));
        if (userDoc.exists()) {
          const data = userDoc.data();
          setUserData({
            ...data,
            // Ensure all fields exist with defaults
            foodPreferences: data.foodPreferences || '',
            allergies: data.allergies || [],
            showMealConfirmation: data.showMealConfirmation ?? true,
            // generateRecipeImages: data.generateRecipeImages ?? false, // temporarily disabled
            menstrualCycle: data.menstrualCycle || {
              enabled: false,
              cycleLength: 28,
              lastPeriodStart: '',
            },
            weight: data.weight || '',
            height: data.height || '',
            activityLevel: data.activityLevel || '',
            sportsPerWeek: data.sportsPerWeek || 0,
            goal: data.goal || '',
            desiredCalories: data.desiredCalories || '',
            calculatedCalories: data.calculatedCalories || {
              bmr: 0,
              tdee: 0,
              bmi: 0,
              bmiCategory: ''
            },
            healthFocus: data.healthFocus || '',
          });
        }

        // Load dietary context (formerly assistant memory)
        const contextDoc = await getDoc(doc(db, 'assistantMemory', auth.currentUser.uid));
        if (contextDoc.exists()) {
          setDietaryContext(contextDoc.data().tags || []);
        }

        // Load timed preferences
        const prefsDoc = await getDoc(doc(db, 'timedPreferences', auth.currentUser.uid));
        if (prefsDoc.exists()) {
          const now = new Date();
          const activePrefs = (prefsDoc.data().preferences || []).filter(pref => {
            return new Date(pref.endDate) > now;
          });
          setTimedPreferences(activePrefs);
        }

      } catch (error) {
        console.error("Error fetching user data:", error);
        toast.error("Failed to load user data");
      }
    };

    if (auth.currentUser) {
      fetchUserData();
    }
  }, []);

  useEffect(() => {
    const calculateUserCalories = () => {
      if (userData.weight && userData.height && userData.gender && userData.dateOfBirth && userData.activityLevel) {
        const age = calculateAge(userData.dateOfBirth);
        const calories = calculateCalories({
          weight: parseFloat(userData.weight),
          height: parseFloat(userData.height),
          age,
          gender: userData.gender,
          activityLevel: userData.activityLevel,
          customCalories: userData.desiredCalories ? parseFloat(userData.desiredCalories) : null
        });

        const bmi = calculateBMI(parseFloat(userData.weight), parseFloat(userData.height));
        const bmiCategory = getBMICategory(bmi);

        setUserData(prev => ({
          ...prev,
          calculatedCalories: {
            ...calories,
            bmi,
            bmiCategory
          }
        }));
      }
    };

    calculateUserCalories();
  }, [userData.weight, userData.height, userData.gender, userData.dateOfBirth, userData.activityLevel, userData.desiredCalories]);

  const handleSave = async (e) => {
    // Prevent event from bubbling up
    if (e) {
      e.stopPropagation();
    }
    
    // Prevent double-clicks
    if (isSaving) {
      return;
    }

    try {
      setIsSaving(true);
      const user = auth.currentUser;
      if (!user) {
        toast.error("No user logged in");
        return;
      }

      // Create a clean copy of the data to save, filtering out undefined values
      const rawPreferences = {
        firstName: userData.firstName,
        lastName: userData.lastName,
        gender: userData.gender,
        dateOfBirth: userData.dateOfBirth,
        weight: userData.weight,
        height: userData.height,
        activityLevel: userData.activityLevel,
        sportsPerWeek: userData.sportsPerWeek,
        goal: userData.goal,
        foodPreferences: userData.foodPreferences,
        allergies: userData.allergies,
        showMealConfirmation: userData.showMealConfirmation,
        // generateRecipeImages: userData.generateRecipeImages, // temporarily disabled
        desiredCalories: userData.desiredCalories,
        calculatedCalories: userData.calculatedCalories,
        menstrualCycle: userData.menstrualCycle,
        healthFocus: userData.healthFocus,
        lastUpdated: new Date().toISOString()
      };

      // Filter out undefined values
      const preferences = Object.fromEntries(
        Object.entries(rawPreferences)
          .filter(([_, value]) => value !== undefined)
      );

      await setDoc(doc(db, 'users', user.uid), preferences, { merge: true });
      setIsEditing(false);
      toast.success("Preferences saved successfully!");
    } catch (error) {
      console.error("Error saving user data:", error);
      toast.error("Failed to save preferences");
    } finally {
      // Add a small delay before allowing another save
      setTimeout(() => {
        setIsSaving(false);
      }, 1000);
    }
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/');
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  const handleToggleChange = (e) => {
    const newValue = e.target.checked;
    setUserData(prev => ({
      ...prev,
      showMealConfirmation: newValue
    }));
    if (auth.currentUser) {
      setDoc(doc(db, 'users', auth.currentUser.uid), {
        showMealConfirmation: newValue
      }, { merge: true });
    }
  };

  // Generate Recipe Images setting - temporarily disabled
  // const handleGenerateRecipeImagesToggleChange = (e) => {
  //   const newValue = e.target.checked;
  //   setUserData(prev => ({
  //     ...prev,
  //     generateRecipeImages: newValue
  //   }));
  //   if (auth.currentUser) {
  //     setDoc(doc(db, 'users', auth.currentUser.uid), {
  //       generateRecipeImages: newValue
  //     }, { merge: true });
  //   }
  // };

  const handleDeleteTag = async (tagToDelete) => {
    try {
      const updatedTags = dietaryContext.filter(tag => tag !== tagToDelete);
      await setDoc(doc(db, 'assistantMemory', auth.currentUser.uid), {
        tags: updatedTags
      }, { merge: true });
      setDietaryContext(updatedTags);
    } catch (error) {
      console.error('Error deleting tag:', error);
    }
  };

  const handleDeleteTimedPreference = async (prefToDelete) => {
    try {
      const updatedPrefs = timedPreferences.filter(pref => 
        pref.content !== prefToDelete.content || 
        pref.category !== prefToDelete.category
      );
      await setDoc(doc(db, 'timedPreferences', auth.currentUser.uid), {
        preferences: updatedPrefs
      }, { merge: true });
      setTimedPreferences(updatedPrefs);
    } catch (error) {
      console.error('Error deleting timed preference:', error);
    }
  };

  const getBMICategory = (bmi) => {
    if (bmi < 18.5) return 'Underweight';
    if (bmi < 25) return 'Normal weight';
    if (bmi < 30) return 'Overweight';
    return 'Obese';
  };

  const handleSectionEdit = (section) => {
    setEditingSections(prev => ({ ...prev, [section]: true }));
  };

  const handleSectionSave = async (section) => {
    try {
      setIsSaving(true);
      await handleSave();
      setEditingSections(prev => ({ ...prev, [section]: false }));
    } catch (error) {
      console.error(`Error saving ${section}:`, error);
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <div className="space-y-6 mt-8">
      {/* Account Header */}
      <h1 className="text-2xl font-bold text-gray-900">
        Your Account
      </h1>
      
      <div className="bg-background min-h-screen">
        <div className="px-4 sm:px-6 lg:px-8 max-w-7xl mx-auto pt-page-top">
          <SectionHeader
            title="Your Account"
            description="Manage your preferences and settings"
            showEditButton={false}
          />

          {auth.currentUser?.email?.endsWith('@foodwise.me') && (
            <div className="mb-8 p-4 bg-yellow-50 border border-yellow-200 rounded-lg">
              <div className="flex items-center justify-between">
                <div>
                  <h3 className="text-lg font-medium text-yellow-800">Testing Tools</h3>
                  <p className="text-sm text-yellow-600">Use this section to test various features</p>
                </div>
                <div className="flex gap-2">
                  <Link 
                    to="/admin/waitlist"
                    className="inline-flex items-center px-4 py-2 bg-yellow-500 text-yellow-900 text-sm font-medium rounded-full hover:bg-yellow-400 transition-colors"
                  >
                    View Waitlist
                  </Link>
                  <button
                    onClick={() => navigate('/onboarding')}
                    className="inline-flex items-center px-4 py-2 bg-yellow-500 text-yellow-900 text-sm font-medium rounded-full hover:bg-yellow-400 transition-colors"
                  >
                    Test Onboarding
                  </button>
                </div>
              </div>
            </div>
          )}

          <div className="max-w-2xl space-y-6 mb-8 edit-form bg-white rounded-xl p-6 shadow-sm">
            <SectionHeader
              title="Personal Information"
              isEditing={editingSections.personalInfo}
              onEdit={() => handleSectionEdit('personalInfo')}
              onSave={() => handleSectionSave('personalInfo')}
              isSaving={isSaving}
            />
            <div className="grid grid-cols-2 gap-6">
              <div>
                <label className="block text-xs uppercase tracking-wider text-gray-500 mb-1">First Name</label>
                {editingSections.personalInfo ? (
                  <input
                    type="text"
                    value={userData.firstName}
                    onChange={(e) => setUserData({...userData, firstName: e.target.value})}
                    className="w-full h-[36px] px-3 border rounded-lg focus:outline-none focus:ring-1 focus:ring-gray-400"
                  />
                ) : (
                  <p className="text-text-dark h-[36px] px-3 flex items-center">{userData.firstName || '-'}</p>
                )}
              </div>
              <div>
                <label className="block text-xs uppercase tracking-wider text-gray-500 mb-1">Last Name</label>
                {editingSections.personalInfo ? (
                  <input
                    type="text"
                    value={userData.lastName}
                    onChange={(e) => setUserData({...userData, lastName: e.target.value})}
                    className="w-full h-[36px] px-3 border rounded-lg focus:outline-none focus:ring-1 focus:ring-gray-400"
                  />
                ) : (
                  <p className="text-text-dark h-[36px] px-3 flex items-center">{userData.lastName || '-'}</p>
                )}
              </div>
            </div>

            <div>
              <label className="block text-xs uppercase tracking-wider text-gray-500 mb-1">Email</label>
              <p className="text-text-dark h-[36px] px-3 flex items-center">{auth.currentUser?.email || '-'}</p>
            </div>

            <div className="grid grid-cols-2 gap-6">
              <div>
                <label className="block text-xs uppercase tracking-wider text-gray-500 mb-1">Gender</label>
                {editingSections.personalInfo ? (
                  <select
                    value={userData.gender}
                    onChange={(e) => setUserData({...userData, gender: e.target.value})}
                    className="w-full h-[36px] px-3 border rounded-lg focus:outline-none focus:ring-1 focus:ring-gray-400 appearance-none bg-white"
                  >
                    <option value="">Select gender</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                  </select>
                ) : (
                  <p className="text-text-dark h-[36px] px-3 flex items-center capitalize">{userData.gender || '-'}</p>
                )}
              </div>
              <div>
                <label className="block text-xs uppercase tracking-wider text-gray-500 mb-1">
                  {editingSections.personalInfo ? 'Date of Birth' : 'Age'}
                </label>
                {editingSections.personalInfo ? (
                  <input
                    type="date"
                    value={userData.dateOfBirth}
                    onChange={(e) => setUserData({...userData, dateOfBirth: e.target.value})}
                    className="w-full h-[36px] px-3 border rounded-lg focus:outline-none focus:ring-1 focus:ring-gray-400"
                  />
                ) : (
                  <p className="text-text-dark h-[36px] px-3 flex items-center">
                    {userData.dateOfBirth ? `${calculateAge(userData.dateOfBirth)} years` : '-'}
                  </p>
                )}
              </div>
            </div>

            <div className="grid grid-cols-2 gap-6">
              <div>
                <label className="block text-xs uppercase tracking-wider text-gray-500 mb-1">Weight (kg)</label>
                {editingSections.personalInfo ? (
                  <input
                    type="number"
                    min="0"
                    step="0.1"
                    value={userData.weight}
                    onChange={(e) => setUserData({...userData, weight: e.target.value})}
                    className="w-full h-[36px] px-3 border rounded-lg focus:outline-none focus:ring-1 focus:ring-gray-400"
                    placeholder="Enter weight in kg"
                  />
                ) : (
                  <p className="text-text-dark h-[36px] px-3 flex items-center">
                    {userData.weight ? `${userData.weight} kg` : '-'}
                  </p>
                )}
              </div>
              <div>
                <label className="block text-xs uppercase tracking-wider text-gray-500 mb-1">Height (cm)</label>
                {editingSections.personalInfo ? (
                  <input
                    type="number"
                    min="0"
                    step="1"
                    value={userData.height}
                    onChange={(e) => setUserData({...userData, height: e.target.value})}
                    className="w-full h-[36px] px-3 border rounded-lg focus:outline-none focus:ring-1 focus:ring-gray-400"
                    placeholder="Enter height in cm"
                  />
                ) : (
                  <p className="text-text-dark h-[36px] px-3 flex items-center">
                    {userData.height ? `${userData.height} cm` : '-'}
                  </p>
                )}
              </div>
            </div>

            <div className="grid grid-cols-2 gap-6">
              <div>
                <label className="block text-xs uppercase tracking-wider text-gray-500 mb-1">Activity Level</label>
                {editingSections.personalInfo ? (
                  <select
                    value={userData.activityLevel}
                    onChange={(e) => setUserData({...userData, activityLevel: e.target.value})}
                    className="w-full h-[36px] px-3 border rounded-lg focus:outline-none focus:ring-1 focus:ring-gray-400 appearance-none bg-white"
                  >
                    <option value="">Select activity level</option>
                    <option value="sedentary">Sedentary</option>
                    <option value="lightly_active">Lightly Active</option>
                    <option value="moderately_active">Moderately Active</option>
                    <option value="very_active">Very Active</option>
                  </select>
                ) : (
                  <p className="text-text-dark h-[36px] px-3 flex items-center capitalize">
                    {userData.activityLevel ? userData.activityLevel.replace('_', ' ') : '-'}
                  </p>
                )}
              </div>
              <div>
                <label className="block text-xs uppercase tracking-wider text-gray-500 mb-1">Sports per Week</label>
                {editingSections.personalInfo ? (
                  <input
                    type="number"
                    min="0"
                    max="30"
                    step="1"
                    value={userData.sportsPerWeek}
                    onChange={(e) => setUserData({...userData, sportsPerWeek: parseInt(e.target.value) || 0})}
                    className="w-full h-[36px] px-3 border rounded-lg focus:outline-none focus:ring-1 focus:ring-gray-400"
                    placeholder="Number of sessions"
                  />
                ) : (
                  <p className="text-text-dark h-[36px] px-3 flex items-center">
                    {userData.sportsPerWeek ? `${userData.sportsPerWeek} sessions` : '-'}
                  </p>
                )}
              </div>
            </div>

            <div>
              <label className="block text-xs uppercase tracking-wider text-gray-500 mb-1">Goal</label>
              {editingSections.personalInfo ? (
                <select
                  value={userData.goal}
                  onChange={(e) => setUserData({...userData, goal: e.target.value})}
                  className="w-full h-[36px] px-3 border rounded-lg focus:outline-none focus:ring-1 focus:ring-gray-400 appearance-none bg-white"
                >
                  <option value="">Select goal</option>
                  <option value="weight_loss">Weight Loss</option>
                  <option value="muscle_gain">Muscle Gain</option>
                  <option value="maintenance">Maintenance</option>
                  <option value="general_health">General Health</option>
                </select>
              ) : (
                <p className="text-text-dark h-[36px] px-3 flex items-center capitalize">
                  {userData.goal ? userData.goal.replace('_', ' ') : '-'}
                </p>
              )}
            </div>

            <div className="mt-2">
              <div className="flex justify-between items-center mb-1">
                <label className="block text-xs uppercase tracking-wider text-gray-500">Diet Preferences</label>
                {editingSections.personalInfo && (
                  <span className="text-xs text-gray-400">
                    {userData.foodPreferences?.length || 0}/300
                  </span>
                )}
              </div>
              {editingSections.personalInfo && (
                <div className="space-y-4">
                  <textarea
                    value={userData.foodPreferences}
                    onChange={(e) => {
                      if (e.target.value.length <= 300) {
                        setUserData({...userData, foodPreferences: e.target.value})
                      }
                    }}
                    placeholder="Enter your general food preferences (e.g., 'I prefer plant-based meals' or 'I love spicy food')"
                    className="w-full h-[96px] px-3 py-2 border rounded-lg focus:outline-none focus:ring-1 focus:ring-gray-400 resize-none"
                  />
                </div>
              )}
            </div>
          </div>

          <div className="max-w-2xl mb-8 bg-white rounded-xl p-6 shadow-sm">
            <SectionHeader
              title="Health Focus"
              isEditing={editingSections.healthFocus}
              onEdit={() => handleSectionEdit('healthFocus')}
              onSave={() => handleSectionSave('healthFocus')}
              isSaving={isSaving}
              showEditButton={false}
            />
            <div className="flex items-center justify-between mb-4">
              <div className="hidden md:flex items-center gap-2 text-sm text-gray-600">
                Selected: {healthFocusOptions.find(opt => opt.id === userData.healthFocus)?.title}
              </div>
            </div>

            {/* Desktop Layout */}
            <div className="hidden md:grid md:grid-cols-3 gap-4">
              {healthFocusOptions.map((option) => (
                <div
                  key={option.id}
                  onClick={() => {
                    setUserData({ ...userData, healthFocus: option.id });
                    if (auth.currentUser) {
                      setDoc(doc(db, 'users', auth.currentUser.uid), {
                        ...userData,
                        healthFocus: option.id,
                        lastUpdated: new Date().toISOString()
                      }, { merge: true })
                      .then(() => {
                        toast.success('Health focus updated');
                      })
                      .catch((error) => {
                        console.error("Error updating health focus:", error);
                        toast.error('Failed to update health focus');
                      });
                    }
                  }}
                  className={`p-6 rounded-lg border-2 cursor-pointer transition-all duration-200 ${
                    userData.healthFocus === option.id
                      ? 'border-green-500 bg-green-50'
                      : 'border-gray-200 hover:border-green-300'
                  }`}
                >
                  <div className="flex items-center mb-3">
                    <span className="text-2xl mr-2">{option.icon}</span>
                    <h4 className="text-lg font-medium">{option.title}</h4>
                  </div>
                  <p className="text-gray-600 mb-4">{option.description}</p>
                  <ul className="space-y-2">
                    {option.benefits.map((benefit, index) => (
                      <li key={index} className="flex items-center text-sm text-gray-700">
                        <span className="w-2 h-2 bg-green-500 rounded-full mr-2"></span>
                        {benefit}
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>

            {/* Mobile Layout */}
            <div className="md:hidden space-y-3">
              {healthFocusOptions.map((option) => (
                <div key={option.id} className="border rounded-xl overflow-hidden">
                  <div
                    onClick={() => {
                      if (userData.healthFocus === option.id) {
                        setIsHealthFocusExpanded(!isHealthFocusExpanded);
                      } else {
                        setUserData({ ...userData, healthFocus: option.id });
                        setIsHealthFocusExpanded(true);
                        if (auth.currentUser) {
                          setDoc(doc(db, 'users', auth.currentUser.uid), {
                            ...userData,
                            healthFocus: option.id,
                            lastUpdated: new Date().toISOString()
                          }, { merge: true })
                          .then(() => {
                            toast.success('Health focus updated');
                          })
                          .catch((error) => {
                            console.error("Error updating health focus:", error);
                            toast.error('Failed to update health focus');
                          });
                        }
                      }
                    }}
                    className={`flex items-center justify-between p-4 cursor-pointer ${
                      userData.healthFocus === option.id ? 'bg-green-50 border-b border-green-100' : 'bg-white'
                    }`}
                  >
                    <div className="flex items-center gap-3">
                      <span className="text-2xl">{option.icon}</span>
                      <h4 className="text-base font-medium text-gray-900">{option.title}</h4>
                    </div>
                    {userData.healthFocus === option.id && (
                      <svg
                        className={`w-5 h-5 text-gray-400 transform transition-transform ${isHealthFocusExpanded ? 'rotate-180' : ''}`}
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                      </svg>
                    )}
                  </div>
                  {userData.healthFocus === option.id && isHealthFocusExpanded && (
                    <div className="p-4 bg-green-50">
                      <p className="text-sm text-gray-600 mb-3">{option.description}</p>
                      <div className="space-y-2">
                        {option.benefits.map((benefit, index) => (
                          <div key={index} className="flex items-center gap-2 text-sm text-gray-600">
                            <span className="w-1.5 h-1.5 bg-green-500 rounded-full"></span>
                            {benefit}
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>

          {userData.gender === 'female' && (
            <div className="max-w-2xl mb-8 bg-white rounded-xl p-6 shadow-sm">
              <SectionHeader
                title="Menstrual Cycle Nutrition"
                isEditing={editingSections.menstrualCycle}
                onEdit={() => handleSectionEdit('menstrualCycle')}
                onSave={() => handleSectionSave('menstrualCycle')}
                isSaving={isSaving}
              />
              <div className="space-y-4">
                <div className="flex items-center justify-between">
                  <div className="flex items-center gap-2">
                    <span className="text-text-dark">Enable cycle-based nutrition</span>
                    <div className="group relative">
                      <FiInfo className="w-4 h-4 text-gray-400 cursor-help" />
                      <div className="absolute bottom-full left-1/2 -translate-x-1/2 mb-2 w-64 p-2 bg-gray-800 text-white text-sm rounded-lg opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all">
                        Receive personalized nutrition recommendations based on your menstrual cycle phases
                      </div>
                    </div>
                  </div>
                  <label className="relative inline-flex items-center cursor-pointer w-11 h-6">
                    <input
                      type="checkbox"
                      className="sr-only peer"
                      checked={userData.menstrualCycle.enabled}
                      onChange={(e) => {
                        const newValue = e.target.checked;
                        setUserData(prev => ({
                          ...prev,
                          menstrualCycle: {
                            ...prev.menstrualCycle,
                            enabled: newValue
                          }
                        }));
                        if (auth.currentUser) {
                          setDoc(doc(db, 'users', auth.currentUser.uid), {
                            ...userData,
                            menstrualCycle: {
                              ...userData.menstrualCycle,
                              enabled: newValue
                            }
                          }, { merge: true });
                        }
                      }}
                    />
                    <div className="absolute inset-0 bg-gray-200 pointer-events-none peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
                  </label>
                </div>

                {userData.menstrualCycle.enabled && (
                  <>
                    <div className="grid grid-cols-2 gap-6">
                      <div>
                        <label className="block text-xs uppercase tracking-wider text-gray-500 mb-1">Cycle Length (days)</label>
                        {editingSections.menstrualCycle ? (
                          <select
                            value={userData.menstrualCycle.cycleLength}
                            onChange={(e) => setUserData({
                              ...userData,
                              menstrualCycle: {
                                ...userData.menstrualCycle,
                                cycleLength: parseInt(e.target.value)
                              }
                            })}
                            className="w-full h-[36px] px-3 border rounded-lg focus:outline-none focus:ring-1 focus:ring-gray-400 appearance-none bg-white"
                          >
                            {Array.from({length: 15}, (_, i) => i + 21).map(days => (
                              <option key={days} value={days}>{days} days</option>
                            ))}
                          </select>
                        ) : (
                          <p className="text-text-dark h-[36px] px-3 flex items-center">
                            {userData.menstrualCycle.cycleLength} days
                          </p>
                        )}
                      </div>
                      <div>
                        <label className="block text-xs uppercase tracking-wider text-gray-500 mb-1">Last Period Start Date</label>
                        {editingSections.menstrualCycle ? (
                          <input
                            type="date"
                            max={new Date().toISOString().split('T')[0]}
                            value={userData.menstrualCycle.lastPeriodStart}
                            onChange={(e) => setUserData({
                              ...userData,
                              menstrualCycle: {
                                ...userData.menstrualCycle,
                                lastPeriodStart: e.target.value
                              }
                            })}
                            className="w-full h-[36px] px-3 border rounded-lg focus:outline-none focus:ring-1 focus:ring-gray-400"
                          />
                        ) : (
                          <p className="text-text-dark h-[36px] px-3 flex items-center">
                            {userData.menstrualCycle.lastPeriodStart || '-'}
                          </p>
                        )}
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          )}

          <div className="max-w-2xl mb-8 bg-white rounded-xl p-6 shadow-sm">
            <SectionHeader
              title="Health Metrics & Calorie Calculator"
              isEditing={editingSections.healthMetrics}
              onEdit={() => handleSectionEdit('healthMetrics')}
              onSave={() => handleSectionSave('healthMetrics')}
              isSaving={isSaving}
            />
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-4">
              <div>
                <div className="flex items-center gap-2">
                  <label className="block text-xs uppercase tracking-wider text-gray-500 mb-1">BMI</label>
                  <div className="group relative">
                    <FiInfo className="w-4 h-4 text-gray-400" />
                    <div className="hidden group-hover:block absolute left-1/2 -translate-x-1/2 bottom-full mb-2 px-3 py-2 text-sm bg-gray-800 text-white rounded-lg whitespace-nowrap z-10">
                      BMI is calculated using your weight and height<br />
                      (weight in kg / height in meters²)
                    </div>
                  </div>
                </div>
                <p className="text-text-dark h-[36px] px-3 flex items-center">
                  {userData.calculatedCalories.bmi ? 
                    `${userData.calculatedCalories.bmi.toFixed(1)} (${userData.calculatedCalories.bmiCategory})` : 
                    '-'}
                </p>
              </div>
              <div>
                <div className="flex items-center gap-2">
                  <label className="block text-xs uppercase tracking-wider text-gray-500 mb-1">Calculated BMR</label>
                  <div className="group relative">
                    <FiInfo className="w-4 h-4 text-gray-400" />
                    <div className="hidden group-hover:block absolute left-1/2 -translate-x-1/2 bottom-full mb-2 px-3 py-2 text-sm bg-gray-800 text-white rounded-lg whitespace-nowrap z-10">
                      BMR is the number of calories your body burns while at complete rest
                    </div>
                  </div>
                </div>
                <p className="text-text-dark h-[36px] px-3 flex items-center">
                  {userData.calculatedCalories.bmr ? `${Math.round(userData.calculatedCalories.bmr)} kcal/day` : '-'}
                </p>
              </div>
              <div>
                <div className="flex items-center gap-2">
                  <label className="block text-xs uppercase tracking-wider text-gray-500 mb-1">Calculated TDEE</label>
                  <div className="group relative">
                    <FiInfo className="w-4 h-4 text-gray-400" />
                    <div className="hidden group-hover:block absolute left-1/2 -translate-x-1/2 bottom-full mb-2 px-3 py-2 text-sm bg-gray-800 text-white rounded-lg whitespace-nowrap z-10">
                      TDEE includes BMR plus calories burned through daily activities
                    </div>
                  </div>
                </div>
                <p className="text-text-dark h-[36px] px-3 flex items-center">
                  {userData.calculatedCalories.tdee ? `${Math.round(userData.calculatedCalories.tdee)} kcal/day` : '-'}
                </p>
              </div>
            </div>

            <div className="flex items-start gap-4">
              <div className="flex-1">
                <div className="flex items-center gap-2">
                  <label className="block text-xs uppercase tracking-wider text-gray-500 mb-1">
                    Desired Daily Calories
                    <span className="normal-case text-gray-400 ml-1">(optional)</span>
                  </label>
                  <div className="group relative">
                    <FiInfo className="w-4 h-4 text-gray-400" />
                    <div className="hidden group-hover:block absolute left-1/2 -translate-x-1/2 bottom-full mb-2 px-3 py-2 text-sm bg-gray-800 text-white rounded-lg whitespace-nowrap z-10">
                      Set this if you have a specific calorie goal different from your TDEE
                    </div>
                  </div>
                </div>
                {editingSections.healthMetrics && (
                  <input
                    type="number"
                    inputMode="numeric"
                    pattern="[0-9]*"
                    value={userData.desiredCalories}
                    onChange={(e) => {
                      const value = e.target.value;
                      setUserData(prev => ({
                        ...prev,
                        desiredCalories: value
                      }));
                    }}
                    className="w-full h-[36px] px-3 border rounded-lg focus:outline-none focus:ring-1 focus:ring-gray-400"
                    placeholder="Enter your daily calorie goal"
                  />
                )}
              </div>
            </div>
          </div>

          <div className="max-w-2xl mb-8 bg-white rounded-xl p-6 shadow-sm">
            <h2 className="text-lg font-medium text-text-dark mb-4">Dietary Context</h2>
            <div className="space-y-4">
              {dietaryContext.length === 0 ? (
                <p className="text-gray-500 text-sm">No dietary context tags yet. Start a conversation to create some!</p>
              ) : (
                <div className="flex flex-wrap gap-2">
                  {dietaryContext.map((tag, index) => (
                    <div
                      key={index}
                      className={`group flex items-center gap-2 px-3 py-1 rounded-full bg-gray-100`}
                    >
                      <span className="text-sm">{tag}</span>
                      <button
                        onClick={() => handleDeleteTag(tag)}
                        className="opacity-0 group-hover:opacity-100 transition-opacity duration-200 hover:text-red-500"
                        title="Delete tag"
                      >
                        <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                        </svg>
                      </button>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>

          <div className="max-w-2xl mb-8 bg-white rounded-xl p-6 shadow-sm">
            <h2 className="text-lg font-medium text-text-dark mb-4">Temporary Preferences</h2>
            <div className="space-y-4">
              {timedPreferences.length === 0 ? (
                <p className="text-gray-500 text-sm">No temporary preferences yet.</p>
              ) : (
                <div className="flex flex-wrap gap-2">
                  {timedPreferences.map((pref, index) => (
                    <div
                      key={index}
                      className={`group flex items-center gap-2 px-3 py-1 rounded-full bg-blue-100`}
                    >
                      <span className="text-sm">
                        {pref.content} (until {new Date(pref.endDate).toLocaleDateString()})
                      </span>
                      <button
                        onClick={() => handleDeleteTimedPreference(pref)}
                        className="opacity-0 group-hover:opacity-100 transition-opacity duration-200 hover:text-red-500"
                        title="Delete preference"
                      >
                        <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                        </svg>
                      </button>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>

          <div className="max-w-2xl mb-8 bg-white rounded-xl p-6 shadow-sm">
            <SectionHeader
              title="Settings"
              isEditing={editingSections.settings}
              onEdit={() => handleSectionEdit('settings')}
              onSave={() => handleSectionSave('settings')}
              isSaving={isSaving}
            />
            <div className="space-y-4">
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-2">
                  <span className="text-text-dark">Show meal confirmation</span>
                  <div className="group relative">
                    <FiInfo className="w-4 h-4 text-gray-400 cursor-help" />
                    <div className="absolute bottom-full left-1/2 -translate-x-1/2 mb-2 w-64 p-2 bg-gray-800 text-white text-sm rounded-lg opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all">
                      If activated, a summary of the log will be displayed upon adding a meal or stool. If it is off, the meal/stool is still saved but we won't show the confirmation.
                    </div>
                  </div>
                </div>
                <label className="relative inline-flex items-center cursor-pointer w-11 h-6">
                  <input
                    type="checkbox"
                    className="sr-only peer"
                    checked={userData.showMealConfirmation ?? true}
                    onChange={handleToggleChange}
                  />
                  <div className="absolute inset-0 bg-gray-200 pointer-events-none peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
                </label>
              </div>
              {/* Generate Recipe Images setting - temporarily disabled
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-2">
                  <span className="text-text-dark">Generate recipe images</span>
                  <div className="group relative">
                    <FiInfo className="w-4 h-4 text-gray-400 cursor-help" />
                    <div className="absolute bottom-full left-1/2 -translate-x-1/2 mb-2 w-64 p-2 bg-gray-800 text-white text-sm rounded-lg opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all">
                      If activated, images of recipes will be generated.
                    </div>
                  </div>
                </div>
                <label className="relative inline-flex items-center cursor-pointer w-11 h-6">
                  <input
                    type="checkbox"
                    className="sr-only peer"
                    checked={userData.generateRecipeImages ?? false}
                    onChange={handleGenerateRecipeImagesToggleChange}
                  />
                  <div className="absolute inset-0 bg-gray-200 pointer-events-none peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
                </label>
              </div>
              */}
            </div>
          </div>

          <button
            onClick={handleLogout}
            className="btn-lean mb-28"
          >
            <span>Sign Out</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Account;

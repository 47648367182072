import React, { useState, useEffect } from 'react';
import { collection, query, orderBy, onSnapshot, deleteDoc, doc } from 'firebase/firestore';
import { db } from '../../firebase';
import { useAuth } from '../../contexts/AuthContext';
import { Navigate } from 'react-router-dom';
import { HiSearch, HiDownload, HiTrash } from 'react-icons/hi';
import { toast } from 'react-hot-toast';

  // List of admin UIDs
  const adminUids = ['oTp364uHESSYRjh5aMjLgnFOmXs1'];

const WaitlistAdmin = () => {
  const [waitlistEntries, setWaitlistEntries] = useState([]);
  const [filteredEntries, setFilteredEntries] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [statusFilter, setStatusFilter] = useState('all');
  const [dateFilter, setDateFilter] = useState('all');
  const { user } = useAuth();

  useEffect(() => {
    if (!user || !adminUids.includes(user.uid)) return;

    const q = query(collection(db, "waitlist"), orderBy("timestamp", "desc"));
    
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const entries = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        timestamp: doc.data().timestamp?.toDate()
      }));
      setWaitlistEntries(entries);
      setFilteredEntries(entries);
      setLoading(false);
    });

    return () => unsubscribe();
  }, [user]);

  // Apply filters and search
  useEffect(() => {
    let filtered = [...waitlistEntries];

    // Search filter
    if (searchTerm) {
      filtered = filtered.filter(entry => 
        entry.email.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    // Status filter
    if (statusFilter !== 'all') {
      filtered = filtered.filter(entry => 
        (entry.status || 'waiting') === statusFilter
      );
    }

    // Date filter
    const now = new Date();
    switch (dateFilter) {
      case 'today':
        filtered = filtered.filter(entry => 
          entry.timestamp?.toDateString() === now.toDateString()
        );
        break;
      case 'week':
        const weekAgo = new Date(now.setDate(now.getDate() - 7));
        filtered = filtered.filter(entry => 
          entry.timestamp >= weekAgo
        );
        break;
      case 'month':
        const monthAgo = new Date(now.setMonth(now.getMonth() - 1));
        filtered = filtered.filter(entry => 
          entry.timestamp >= monthAgo
        );
        break;
      default:
        break;
    }

    setFilteredEntries(filtered);
  }, [waitlistEntries, searchTerm, statusFilter, dateFilter]);

  const getSourceTagColor = (source) => {
    switch (source) {
      case 'cycle-nutrition':
        return 'bg-pink-100 text-pink-800';
      case 'microbiome':
        return 'bg-green-100 text-green-800';
      case 'longevity-nutrition':
        return 'bg-purple-100 text-purple-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

  const formatSource = (source) => {
    switch (source) {
      case 'cycle-nutrition':
        return 'Cycle Nutrition';
      case 'microbiome':
        return 'Microbiome';
      case 'longevity-nutrition':
        return 'Longevity';
      default:
        return source || 'Unknown';
    }
  };

  const handleExport = () => {
    const csvContent = [
      ['Email', 'Date', 'Source', 'Status'],
      ...filteredEntries.map(entry => [
        entry.email,
        entry.timestamp?.toLocaleString(),
        entry.source,
        entry.status
      ])
    ].map(row => row.join(',')).join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('hidden', '');
    a.setAttribute('href', url);
    a.setAttribute('download', 'waitlist_export.csv');
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to remove this user from the waitlist?')) {
      try {
        await deleteDoc(doc(db, "waitlist", id));
        toast.success('User removed from waitlist');
      } catch (error) {
        console.error('Error deleting user:', error);
        toast.error('Failed to remove user');
      }
    }
  };

  // Get source counts
  const getSourceCounts = () => {
    const counts = waitlistEntries.reduce((acc, entry) => {
      const source = entry.source || 'unknown';
      acc[source] = (acc[source] || 0) + 1;
      return acc;
    }, {});
    return counts;
  };

  const getSourceUrl = (source) => {
    switch (source) {
      case 'cycle-nutrition':
        return 'https://www.foodwise.me/lp-cycle-nutrition';
      case 'microbiome':
        return 'https://www.foodwise.me/lp-microbiome';
      case 'longevity-nutrition':
        return 'https://www.foodwise.me/lp-longevity-nutrition';
      default:
        return null;
    }
  };

  // Redirect non-admin users
  if (!user || !adminUids.includes(user.uid)) {
    return <Navigate to="/" />;
  }

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-primary"></div>
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8 mt-20">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-2xl font-semibold text-gray-900">Waitlist</h1>
        </div>
      </div>

      {/* Mini Dashboard */}
      <div className="mt-6 mb-8 flex space-x-4">
        {Object.entries(getSourceCounts()).map(([source, count]) => (
          <div key={source} className="bg-white overflow-hidden shadow rounded-lg w-48">
            <div className="p-4">
              <div className="flex flex-col items-start">
                <div className="mb-2">
                  <span className={`px-2 py-1 text-xs font-medium rounded-full ${getSourceTagColor(source)}`}>
                    {formatSource(source)}
                  </span>
                </div>
                <div className="w-full">
                  <dl>
                    <dd className="text-2xl font-semibold text-gray-900">{count}</dd>
                    <dt className="text-xs font-medium text-gray-500 truncate">Users</dt>
                  </dl>
                </div>
                {getSourceUrl(source) && (
                  <a 
                    href={getSourceUrl(source)} 
                    target="_blank" 
                    rel="noopener noreferrer" 
                    className="mt-2 text-xs text-blue-600 hover:text-blue-800 hover:underline truncate max-w-full"
                  >
                    {getSourceUrl(source)}
                  </a>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Search and Filter Section */}
      <div className="flex justify-between items-center mb-8">
        <h1 className="text-3xl font-bold">Waitlist Entries</h1>
        <button
          onClick={handleExport}
          className="flex items-center gap-2 bg-primary text-white px-4 py-2 rounded-lg hover:bg-primary/90 transition-colors"
        >
          <HiDownload className="w-5 h-5" />
          Export CSV
        </button>
      </div>

      <div className="bg-white rounded-lg shadow-lg p-6 mb-6">
        <div className="flex flex-wrap gap-4 items-center">
          {/* Search */}
          <div className="flex-grow max-w-md relative">
            <HiSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
            <input
              type="text"
              placeholder="Search by email..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-full pl-10 pr-4 py-2 border border-gray-200 rounded-lg focus:outline-none focus:border-primary"
            />
          </div>

          {/* Filters */}
          <div className="flex gap-4">
            <select
              value={statusFilter}
              onChange={(e) => setStatusFilter(e.target.value)}
              className="border border-gray-200 rounded-lg px-4 py-2 focus:outline-none focus:border-primary"
            >
              <option value="all">All Status</option>
              <option value="waiting">Waiting</option>
              <option value="active">Active</option>
            </select>

            <select
              value={dateFilter}
              onChange={(e) => setDateFilter(e.target.value)}
              className="border border-gray-200 rounded-lg px-4 py-2 focus:outline-none focus:border-primary"
            >
              <option value="all">All Time</option>
              <option value="today">Today</option>
              <option value="week">Last 7 Days</option>
              <option value="month">Last 30 Days</option>
            </select>
          </div>
        </div>
      </div>

      <div className="bg-white rounded-lg shadow overflow-hidden">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Email
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Source
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Date
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Status
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {filteredEntries.map((entry) => (
              <tr key={entry.id} className="hover:bg-gray-50">
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm text-gray-900">{entry.email}</div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${getSourceTagColor(entry.source)}`}>
                    {formatSource(entry.source)}
                  </span>
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {entry.timestamp?.toLocaleDateString()} {entry.timestamp?.toLocaleTimeString()}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm">
                  <span className={`px-2 py-1 rounded-lg text-xs font-medium
                    ${entry.status === 'active' ? 'bg-green-100 text-green-800' : 'bg-yellow-100 text-yellow-800'}`}
                  >
                    {entry.status || 'waiting'}
                  </span>
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm">
                  <button
                    onClick={() => handleDelete(entry.id)}
                    className="text-gray-400 hover:text-red-600 transition-colors duration-200"
                    title="Remove from waitlist"
                  >
                    <HiTrash className="h-5 w-5" />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default WaitlistAdmin;

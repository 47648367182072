import { doc, getDoc, setDoc } from 'firebase/firestore';
import { db } from '../firebase';
import * as tagService from './tagService';

/**
 * Update permanent tags (stored in assistantMemory collection)
 */
export const updateMemory = async (userId, category, content) => {
  return await tagService.updatePermanentTags(userId, category, content);
};

/**
 * Update timed preferences (stored in timedPreferences collection)
 */
export const updateTimedPreference = async (userId, category, content, duration, endDate) => {
  await tagService.updateTimedPreference(userId, category, content, duration, endDate);
};

/**
 * Load user's memory tags
 */
export const loadMemory = async (userId) => {
  const memoryDoc = await getDoc(doc(db, 'assistantMemory', userId));
  return memoryDoc.exists() ? memoryDoc.data().tags || [] : [];
};

/**
 * Load user's timed preferences
 * Filters out expired preferences automatically
 */
export const loadTimedPreferences = async (userId) => {
  const prefsDoc = await getDoc(doc(db, 'timedPreferences', userId));
  if (!prefsDoc.exists()) return [];
  
  const now = new Date();
  const activePrefs = (prefsDoc.data().preferences || [])
    .filter(pref => new Date(pref.endDate) > now);
  
  // Clean up expired preferences
  if (activePrefs.length !== prefsDoc.data().preferences.length) {
    await setDoc(doc(db, 'timedPreferences', userId), {
      preferences: activePrefs
    }, { merge: true });
  }
  
  return activePrefs;
};

// src/firebase.js
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";

// Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyBbgThsclZPPoKe_Wb5_13mFrU26G0nifg",
    authDomain: "foodwise-food.firebaseapp.com",
    projectId: "foodwise-food",
    storageBucket: "foodwise-food.firebasestorage.app",
    messagingSenderId: "1087768491662",
    appId: "1:1087768491662:web:e469e7a44d771409fafbdf",
    measurementId: "G-35L2HT507P"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize services
const auth = getAuth(app);
const db = getFirestore(app);

export { app, auth, db };

import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

const AdminRoute = ({ children }) => {
  const { user } = useAuth();
  const adminUids = ['oTp364uHESSYRjh5aMjLgnFOmXs1']; // Your admin UIDs

  if (!user || !adminUids.includes(user.uid)) {
    return <Navigate to="/home" />;
  }

  return children;
};

export default AdminRoute;

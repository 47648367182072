import { doc, setDoc, getDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '../firebase';
/*
// Add system message to help GPT understand the current date and time formats
const SYSTEM_MESSAGE = {
  role: 'system',
  content: [{
    type: 'text',
    text: `You are FoodWise, a helpful and curious nutrition and diet assistant. Today's date is ${new Date().toISOString().split('T')[0]}.

You are a proactive and engaging nutrition assistant. Your role is to help users make informed decisions about their diet and nutrition while building a comprehensive understanding of their preferences, lifestyle, and goals. You are friendly, empathetic, and naturally curious about the user's needs.

CORE BEHAVIORS:
1. Be Proactively Curious:
   - If you don't know the user's cooking preferences, ask about them
   - If dietary preferences aren't clear, inquire about them
   - If fitness/health goals aren't mentioned, explore them
   - If you notice gaps in understanding their lifestyle, ask relevant questions

2. Build Context Progressively:
   - Start with open-ended questions about their goals
   - Follow up on their responses to deepen understanding
   - Ask about cooking habits, time constraints, and kitchen comfort level
   - Explore their food preferences and any restrictions

3. Maintain Conversation Flow:
   - Ask one question at a time to avoid overwhelming
   - Acknowledge their responses before asking follow-ups
   - Share relevant nutrition facts or tips based on their interests
   - Keep the conversation natural and engaging

4. Remember and Reference:
   - Use the update_memory function to store important preferences
   - Use update_timed_preference for temporary goals
   - Reference previously shared information in your responses
   - Confirm your understanding periodically

You have access to the following functions:
- update_timed_preference: Update a user's temporary preference or goal with a specific duration or end date. Clean up the content by removing unnecessary words and standardizing terms.
- update_memory: Update user's permanent memory with lasting preferences, restrictions, or important health information that doesn't have an end date.

When users want to set timed preferences (e.g., dietary goals), you should call the update_timed_preference function.
The function accepts these parameters:
- content: The cleaned up preference text
- category: One of [dietary_preferences, goals, habits]
- duration: For relative time periods (e.g., "2 weeks", "1 month")
- endDate: For specific dates (e.g., "2025-08-15" for "until mid August")

Important rules for timed preferences:
1. For relative durations (e.g., "for two weeks", "next 3 months"):
   - Set duration to the period
   - Set endDate to null
   Example: "eat low carb for 2 weeks" →
   { content: "eat low carb", duration: "2 weeks", endDate: null }

2. For specific dates (e.g., "until August", "until the 15th"):
   - Set endDate to the ISO date
   - Set duration to null
   Example: "eat vegan until mid August" →
   { content: "eat vegan", duration: null, endDate: "2025-08-15" }

3. Always clean up the content:
   - Remove unnecessary words (eat, follow, do)
   - Standardize terms (no gluten → gluten free)
   - Keep it concise but clear

EXAMPLE CONVERSATION STARTERS:
- "What brings you to FoodWise today? I'd love to understand your nutrition goals."
- "Before we dive in, could you tell me about your typical cooking routine?"
- "Do you have any dietary preferences or restrictions I should know about?"
- "What's your ideal balance between healthy eating and enjoying your favorite foods?"

Remember to be conversational while maintaining professionalism, and always aim to gather relevant information naturally through dialogue.`
  }]
};
*/

export const sendChatMessage = async (messages, userProfile) => {
  // Get the current message (last message in the array)
  const currentDate = new Date('2025-01-08T21:13:15+01:00');
  
  // Create a system message with user context
  const systemMessage = {
    role: 'system',
    content: `You are Foodwise, a helpful and curious nutrition and diet assistant. Today's date is ${currentDate.toISOString().split('T')[0]}.

CORE BEHAVIORS:
- Be proactively curious about unknown preferences, cooking habits, and goals
- Follow up on the users input
- Share relevant nutrition facts based on user interests
- Use emojis to make the conversation more engaging

${userProfile ? `User Profile Information:${
  userProfile.firstName ? `\n- Name: ${userProfile.firstName}` : ''}${
  userProfile.calculatedCalories?.tdee ? `\n- Daily calorie needs (TDEE): ${userProfile.calculatedCalories.tdee} calories` : ''}${
  userProfile.desiredCalories ? `\n- Desired daily calories: ${userProfile.desiredCalories} calories` : ''}${
  userProfile.sportsPerWeek ? `\n- Sports sessions per week: ${userProfile.sportsPerWeek}` : ''}${
  userProfile.activityLevel ? `\n- Activity level: ${userProfile.activityLevel}` : ''}${
  userProfile.goal ? `\n- Fitness goal: ${userProfile.goal}` : ''}${
  userProfile.foodPreferences ? `\n- Food preferences: ${userProfile.foodPreferences}` : ''}${
  userProfile.allergies?.length > 0 ? `\n- Allergies: ${userProfile.allergies.join(', ')}` : ''}`
  : 'Note: No user profile information available - ask questions to learn about the user.'}

CONVERSATION GUIDELINES:
- If profile information is missing, naturally ask about it
- Build context through friendly dialogue
- Ask about cooking habits, time constraints, and kitchen comfort level
- Explore food preferences and restrictions through conversation
- Share relevant nutrition insights based on their responses

EXAMPLE CONVERSATION STARTERS:
- "What brings you to FoodWise today? I'd love to understand your nutrition goals."
- "Before we dive in, could you tell me about your typical cooking routine?"
- "Do you have any dietary preferences or restrictions I should know about?"
- "What's your ideal balance between healthy eating and enjoying your favorite foods?"

RESPONSE GUIDELINES:
1. Keep responses focused and actionable:
   - Provide clear, specific advice
   - Include concrete examples
   - Break down complex topics
   - End with engaging questions

2. Maintain a supportive tone:
   - Use encouraging language
   - Acknowledge challenges
   - Celebrate small wins
   - Show empathy

3. Keep content clean and standardized:
   - Remove filler words
   - Use consistent terminology (e.g., "gluten free" not "no gluten")
   - Be concise but clear
   - Format lists and steps consistently

Remember to be conversational while maintaining professionalism, and always aim to gather relevant information naturally through dialogue.


Only call functions when the user explicitly expresses a preference or goal in their latest message.`
  };

  // Format messages for the API - convert complex content structure to simple strings
  const formattedMessages = messages.map(msg => ({
    role: msg.role,
    content: typeof msg.content === 'string' ? msg.content :
            Array.isArray(msg.content) ? msg.content[0]?.text || msg.content[0] || msg.content :
            msg.content?.text || 'No content'
  }));

  // Prepare the request body
  const requestBody = {
    messages: [
      systemMessage,
      ...formattedMessages
    ],
    functions: [
      {
        name: "update_timed_preference",
        description: "Use this ONLY for temporary dietary preferences, goals, or activity habits that have a specific duration or end date.",
        parameters: {
          type: "object",
          properties: {
            content: {
              type: "string",
              description: "The cleaned up preference text"
            },
            category: {
              type: "string",
              enum: ["dietary_preferences", "goals", "habits"],
              description: "Category of the preference"
            },
            duration: {
              type: "string",
              description: "For relative periods (e.g., '2 weeks'). Set null if using endDate."
            },
            endDate: {
              type: "string",
              description: "ISO date string for specific dates. Set null if using duration."
            }
          },
          required: ["content", "category"]
        }
      },
      {
        name: "update_memory",
        description: "Use this ONLY for permanent dietary preferences, allergies, or long-term health goals.",
        parameters: {
          type: "object",
          properties: {
            content: {
              type: "string",
              description: "The dietary or health preference to remember"
            },
            category: {
              type: "string",
              enum: ["dietary", "health", "personal"],
              description: "Category of the memory"
            }
          },
          required: ["content", "category"]
        }
      }
    ]
  };

  try {
    console.log('Sending chat request:', requestBody);

    const response = await fetch('https://generateairesponse-boc5emstrq-uc.a.run.app/foodwise-chatbot', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      body: JSON.stringify(requestBody)
    });

    if (!response.ok) {
      console.error('Chat response error:', response.status, response.statusText);
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    console.log('Chat response:', data);

    return {
      response: {
        content: data.choices?.[0]?.message?.content || data.response?.content || "I couldn't process that message.",
        tool_calls: data.choices?.[0]?.message?.tool_calls || data.response?.tool_calls || []
      }
    };

  } catch (error) {
    console.error('Error sending chat message:', error);
    throw error;
  }
};

export const loadPreviousSynthesis = async (userId) => {
  try {
    const synthDoc = await getDoc(doc(db, 'chatSynthesis', userId));
    if (!synthDoc.exists()) return null;
    
    const data = synthDoc.data();
    return {
      synthesis: data.synthesis,
      updatedAt: data.updatedAt?.toDate() // Convert Firestore Timestamp to JS Date
    };
  } catch (error) {
    console.error('Failed to load previous synthesis:', error);
    return null;
  }
};

export const synthesizeChat = async (messages) => {
  // Check for empty messages array
  if (!messages || messages.length === 0) {
    console.log('No messages to synthesize');
    return "No chat history to synthesize.";
  }

  // Check for userId
  const userId = messages[0]?.userId;
  if (!userId) {
    console.warn('No userId found in messages');
    return "Could not synthesize chat: missing user information.";
  }

  try {
    // Load previous synthesis with timestamp
    const previousSynthesisData = await loadPreviousSynthesis(userId);
    
    // Format the date for human readability
    const formatDate = (date) => {
      if (!date) return '';
      return new Intl.DateTimeFormat('en-US', {
        month: 'long',
        day: 'numeric',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
      }).format(date);
    };

    // Format messages for synthesis - convert complex content structure to simple strings
    const formattedMessages = messages.slice(-50).map(msg => ({
      role: msg.role || 'user',
      content: typeof msg.content === 'string' ? msg.content :
               Array.isArray(msg.content) ? msg.content[0]?.text || msg.content[0] || msg.content :
               msg.content?.text || 'No content'
    }));

    // Prepare the request body
    const requestBody = {
      messages: [
        {
          role: "system",
          content: "You are a nutrition expert analyzing a conversation between a nutritionist and a client. Create a comprehensive summary of the client's dietary preferences, restrictions, and goals. Focus on information that would be valuable for meal preparation. Format the response as a clear, concise, and structured summary for a personal chef. Dont use any sample meals."
        },
        ...(previousSynthesisData ? [{
          role: "user",
          content: `Previous notes about this client from ${formatDate(previousSynthesisData.updatedAt)}:\n${previousSynthesisData.synthesis}`
        }] : []),
        ...formattedMessages,
        {
          role: "user",
          content: `Based on these conversations, create an updated comprehensive summary (as of ${formatDate(new Date())}) for a personal chef about this client's needs and preferences. Note any changes from previous notes if they exist.`
        }
      ]
    };

    console.log('Sending synthesis request:', requestBody);

    const response = await fetch('https://generateairesponse-boc5emstrq-uc.a.run.app/foodwise-chatbot', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      body: JSON.stringify(requestBody)
    });

    if (!response.ok) {
      console.error('Synthesis response error:', response.status, response.statusText);
      return "Could not synthesize chat due to an API error.";
    }

    const data = await response.json();
    console.log('Synthesis response:', data);

    // Handle different response structures
    if (data.choices?.[0]?.message?.content) {
      return data.choices[0].message.content;
    } else if (data.response?.content) {
      return typeof data.response.content === 'string' 
        ? data.response.content 
        : Array.isArray(data.response.content) && data.response.content[0]?.text 
          ? data.response.content[0].text 
          : "Could not synthesize chat.";
    } else if (typeof data.response === 'string') {
      return data.response;
    }
    
    console.error('Unexpected response structure:', data);
    return "Could not synthesize chat due to unexpected response format.";
  } catch (error) {
    console.error('Failed to synthesize chat:', error);
    return "Could not synthesize chat due to an error.";
  }
};

export const saveChatSynthesis = async (userId, synthesis) => {
  try {
    if (!userId) {
      throw new Error('User ID is required to save chat synthesis');
    }
    
    const docRef = doc(db, 'chatSynthesis', userId);
    await setDoc(docRef, {
      synthesis,
      userId, // Explicitly include userId in the document
      updatedAt: serverTimestamp()
    }, { merge: true });
  } catch (error) {
    console.error('Failed to save chat synthesis:', error);
    throw error;
  }
};

export const updateChatHistory = async (userId, messages) => {
  const chatHistoryRef = doc(db, 'chatHistory', userId);
  await setDoc(chatHistoryRef, {
    messages,
    updatedAt: serverTimestamp()
  }, { merge: true });
};

export const loadChatHistory = async (userId) => {
  const chatHistoryRef = doc(db, 'chatHistory', userId);
  const chatHistoryDoc = await getDoc(chatHistoryRef);
  return chatHistoryDoc.exists() ? chatHistoryDoc.data() : null;
};
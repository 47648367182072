import React, { useState, useEffect, useMemo, useRef } from 'react';
import { XMarkIcon, FireIcon } from '@heroicons/react/24/outline';
import { doc, updateDoc, getDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { db } from '../../firebase';
import { handleRecipeGeneration } from '../../services/recipeService';
import FeaturedRecipe from '../recipes/FeaturedRecipe';
import { useRecipeActions } from '../../hooks/useRecipeActions';
import CalendarAudioPopup from './CalendarAudioPopup';
import CalendarCameraPopup from './CalendarCameraPopup';

const MealPopup = ({ meal, mealType, onClose }) => {

  const hasEnhancedRecipe = meal.enhancedRecipe === true;

  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [recipeResponse, setRecipeResponse] = useState(null);
  const [nutritionalContext, setNutritionalContext] = useState(null);
  const [userPreferences, setUserPreferences] = useState(null);
  const [isAdviceExpanded, setIsAdviceExpanded] = useState(false);
  const [isPersonalizedExpanded, setIsPersonalizedExpanded] = useState(false);
  const [isCycleSupportExpanded, setIsCycleSupportExpanded] = useState(false);
  const [isInstructionsExpanded, setIsInstructionsExpanded] = useState(false);
  const [showAudioPopup, setShowAudioPopup] = useState(false);
  const [showCameraPopup, setShowCameraPopup] = useState(false);
  const [showMealPrefsPopup, setShowMealPrefsPopup] = useState(false);
  const [mealPreferences, setMealPreferences] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const mediaRecorderRef = useRef(null);
  const streamRef = useRef(null);

  // Get user preferences
  useEffect(() => {
    const fetchUserPreferences = async () => {
      try {
        const auth = getAuth();
        const userId = auth.currentUser.uid;
        const userDoc = await getDoc(doc(db, 'users', userId));
        if (userDoc.exists()) {
          setUserPreferences(userDoc.data().preferences || {});
        }
      } catch (error) {
        console.error('Error fetching user preferences:', error);
      }
    };
    fetchUserPreferences();
  }, []);

  // Use our new custom hook
  const {
    addToShoppingList,
    logMeal,
    isLogged,
    showLogSuccess
  } = useRecipeActions(userPreferences);

  // Load enhanced recipe details if they exist
  useEffect(() => {
    if (hasEnhancedRecipe) {
      const processedIngredients = meal.ingredients?.map(ing => ({
        ...ing,
        grams: ing.grams || ing.quantity ? parseFloat(ing.quantity) : 100,
        unit: ing.unit || 'g'
      })) || [];

      setRecipeResponse({
        title: meal.description || '',  // Use description instead of descriptions array
        ingredients: processedIngredients,
        instructions: meal.instructions || [],
        nutritionalBenefits: meal.nutritionalBenefits || [],
        prepTime: meal.prepTime || 'N/A',  // Use prepTime consistently
        calories: meal.calories || 'N/A',  // Add calories
        difficulty: meal.difficulty || 'Medium',
        servings: meal.servings || '2'
      });
      setNutritionalContext(meal.nutritionalContext || {
        benefits: [],
        warnings: [],
        recommendations: [],
        holisticAdvice: '',
        personalizedContext: ''
      });
    }
  }, [meal, hasEnhancedRecipe]);

  // Check if meal is in the past
  const isPastMeal = useMemo(() => {
    const mealDate = new Date(meal.date);
    const mealTime = meal.time.split(':');
    mealDate.setHours(parseInt(mealTime[0]), parseInt(mealTime[1] || 0));
    return mealDate < new Date();
  }, [meal.date, meal.time]);

  // Check if meal is empty (no logged meal)
  const isEmpty = !meal.description && !meal.title;

  // Determine which buttons to show
  const isPastEmptyMeal = isPastMeal && isEmpty;

  const stopRecording = () => {
    if (mediaRecorderRef.current) {
      if (mediaRecorderRef.current.state === 'recording') {
        mediaRecorderRef.current.stop();
      }
      mediaRecorderRef.current = null;
    }
    if (streamRef.current) {
      streamRef.current.getTracks().forEach(track => track.stop());
      streamRef.current = null;
    }
  };

  const handleCloseAudioPopup = () => {
    stopRecording();
    setShowAudioPopup(false);
  };

  // generateRecipeDetails prepares the context for the recipe generation
  // It doesn't make API calls directly - instead it:
  // 1. Formats the meal data into a prompt
  // 2. Passes this prompt to recipeService.handleRecipeGeneration
  // 3. handleRecipeGeneration then makes two API calls:
  //    - First call: Gets nutritional recommendations
  //    - Second call: Creates detailed recipe
  const generateRecipeDetails = async (userPreferences) => {
    setIsLoading(true);
    try {
      const mealInfo = {
        title: meal.description || '',
        ingredients: meal.ingredients ? meal.ingredients.map(ing => `${ing.quantity} ${ing.unit} ${ing.name}`).join(', ') : '',
        mealType: meal.mealType,
        time: meal.time,
        userPreferences: userPreferences // Add user preferences to the prompt
      };

      const promptContext = `Please create a recipe for this meal taking into account these specific user preferences:
        User Preferences: ${userPreferences}
        
        Meal Details:
        Title: ${mealInfo.title}
        Meal Type: ${mealInfo.mealType}
        Time: ${mealInfo.time}
        Original Ingredients: ${mealInfo.ingredients}
        
        Create a recipe that matches the user's preferences while maintaining nutritional value and proper preparation method.`;

      const { recipe, nutritionalContext: recipeContext } = await handleRecipeGeneration(
        'detailed',
        promptContext
      );

      // Validate recipe data
      if (!recipe || typeof recipe !== 'object') {
        throw new Error('Invalid recipe data received');
      }

      setRecipeResponse({
        ...recipe,
        prepTime: recipe.prepTime || recipe.cookingTime || '30 minutes',  // Handle both fields
        calories: recipe.calories ? `${recipe.calories} kcal` : '400 kcal',  // Ensure kcal unit is added
        servings: recipe.servings
      });
      setNutritionalContext(recipeContext || {
        benefits: [],
        warnings: [],
        recommendations: [],
        holisticAdvice: '',
        personalizedContext: ''
      });
    } catch (error) {
      console.error('Error generating recipe details:', error);
      // Set default values on error
      setRecipeResponse({
        title: meal.description || '',
        ingredients: meal.ingredients || [],
        instructions: [],
        nutritionalBenefits: [],
        prepTime: '30 minutes',
        calories: '400 kcal',
        difficulty: 'Medium',
        servings: '2'
      });
      setNutritionalContext({
        benefits: [],
        warnings: [],
        recommendations: [],
        holisticAdvice: '',
        personalizedContext: ''
      });
    } finally {
      setIsLoading(false);
    }
  };

  const saveRecipe = async () => {
    if (!recipeResponse) {
      console.log('No recipe response to save');
      return;
    }

    setIsSaving(true);
    try {
      const auth = getAuth();
      const userId = auth.currentUser.uid;

      console.log('Starting recipe save with:', {
        recipeResponse,
        nutritionalContext,
        meal,
        mealType
      });

      // Parse ingredients from recipe
      const ingredients = recipeResponse.ingredients.map(ingredient => {
        const parts = ingredient.split(' ');
        return {
          quantity: parseFloat(parts[0]) || 1,
          unit: parts[1] || 'unit',
          name: parts.slice(2).join(' ')
        };
      });

      console.log('Parsed ingredients:', ingredients);

      // Get the current mealplan
      const mealPlanRef = doc(db, 'mealplans', userId);
      const mealPlanDoc = await getDoc(mealPlanRef);
      
      if (!mealPlanDoc.exists()) {
        throw new Error('Meal plan not found');
      }

      const mealPlan = mealPlanDoc.data();
      console.log('Current meal plan:', mealPlan);
      
      // Find the day index that matches our date
      let dayIndex = mealPlan.days.findIndex(day => day.date === meal.date);
      
      // If day doesn't exist, create it
      if (dayIndex === -1) {
        console.log('Creating new day for date:', meal.date);
        mealPlan.days.push({
          date: meal.date,
          meals: [],
          summary: 'Added new meal'
        });
        dayIndex = mealPlan.days.length - 1;
      }

      console.log('Found/Created day at index:', dayIndex, 'with data:', mealPlan.days[dayIndex]);

      // Create the updated meal object with enhanced recipe details
      const updatedMeal = {
        ...meal, // Keep existing properties
        time: meal.time || '',
        description: recipeResponse.title || '', // Use description instead of descriptions array
        ingredients: ingredients.filter(Boolean) || [],
        instructions: recipeResponse.instructions.filter(Boolean) || [],
        nutritionalBenefits: recipeResponse.nutritionalBenefits?.filter(Boolean) || [],
        prepTime: recipeResponse.prepTime,
        calories: recipeResponse.calories,
        servings: recipeResponse.servings,
        difficulty: recipeResponse.difficulty || '',
        nutritionalContext: {
          benefits: nutritionalContext?.benefits?.filter(Boolean) || [],
          warnings: nutritionalContext?.warnings?.filter(Boolean) || [],
          recommendations: nutritionalContext?.recommendations?.filter(Boolean) || [],
          holisticAdvice: nutritionalContext?.holisticAdvice || '',
          personalizedContext: nutritionalContext?.personalizedContext || ''
        },
        enhancedRecipe: true,
        tags: meal.tags?.filter(Boolean) || []
      };

      // Deep clean object to remove any undefined values
      const cleanObject = (obj) => {
        Object.keys(obj).forEach(key => {
          if (obj[key] === undefined) {
            delete obj[key];
          } else if (obj[key] && typeof obj[key] === 'object') {
            cleanObject(obj[key]);
          }
        });
        return obj;
      };

      const cleanedMeal = cleanObject({...updatedMeal});
      
      console.log('Created updated meal with enhanced flag:', {
        cleanedMeal,
        enhancedRecipe: cleanedMeal.enhancedRecipe,
        hasUndefined: JSON.stringify(cleanedMeal).includes('undefined')
      });

      // Update the meal in the mealplan
      const updatedDays = [...mealPlan.days];
      const currentDay = { ...updatedDays[dayIndex] };
      
      // Ensure meals array exists and is clean
      if (!Array.isArray(currentDay.meals)) {
        currentDay.meals = [];
      }
      currentDay.meals = currentDay.meals.filter(Boolean);

      // Find the meal index based on time
      const mealIndex = currentDay.meals.findIndex(m => m && m.time === meal.time);
      console.log('Found meal at index:', mealIndex, 'with time:', meal.time);

      // Update or add the meal
      if (mealIndex !== -1) {
        // Update existing meal
        currentDay.meals = [
          ...currentDay.meals.slice(0, mealIndex),
          cleanedMeal,
          ...currentDay.meals.slice(mealIndex + 1)
        ].filter(Boolean);
      } else {
        // Add new meal
        currentDay.meals.push(cleanedMeal);
      }

      console.log('Updated meals array:', {
        mealsLength: currentDay.meals.length,
        meals: currentDay.meals,
        hasUndefined: JSON.stringify(currentDay.meals).includes('undefined'),
        enhancedMeals: currentDay.meals.filter(m => m && m.enhancedRecipe)
      });

      // Clean the current day object
      cleanObject(currentDay);
      updatedDays[dayIndex] = currentDay;

      console.log('Updated day after changes:', {
        dayValid: !!currentDay,
        mealsValid: Array.isArray(currentDay.meals),
        mealsLength: currentDay.meals.length,
        hasUndefined: JSON.stringify(currentDay).includes('undefined')
      });

      // Save the updated mealplan
      const updateData = {
        days: updatedDays.filter(Boolean),
        updatedAt: new Date().toISOString()
      };

      // Clean and verify the data before saving
      cleanObject(updateData);
      console.log('Final update data:', {
        daysLength: updateData.days.length,
        firstDayMeals: updateData.days[0].meals.length,
        hasUndefined: JSON.stringify(updateData).includes('undefined'),
        dataString: JSON.stringify(updateData).slice(0, 100) + '...'
      });

      await updateDoc(mealPlanRef, updateData);
      console.log('Successfully updated Firestore');

      // Update local state
      console.log('Updating local state with enhanced recipe flag...');
      meal.enhancedRecipe = true;
      setRecipeResponse(prev => {
        const updated = {
          ...prev,
          title: updatedMeal.description,
          ingredients: updatedMeal.ingredients,
          instructions: updatedMeal.instructions,
          nutritionalBenefits: updatedMeal.nutritionalBenefits,
          prepTime: updatedMeal.prepTime,
          calories: updatedMeal.calories,
          servings: updatedMeal.servings
        };
        console.log('Updated recipe response:', updated);
        return updated;
      });

      // Trigger a refresh of the calendar by dispatching a custom event
      window.dispatchEvent(new CustomEvent('mealPlanUpdated'));

      console.log('All updates complete, closing popup');
      onClose(); // Close popup after successful save
    } catch (error) {
      console.error('Error saving recipe:', error);
    } finally {
      setIsSaving(false);
    }
  };

  const formatTime = (timeString) => {
    if (!timeString) return '';
    const [hours, minutes] = timeString.split(':');
    const date = new Date();
    date.setHours(parseInt(hours), parseInt(minutes));
    return date.toLocaleTimeString('en-US', { 
      hour: 'numeric', 
      minute: '2-digit',
      hour12: true 
    });
  };

  // Helper function to format macro values
  const formatMacro = (value) => {
    return typeof value === 'number' ? `${value}g` : 'N/A';
  };

  // Helper function to get tag labels
  const getTagLabel = (tag) => {
    const tagMap = {
      'k': 'Keto',
      'lc': 'Low Carb',
      'gf': 'Gluten Free',
      'v': 'Vegetarian',
      'df': 'Dairy Free',
      'quick': 'Quick Meal'
    };
    return tagMap[tag] || tag;
  };

  return (
    <div className="fixed inset-0 flex items-start justify-center p-4 mt-12 mb-12" onClick={(e) => e.stopPropagation()}>
      <div className="bg-white rounded-lg shadow-xl w-full max-w-lg max-h-[calc(100vh-8rem)] overflow-hidden flex flex-col">
        <div className="p-4 border-b border-gray-200 flex justify-between items-center sticky top-0 bg-white z-10">
          <div>
            <h3 className="text-lg font-semibold text-gray-900">
              {meal.date ? new Date(meal.date).toLocaleDateString('en-US', { 
                weekday: 'long',
                month: 'long',
                day: 'numeric'
              }) : 'Meal'}
            </h3>
            <div className="flex items-center gap-2 text-sm text-gray-500">
              <span className="capitalize">{mealType}</span>
              <span>•</span>
              <span>{formatTime(meal.time)}</span>
            </div>
          </div>
          <div className="flex items-center gap-3">
            {isPastEmptyMeal && (
              <div className="text-gray-500 text-sm">
                No planned meal
              </div>
            )}
            {/* {!hasEnhancedRecipe && (
              <button
                onClick={() => setShowMealPrefsPopup(true)}
                disabled={isLoading || (isPastMeal && !isEmpty)}
                className="inline-flex items-center px-6 py-1.5 text-sm rounded-full font-medium
                  hover:opacity-90 transform hover:scale-105 transition-all bg-gradient-to-r from-violet-600 via-fuchsia-500 to-pink-500
                  text-white shadow-md hover:shadow-lg
                  border border-white/20 backdrop-blur-sm disabled:opacity-50 min-w-[140px] justify-center"
              >
                <SparklesIcon className="h-4 w-4 mr-1.5" />
                {isLoading ? 'Generating...' : isEmpty ? 'Create Meal' : 'Enhance Recipe'}
              </button>
            )} */}
            <button
              onClick={onClose}
              className="text-gray-400 hover:text-gray-500 focus:outline-none"
            >
              <XMarkIcon className="h-5 w-5" />
            </button>
          </div>
        </div>

        <div className="overflow-y-auto flex-1 p-6">
          {!recipeResponse && (
            <>
              {isEmpty && !isPastMeal ? (
                <div className="flex flex-col items-center justify-center h-32">
                  <div className="text-gray-500 text-base mb-2">
                    No meals planned
                  </div>
                  <p className="text-gray-400 text-sm text-center">
                    Click 'Create meal' to plan your meal
                  </p>
                </div>
              ) : (
                <>
                  {/* Meal Title and Description */}
                  {(meal.name || meal.title || meal.description) && (
                    <div className="mb-6">
                      <h3 className="text-lg font-semibold text-gray-900 mb-1">
                        {meal.name || meal.title || 'Meal Details'}
                      </h3>
                      {meal.description && (
                        <p className="text-sm text-gray-600">
                          {meal.description}
                        </p>
                      )}
                    </div>
                  )}

                  {/* Calories and Macros in compact format */}
                  {(meal.calories || meal.macros) && (
                    <div className="mb-6 text-sm text-gray-600">
                      <div className="flex items-center gap-1">
                        <FireIcon className="w-4 h-4" />
                        <span>{meal.calories}</span>
                      </div>
                      {meal.macros && (
                        <div className="mt-1 flex items-center gap-2">
                          <span>P: {formatMacro(meal.macros.protein)}</span>
                          <span>C: {formatMacro(meal.macros.carbs)}</span>
                          <span>F: {formatMacro(meal.macros.fat)}</span>
                          <span>Fiber: {formatMacro(meal.macros.fiber)}</span>
                        </div>
                      )}
                    </div>
                  )}

                  {/* Tags */}
                  {meal.tags && meal.tags.length > 0 && (
                    <div className="flex flex-wrap gap-2 mb-6">
                      {meal.tags.map((tag, index) => (
                        <span
                          key={index}
                          className="px-3 py-1 bg-gray-100 text-gray-600 rounded-full text-sm"
                        >
                          {getTagLabel(tag)}
                        </span>
                      ))}
                    </div>
                  )}

                  {/* Ingredients */}
                  {meal.ingredients && meal.ingredients.length > 0 && (
                    <div className="mb-6">
                      <h3 className="text-lg font-semibold mb-3">Ingredients</h3>
                      <div className="grid grid-cols-1 sm:grid-cols-2 gap-3">
                        {meal.ingredients.map((ingredient, index) => (
                          <div key={index} className="flex items-center gap-2 text-gray-600">
                            <span className="w-1.5 h-1.5 bg-primary rounded-full"></span>
                            <span>
                              {ingredient.quantity} {ingredient.unit} {ingredient.name}
                            </span>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}

                  {/* Cooking Instructions */}
                  {meal.instructions && meal.instructions.length > 0 && (
                    <div className="mb-6">
                      <h3 className="text-lg font-semibold mb-3">Cooking Steps</h3>
                      <ol className="space-y-3">
                        {meal.instructions.map((instruction, index) => (
                          <li key={index} className="flex gap-3">
                            <span className="flex-shrink-0 w-6 h-6 bg-primary/10 rounded-full flex items-center justify-center text-primary font-medium">
                              {index + 1}
                            </span>
                            <span className="text-gray-600">{instruction}</span>
                          </li>
                        ))}
                      </ol>
                    </div>
                  )}

                  {/* Additional Information */}
                  {meal.nutritionalBenefits && meal.nutritionalBenefits.length > 0 && (
                    <div className="mb-6">
                      <h3 className="text-lg font-semibold mb-3">Nutritional Benefits</h3>
                      <ul className="space-y-2">
                        {meal.nutritionalBenefits.map((benefit, index) => (
                          <li key={index} className="flex items-start gap-2 text-gray-600">
                            <span className="text-primary">•</span>
                            <span>{benefit}</span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </>
              )}
            </>
          )}

          {recipeResponse && (
            <>
              <div className="mt-4">
                <FeaturedRecipe
                  recipe={recipeResponse}
                  nutritionalContext={nutritionalContext}
                  userPreferences={userPreferences}
                  addToShoppingList={addToShoppingList}
                  logMeal={logMeal}
                  isLogged={isLogged}
                  showLogSuccess={showLogSuccess}
                  isAdviceExpanded={isAdviceExpanded}
                  setIsAdviceExpanded={setIsAdviceExpanded}
                  isPersonalizedExpanded={isPersonalizedExpanded}
                  setIsPersonalizedExpanded={setIsPersonalizedExpanded}
                  isCycleSupportExpanded={isCycleSupportExpanded}
                  setIsCycleSupportExpanded={setIsCycleSupportExpanded}
                  isInstructionsExpanded={isInstructionsExpanded}
                  setIsInstructionsExpanded={setIsInstructionsExpanded}
                />
              </div>
              <div className="mt-6 flex justify-end">
                {!hasEnhancedRecipe && (
                  <button
                    onClick={saveRecipe}
                    disabled={isSaving}
                    className="inline-flex items-center px-6 py-2 text-sm font-medium rounded-full
                      text-gray-900 bg-white hover:bg-gray-50 border border-gray-900
                      focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 
                      transition-all duration-200 disabled:opacity-50 disabled:cursor-not-allowed
                      hover:shadow-sm"
                  >
                    {isSaving ? 'Saving...' : 'Save Recipe'}
                  </button>
                )}
              </div>
            </>
          )}
        </div>

        {showMealPrefsPopup && (
          <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50" onClick={(e) => e.stopPropagation()}>
            <div className="bg-white rounded-lg p-6 max-w-md w-full mx-4 shadow-xl">
              <div className="flex justify-between items-center mb-4">
                <div>
                  <h3 className="text-lg font-semibold text-gray-900">
                    Create Meal for {new Date(meal.date).toLocaleDateString('en-US', { 
                      weekday: 'long',
                      month: 'long',
                      day: 'numeric'
                    })}
                  </h3>
                  <p className="text-sm text-gray-500">{formatTime(meal.time)} - {meal.mealType}</p>
                </div>
                <button
                  onClick={() => {
                    setShowMealPrefsPopup(false);
                    setMealPreferences('');
                  }}
                  className="text-gray-400 hover:text-gray-500"
                >
                  <XMarkIcon className="h-5 w-5" />
                </button>
              </div>
              
              <div className="mb-6">
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  What kind of meal would you like?
                </label>
                <textarea
                  value={mealPreferences}
                  onChange={(e) => setMealPreferences(e.target.value)}
                  placeholder={`E.g., 'A healthy ${meal.mealType.toLowerCase()} with protein' or 'A quick vegetarian meal'`}
                  className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-violet-500 focus:border-violet-500 min-h-[100px] text-sm"
                  maxLength={300}
                  autoFocus
                />
                <div className="mt-2 flex justify-between items-center">
                  <p className="text-sm text-gray-500">
                    Specify any dietary preferences, ingredients you'd like to include, or style of cooking
                  </p>
                  <span className="text-xs text-gray-400">
                    {mealPreferences.length}/300
                  </span>
                </div>
              </div>

              <div className="flex justify-end gap-3">
                <button
                  onClick={() => {
                    setShowMealPrefsPopup(false);
                    setMealPreferences('');
                  }}
                  className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-lg hover:bg-gray-50"
                >
                  Cancel
                </button>
                <button
                  onClick={() => {
                    setShowMealPrefsPopup(false);
                    generateRecipeDetails(mealPreferences);
                    setMealPreferences('');
                  }}
                  className="px-6 py-2 text-sm font-medium text-white bg-gradient-to-r from-violet-600 via-fuchsia-500 to-pink-500 rounded-lg hover:opacity-90 min-w-[120px]"
                >
                  {isLoading ? 'Generating...' : 'Generate'}
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
      {isLoading && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-8 max-w-sm w-full mx-4">
            <div className="flex flex-col items-center justify-center space-y-6">
              <div className="relative w-20 h-20 sm:w-24 sm:h-24">
                {/* Outer spinning ring */}
                <div className="absolute inset-0 rounded-full border-[3px] sm:border-4 border-t-blue-500 border-r-blue-300 border-b-blue-200 border-l-blue-400 animate-spin"></div>
                
                {/* Inner pulsing circle */}
                <div className="absolute inset-3 sm:inset-4 bg-gradient-to-br from-blue-400 to-blue-600 rounded-full animate-pulse"></div>
                
                {/* Center icon */}
                <div className="absolute inset-0 flex items-center justify-center text-xl sm:text-2xl">
                  🧑‍🍳
                </div>
              </div>
              <div className="flex flex-col items-center space-y-3">
                <div className="text-base sm:text-lg font-medium text-gray-800 animate-pulse text-center">
                  Enhancing your recipe...
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {showCameraPopup && (
        <CalendarCameraPopup 
          onClose={() => setShowCameraPopup(false)} 
          overrideTimestamp={meal.date}
        />
      )}

      {showAudioPopup && (
        <CalendarAudioPopup
          onClose={handleCloseAudioPopup}
          mediaRecorderRef={mediaRecorderRef}
          streamRef={streamRef}
          isProcessing={isProcessing}
          setIsProcessing={setIsProcessing}
          overrideTimestamp={meal.date}
        />
      )}
    </div>
  );
};

export default MealPopup;
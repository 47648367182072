import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { auth, db } from '../../firebase';
import PersonalInfo from './steps/PersonalInfo';
import HealthMetrics from './steps/HealthMetrics';
import Goals from './steps/Goals';
import NutritionistChat from './steps/NutritionistChat';
import ProgressBar from './ProgressBar';
import { XMarkIcon } from '@heroicons/react/24/outline';

const OnboardingFlow = () => {
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [showExitConfirmation, setShowExitConfirmation] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);
  const [userData, setUserData] = useState({
    // Personal Info
    firstName: '',
    lastName: '',
    gender: '',
    dateOfBirth: '',
    
    // Health Metrics
    weight: '',
    height: '',
    activityLevel: '',
    sportsPerWeek: 0,
    
    // Goals & Preferences
    goal: '',
    healthFocus: '',
    foodPreferences: '',
    allergies: [],
    
    // Menstrual Cycle Data
    menstrualCycleTracking: false,
    cycleStartDate: '',
    cycleDuration: 28, // Default cycle length
    
    // Calculated metrics (will be set in HealthMetrics step)
    calculatedCalories: {
      bmr: 0,
      tdee: 0,
      bmi: 0,
      bmiCategory: ''
    }
  });

  // Fetch existing user data for pre-filling
  useEffect(() => {
    const fetchUserData = async () => {
      if (!auth.currentUser) return;
      
      try {
        const userDoc = await getDoc(doc(db, 'users', auth.currentUser.uid));
        if (userDoc.exists()) {
          const data = userDoc.data();
          setUserData(prevData => ({
            ...prevData,
            firstName: data.firstName || '',
            lastName: data.lastName || '',
            gender: data.gender || '',
            dateOfBirth: data.dateOfBirth || '',
            weight: data.weight || '',
            height: data.height || '',
            activityLevel: data.activityLevel || '',
            sportsPerWeek: data.sportsPerWeek || 0,
            goal: data.goal || '',
            healthFocus: data.healthFocus || '',
            foodPreferences: data.foodPreferences || '',
            allergies: data.allergies || [],
            menstrualCycleTracking: data.menstrualCycleTracking || false,
            cycleStartDate: data.cycleStartDate || '',
            cycleDuration: data.cycleDuration || 28,
            calculatedCalories: data.calculatedCalories || {
              bmr: 0,
              tdee: 0,
              bmi: 0,
              bmiCategory: ''
            }
          }));
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, []);

  const totalSteps = 4;

  const handleNext = async () => {
    if (step < totalSteps) {
      setIsAnimating(true);
      setTimeout(() => {
        setStep(step + 1);
        setIsAnimating(false);
      }, 200);
    } else {
      // Save final data and redirect
      try {
        const user = auth.currentUser;
        if (user) {
          await setDoc(doc(db, 'users', user.uid), {
            ...userData,
            onboardingCompleted: true,
            lastUpdated: new Date().toISOString()
          });
          navigate('/dashboard');
        }
      } catch (error) {
        console.error('Error saving onboarding data:', error);
      }
    }
  };

  const handleBack = () => {
    if (step > 1) {
      setIsAnimating(true);
      setTimeout(() => {
        setStep(step - 1);
        setIsAnimating(false);
      }, 200);
    }
  };

  const updateUserData = useCallback((newData) => {
    setUserData(prev => ({
      ...prev,
      ...(typeof newData === 'function' ? newData(prev) : newData)
    }));
  }, []);

  const handleClose = () => {
    setShowExitConfirmation(true);
  };

  const confirmExit = () => {
    navigate('/dashboard');
  };

  const cancelExit = () => {
    setShowExitConfirmation(false);
  };

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <PersonalInfo
            userData={userData}
            updateUserData={updateUserData}
            onNext={handleNext}
          />
        );
      case 2:
        return (
          <HealthMetrics
            userData={userData}
            updateUserData={updateUserData}
            onNext={handleNext}
            onBack={handleBack}
          />
        );
      case 3:
        return (
          <Goals
            userData={userData}
            updateUserData={updateUserData}
            onNext={handleNext}
            onBack={handleBack}
          />
        );
      case 4:
        return (
          <NutritionistChat
            userData={userData}
            updateUserData={updateUserData}
            onNext={handleNext}
            onBack={handleBack}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="min-h-screen bg-gray-50">
      <div className="container mx-auto px-4 py-8 max-w-2xl relative">
        <button
          onClick={handleClose}
          className="absolute top-2 right-6 p-2 rounded-full hover:bg-gray-100 transition-colors"
          aria-label="Close onboarding"
        >
          <XMarkIcon className="h-6 w-6 text-gray-500" />
        </button>

        <ProgressBar currentStep={step} totalSteps={totalSteps} />
        <div className="mt-8 bg-white rounded-xl shadow-lg p-6">
          <div className={`transition-opacity duration-200 ${isAnimating ? 'opacity-0' : 'opacity-100'}`}>
            {renderStep()}
          </div>
        </div>

        {showExitConfirmation && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white p-6 rounded-lg shadow-xl max-w-sm w-full mx-4">
              <h3 className="text-lg font-semibold mb-4">Exit Onboarding?</h3>
              <p className="text-gray-600 mb-6">
                Are you sure you want to exit? Your progress will not be saved.
              </p>
              <div className="flex justify-end space-x-4">
                <button
                  onClick={cancelExit}
                  className="px-4 py-2 text-gray-600 hover:text-gray-800"
                >
                  Cancel
                </button>
                <button
                  onClick={confirmExit}
                  className="px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700"
                >
                  Exit
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default OnboardingFlow; 
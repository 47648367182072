import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { getAnalytics, logEvent } from "firebase/analytics";
import { app } from './firebase';

// Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";

// Your web app's Firebase configuration
// const firebaseConfig = {
//   apiKey: "AIzaSyBbgThsclZPPoKe_Wb5_13mFrU26G0nifg",
//   authDomain: "foodwise-food.firebaseapp.com",
//   projectId: "foodwise-food",
//   storageBucket: "foodwise-food.firebasestorage.app",
//   messagingSenderId: "1087768491662",
//   appId: "1:1087768491662:web:e469e7a44d771409fafbdf",
//   measurementId: "G-35L2HT507P"
// };

// Initialize Firebase
// const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// Register service worker
if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/serviceWorker.js')
      .then(registration => {
        console.log('ServiceWorker registration successful');
      })
      .catch(err => {
        console.log('ServiceWorker registration failed: ', err);
      });
  });
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals((metric) => {
  // Send web vitals to Firebase Analytics
  logEvent(analytics, 'web_vital', {
    name: metric.name,
    value: metric.value,
    id: metric.id
  });
});

import React, { useEffect, useState, useMemo } from 'react';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db, auth } from '../../firebase';


const NutritionProgress = ({ targetCalories = 2000, targetMacros = {} }) => {
  const [todaysMeals, setTodaysMeals] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  // Fetch today's meals from Firebase
  useEffect(() => {
    const fetchTodaysMeals = async () => {
      if (!auth.currentUser) return;

      try {
        setIsLoading(true);
        // Get today's start and end timestamps
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        const tomorrow = new Date(today);
        tomorrow.setDate(tomorrow.getDate() + 1);

        // Query meals collection for today's meals
        const mealsRef = collection(db, 'meals');
        const q = query(
          mealsRef,
          where('userId', '==', auth.currentUser.uid),
          where('timestamp', '>=', today),
          where('timestamp', '<', tomorrow)
        );

        const querySnapshot = await getDocs(q);
        const meals = [];
        
        querySnapshot.forEach((doc) => {
          const mealData = doc.data();
          console.log('Fetched meal:', { id: doc.id, ...mealData });
          meals.push({
            id: doc.id,
            ...mealData,
            // Use the pre-calculated nutrition values from saved meal
            nutrition: mealData.nutrition || {
              calories: 0,
              protein: 0,
              carbs: 0,
              fat: 0
            }
          });
        });

        console.log('All fetched meals:', meals);
        setTodaysMeals(meals);
      } catch (error) {
        console.error('Error fetching meals:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchTodaysMeals();
  }, []);

  // Calculate totals from pre-calculated values or ingredients
  const totals = React.useMemo(() => {
    console.log('Calculating totals from meals:', todaysMeals);
    
    return todaysMeals.reduce((acc, meal) => {
      console.log('Processing meal:', meal.title, meal);
      
      // If meal has pre-calculated nutrition values, use those
      if (meal.nutrition && Object.values(meal.nutrition).some(v => v > 0)) {
        console.log('Using pre-calculated nutrition:', meal.nutrition);
        return {
          calories: acc.calories + (meal.nutrition?.calories || 0),
          protein: acc.protein + (meal.nutrition?.protein || 0),
          carbs: acc.carbs + (meal.nutrition?.carbs || 0),
          fat: acc.fat + (meal.nutrition?.fat || 0)
        };
      }
      
      // Otherwise calculate from ingredients
      if (meal.ingredients && Array.isArray(meal.ingredients)) {
        console.log('Calculating from ingredients:', meal.ingredients);
        const mealTotals = meal.ingredients.reduce((mealAcc, ingredient) => {
          // Calculate actual values based on estimated grams
          const multiplier = ingredient.estimated_grams / 100; // Convert per 100g to actual grams
          const ingredientTotals = {
            calories: ingredient.kcal_per_100g * multiplier,
            protein: ingredient.protein_g * multiplier,
            carbs: ingredient.carbs_g * multiplier,
            fat: ingredient.fat_g * multiplier
          };
          console.log(`Ingredient ${ingredient.name}:`, ingredientTotals);
          
          return {
            calories: mealAcc.calories + ingredientTotals.calories,
            protein: mealAcc.protein + ingredientTotals.protein,
            carbs: mealAcc.carbs + ingredientTotals.carbs,
            fat: mealAcc.fat + ingredientTotals.fat
          };
        }, { calories: 0, protein: 0, carbs: 0, fat: 0 });

        console.log('Meal totals:', mealTotals);
        
        // Add meal totals to accumulator
        return {
          calories: acc.calories + mealTotals.calories,
          protein: acc.protein + mealTotals.protein,
          carbs: acc.carbs + mealTotals.carbs,
          fat: acc.fat + mealTotals.fat
        };
      }

      console.log('No nutrition data found for meal:', meal.title);
      return acc;
    }, { calories: 0, protein: 0, carbs: 0, fat: 0 });
  }, [todaysMeals]);

  // Ensure target values are numbers and memoize them
  const safeTargetCalories = Number(targetCalories) || 2000;
  const safeTargetMacros = useMemo(() => ({
    protein: Number(targetMacros.protein) || 150,
    carbs: Number(targetMacros.carbs) || 300,
    fat: Number(targetMacros.fat) || 65
  }), [targetMacros.protein, targetMacros.carbs, targetMacros.fat]);

  useEffect(() => {
    console.log('Final calculated totals:', totals);
    console.log('Target values:', {
      calories: safeTargetCalories,
      macros: safeTargetMacros
    });
    console.log('Percentage calculations:', {
      calories: Math.round((totals.calories / safeTargetCalories) * 100),
      protein: Math.round((totals.protein / safeTargetMacros.protein) * 100),
      carbs: Math.round((totals.carbs / safeTargetMacros.carbs) * 100),
      fat: Math.round((totals.fat / safeTargetMacros.fat) * 100)
    });
  }, [totals, safeTargetCalories, safeTargetMacros]);

  // Calculate percentages for progress bars
  const getPercentage = (value, target) => {
    const percentage = (value / target) * 100;
    return Math.min(Math.round(percentage), 100);
  };

  // Format number with appropriate unit
  const formatValue = (value, unit = '') => {
    return `${Math.round(value)}${unit}`;
  };

  if (isLoading) {
    return (
      <div className="bg-white rounded-xl shadow-lg p-6">
        <div className="animate-pulse">
          <div className="h-4 bg-gray-200 rounded w-1/4 mb-4"></div>
          <div className="space-y-3">
            <div className="h-8 bg-gray-200 rounded"></div>
            <div className="h-8 bg-gray-200 rounded"></div>
            <div className="h-8 bg-gray-200 rounded"></div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-white rounded-xl shadow-sm border border-gray-100">
      <div className="p-6">
        {/* Main Calories Progress */}
        <div className="flex items-center justify-between mb-8">
          <div>
            <h2 className="text-2xl font-bold text-gray-900 mb-1">
              {formatValue(totals.calories)}
              <span className="text-base font-normal text-gray-500 ml-1">/ {formatValue(safeTargetCalories)} kcal</span>
            </h2>
            <p className="text-sm text-gray-500">
              {totals.calories > 0 
                ? `${Math.round((totals.protein * 4) / totals.calories * 100)}% protein • ${Math.round((totals.carbs * 4) / totals.calories * 100)}% carbs • ${Math.round((totals.fat * 9) / totals.calories * 100)}% fat`
                : "No meals logged yet"}
            </p>
          </div>
          <div className="relative w-16 h-16">
            <svg className="w-full h-full transform -rotate-90">
              <circle
                className="text-gray-100"
                strokeWidth="4"
                stroke="currentColor"
                fill="transparent"
                r="26"
                cx="32"
                cy="32"
              />
              <circle
                className="text-primary"
                strokeWidth="4"
                strokeDasharray={164}
                strokeDashoffset={164 - (164 * getPercentage(totals.calories, safeTargetCalories)) / 100}
                strokeLinecap="round"
                stroke="currentColor"
                fill="transparent"
                r="26"
                cx="32"
                cy="32"
              />
            </svg>
            <span className="absolute inset-0 flex items-center justify-center text-sm font-medium">
              {getPercentage(totals.calories, safeTargetCalories)}%
            </span>
          </div>
        </div>

        {/* Macros Progress */}
        <div className="space-y-4">
          {/* Protein */}
          <div>
            <div className="flex items-center justify-between mb-1.5">
              <div className="flex items-center gap-2">
                <div className="w-2 h-2 rounded-full bg-primary"></div>
                <span className="text-sm font-medium text-gray-700">Protein</span>
              </div>
              <div className="flex items-baseline gap-2">
                <span className="text-sm font-semibold text-gray-900">{formatValue(totals.protein)}g</span>
                <span className="text-xs text-gray-500">/ {formatValue(safeTargetMacros.protein)}g</span>
                <span className="text-xs text-primary">({getPercentage(totals.protein, safeTargetMacros.protein)}%)</span>
              </div>
            </div>
            <div className="h-1 bg-gray-100 rounded-full overflow-hidden">
              <div 
                className="h-full bg-primary rounded-full transition-all duration-500 ease-out"
                style={{ width: `${getPercentage(totals.protein, safeTargetMacros.protein)}%` }}
              />
            </div>
          </div>

          {/* Carbs */}
          <div>
            <div className="flex items-center justify-between mb-1.5">
              <div className="flex items-center gap-2">
                <div className="w-2 h-2 rounded-full bg-primary/80"></div>
                <span className="text-sm font-medium text-gray-700">Carbs</span>
              </div>
              <div className="flex items-baseline gap-2">
                <span className="text-sm font-semibold text-gray-900">{formatValue(totals.carbs)}g</span>
                <span className="text-xs text-gray-500">/ {formatValue(safeTargetMacros.carbs)}g</span>
                <span className="text-xs text-primary/80">({getPercentage(totals.carbs, safeTargetMacros.carbs)}%)</span>
              </div>
            </div>
            <div className="h-1 bg-gray-100 rounded-full overflow-hidden">
              <div 
                className="h-full bg-primary/80 rounded-full transition-all duration-500 ease-out"
                style={{ width: `${getPercentage(totals.carbs, safeTargetMacros.carbs)}%` }}
              />
            </div>
          </div>

          {/* Fat */}
          <div>
            <div className="flex items-center justify-between mb-1.5">
              <div className="flex items-center gap-2">
                <div className="w-2 h-2 rounded-full bg-primary/60"></div>
                <span className="text-sm font-medium text-gray-700">Fat</span>
              </div>
              <div className="flex items-baseline gap-2">
                <span className="text-sm font-semibold text-gray-900">{formatValue(totals.fat)}g</span>
                <span className="text-xs text-gray-500">/ {formatValue(safeTargetMacros.fat)}g</span>
                <span className="text-xs text-primary/60">({getPercentage(totals.fat, safeTargetMacros.fat)}%)</span>
              </div>
            </div>
            <div className="h-1 bg-gray-100 rounded-full overflow-hidden">
              <div 
                className="h-full bg-primary/60 rounded-full transition-all duration-500 ease-out"
                style={{ width: `${getPercentage(totals.fat, safeTargetMacros.fat)}%` }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NutritionProgress; 
import React, { useState, useRef, useEffect } from 'react';
import { FaMicrophone, FaCamera, FaComments } from 'react-icons/fa';
import AudioRecordingPopup from './AudioRecordingPopup';
import CameraPopup from './CameraPopup';
import NutritionistChatPopup from '../chat/NutritionistChatPopup';
import { getAnalytics, logEvent } from "firebase/analytics";
import * as chatService from '../../services/chatService';
import { useAuth } from '../../contexts/AuthContext';

const FloatingActionButton = () => {
  const { user } = useAuth();
  const [isOpen, setIsOpen] = useState(false);
  const [showAudioPopup, setShowAudioPopup] = useState(false);
  const [showCameraPopup, setShowCameraPopup] = useState(false);
  const [showChatPopup, setShowChatPopup] = useState(false);
  const [messages, setMessages] = useState([]);
  const mediaRecorderRef = useRef(null);
  const streamRef = useRef(null);
  const menuRef = useRef(null);
  const [isProcessing, setIsProcessing] = useState(false);

  const stopRecording = () => {
    if (mediaRecorderRef.current) {
      if (mediaRecorderRef.current.state === 'recording') {
        mediaRecorderRef.current.stop();
      }
      mediaRecorderRef.current = null;
    }
    if (streamRef.current) {
      streamRef.current.getTracks().forEach(track => track.stop());
      streamRef.current = null;
    }
  };

  const handleCloseAudioPopup = () => {
    stopRecording();
    setShowAudioPopup(false);
  };

  const startRecording = async () => {
    try {
      const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
      const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
      
      const constraints = {
        audio: {
          echoCancellation: true,
          noiseSuppression: true,
          autoGainControl: true,
          sampleRate: 44100,
          channelCount: 1
        }
      };

      console.log('Requesting media with constraints:', constraints);
      const stream = await navigator.mediaDevices.getUserMedia(constraints);
      
      const mimeType = (isIOS || isSafari) ? 'audio/mp4;codecs=mp4a.40.2' : 'audio/webm';
      console.log('Starting recording with mime type:', mimeType);
      
      const options = {
        mimeType,
        audioBitsPerSecond: 128000
      };

      let mediaRecorder;
      try {
        mediaRecorder = new MediaRecorder(stream, options);
      } catch (e) {
        console.warn('Failed to create MediaRecorder with options, trying fallback options');
        if (isIOS || isSafari) {
          mediaRecorder = new MediaRecorder(stream, {
            mimeType: 'audio/mp4'
          });
        } else {
          mediaRecorder = new MediaRecorder(stream);
        }
      }

      const chunks = [];
      
      mediaRecorder.ondataavailable = (event) => {
        console.log('Data available event:', event.data?.size);
        if (event.data?.size > 0) {
          chunks.push(event.data);
        }
      };

      mediaRecorderRef.current = mediaRecorder;
      mediaRecorderRef.current.chunks = chunks;
      streamRef.current = stream;

      if (isIOS || isSafari) {
        mediaRecorder.start(100);
      } else {
        mediaRecorder.start();
      }

      console.log('Recording started');
      setShowAudioPopup(true);
    } catch (error) {
      console.error('Error accessing microphone:', error);
      alert('Could not access microphone. Please ensure you have granted permission.');
      setShowAudioPopup(false);
    }
  };

  const toggleMenu = () => {
    if (!isProcessing) {
      const newIsOpen = !isOpen;
      setIsOpen(newIsOpen);
      if (newIsOpen) {
        logEvent(getAnalytics(), 'openFloatingButton', {
          timestamp: new Date().toISOString()
        });
      }
    }
  };

  // Clean up on unmount
  useEffect(() => {
    return () => {
      stopRecording();
    };
  }, []);

  // Add this useEffect to handle clicks outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target) && isOpen) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  // Add handler for messages update
  const handleMessagesUpdate = (newMessages) => {
    setMessages(newMessages);
  };

  const buttonClasses = `
    w-12 h-12 
    text-gray-600
    rounded-full 
    shadow-lg 
    flex items-center justify-center 
    transition-all duration-300
    hover:scale-105 
    focus:outline-none 
    relative
    before:absolute
    before:inset-[-3px]
    before:-z-10
    before:rounded-full
    before:bg-[conic-gradient(from_0deg,#00ffff,#7000ff,#00ff66,#00ffff)]
    after:absolute
    after:inset-[-3px]
    after:-z-20
    after:rounded-full
    after:blur-md
    after:bg-[conic-gradient(from_0deg,#00ffff80,#7000ff80,#00ff6680,#00ffff80)]
    [&>*]:z-10
    active:scale-100
    ${isProcessing ? 'opacity-50 cursor-not-allowed' : ''}
  `;

  const innerButtonClasses = `
    absolute 
    inset-[0] 
    bg-white 
    rounded-full 
    z-[1]
  `;

  const mainButtonClasses = `
    w-14 h-14 
    text-gray-600
    rounded-full 
    shadow-lg 
    flex items-center justify-center 
    transition-all duration-300
    hover:scale-105 
    focus:outline-none 
    relative
    before:absolute
    before:inset-[-3px]
    before:-z-10
    before:rounded-full
    before:bg-[conic-gradient(from_0deg,#00ffff,#7000ff,#00ff66,#00ffff)]
    after:absolute
    after:inset-[-3px]
    after:-z-20
    after:rounded-full
    after:blur-md
    after:bg-[conic-gradient(from_0deg,#00ffff80,#7000ff80,#00ff6680,#00ffff80)]
    [&>*]:z-10
    active:scale-100
    ${isOpen ? 'rotate-45' : ''}
  `;

  return (
    <>
      <div ref={menuRef} className={`fixed bottom-28 md:bottom-6 right-6 flex flex-col items-center gap-10 ${
        isOpen ? 'pointer-events-auto' : 'pointer-events-none'
      }`}>
        <div className={`flex flex-col items-center gap-6 transition-all duration-200 transform ${
          isOpen ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10 pointer-events-none'
        }`}>
          {/* Chat button - hidden on mobile, visible on md and up */}
          <div className="hidden md:block">
            <button
              onClick={() => {
                if (!isProcessing) {
                  setShowChatPopup(true);
                  setIsOpen(false);
                  logEvent(getAnalytics(), 'clickFloatingButton', {
                    type: 'chat',
                    timestamp: new Date().toISOString()
                  });
                }
              }}
              disabled={isProcessing}
              className={buttonClasses}
              aria-label="Open chat"
            >
              <div className={innerButtonClasses} />
              <FaComments className="h-5 w-5" />
            </button>
          </div>

          <button
            onClick={() => {
              if (!isProcessing) {
                startRecording();
                setIsOpen(false);
                logEvent(getAnalytics(), 'clickFloatingButton', {
                  type: 'audio',
                  timestamp: new Date().toISOString()
                });
              }
            }}
            disabled={isProcessing}
            className={buttonClasses}
            aria-label="Record audio"
          >
            <div className={innerButtonClasses} />
            {isProcessing ? (
              <div className="animate-spin h-5 w-5 border-2 border-gray-500 border-t-transparent rounded-full" />
            ) : (
              <FaMicrophone className="h-5 w-5" />
            )}
          </button>

          <button
            onClick={() => {
              if (!isProcessing) {
                setShowCameraPopup(true);
                setIsOpen(false);
                logEvent(getAnalytics(), 'clickFloatingButton', {
                  type: 'camera',
                  timestamp: new Date().toISOString()
                });
              }
            }}
            disabled={isProcessing}
            className={buttonClasses}
            aria-label="Take photo"
          >
            <div className={innerButtonClasses} />
            <FaCamera className="h-5 w-5" />
          </button>
        </div>

        <button
          onClick={toggleMenu}
          disabled={isProcessing}
          className={`pointer-events-auto ${mainButtonClasses}`}
          aria-label="Toggle menu"
        >
          <div className={innerButtonClasses} />
          <svg 
            xmlns="http://www.w3.org/2000/svg" 
            className="h-8 w-8" 
            fill="none" 
            viewBox="0 0 24 24" 
            stroke="currentColor"
          >
            <path 
              strokeLinecap="round" 
              strokeLinejoin="round" 
              strokeWidth={2} 
              d="M12 6v6m0 0v6m0-6h6m-6 0H6" 
            />
          </svg>
        </button>
      </div>

      {showChatPopup && (
        <NutritionistChatPopup 
          isOpen={showChatPopup} 
          onClose={async () => {
            try {
              // Synthesize chat before closing
              const synthesis = await chatService.synthesizeChat(messages);
              await chatService.saveChatSynthesis(user.uid, synthesis);
              console.log('Chat synthesis saved on close');
            } catch (error) {
              console.error('Error synthesizing chat:', error);
            }
            setShowChatPopup(false);
          }}
          hideMealPlanButton={true}
          onGenerateMealPlan={() => {
            setShowChatPopup(false);
          }}
          onMessagesUpdate={handleMessagesUpdate}
        />
      )}

      {showAudioPopup && (
        <AudioRecordingPopup 
          onClose={handleCloseAudioPopup}
          mediaRecorderRef={mediaRecorderRef}
          streamRef={streamRef}
          isProcessing={isProcessing}
          setIsProcessing={setIsProcessing}
        />
      )}
      
      {showCameraPopup && (
        <CameraPopup onClose={() => setShowCameraPopup(false)} />
      )}
    </>
  );
};

export default FloatingActionButton; 
import React, { useState, useEffect } from 'react';
import { collection, query, where, getDocs, orderBy } from 'firebase/firestore';
import { db } from '../../firebase';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, PieChart, Pie, Cell, LineChart, Line, Legend } from 'recharts';

const HomeStats = ({ user }) => {
  const [stats, setStats] = useState({
    totalMeals: 0,
    dailyNutrients: [],
    mealTimings: [],
    ingredientFrequency: [],
    uniqueFruits: 0,
    uniqueVegetables: 0,
    fruitsList: [],
    vegetablesList: []
  });

  useEffect(() => {
    const fetchStats = async () => {
      if (!user) return;

      try {
        // Fetch meals from the last 7 days
        const sevenDaysAgo = new Date();
        sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);

        const mealsRef = collection(db, 'meals');
        const mealsQuery = query(
          mealsRef,
          where('userId', '==', user.uid),
          where('timestamp', '>=', sevenDaysAgo),
          orderBy('timestamp', 'desc')
        );

        const mealsSnapshot = await getDocs(mealsQuery);
        const meals = mealsSnapshot.docs.map(doc => ({
          ...doc.data(),
          id: doc.id,
          timestamp: doc.data().timestamp?.toDate()
        }));

        // Initialize daily tracking with sets for unique ingredients
        const dailyStats = {};
        const mealTimings = { morning: 0, afternoon: 0, evening: 0 };
        const ingredientCount = {};
        const uniqueIngredients = { 
          fruits: new Set(), 
          vegetables: new Set(),
          fruitsList: [], 
          vegetablesList: [] 
        };

        // Initialize last 7 days
        for (let i = 0; i < 7; i++) {
          const date = new Date();
          date.setDate(date.getDate() - i);
          const day = date.toLocaleDateString('en-US', { weekday: 'short' });
          dailyStats[day] = {
            fruits: new Set(),
            vegetables: new Set(),
            day,
            date: date // Keep date for sorting
          };
        }

        meals.forEach(meal => {
          // Get day string for grouping
          const day = meal.timestamp?.toLocaleDateString('en-US', { weekday: 'short' });
          
          if (dailyStats[day]) {
            // Track meal timings
            const hour = meal.timestamp?.getHours();
            if (hour < 11) mealTimings.morning++;
            else if (hour < 17) mealTimings.afternoon++;
            else mealTimings.evening++;

            // Process ingredients
            if (meal.ingredients) {
              meal.ingredients.forEach(ingredient => {
                const category = ingredient.category?.toLowerCase();
                // Skip condiments and certain categories
                if (category && 
                    !['condiments', 'spices', 'seasonings', 'oils', 'sauces'].includes(category) &&
                    !['salt', 'pepper', 'oil', 'water'].includes(ingredient.name?.toLowerCase())) {
                  // Count ingredient frequency
                  ingredientCount[ingredient.name] = (ingredientCount[ingredient.name] || 0) + 1;
                }

                // Track unique fruits and vegetables by day
                if (category === 'fruits') {
                  dailyStats[day].fruits.add(ingredient.name);
                  uniqueIngredients.fruits.add(ingredient.name);
                  if (!uniqueIngredients.fruitsList.includes(ingredient.name)) {
                    uniqueIngredients.fruitsList.push(ingredient.name);
                  }
                }
                if (category === 'vegetables') {
                  dailyStats[day].vegetables.add(ingredient.name);
                  uniqueIngredients.vegetables.add(ingredient.name);
                  if (!uniqueIngredients.vegetablesList.includes(ingredient.name)) {
                    uniqueIngredients.vegetablesList.push(ingredient.name);
                  }
                }
              });
            }
          }
        });

        // Convert Sets to counts and sort by date
        const dailyNutrients = Object.values(dailyStats)
          .map(day => ({
            day: day.day,
            fruits: day.fruits.size,
            vegetables: day.vegetables.size,
            date: day.date
          }))
          .sort((a, b) => a.date - b.date);

        const ingredientFrequency = Object.entries(ingredientCount)
          .map(([name, count]) => ({ name, count }))
          .sort((a, b) => b.count - a.count)
          .slice(0, 5);

        const mealTimingData = [
          { name: 'Morning', meals: mealTimings.morning },
          { name: 'Afternoon', meals: mealTimings.afternoon },
          { name: 'Evening', meals: mealTimings.evening }
        ];

        // Sort the lists alphabetically
        uniqueIngredients.fruitsList.sort();
        uniqueIngredients.vegetablesList.sort();

        setStats({
          totalMeals: meals.length,
          dailyNutrients,
          mealTimings: mealTimingData,
          ingredientFrequency,
          uniqueFruits: uniqueIngredients.fruits.size,
          uniqueVegetables: uniqueIngredients.vegetables.size,
          fruitsList: uniqueIngredients.fruitsList,
          vegetablesList: uniqueIngredients.vegetablesList
        });

      } catch (error) {
        console.error('Error fetching stats:', error);
      }
    };

    fetchStats();
  }, [user]);

  const [showIngredientTooltip, setShowIngredientTooltip] = useState(false);

  const COLORS = {
    morning: '#0088FE',    // Blue
    afternoon: '#6366F1',  // Indigo
    evening: '#FFBB28',    // Yellow/Orange
  };

  const INGREDIENT_COLORS = ['#0088FE', '#6366F1', '#FFBB28', '#FF8042', '#8884d8'];

  return (
    <div className="bg-white rounded-lg shadow p-6 mb-6">
      <h2 className="text-xl font-semibold mb-4">Last 7 Days Food Statistics</h2>
      
      {/* Quick Stats */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-6">
        <div className="bg-blue-50 p-4 rounded-lg">
          <p className="text-sm text-gray-600">Total Meals</p>
          <p className="text-2xl font-bold text-blue-600">{stats.totalMeals}</p>
        </div>
        <div 
          className="bg-green-50 p-4 rounded-lg relative cursor-pointer"
          onMouseEnter={() => setShowIngredientTooltip(true)}
          onMouseLeave={() => setShowIngredientTooltip(false)}
          onClick={() => setShowIngredientTooltip(!showIngredientTooltip)} // For mobile
        >
          <p className="text-sm text-gray-600">Unique Fruits & Veggies</p>
          <p className="text-2xl font-bold text-green-600">
            {stats.uniqueFruits + stats.uniqueVegetables}
          </p>
          <p className="text-xs text-gray-500">
            {stats.uniqueFruits} fruits, {stats.uniqueVegetables} vegetables
          </p>
          
          {/* Detailed Tooltip */}
          {showIngredientTooltip && (
            <div className="absolute left-0 top-full mt-2 w-64 p-4 bg-white rounded-lg shadow-lg z-50">
              <div className="mb-3">
                <p className="font-semibold text-green-600 mb-1">Vegetables ({stats.vegetablesList.length})</p>
                <ul className="text-sm text-gray-600">
                  {stats.vegetablesList.map((veg, index) => (
                    <li key={`veg-${index}`}>{veg}</li>
                  ))}
                </ul>
              </div>
              <div>
                <p className="font-semibold text-yellow-600 mb-1">Fruits ({stats.fruitsList.length})</p>
                <ul className="text-sm text-gray-600">
                  {stats.fruitsList.map((fruit, index) => (
                    <li key={`fruit-${index}`}>{fruit}</li>
                  ))}
                </ul>
              </div>
            </div>
          )}
        </div>
        <div 
          className={`${
            stats.mealTimings.length > 0 
              ? stats.mealTimings.reduce((a, b) => a.meals > b.meals ? a : b).name.toLowerCase() === 'morning'
                ? 'bg-blue-50'
                : stats.mealTimings.reduce((a, b) => a.meals > b.meals ? a : b).name.toLowerCase() === 'afternoon'
                  ? 'bg-indigo-50'
                  : 'bg-yellow-50'
              : 'bg-purple-50'
          } p-4 rounded-lg`}
        >
          <p className="text-sm text-gray-600">Most Active Mealtime</p>
          <p className={`text-2xl font-bold ${
            stats.mealTimings.length > 0 
              ? stats.mealTimings.reduce((a, b) => a.meals > b.meals ? a : b).name.toLowerCase() === 'morning'
                ? 'text-blue-600'
                : stats.mealTimings.reduce((a, b) => a.meals > b.meals ? a : b).name.toLowerCase() === 'afternoon'
                  ? 'text-indigo-600'
                  : 'text-yellow-600'
              : 'text-purple-600'
          }`}>
            {stats.mealTimings.length > 0 
              ? stats.mealTimings.reduce((a, b) => a.meals > b.meals ? a : b).name 
              : 'No data'}
          </p>
        </div>
      </div>

      {/* Daily Unique Fruits & Vegetables Trend */}
      <div className="mb-8">
        <h3 className="text-lg font-semibold mb-2">Daily Unique Fruits & Vegetables</h3>
        <p className="text-sm text-gray-600 mb-4">Number of different fruits and vegetables consumed each day</p>
        <div className="h-72">
          {stats.dailyNutrients.length > 0 ? (
            <ResponsiveContainer width="100%" height="100%">
              <LineChart data={stats.dailyNutrients} margin={{ top: 20, right: 30, left: 20, bottom: 20 }}>
                <CartesianGrid strokeDasharray="3 3" strokeOpacity={0.3} />
                <XAxis dataKey="day" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line 
                  type="monotone" 
                  dataKey="fruits" 
                  stroke="#FFBB28" 
                  name="Fruits" 
                  strokeWidth={3}
                  dot={{ strokeWidth: 2, r: 4 }}
                  activeDot={{ r: 6, strokeWidth: 3 }}
                />
                <Line 
                  type="monotone" 
                  dataKey="vegetables" 
                  stroke="#6366F1" 
                  name="Vegetables" 
                  strokeWidth={3}
                  dot={{ strokeWidth: 2, r: 4 }}
                  activeDot={{ r: 6, strokeWidth: 3 }}
                />
              </LineChart>
            </ResponsiveContainer>
          ) : (
            <div className="h-full flex items-center justify-center text-gray-500">
              No meal data available for the past 7 days
            </div>
          )}
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        {/* Meal Timing Distribution */}
        <div>
          <h3 className="text-lg font-semibold mb-2">Meal Timing Distribution</h3>
          <div className="h-64">
            {stats.mealTimings.some(timing => timing.meals > 0) ? (
              <ResponsiveContainer width="100%" height="100%">
                <PieChart>
                  <Pie
                    data={stats.mealTimings}
                    cx="50%"
                    cy="50%"
                    labelLine={false}
                    outerRadius={80}
                    fill="#8884d8"
                    dataKey="meals"
                    nameKey="name"
                    label={({ name, percent }) => `${name} ${(percent * 100).toFixed(0)}%`}
                  >
                    {stats.mealTimings.map((entry, index) => (
                      <Cell 
                        key={`cell-${index}`} 
                        fill={COLORS[entry.name.toLowerCase()]}
                      />
                    ))}
                  </Pie>
                  <Tooltip />
                </PieChart>
              </ResponsiveContainer>
            ) : (
              <div className="h-full flex items-center justify-center text-gray-500">
                No meal timing data available
              </div>
            )}
          </div>
        </div>

        {/* Top 5 Ingredients */}
        <div>
          <h3 className="text-lg font-semibold mb-2">Most Used Ingredients</h3>
          <div className="h-64">
            {stats.ingredientFrequency.length > 0 ? (
              <ResponsiveContainer width="100%" height="100%">
                <BarChart
                  data={stats.ingredientFrequency}
                  layout="vertical"
                  margin={{ top: 5, right: 30, left: 100, bottom: 5 }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis type="number" />
                  <YAxis type="category" dataKey="name" />
                  <Tooltip />
                  <Bar dataKey="count" fill="#8884d8">
                    {stats.ingredientFrequency.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={INGREDIENT_COLORS[index % INGREDIENT_COLORS.length]} />
                    ))}
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            ) : (
              <div className="h-full flex items-center justify-center text-gray-500">
                No ingredient data available
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeStats;

import React, { useState, useEffect } from 'react';
import { FiActivity, FiBarChart2 } from 'react-icons/fi';
import { calculateBMI } from '../../../services/calorieCalculator';

const HealthMetrics = ({ userData, updateUserData, onNext, onBack }) => {
  const [errors, setErrors] = useState({});

  const activityLevels = [
    { value: 'sedentary', label: 'Sedentary', description: 'Little or no exercise' },
    { value: 'lightly-active', label: 'Lightly Active', description: 'Light exercise/sports 1-3 days/week' },
    { value: 'moderately-active', label: 'Moderately Active', description: 'Moderate exercise/sports 3-5 days/week' },
    { value: 'very-active', label: 'Very Active', description: 'Hard exercise/sports 6-7 days/week' },
    { value: 'super-active', label: 'Super Active', description: 'Very hard exercise/sports & physical job or training twice per day' },
  ];

  // Calculate BMI and update user data whenever weight or height changes (but don't display it)
  useEffect(() => {
    if (userData.weight && userData.height) {
      const bmi = calculateBMI(parseFloat(userData.weight), parseFloat(userData.height));
      const category = getBMICategory(bmi);
      // Only store BMI in userData, don't display it
      updateUserData(prevData => ({
        ...prevData,
        calculatedCalories: {
          ...(prevData.calculatedCalories || {}),
          bmi,
          bmiCategory: category,
        },
      }));
    }
  }, [userData.weight, userData.height, updateUserData]);

  const getBMICategory = (bmi) => {
    if (bmi < 18.5) return 'Underweight';
    if (bmi < 25) return 'Normal weight';
    if (bmi < 30) return 'Overweight';
    return 'Obese';
  };

  const validateForm = () => {
    const newErrors = {};
    if (!userData.weight) newErrors.weight = 'Weight is required';
    if (!userData.height) newErrors.height = 'Height is required';
    if (!userData.activityLevel) newErrors.activityLevel = 'Activity level is required';
    
    // Validate reasonable ranges
    if (userData.weight && (userData.weight < 30 || userData.weight > 300)) {
      newErrors.weight = 'Please enter a valid weight between 30-300 kg';
    }
    if (userData.height && (userData.height < 100 || userData.height > 250)) {
      newErrors.height = 'Please enter a valid height between 100-250 cm';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      onNext();
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    updateUserData({ [name]: value });
    if (errors[name]) {
      setErrors(prev => ({ ...prev, [name]: undefined }));
    }
  };

  return (
    <div className="space-y-6">
      <div className="text-center">
        <h2 className="text-2xl font-bold text-gray-900">Your Health Profile</h2>
        <p className="mt-2 text-sm text-gray-600">
          Let's gather some information about your physical metrics to provide accurate
          nutritional recommendations.
        </p>
      </div>

      <form onSubmit={handleSubmit} className="space-y-6">
        {/* Physical Metrics */}
        <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
          <div>
            <label htmlFor="weight" className="block text-sm font-medium text-gray-700">
              Weight (kg)
            </label>
            <div className="mt-1 relative">
              <div className="absolute inset-y-0 left-0 pl-4 flex items-center pointer-events-none">
                <FiBarChart2 className="h-5 w-5 text-gray-400" />
              </div>
              <input
                type="number"
                name="weight"
                id="weight"
                min="30"
                max="300"
                step="0.1"
                value={userData.weight || ''}
                onChange={handleChange}
                className={`block w-full pl-12 pr-3 py-2 border ${
                  errors.weight ? 'border-red-300' : 'border-gray-300'
                } rounded-md shadow-sm focus:ring-primary-500 focus:border-primary-500`}
                placeholder="70"
              />
            </div>
            {errors.weight && (
              <p className="mt-1 text-sm text-red-600">{errors.weight}</p>
            )}
          </div>

          <div>
            <label htmlFor="height" className="block text-sm font-medium text-gray-700">
              Height (cm)
            </label>
            <div className="mt-1 relative">
              <div className="absolute inset-y-0 left-0 pl-4 flex items-center pointer-events-none">
                <FiBarChart2 className="h-5 w-5 text-gray-400" />
              </div>
              <input
                type="number"
                name="height"
                id="height"
                min="100"
                max="250"
                value={userData.height || ''}
                onChange={handleChange}
                className={`block w-full pl-12 pr-3 py-2 border ${
                  errors.height ? 'border-red-300' : 'border-gray-300'
                } rounded-md shadow-sm focus:ring-primary-500 focus:border-primary-500`}
                placeholder="170"
              />
            </div>
            {errors.height && (
              <p className="mt-1 text-sm text-red-600">{errors.height}</p>
            )}
          </div>
        </div>

        {/* Activity Level */}
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Activity Level
          </label>
          <div className="mt-1 relative">
            <div className="absolute inset-y-0 left-0 pl-4 flex items-center pointer-events-none">
              <FiActivity className="h-5 w-5 text-gray-400" />
            </div>
            <select
              name="activityLevel"
              value={userData.activityLevel || ''}
              onChange={handleChange}
              className={`block w-full pl-12 pr-3 py-2 border ${
                errors.activityLevel ? 'border-red-300' : 'border-gray-300'
              } rounded-md shadow-sm focus:ring-primary-500 focus:border-primary-500`}
            >
              <option value="">Select activity level</option>
              {activityLevels.map(level => (
                <option key={level.value} value={level.value}>
                  {level.label} - {level.description}
                </option>
              ))}
            </select>
          </div>
          {errors.activityLevel && (
            <p className="mt-1 text-sm text-red-600">{errors.activityLevel}</p>
          )}
        </div>

        {/* Navigation Buttons */}
        <div className="flex justify-between px-4 py-2 bg-white border-t border-gray-100">
          <button
            type="button"
            onClick={onBack}
            className="text-sm text-gray-600 hover:text-gray-900"
          >
            Back
          </button>
          <button
            type="submit"
            className="text-sm text-primary hover:text-primary-dark"
          >
            Continue
          </button>
        </div>
      </form>
    </div>
  );
};

export default HealthMetrics; 
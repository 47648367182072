import { useState, useEffect } from 'react';
import { db, auth } from '../firebase';
import { 
  collection, 
  query, 
  where, 
  orderBy, 
  limit, 
  getDocs, 
  doc, 
  updateDoc,
  deleteDoc 
} from 'firebase/firestore';

export const useAnalysisHistory = (limitCount = 10) => {
  const [history, setHistory] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const updateMeal = async (mealId, updates) => {
    try {
      const mealRef = doc(db, 'meals', mealId);
      await updateDoc(mealRef, updates);
      // Update local state after successful Firestore update
      setHistory(prevHistory =>
        prevHistory.map(meal =>
          meal.id === mealId
            ? { ...meal, ...updates }
            : meal
        )
      );
    } catch (error) {
      console.error('Error updating meal:', error);
      throw error;
    }
  };

  const deleteMeal = async (mealId) => {
    try {
      const mealRef = doc(db, 'meals', mealId);
      await deleteDoc(mealRef);
      // Update the local state to remove the deleted meal
      setHistory(prevHistory => prevHistory.filter(meal => meal.id !== mealId));
    } catch (error) {
      console.error('Error deleting meal:', error);
      throw error;
    }
  };

  useEffect(() => {
    const fetchHistory = async () => {
      try {
        const user = auth.currentUser;
        if (!user) {
          setError("No user logged in");
          setLoading(false);
          return;
        }

        const mealsRef = collection(db, 'meals');
        const q = query(
          mealsRef,
          where('userId', '==', user.uid),
          orderBy('timestamp', 'desc'),
          limit(limitCount)
        );

        const querySnapshot = await getDocs(q);
        const mealsHistory = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));

        setHistory(mealsHistory);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchHistory();
  }, [limitCount]);

  return { history, setHistory, loading, error, deleteMeal, updateMeal };
}; 
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { HiChevronDown, HiChevronUp } from 'react-icons/hi';
import { IoFlaskOutline, IoWaterOutline, IoFlowerOutline  } from 'react-icons/io5';
import { useAuth } from '../contexts/AuthContext';
import { toast } from 'react-hot-toast';
import { db } from '../firebase';
import { collection, addDoc, serverTimestamp, query, where, getDocs } from 'firebase/firestore';

const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="border border-pink-100 rounded-2xl overflow-hidden mb-4 transition-all duration-300 hover:shadow-lg">
      <button
        className="w-full px-8 py-5 text-left bg-white hover:bg-pink-50 flex justify-between items-center transition-all duration-300"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="text-lg font-medium text-gray-800">{question}</span>
        <div className="text-pink-500 transition-transform duration-300" style={{ transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)' }}>
          {isOpen ? <HiChevronUp className="w-6 h-6" /> : <HiChevronDown className="w-6 h-6" />}
        </div>
      </button>
      <div className={`transition-all duration-300 ${isOpen ? 'max-h-96 opacity-100' : 'max-h-0 opacity-0'}`}>
        <div className="px-8 py-5 bg-white">
          <p className="text-gray-600 leading-relaxed">{answer}</p>
        </div>
      </div>
    </div>
  );
};

const Landing1 = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const { currentUser } = useAuth();

  useEffect(() => {
    if (currentUser) {
      navigate('/home');
    }
  }, [currentUser, navigate]);

  const handleWaitlistSubmit = async (e) => {
    e.preventDefault();
    
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      toast.error('Please enter a valid email address');
      return;
    }

    try {
      const waitlistQuery = query(
        collection(db, "waitlist"),
        where("email", "==", email)
      );

      const querySnapshot = await getDocs(waitlistQuery);
      if (!querySnapshot.empty) {
        toast.error('This email is already on the waitlist');
        return;
      }

      await addDoc(collection(db, "waitlist"), {
        email,
        timestamp: serverTimestamp(),
        source: 'cycle-nutrition',
        status: 'pending'
      });

      toast.success('Successfully joined the waitlist!');
      setEmail('');
    } catch (error) {
      console.error('Error:', error);
      toast.error('Failed to join waitlist. Please try again.');
    }
  };

  return (
    <div className="w-full">
      {/* Hero Section */}
      <div className="bg-gradient-to-br from-rose-50 via-pink-50 to-purple-50 py-20 sm:py-28">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-12 items-center">
            <div className="text-left">
              <h1 className="text-4xl sm:text-5xl md:text-6xl font-extrabold mb-6 leading-tight">
                Live 10 Years Longer with
                <span className="text-transparent bg-clip-text bg-gradient-to-r from-rose-400 to-purple-400"> Optimal Nutrition</span>
              </h1>
              <p className="text-xl text-gray-700 mb-8 leading-relaxed">
                Get personalized meal plans powered by AI to reach your health goals. Track easily, eat better, live longer.
              </p>
              <form onSubmit={handleWaitlistSubmit} className="max-w-lg flex flex-col sm:flex-row gap-4">
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Enter your email"
                  className="flex-1 px-6 py-4 text-lg rounded-full border-2 border-purple-100 focus:border-purple-200 focus:ring-2 focus:ring-purple-100 focus:outline-none transition-all duration-300 shadow-sm hover:shadow-md"
                />
                <button
                  type="submit"
                  className="px-8 py-4 bg-gradient-to-r from-rose-400 to-purple-400 text-white font-medium rounded-full hover:from-rose-500 hover:to-purple-500 transform hover:scale-105 transition-all duration-300 shadow-md hover:shadow-lg"
                >
                  Start Your Journey
                </button>
              </form>
            </div>
            <div className="relative">
              <img
                src="/images/landing/app-main-interface.jpg"
                alt="FoodWise App Interface"
                className="rounded-3xl shadow-2xl"
              />
              <div className="absolute inset-0 rounded-3xl bg-gradient-to-br from-rose-200/20 to-purple-200/20"></div>
            </div>
          </div>
        </div>
      </div>

      {/* Three Simple Steps Section */}
      <section className="py-20 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6">
          <div className="text-center mb-16">
            <h2 className="text-3xl font-bold mb-4">Three Simple Steps to Better Health</h2>
            <p className="text-xl text-gray-600 max-w-3xl mx-auto">
              Your journey to optimal nutrition made simple and personalized.
            </p>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="bg-gradient-to-br from-rose-50 to-white p-8 rounded-3xl shadow-lg">
              <div className="w-14 h-14 bg-rose-100 rounded-2xl flex items-center justify-center mb-6">
                <span className="text-2xl">📸</span>
              </div>
              <h3 className="text-xl font-semibold mb-4">Easy Tracking</h3>
              <p className="text-gray-600">
                Simply take a photo of your meal or tell your phone what you're eating. Our AI does the rest.
              </p>
            </div>
            <div className="bg-gradient-to-br from-pink-50 to-white p-8 rounded-3xl shadow-lg">
              <div className="w-14 h-14 bg-pink-100 rounded-2xl flex items-center justify-center mb-6">
                <span className="text-2xl">🎯</span>
              </div>
              <h3 className="text-xl font-semibold mb-4">Set Your Goals</h3>
              <p className="text-gray-600">
                Tell our AI what matters to you - from health goals to cooking preferences. We adapt to your needs.
              </p>
            </div>
            <div className="bg-gradient-to-br from-purple-50 to-white p-8 rounded-3xl shadow-lg">
              <div className="w-14 h-14 bg-purple-100 rounded-2xl flex items-center justify-center mb-6">
                <span className="text-2xl">✨</span>
              </div>
              <h3 className="text-xl font-semibold mb-4">Get Your Plan</h3>
              <p className="text-gray-600">
                Receive sophisticated, personalized meal plans designed to help you reach your optimal health.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Scientific Approach Section */}
      <section className="py-20 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6">
          <div className="text-center mb-16">
            <h2 className="text-3xl font-bold mb-4">The Science of Cycle Nutrition</h2>
            <p className="text-xl text-gray-600 max-w-3xl mx-auto">
              Our approach combines nutritional science with hormonal wisdom to support your body's natural rhythms.
            </p>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="bg-gradient-to-br from-rose-50 to-white p-8 rounded-3xl shadow-lg">
              <div className="w-14 h-14 bg-rose-100 rounded-2xl flex items-center justify-center mb-6">
                <IoFlaskOutline className="w-8 h-8 text-rose-500" />
              </div>
              <h3 className="text-xl font-semibold mb-4">Research-Backed</h3>
              <p className="text-gray-600">
                Evidence-based nutrition protocols tailored to each phase of your menstrual cycle.
              </p>
            </div>
            <div className="bg-gradient-to-br from-pink-50 to-white p-8 rounded-3xl shadow-lg">
              <div className="w-14 h-14 bg-pink-100 rounded-2xl flex items-center justify-center mb-6">
                <IoFlowerOutline className="w-8 h-8 text-pink-500" />
              </div>
              <h3 className="text-xl font-semibold mb-4">Hormone Balance</h3>
              <p className="text-gray-600">
                Support natural hormone production with phase-specific nutrients and minerals.
              </p>
            </div>
            <div className="bg-gradient-to-br from-purple-50 to-white p-8 rounded-3xl shadow-lg">
              <div className="w-14 h-14 bg-purple-100 rounded-2xl flex items-center justify-center mb-6">
                <IoWaterOutline className="w-8 h-8 text-purple-500" />
              </div>
              <h3 className="text-xl font-semibold mb-4">Natural Relief</h3>
              <p className="text-gray-600">
                Anti-inflammatory foods and nutrients to reduce menstrual discomfort naturally.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Cycle Phases Section */}
      <section className="py-20 bg-gradient-to-br from-rose-50 via-pink-50 to-purple-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6">
          <div className="text-center mb-16">
            <h2 className="text-3xl font-bold mb-4">Nutrition for Every Phase</h2>
            <p className="text-xl text-gray-600 max-w-3xl mx-auto">
              Personalized nutrition recommendations that evolve with your cycle.
            </p>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            <div className="bg-white p-8 rounded-3xl shadow-lg">
              <div className="w-14 h-14 bg-gradient-to-br from-rose-100 to-rose-200 rounded-full mb-6 flex items-center justify-center">
                <span className="text-2xl">🌱</span>
              </div>
              <h3 className="text-xl font-semibold mb-4">Menstrual Phase</h3>
              <p className="text-gray-600 mb-4">Iron-rich foods and anti-inflammatory nutrients for natural pain relief.</p>
              <ul className="space-y-2 text-sm text-gray-600">
                <li className="flex items-center">
                  <span className="w-2 h-2 bg-rose-400 rounded-full mr-2"></span>
                  Iron-rich leafy greens
                </li>
                <li className="flex items-center">
                  <span className="w-2 h-2 bg-rose-400 rounded-full mr-2"></span>
                  Omega-3 fatty acids
                </li>
              </ul>
            </div>
            <div className="bg-white p-8 rounded-3xl shadow-lg">
              <div className="w-14 h-14 bg-gradient-to-br from-pink-100 to-pink-200 rounded-full mb-6 flex items-center justify-center">
                <span className="text-2xl">🌸</span>
              </div>
              <h3 className="text-xl font-semibold mb-4">Follicular Phase</h3>
              <p className="text-gray-600 mb-4">Light, energizing foods to support rising energy levels.</p>
              <ul className="space-y-2 text-sm text-gray-600">
                <li className="flex items-center">
                  <span className="w-2 h-2 bg-pink-400 rounded-full mr-2"></span>
                  Antioxidant-rich fruits
                </li>
                <li className="flex items-center">
                  <span className="w-2 h-2 bg-pink-400 rounded-full mr-2"></span>
                  Fermented foods
                </li>
              </ul>
            </div>
            <div className="bg-white p-8 rounded-3xl shadow-lg">
              <div className="w-14 h-14 bg-gradient-to-br from-purple-100 to-purple-200 rounded-full mb-6 flex items-center justify-center">
                <span className="text-2xl">⚡</span>
              </div>
              <h3 className="text-xl font-semibold mb-4">Ovulatory Phase</h3>
              <p className="text-gray-600 mb-4">Nutrient-dense foods to support hormone production.</p>
              <ul className="space-y-2 text-sm text-gray-600">
                <li className="flex items-center">
                  <span className="w-2 h-2 bg-purple-400 rounded-full mr-2"></span>
                  Zinc-rich foods
                </li>
                <li className="flex items-center">
                  <span className="w-2 h-2 bg-purple-400 rounded-full mr-2"></span>
                  Raw vegetables
                </li>
              </ul>
            </div>
            <div className="bg-white p-8 rounded-3xl shadow-lg">
              <div className="w-14 h-14 bg-gradient-to-br from-violet-100 to-violet-200 rounded-full mb-6 flex items-center justify-center">
                <span className="text-2xl">🌙</span>
              </div>
              <h3 className="text-xl font-semibold mb-4">Luteal Phase</h3>
              <p className="text-gray-600 mb-4">Mood-stabilizing and energy-sustaining nutrients.</p>
              <ul className="space-y-2 text-sm text-gray-600">
                <li className="flex items-center">
                  <span className="w-2 h-2 bg-violet-400 rounded-full mr-2"></span>
                  Magnesium-rich foods
                </li>
                <li className="flex items-center">
                  <span className="w-2 h-2 bg-violet-400 rounded-full mr-2"></span>
                  Complex carbohydrates
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      {/* FAQ Section */}
      <section className="py-20 bg-white">
        <div className="max-w-4xl mx-auto px-4 sm:px-6">
          <h2 className="text-3xl font-bold text-center mb-16">Common Questions</h2>
          <div className="space-y-6">
            <FAQItem
              question="How does nutrition affect menstrual symptoms?"
              answer="Specific nutrients can help reduce inflammation, balance hormones, and ease common menstrual symptoms. Our app provides targeted nutritional recommendations based on your cycle phase and symptoms."
            />
            <FAQItem
              question="What makes your approach scientific?"
              answer="Our recommendations are based on peer-reviewed research in nutritional science and reproductive health. We combine this with real-time tracking to provide personalized, evidence-based nutrition guidance."
            />
            <FAQItem
              question="Can this help with hormone-related issues?"
              answer="Yes! Our nutrition protocols are designed to support hormone balance naturally through diet. While not a replacement for medical care, many users report improved symptoms with our cycle-synced approach."
            />
          </div>
        </div>
      </section>

      {/* Call to Action Section */}
      <div className="bg-gradient-to-br from-rose-50 via-pink-50 to-purple-50 py-20">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 text-center">
          <h2 className="text-3xl font-bold mb-6">Begin Your Journey to Better Cycle Health</h2>
          <p className="text-xl text-gray-700 mb-8 max-w-2xl mx-auto">
            Join our waitlist to be the first to experience scientifically-backed, personalized cycle nutrition.
          </p>
          <form onSubmit={handleWaitlistSubmit} className="max-w-lg mx-auto flex flex-col sm:flex-row gap-4">
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter your email"
              className="flex-1 px-6 py-4 text-lg rounded-full border-2 border-purple-100 focus:border-purple-200 focus:ring-2 focus:ring-purple-100 focus:outline-none transition-all duration-300 shadow-sm hover:shadow-md"
            />
            <button
              type="submit"
              className="px-8 py-4 bg-gradient-to-r from-rose-400 to-purple-400 text-white font-medium rounded-full hover:from-rose-500 hover:to-purple-500 transform hover:scale-105 transition-all duration-300 shadow-md hover:shadow-lg"
            >
              Join Waitlist
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Landing1;

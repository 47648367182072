import React, { useState, useEffect } from 'react';
import { MessageList } from '../../chat/MessageList';
import { ChatInput } from '../../chat/ChatInput';
import * as chatService from '../../../services/chatService';
import { FiMessageSquare, FiArrowLeft, FiCheck } from 'react-icons/fi';
import { auth } from '../../../firebase';
import { useNavigate } from 'react-router-dom';

const NutritionistChat = ({ userData, updateUserData, onNext, onBack }) => {
  const navigate = useNavigate();
  const [messages, setMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [inputMessage, setInputMessage] = useState('');
  const [error, setError] = useState(null);
  const [isTyping, setIsTyping] = useState(false);
  const [completionState, setCompletionState] = useState({ status: 'idle', step: null });

  // Initialize chat with welcome message
  useEffect(() => {
    const welcomeMessage = {
      role: 'assistant',
      content: `👋 Hi ${userData.firstName}! I'm your AI nutritionist. I see that you're interested in **${
        userData.healthFocus === 'gut-microbiome' ? 'improving your gut health' : 
        userData.healthFocus === 'longevity' ? 'optimizing for longevity' : 
        'enhancing your fitness'}** and your main goal is **${userData.goal.replace('-', ' ')}**.

🎯 Before we create your personalized plan, I'd love to learn more about:
• Your food preferences and any dietary restrictions
• Your cooking experience and available equipment
• Any specific health concerns or conditions
• Your typical daily schedule
• Food allergies or intolerances

Feel free to share as much as you'd like!`,
      timestamp: new Date().toISOString()
    };
    setMessages([welcomeMessage]);
    
    // Simulate typing indicator
    setIsTyping(true);
    setTimeout(() => setIsTyping(false), 1000);
  }, [userData.firstName, userData.healthFocus, userData.goal]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!inputMessage.trim() || isLoading) return;

    const userMessage = {
      role: 'user',
      content: inputMessage,
      timestamp: new Date().toISOString()
    };

    try {
      setIsLoading(true);
      setIsTyping(true);
      // Add user message to the UI immediately
      const newMessages = [...messages, userMessage];
      setMessages(newMessages);
      setInputMessage(''); // Clear input right after showing the message

      // Send message and get AI response
      const data = await chatService.sendChatMessage(newMessages, userData);
      
      const assistantMessage = {
        role: 'assistant',
        content: data.response.content?.[0]?.text || data.response.content || "I couldn't process that message.",
        timestamp: new Date().toISOString()
      };

      const updatedMessages = [...newMessages, assistantMessage];
      setMessages(updatedMessages);
      
      // Save chat history in userData
      updateUserData({
        initialConsultation: updatedMessages
      });
      
    } catch (error) {
      console.error('Error sending message:', error);
      setError('Failed to send message');
    } finally {
      setIsLoading(false);
      setIsTyping(false);
    }
  };

  const handleComplete = async () => {
    try {
      setCompletionState({ status: 'processing', step: 'synthesis' });
      
      // Add user ID to messages for synthesis
      const messagesWithUserId = messages.map(msg => ({
        ...msg,
        userId: auth.currentUser.uid
      }));

      // Generate synthesis
      const synthesis = await chatService.synthesizeChat(messagesWithUserId);
      
      setCompletionState({ status: 'processing', step: 'saving' });
      
      // Save synthesis
      if (synthesis && auth.currentUser) {
        await chatService.saveChatSynthesis(auth.currentUser.uid, synthesis);
      }

      // Save chat history
      if (auth.currentUser) {
        await chatService.updateChatHistory(auth.currentUser.uid, messagesWithUserId);
      }

      setCompletionState({ status: 'processing', step: 'finalizing' });
      
      // Complete onboarding
      await onNext();

      // Short delay before redirect
      setTimeout(() => {
        navigate('/mymeals');
      }, 500);
      
    } catch (error) {
      console.error('Error completing chat:', error);
      setError('Failed to complete chat synthesis');
      setCompletionState({ status: 'error', step: null });
    }
  };

  const getCompletionMessage = () => {
    switch (completionState.step) {
      case 'synthesis':
        return 'Analyzing your preferences...';
      case 'saving':
        return 'Updating your profile...';
      case 'finalizing':
        return 'Preparing your meal recommendations...';
      default:
        return 'Complete Chat';
    }
  };

  return (
    <div className="fixed inset-0 flex flex-col bg-gradient-to-b from-primary-50 to-white">
      {/* Header */}
      <div className="flex-shrink-0 bg-white shadow-sm px-4 py-4">
        <div className="flex items-center justify-center">
          <div className="max-w-xl">
            <div className="flex items-center justify-center mb-2">
              <div className="bg-primary-100 p-2 rounded-full">
                <FiMessageSquare className="h-6 w-6 text-primary-600" />
              </div>
            </div>
            <h2 className="text-xl font-semibold text-gray-900">Chat with Your Nutritionist</h2>
            <p className="mt-1 text-sm text-gray-600">
              Let's have a conversation to better understand your needs and create your perfect plan
            </p>
          </div>
        </div>
      </div>

      {/* Chat Area */}
      <div className="flex-1 min-h-0">
        {completionState.status === 'processing' && (
          <div className="absolute inset-0 bg-white bg-opacity-90 z-10 flex items-center justify-center">
            <div className="text-center p-6 rounded-lg">
              <div className="flex flex-col items-center space-y-4">
                <svg className="animate-spin h-8 w-8 text-primary-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                <div className="space-y-2">
                  <h3 className="text-lg font-medium text-gray-900">{getCompletionMessage()}</h3>
                  <p className="text-sm text-gray-500">Please wait while we update your profile</p>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="h-full flex flex-col bg-white">
          <div className="flex-1 overflow-y-auto">
            <div className="px-4">
              <MessageList 
                messages={messages}
                isLoading={isLoading}
                error={error}
                isTyping={isTyping}
              />
            </div>
          </div>

          {/* Navigation Buttons Section */}
          <div className="flex-shrink-0 px-4 py-3 bg-gray-50 border-t border-gray-100">
            <div className="max-w-4xl mx-auto flex justify-between items-center">
              {/* Back Button */}
              <button
                onClick={onBack}
                className="group relative flex items-center px-4 py-2 bg-white hover:bg-gray-50 rounded-lg transition-colors border border-gray-200"
                title="Go back"
                disabled={completionState.status === 'processing'}
              >
                <FiArrowLeft className="h-5 w-5 text-gray-600 mr-2" />
                <span className="text-sm font-medium text-gray-600">Back</span>
              </button>

              {/* Complete Button */}
              <button
                onClick={handleComplete}
                disabled={completionState.status === 'processing'}
                className="group relative flex items-center px-4 py-2 bg-white hover:bg-primary-50 rounded-lg transition-colors border border-primary-200 disabled:opacity-50 disabled:cursor-not-allowed"
                title="Complete consultation"
              >
                {completionState.status === 'processing' ? (
                  <>
                    <svg className="animate-spin h-5 w-5 text-primary-600 mr-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                    <span className="text-sm font-medium text-primary-600">{getCompletionMessage()}</span>
                  </>
                ) : (
                  <>
                    <FiCheck className="h-5 w-5 text-primary-600 mr-2" />
                    <span className="text-sm font-medium text-primary-600">Complete Chat</span>
                  </>
                )}
                
                {/* Tooltip */}
                <div className="absolute bottom-full right-0 mb-2 w-48 px-2 py-1 bg-gray-900 text-white text-xs rounded opacity-0 group-hover:opacity-100 transition-opacity pointer-events-none">
                  Click here when you're done chatting
                </div>
              </button>
            </div>
          </div>

          {/* Chat Input */}
          <div className="flex-shrink-0 border-t border-gray-100 p-4 bg-white">
            <div className="max-w-4xl mx-auto">
              <ChatInput 
                inputMessage={inputMessage}
                setInputMessage={setInputMessage}
                onSubmit={handleSubmit}
                isLoading={isLoading}
                placeholder="Type your message here..."
                disabled={completionState.status === 'processing'}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NutritionistChat; 
import React from 'react';
import { useAuth } from '../contexts/AuthContext';
import HomeStats from '../components/stats/HomeStats';

const Statistics = () => {
  const { user } = useAuth();

  return (
    <div className="container mx-auto px-4 py-8 pt-24">
      <div className="mb-8">
        <h1 className="text-h1 font-thin text-text-dark mb-4">Food Statistics</h1>
        <p className="text-lg text-text-light">
          Track your nutrition patterns and meal habits over time
        </p>
      </div>
      <HomeStats user={user} />
    </div>
  );
};

export default Statistics;

//Centralizes the authentication logic and provides an easy way to share the user's authentication state (user object) throughout the entire application.
import React, { createContext, useState, useContext, useEffect } from 'react';
import { auth } from '../firebase';
import {
  onAuthStateChanged
} from 'firebase/auth';

// Create the context with a default value
const AuthContext = createContext({
  user: null,
  currentUser: null,
  loading: true
});

// Custom hook to use the auth context
export function useAuth() {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}

// Provider component
export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Subscribe to auth state changes
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      console.log('Auth state changed:', user?.uid);
      setCurrentUser(user);
      setLoading(false);
    });

    // Cleanup subscription
    return () => unsubscribe();
  }, []);

  // Create the value object only once when currentUser or loading changes
  const value = React.useMemo(
    () => ({
      user: currentUser, // Add user alias for currentUser
      currentUser,
      loading
    }),
    [currentUser, loading]
  );

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
import React, { useState, useEffect } from 'react';
import { 
  collection, 
  query, 
  where, 
  addDoc, 
  getDocs,
  orderBy,
  doc,
  getDoc,
  setDoc,
  limit
} from 'firebase/firestore';

import { XMarkIcon } from '@heroicons/react/24/solid';
import { IoNutritionOutline, IoRestaurantOutline, IoLeafOutline, IoTrendingUpOutline } from 'react-icons/io5';
import { FaCamera, FaMicrophone } from 'react-icons/fa';
import { db } from '../firebase';
import { useAuth } from '../contexts/AuthContext';
import { usePWA } from '../contexts/PWAContext';
import { useNavigate } from 'react-router-dom';
import { loadMemory, loadTimedPreferences } from '../services/memoryService';
import { loadPreviousSynthesis } from '../services/chatService';
import { getMealPlan } from '../services/mealPlanService';
import FAQItem from '../components/FAQItem';
import HomeMealPopup from '../components/home/HomeMealPopup';
import CameraPopup from '../components/common/CameraPopup';
import AudioRecordingPopup from '../components/common/AudioRecordingPopup';
import NutritionProgress from '../components/home/NutritionProgress';

const Home = () => {
  const { user } = useAuth();
  const { isPWA } = usePWA();
  const navigate = useNavigate();
  const [recipeResponse, setRecipeResponse] = useState(null);
  const [ingredients, setIngredients] = useState([]);
  const [setIsLoading] = useState(false);
  const [setCurrentStep] = useState(null);
  const [nutritionalContext, setNutritionalContext] = useState(null);
  const [userPreferences, setUserPreferences] = useState(null);
  const [dietaryContext, setDietaryContext] = useState([]);
  const [timedPreferences, setTimedPreferences] = useState([]);
  const [pastIngredients, setPastIngredients] = useState([]);
  const [showContextPopup, setShowContextPopup] = useState(false);
  const [additionalContext, setAdditionalContext] = useState('');
  const [chatSynthesis, setChatSynthesis] = useState(null);
  const [todaysMeals, setTodaysMeals] = useState(null);
  const [isLoadingMeals, setIsLoadingMeals] = useState(true);
  const [selectedMeal, setSelectedMeal] = useState(null);
  const [showMealPopup, setShowMealPopup] = useState(false);
  const [showCameraPopup, setShowCameraPopup] = useState(false);
  const [showAudioPopup, setShowAudioPopup] = useState(false);
  const [targetNutrition, setTargetNutrition] = useState({
    calories: 0,
    macros: {
      protein: 0,
      carbs: 0,
      fat: 0
    }
  });

  const calculateCyclePhase = (lastPeriodStart, cycleLength = 28) => {
    if (!lastPeriodStart) return null;
    
    const today = new Date();
    const lastPeriod = new Date(lastPeriodStart);
    const daysSinceLastPeriod = Math.floor((today - lastPeriod) / (1000 * 60 * 60 * 24));
    const currentCycleDay = daysSinceLastPeriod % cycleLength;
    
    // Divide cycle into phases
    if (currentCycleDay <= 5) return 'menstrual';
    if (currentCycleDay <= 13) return 'follicular';
    if (currentCycleDay <= 17) return 'ovulatory';
    return 'luteal';
  };

  const logStep = (step, details = '') => {
    const timestamp = new Date().toLocaleTimeString();
    console.log(`%c[${timestamp}] 🔄 ${step}`, 'color: #2563eb; font-weight: bold');
    if (details) {
      console.log(`%c${details}`, 'color: #4b5563; margin-left: 20px');
    }
  };

  const logSuccess = (step, details = '') => {
    const timestamp = new Date().toLocaleTimeString();
    console.log(`%c[${timestamp}] ✅ ${step}`, 'color: #059669; font-weight: bold');
    if (details) {
      console.log(`%c${details}`, 'color: #4b5563; margin-left: 20px');
    }
  };

  const logError = (step, error) => {
    const timestamp = new Date().toLocaleTimeString();
    console.log(`%c[${timestamp}] ❌ ${step}`, 'color: #dc2626; font-weight: bold');
    console.error(error);
  };

  useEffect(() => {
    const fetchUserPreferences = async () => {
      if (!user) return;
      try {
        // Load user preferences
        const userDoc = await getDoc(doc(db, 'users', user.uid));
        if (userDoc.exists()) {
          const data = userDoc.data();
          const cyclePhase = data.menstrualCycle?.enabled && data.gender === 'female' && data.menstrualCycle.lastPeriodStart
            ? calculateCyclePhase(data.menstrualCycle.lastPeriodStart, data.menstrualCycle.cycleLength)
            : null;
            
          setUserPreferences({
            foodPreferences: data.foodPreferences || 'healthy and balanced',
            allergies: data.allergies || [],
            gender: data.gender,
            menstrualCycle: data.menstrualCycle,
            currentCyclePhase: cyclePhase,
            // generateRecipeImages: data.generateRecipeImages ?? false, // temporarily disabled
            showMealConfirmation: data.showMealConfirmation ?? true,
            healthFocus: data.healthFocus || '',
          });

          // Get target calories (either desired or TDEE)
          const targetCals = data.desiredCalories || data.calculatedCalories?.tdee || 2000;
          
          // Calculate target macros based on standard distribution
          // Protein: 25%, Carbs: 45%, Fat: 30%
          const targetMacros = {
            protein: Math.round((targetCals * 0.25) / 4), // 4 calories per gram of protein
            carbs: Math.round((targetCals * 0.45) / 4),   // 4 calories per gram of carbs
            fat: Math.round((targetCals * 0.30) / 9)      // 9 calories per gram of fat
          };

          setTargetNutrition({
            calories: targetCals,
            macros: targetMacros
          });
        }

        // Load dietary context
        const memory = await loadMemory(user.uid);
        const dietaryTags = memory.filter(tag => tag.category === 'dietary');
        setDietaryContext(dietaryTags);

        // Load temporary preferences
        const activePrefs = await loadTimedPreferences(user.uid);
        setTimedPreferences(activePrefs);

        // Load past meals from the last 7 days
        const sevenDaysAgo = new Date();
        sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);
        
        const mealsRef = collection(db, 'meals');
        const mealsQuery = query(
          mealsRef,
          where('userId', '==', user.uid),
          orderBy('createdAt', 'desc'),
          limit(20)  // Get last 20 meals
        );
        
        const mealsSnapshot = await getDocs(mealsQuery);
        const ingredients = [];
        const ingredientCounts = {};
        
        mealsSnapshot.forEach(doc => {
          const meal = doc.data();
          const mealDate = meal.createdAt?.toDate?.() || new Date(meal.createdAt);
          
          // Only include meals from last 7 days
          if (mealDate >= sevenDaysAgo) {
            meal.ingredients?.forEach(ing => {
              const key = `${ing.name}|${ing.category || 'unknown'}`;
              ingredientCounts[key] = (ingredientCounts[key] || 0) + 1;
            });
          }
        });
        
        // Convert to array format
        Object.entries(ingredientCounts).forEach(([key, count]) => {
          const [name, category] = key.split('|');
          ingredients.push({ name, category, count });
        });
        
        setPastIngredients(ingredients);

        // Load chat synthesis
        const synthesis = await loadPreviousSynthesis(user.uid);
        setChatSynthesis(synthesis);
      } catch (error) {
        console.error("Error fetching user preferences:", error);
      }
    };

    fetchUserPreferences();
  }, [user]);

  useEffect(() => {
    const loadSavedMeal = async () => {
      if (!user) return;
      try {
        // Get the most recent saved meal for this user
        const mealsRef = collection(db, 'savedMeals');
        const q = query(
          mealsRef,
          where('userId', '==', user.uid),
          orderBy('createdAt', 'desc'),
          limit(1)
        );
        
        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
          const savedMeal = querySnapshot.docs[0].data();
          setRecipeResponse(savedMeal.recipe);
          setIngredients(savedMeal.ingredients || []);
          setNutritionalContext(savedMeal.nutritionalContext);
        }
      } catch (error) {
        console.error("Error loading saved meal:", error);
      }
    };

    loadSavedMeal();
  }, [user]);

  useEffect(() => {
    const saveMeal = async () => {
      if (!user || !recipeResponse) return;
      
      try {
        // Query for existing saved meal
        const mealsRef = collection(db, 'savedMeals');
        const q = query(mealsRef, where('userId', '==', user.uid));
        const querySnapshot = await getDocs(q);
        
        const mealData = {
          recipe: recipeResponse,
          ingredients,
          nutritionalContext,
          createdAt: new Date(),
          userId: user.uid
        };

        // If there's an existing meal, update it; otherwise create new
        if (!querySnapshot.empty) {
          const docRef = doc(db, 'savedMeals', querySnapshot.docs[0].id);
          await setDoc(docRef, mealData);
        } else {
          await addDoc(mealsRef, mealData);
        }
      } catch (error) {
        console.error("Error saving meal:", error);
      }
    };

    saveMeal();
  }, [recipeResponse, ingredients, nutritionalContext, user]);

  const extractHealthConcerns = (input) => {
    const healthKeywords = [
      'sick', 'cold', 'flu', 'fever', 'headache', 'migraine',
      'tired', 'fatigue', 'exhausted', 'stress', 'stressed',
      'anxiety', 'anxious', 'depressed', 'depression',
      'stomach', 'digestion', 'nausea', 'pain'
    ];
    
    return healthKeywords.find(keyword => input.includes(keyword)) || null;
  };

  const handleRecipeGeneration = async (type = 'quick', context = '') => {
    setIsLoading(true);
    setCurrentStep(null);
    try {
      logStep('Starting recipe generation');
      logStep('Fetching past meals data');
      
      // Extract any health concerns from the user input
      const healthConcerns = extractHealthConcerns(context.toLowerCase());
      
      // Step 1: Get ingredient recommendations from GPT acting as a nutritionist/doctor
      setCurrentStep('analyzing');
      logStep('Step 1: Getting nutritional recommendations');
      
      const nutritionistPrompt = `You are a professional nutritionist and healthcare expert. Analyze the user's profile and provide nutritional recommendations.
              
              USER PROFILE:
              - Dietary preferences: ${userPreferences?.foodPreferences || 'healthy and balanced'}
              ${context ? `- Current context/needs: "${context}"` : ''}
              ${healthConcerns ? `- Health concerns: "${healthConcerns}"` : ''}
              - Dietary context: ${dietaryContext?.length > 0 ? dietaryContext.map(tag => tag.content).join(', ') : 'None specified'}
              - Temporary preferences: ${timedPreferences?.length > 0 ? timedPreferences.map(pref => pref.content).join(', ') : 'None specified'}
              ${userPreferences?.menstrualCycle?.enabled && userPreferences.gender === 'female' 
                ? `- Menstrual cycle phase: ${userPreferences.currentCyclePhase}` : ''}
              - Recent meals (7 days): ${pastIngredients.length > 0 
                ? pastIngredients.map(ing => `${ing.name} (${ing.category}) - ${ing.count} times`).join(', ') 
                : 'No past meals recorded'}
              ${chatSynthesis?.synthesis ? `- Previous chat insights: ${chatSynthesis.synthesis}` : ''}
              
              Provide recommendations in this JSON format:
              {
                "fixed_requirements": ["List of non-negotiable requirements, e.g., 'gluten-free', 'vegan', 'keto'"],
                "recommendations": ["List of flexible recommendations, e.g., 'focus on iron-rich foods'"],
                "recommended_ingredients": ["List of specific ingredients that meet the requirements"]
              }`;

      console.log('🔍 Nutritionist Prompt:', nutritionistPrompt);
      logStep('Nutritionist Prompt:', nutritionistPrompt);
      
      const recommendationsResponse = await fetch('https://generateairesponse-boc5emstrq-uc.a.run.app/chat', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          messages: [
            {
              role: 'system',
              content: nutritionistPrompt
            }
          ]
        })
      });

      const recommendationsData = await recommendationsResponse.json();
      console.log('🔍 Nutritionist Response:', recommendationsData.response);
      logStep('Nutritionist Response:', recommendationsData.response);
      
      let nutritionRecommendations;
      try {
        nutritionRecommendations = typeof recommendationsData.response === 'string' ? 
          JSON.parse(recommendationsData.response.replace(/```json\n|\n```/g, '')) 
          : recommendationsData.response;
        logSuccess('Nutritional recommendations received');
      } catch (error) {
        logError('Failed to parse nutritional recommendations', error);
        nutritionRecommendations = {
          fixed_requirements: [],
          recommendations: ['focus on balanced nutrition'],
          recommended_ingredients: ['vegetables', 'lean protein', 'whole grains']
        };
      }

      // Step 2: Generate recipe based on recommendations
      setCurrentStep('composing');
      logStep('Step 2: Generating recipe with recommendations');
      
      const recipePrompt = type === 'quick'
        ? 'Generate a basic, easy to cook recipe with 3-5 fresh ingredients that:'
        : 'Generate an impressive, restaurant-style recipe with more than 5 fresh ingredients that:';

      let recipeData;
      let attempts = 0;
      const maxAttempts = 3;

      do {
        if (attempts > 0) {
          logStep(`Attempt ${attempts + 1}: Regenerating recipe to better match requirements`);
        }

        const recipePromptFull = `You are a professional chef specializing in nutritious recipe design. ${recipePrompt}
                1. MUST follow these fixed requirements: ${nutritionRecommendations.fixed_requirements.join(', ')}
                2. Should incorporate these recommendations: ${nutritionRecommendations.recommendations.join(', ')}
                3. Use these recommended ingredients where appropriate: ${nutritionRecommendations.recommended_ingredients.join(', ')}
                4. Consider that it's ${new Date().toLocaleString('en-US', { month: 'long' })} (use seasonal ingredients)
                ${context ? `5. Address this specific context: "${context}"` : ''}
                ${userPreferences?.allergies?.length ? `6. Avoid these allergens: ${userPreferences.allergies.join(', ')}` : ''}
                ${recipeResponse?.title ? `7. IMPORTANT: Generate a different recipe than "${recipeResponse.title}"` : ''}
                
                Format response as JSON:
                {
                  "title": "Recipe Name",
                  "description": "Brief 1-2 sentence description explaining how this recipe addresses the user's needs",
                  "tags": ["key features only"],
                  "prepTime": "X mins",
                  "calories": "X kcal per serving",
                  "servings": 2,
                  "ingredients": ["formatted ingredients with amounts"],
                  "instructions": ["clear steps with temperatures in °C"]
                }`;

        console.log('👩‍🍳 Recipe Generation Prompt:', recipePromptFull);
        logStep('Recipe Generation Prompt:', recipePromptFull);

        const response = await fetch('https://generateairesponse-boc5emstrq-uc.a.run.app/chat', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            messages: [
              {
                role: 'system',
                content: recipePromptFull
              }
            ]
          })
        });

        const data = await response.json();
        try {
          recipeData = typeof data.response === 'string' ? 
            JSON.parse(data.response.replace(/```json\n|\n```/g, '')) 
            : data.response;
        } catch (error) {
          logError('Failed to parse recipe JSON', error);
          recipeData = data.response;
          break;
        }

        // Step 2.5: Validate recipe (if not first attempt)
        if (attempts < maxAttempts - 1) {
          logStep('Validating recipe against requirements');
          
          const validationResponse = await fetch('https://generateairesponse-boc5emstrq-uc.a.run.app/chat', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              messages: [
                {
                  role: 'system',
                  content: `You are a nutrition expert validating recipes. Given these requirements:
                  Fixed Requirements: ${nutritionRecommendations.fixed_requirements.join(', ')}
                  Recommendations: ${nutritionRecommendations.recommendations.join(', ')}
                  
                  Validate this recipe and respond with JSON:
                  {
                    "is_valid": boolean,
                    "reason": "If not valid, explain why. If valid, explain why it's good"
                  }`
                },
                {
                  role: 'user',
                  content: JSON.stringify(recipeData)
                }
              ]
            })
          });

          const validationData = await validationResponse.json();
          try {
            const validation = typeof validationData.response === 'string'
              ? JSON.parse(validationData.response.replace(/```json\n|\n```/g, ''))
              : validationData.response;

            if (validation.is_valid) {
              logSuccess('Recipe validation passed: ' + validation.reason);
              break;
            } else {
              logStep('Recipe needs improvement: ' + validation.reason);
            }
          } catch (error) {
            logError('Failed to parse validation response', error);
            break;
          }
        }

        attempts++;
      } while (attempts < maxAttempts);

      setRecipeResponse(recipeData);
      logSuccess('Recipe generated successfully');

      // Recipe image generation temporarily disabled
      /*if (userPreferences?.generateRecipeImages) {
        logStep('Starting image generation', 'Creating a beautiful photo of your recipe 📸');
        try {
          const basePrompt = `${recipeData.title} with these ingredients: ${recipeData.ingredients.join(', ')}`;
          
          // Enhanced prompt with style guidelines
          const enhancedPrompt = `Create a minimalist food photograph of ${basePrompt}. STRICT requirements:
          - ONLY the main dish on a single white plate
          - Absolutely NO ingredients or items around the plate
          - NO spices, NO garnishes, NO decorative elements
          - Pure white background, NO shadows, NO textures
          - Small portion size, plate should be mostly empty
          - Centered composition with plenty of white space
          - NO props, NO utensils, NO additional bowls
          - Clean and simple presentation
          - Shot directly from above
          - High-end restaurant plating style
          - Colors should be natural and appetizing
          
          The image should feel like a piece of minimal art, focusing solely on the dish itself with maximum white space around it.`;
          
          logStep('Crafting image prompt', `Using recipe title: "${recipeData.title}" and ${recipeData.ingredients.length} ingredients`);
          
          const imageResponse = await fetch('https://generateairesponse-boc5emstrq-uc.a.run.app/generate-image', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              prompt: enhancedPrompt
            })
          });

          logStep('Processing image response', 'Almost there! Preparing your recipe photo...');

          const imageData = await imageResponse.json();
          if (imageData.response) {
            setRecipeResponse(prev => ({
              ...prev,
              imageUrl: imageData.response
            }));
            logSuccess('Recipe photo ready! 🎉', 'Your recipe now has a beautiful visual representation');
          } else {
            logError('Image generation incomplete', 'Response received but no image URL found');
          }
        } catch (error) {
          logError('Image generation failed', `Unable to create recipe photo: ${error.message}`);
          console.error('Image generation error details:', error);
        }
      } else {
        logStep('Image generation skipped', 'Image generation is disabled in user preferences');
      }*/

      // Step 3: Generate nutritional context and explanation
      setCurrentStep('nutrition');
      logStep('Step 3: Generating nutritional context');
      
      const contextResponse = await fetch('https://generateairesponse-boc5emstrq-uc.a.run.app/chat', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          messages: [
            {
              role: 'system',
              content: `You are a nutrition and wellness expert. Analyze this recipe and explain its benefits.
              
              USER PROFILE:
              - Preferences: ${userPreferences?.foodPreferences || 'healthy and balanced'}
              ${context ? `- Context: "${context}"` : ''}
              ${healthConcerns ? `- Health concerns: "${healthConcerns}"` : ''}
              ${userPreferences?.menstrualCycle?.enabled && userPreferences.gender === 'female' 
                ? `- Menstrual cycle phase: ${userPreferences.currentCyclePhase}` : ''}
              ${chatSynthesis?.synthesis ? `- Previous chat insights: ${chatSynthesis.synthesis}` : ''}
              
              Respond with JSON:
              {
                "nutritionalBenefits": ["List key nutritional benefits"],
                "personalizedContext": "Explain why this recipe is good for the user",
                ${healthConcerns ? `"holisticAdvice": "Provide comprehensive wellness advice",` : ''}
                "macros": {
                  "protein": "X g",
                  "carbs": "X g",
                  "fats": "X g",
                  "calories": "X kcal"
                }
              }`
            },
            {
              role: 'user',
              content: JSON.stringify(recipeData)
            }
          ]
        })
      });

      const contextData = await contextResponse.json();
      let parsedContext;
      try {
        if (typeof contextData.response === 'string') {
          // Try to extract JSON from markdown code blocks
          const jsonMatch = contextData.response.match(/```json\n([\s\S]*?)\n```/);
          if (jsonMatch) {
            parsedContext = JSON.parse(jsonMatch[1]);
          } else {
            // Try parsing the raw string
            parsedContext = JSON.parse(contextData.response.trim());
          }
        } else {
          parsedContext = contextData.response;
        }
        setNutritionalContext(parsedContext);
        logSuccess('Nutritional context generated');
      } catch (error) {
        logError('Failed to parse nutritional context', error);
        // Create a properly structured object from the text response
        parsedContext = {
          nutritionalBenefits: [contextData.response],
          personalizedContext: contextData.response,
          macros: {
            protein: "N/A",
            carbs: "N/A",
            fats: "N/A",
            calories: "N/A"
          }
        };
        setNutritionalContext(parsedContext);
      }

      setIsLoading(false);
      setCurrentStep(null);
      logSuccess('Recipe generation completed');
    } catch (error) {
      logError('Recipe generation failed', error);
      setIsLoading(false);
      setCurrentStep(null);
    }
  };

  // New function to categorize ingredients
  const categorizeIngredients = (ingredients) => {
    const produce = ingredients.filter(ing => 
      ing.category?.toLowerCase().includes('vegetable') || 
      ing.category?.toLowerCase().includes('fruit')
    );
    const other = ingredients.filter(ing => 
      !ing.category?.toLowerCase().includes('vegetable') && 
      !ing.category?.toLowerCase().includes('fruit')
    );
    return { produce, other };
  };

  // Fetch today's meals
  useEffect(() => {
    const fetchTodaysMeals = async () => {
      if (!user) return;
      
      try {
        setIsLoadingMeals(true);
        const mealPlan = await getMealPlan(user.uid);
        
        if (mealPlan && mealPlan.days) {
          // Get today's date in local timezone and convert to YYYY-MM-DD format
          const today = new Date();
          today.setHours(0, 0, 0, 0);
          const todayString = today.toISOString().split('T')[0];
          
          const todaysPlan = mealPlan.days.find(day => day.date === todayString);
          
          if (todaysPlan) {
            // Convert meals array to object grouped by meal type
            const mealsObject = {};
            todaysPlan.meals.sort((a, b) => {
              const timeA = parseInt(a.time.split(':')[0]);
              const timeB = parseInt(b.time.split(':')[0]);
              return timeA - timeB;
            }).forEach(meal => {
              // Extract time to determine meal type using the same logic as calendar
              const hour = parseInt(meal.time.split(':')[0]);
              let mealType;
              
              if (hour >= 7 && hour <= 9) mealType = 'breakfast';
              else if (hour >= 12 && hour <= 14) mealType = 'lunch';
              else if (hour >= 15 && hour <= 16) mealType = 'snack';
              else if (hour >= 18 && hour <= 20) mealType = 'dinner';
              else {
                // Default fallback based on rough time ranges
                if (hour >= 5 && hour < 11) mealType = 'breakfast';
                else if (hour >= 11 && hour < 15) mealType = 'lunch';
                else if (hour >= 15 && hour < 17) mealType = 'snack';
                else mealType = 'dinner';
              }
              
              // Store all meals of the same type in an array
              if (!mealsObject[mealType]) {
                mealsObject[mealType] = [];
              }
              
              mealsObject[mealType].push({
                ...meal,
                mealType,
                description: meal.name || meal.description,
                tags: meal.tags || [],
                imageUrl: meal.imageUrl,
                enhancedRecipe: meal.enhancedRecipe || false
              });
            });
            
            // For each meal type, use the first meal in the array
            const finalMeals = {};
            Object.entries(mealsObject).forEach(([type, meals]) => {
              if (meals.length > 0) {
                finalMeals[type] = meals[0];
              }
            });
            
            setTodaysMeals(finalMeals);
          } else {
            setTodaysMeals(null);
          }
        }
      } catch (error) {
        console.error('Error fetching today\'s meals:', error);
        setTodaysMeals(null);
      } finally {
        setIsLoadingMeals(false);
      }
    };

    fetchTodaysMeals();
  }, [user]);

  // Helper function to get meal icon
  const getMealIcon = (mealType) => {
    switch (mealType) {
      case 'breakfast':
        return '🍳';
      case 'lunch':
        return '🍜';
      case 'snack':
        return '🥪';
      case 'dinner':
        return '🍽️';
      default:
        return '🍴';
    }
  };

  // Add dynamic titles based on health focus
  const getHealthFocusContent = () => {
   
    switch (userPreferences?.healthFocus) {
      case 'gut-microbiome':
        return {
          title: "Transform Your Gut Health in 8 Weeks",
          subtitle: "Optimize Your Microbiome"
        };
      case 'longevity':
        return {
          title: "Add 10 Years to Your Lifespan",
          subtitle: "Activate Longevity Pathways"
        };
      case 'fitness':
        return {
          title: "Fuel Your Body Like a Pro Athlete",
          subtitle: "Maximize Performance"
        };
      default:
        return {
          title: "Optimize Your Nutrition for Longevity",
          subtitle: "Boost Gut Health + Extend Lifespan"
        };
    }
  };

  // Add this function after getHealthFocusContent
  const getPersonalizedImages = () => {
    const gender = userPreferences?.gender || 'neutral';
    const healthFocus = userPreferences?.healthFocus || 'default';
    
    // Hero section images
    const heroImages = {
      'gut-microbiome': {
        male: '/images/landing/hero-gut-male.jpg',
        female: '/images/landing/hero-gut-female.jpg',
        neutral: '/images/landing/hero-gut-neutral.jpg'
      },
      'longevity': {
        male: '/images/landing/hero-longevity-male.jpg',
        female: '/images/landing/hero-longevity-female.jpg',
        neutral: '/images/landing/hero-longevity-neutral.jpg'
      },
      'fitness': {
        male: '/images/landing/hero-fitness-male.jpg',
        female: '/images/landing/hero-fitness-female.jpg',
        neutral: '/images/landing/hero-fitness-neutral.jpg'
      },
      'default': {
        male: '/images/landing/hero-default-male.jpg',
        female: '/images/landing/hero-default-female.jpg',
        neutral: '/images/landing/hero-default-neutral.jpg'
      }
    };

    // Features section images
    const featureImages = {
      'gut-microbiome': '/images/landing/feature-gut-health.jpg',
      'longevity': '/images/landing/feature-longevity.jpg',
      'fitness': '/images/landing/feature-fitness.jpg',
      'default': '/images/landing/feature-default.jpg'
    };

    return {
      hero: heroImages[healthFocus]?.[gender] || heroImages.default.neutral,
      feature: featureImages[healthFocus] || featureImages.default
    };
  };

  const handleMealClick = (meal) => {
    setSelectedMeal(meal);
    setShowMealPopup(true);
  };

  return (
    <div className={`flex flex-col w-full max-w-4xl mx-auto px-4 ${!isPWA ? 'pt-20 md:pt-24' : 'pt-6'} space-y-8`}>
      {/* Nutrition Progress - Moved to top */}
      <div>
        <NutritionProgress
          targetCalories={targetNutrition.calories}
          targetMacros={targetNutrition.macros}
        />
      </div>

      {/* Track Meal CTA - Moved below nutrition */}
      <div className="space-y-4">
        <div className="relative overflow-hidden bg-gradient-to-r from-blue-500 to-purple-600 rounded-2xl shadow-xl">
          <div className="absolute inset-0 bg-black opacity-10"></div>
          <div 
            onClick={() => setShowCameraPopup(true)}
            className="relative flex items-center justify-between p-6 cursor-pointer hover:bg-black/5 transition-colors"
          >
            <div className="flex-1">
              <h2 className="text-2xl font-bold text-white mb-2">
                Track Your Meal Now
              </h2>
              <p className="text-white/90">
                Take a quick photo of your meal to track your nutrition
              </p>
            </div>
            <div className="flex items-center justify-center w-16 h-16 bg-white/20 rounded-full">
              <FaCamera className="w-8 h-8 text-white" />
            </div>
          </div>
        </div>

        {/* Audio Recording Link */}
        <button
          onClick={() => setShowAudioPopup(true)}
          className="w-full flex items-center justify-center gap-2 text-primary hover:text-primary/80 transition-colors py-2"
        >
          <FaMicrophone className="w-4 h-4" />
          <span>Or tell us what you ate</span>
        </button>
      </div>

      {/* Hero Section */}
      <div className="relative bg-gradient-to-b from-primary/5 to-transparent pt-12 sm:pt-20">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex flex-col lg:flex-row items-center gap-6 sm:gap-12 pb-8 sm:pb-16">
            <div className="lg:w-2/5 text-center lg:text-left">
              <h1 className="text-3xl sm:text-4xl lg:text-5xl font-bold text-text-dark mb-4 sm:mb-6 lg:max-w-md">
                {getHealthFocusContent().title}
              </h1>
              <p className="text-base sm:text-lg text-text-light mb-6 sm:mb-8 lg:max-w-md">
                {getHealthFocusContent().subtitle}
              </p>
              <button 
                onClick={() => navigate('/meals')}
                className="inline-flex items-center group text-primary hover:text-primary/80 transition-colors text-lg sm:text-xl font-medium"
              >
                <span>Generate your mealplan</span>
                <span className="ml-2 transform group-hover:translate-x-1 transition-transform">→</span>
              </button>
            </div>
            <div className="lg:w-3/5">
              <div className="relative w-[280px] sm:w-[400px] lg:w-full aspect-[16/9] rounded-2xl overflow-hidden shadow-2xl mx-auto transform lg:translate-x-4">
                <img
                  src={getPersonalizedImages().hero}
                  alt="Healthy and colorful meal"
                  className="object-cover w-full h-full"
                />
                <div className="absolute inset-0 bg-gradient-to-br from-primary/10 to-transparent"></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Today's Meals Section */}
      <div className="bg-white py-12 sm:py-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-8 sm:mb-12">
            <h2 className="text-2xl sm:text-3xl font-bold text-gray-900 mb-3">
              Today's Meals
            </h2>
            <p className="text-base sm:text-lg text-gray-500">
              Your personalized nutrition plan
            </p>
          </div>

          {isLoadingMeals ? (
            <div className="flex justify-center items-center py-12">
              <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-primary"></div>
            </div>
          ) : todaysMeals ? (
            <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
              {['breakfast', 'lunch', 'dinner'].map((mealType) => {
                const meal = todaysMeals[mealType];
                const icon = getMealIcon(mealType);
                
                return (
                  <div 
                    key={mealType}
                    className={`relative rounded-lg overflow-hidden group transition-all duration-300 border ${
                      meal?.description 
                        ? 'bg-white border-gray-100 hover:border-primary/30' 
                        : 'bg-gray-50 border-dashed border-gray-200 hover:border-primary/30'
                    }`}
                    onClick={() => meal && handleMealClick(meal)}
                  >
                    <div className="flex h-24">
                      {meal?.imageUrl && (
                        <div className="w-24 h-full">
                          <img 
                            src={meal.imageUrl} 
                            alt={meal.description} 
                            className="w-full h-full object-cover"
                          />
                        </div>
                      )}
                      <div className="flex-1 p-3">
                        {/* Header with meal type and icon */}
                        <div className="flex items-center gap-2 mb-1.5">
                          <span className="text-xs" role="img" aria-label={mealType}>
                            {icon}
                          </span>
                          <h3 className="text-sm sm:text-base font-semibold capitalize">
                            {mealType}
                          </h3>
                        </div>
                        
                        {/* Description and metadata */}
                        {meal?.description ? (
                          <>
                            <p className="text-xs text-gray-600 line-clamp-1 mb-1.5">
                              {meal.description}
                            </p>
                            <div className="flex items-center justify-between">
                              {/* Ingredient count */}
                              <span className="text-[10px] text-gray-500">
                                {meal.ingredients?.length || '0'} ingredients
                              </span>
                              {/* Tags */}
                              {meal?.tags && meal.tags.length > 0 && (
                                <div className="flex gap-1">
                                  {meal.tags.map((tag) => (
                                    <span 
                                      key={tag}
                                      className="px-1.5 py-0.5 bg-primary/5 rounded-full text-[10px] font-medium text-primary"
                                    >
                                      {tag.toUpperCase()}
                                    </span>
                                  ))}
                                </div>
                              )}
                            </div>
                          </>
                        ) : (
                          <button 
                            className="text-xs text-primary hover:text-primary/80"
                            onClick={(e) => {
                              e.stopPropagation();
                              navigate('/meals');
                            }}
                          >
                            Plan {mealType}
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="text-center py-8">
              <p className="text-gray-500 mb-4">No meals planned for today</p>
              <button
                onClick={() => navigate('/meals')}
                className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-primary hover:bg-primary/90 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
              >
                Plan Your Meals
              </button>
            </div>
          )}
        </div>
      </div>

      {/* How It Works Section */}
      <div className="max-w-7xl mx-auto py-8 sm:py-16 px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <h2 className="text-2xl sm:text-3xl font-bold text-gray-900">
            How It Works
          </h2>
          <p className="mt-2 sm:mt-4 text-base sm:text-xl text-gray-500">
            Your journey to personalized nutrition made simple
          </p>
        </div>

        <div className="mt-8 sm:mt-12 grid gap-4 sm:gap-8 md:grid-cols-3">
          {/* Step 1 */}
          <div 
            className="relative bg-white p-4 sm:p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300"
            onClick={() => navigate('/chat')}
            style={{ cursor: 'pointer' }}
          >
            <div className="flex items-center gap-3">
              <span className="text-2xl" role="img" aria-label="chat">💬</span>
              <h3 className="text-lg font-semibold text-gray-900">Chat & Learn</h3>
            </div>
            <p className="text-sm text-gray-600 mt-2">
              Share your goals and get personalized nutrition advice instantly.
            </p>
          </div>

          {/* Step 2 */}
          <div className="relative bg-white p-4 sm:p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300">
            <div className="flex items-center gap-3">
              <span className="text-2xl" role="img" aria-label="recipe">🥗</span>
              <h3 className="text-lg font-semibold text-gray-900">Get Recipes</h3>
            </div>
            <p className="text-sm text-gray-600 mt-2">
              Receive AI-crafted recipes tailored to your preferences.
            </p>
          </div>

          {/* Step 3 */}
          <div className="relative bg-white p-4 sm:p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300">
            <div className="flex items-center gap-3">
              <span className="text-2xl" role="img" aria-label="track">📈</span>
              <h3 className="text-lg font-semibold text-gray-900">Track & Improve</h3>
            </div>
            <p className="text-sm text-gray-600 mt-2">
              Monitor progress and optimize your nutrition journey.
            </p>
          </div>
        </div>
      </div>

      {/* Stats Grid */}
      <div className="bg-white py-16">
        <div className="max-w-7xl mx-auto px-6 sm:px-8 lg:px-12">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            {pastIngredients.length > 0 ? (
              <>
                {/* Produce Stats */}
                <div className="group">
                  <div className="bg-primary/5 rounded-2xl p-6">
                    <div className="flex items-center gap-4 mb-4">
                      <div className="w-16 h-16 shrink-0 bg-primary/10 rounded-full flex items-center justify-center backdrop-blur-sm">
                        <IoLeafOutline className="w-8 h-8 text-primary" />
                      </div>
                      <h3 className="text-lg sm:text-xl font-semibold text-text-dark">Produce</h3>
                    </div>
                    <div className="space-y-2">
                      {categorizeIngredients(pastIngredients).produce.slice(0, 3).map(ing => (
                        <div key={ing.name} className="flex justify-between items-center">
                          <span className="text-text-light">{ing.name}</span>
                          <span className="text-primary font-medium">{ing.count}×</span>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>

                {/* Other Ingredients Stats */}
                <div className="group">
                  <div className="bg-primary/5 rounded-2xl p-6">
                    <div className="flex items-center gap-4 mb-4">
                      <div className="w-16 h-16 shrink-0 bg-primary/10 rounded-full flex items-center justify-center backdrop-blur-sm">
                        <IoRestaurantOutline className="w-8 h-8 text-primary" />
                      </div>
                      <h3 className="text-lg sm:text-xl font-semibold text-text-dark">Other Ingredients</h3>
                    </div>
                    <div className="space-y-2">
                      {categorizeIngredients(pastIngredients).other.slice(0, 3).map(ing => (
                        <div key={ing.name} className="flex justify-between items-center">
                          <span className="text-text-light">{ing.name}</span>
                          <span className="text-primary font-medium">{ing.count}×</span>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>

                {/* Nutrition Stats */}
                <div className="group">
                  <div className="bg-primary/5 rounded-2xl p-6">
                    <div className="flex items-center gap-4 mb-4">
                      <div className="w-16 h-16 shrink-0 bg-primary/10 rounded-full flex items-center justify-center backdrop-blur-sm">
                        <IoNutritionOutline className="w-8 h-8 text-primary" />
                      </div>
                      <h3 className="text-lg sm:text-xl font-semibold text-text-dark">Nutrition</h3>
                    </div>
                    <div className="space-y-2">
                      <div className="flex justify-between items-center">
                        <span className="text-text-light">Produce Ratio</span>
                        <span className="text-primary font-medium">
                          {Math.round((categorizeIngredients(pastIngredients).produce.length / pastIngredients.length) * 100)}%
                        </span>
                      </div>
                      <div className="flex justify-between items-center">
                        <span className="text-text-light">Unique Foods</span>
                        <span className="text-primary font-medium">{pastIngredients.length}</span>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Trends */}
                <div className="group">
                  <div className="bg-primary/5 rounded-2xl p-6">
                    <div className="flex items-center gap-4 mb-4">
                      <div className="w-16 h-16 shrink-0 bg-primary/10 rounded-full flex items-center justify-center backdrop-blur-sm">
                        <IoTrendingUpOutline className="w-8 h-8 text-primary" />
                      </div>
                      <h3 className="text-lg sm:text-xl font-semibold text-text-dark">Trends</h3>
                    </div>
                    <div className="space-y-2">
                      <div className="flex justify-between items-center">
                        <span className="text-text-light">Most Common</span>
                        <span className="text-primary font-medium">
                          {pastIngredients[0]?.name || 'N/A'}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <div className="col-span-full text-center py-8">
                <div className="max-w-md mx-auto">
                  <div className="w-16 h-16 mx-auto mb-4 bg-primary/10 rounded-full flex items-center justify-center backdrop-blur-sm">
                    <IoRestaurantOutline className="w-8 h-8 text-primary" />
                  </div>
                  <h3 className="text-xl font-semibold text-text-dark mb-2">Start Your Journey</h3>
                  <p className="text-text-light">Log your first meal to see personalized nutrition insights and track your progress!</p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Features Section */}
      <div className="bg-primary/5 py-12 sm:py-24">
        <div className="max-w-7xl mx-auto px-4 sm:px-8 lg:px-12">
          <h2 className="text-2xl sm:text-4xl font-bold text-center mb-8 sm:mb-16">How Foodwise Empowers You</h2>
          <div className="grid lg:grid-cols-2 gap-8 sm:gap-16 items-center">
            <div className="space-y-6 sm:space-y-12">
              <div className="flex gap-4">
                <div className="w-12 h-12 sm:w-16 sm:h-16 shrink-0 bg-primary/10 rounded-full flex items-center justify-center">
                  <span className="text-xl sm:text-2xl" role="img" aria-label="gut health">🦠</span>
                </div>
                <div>
                  <h3 className="text-lg font-semibold mb-1 sm:mb-2">Gut Microbiome Support</h3>
                  <p className="text-sm text-text-light">
                    Optimize your gut health with targeted nutrition.
                  </p>
                </div>
              </div>
              <div className="flex gap-4">
                <div className="w-12 h-12 sm:w-16 sm:h-16 shrink-0 bg-primary/10 rounded-full flex items-center justify-center">
                  <span className="text-xl sm:text-2xl" role="img" aria-label="longevity">⚡</span>
                </div>
                <div>
                  <h3 className="text-lg font-semibold mb-1 sm:mb-2">Longevity-Focused</h3>
                  <p className="text-sm text-text-light">
                    Boost cellular health with antioxidant-rich meals.
                  </p>
                </div>
              </div>
              <div className="flex gap-4">
                <div className="w-12 h-12 sm:w-16 sm:h-16 shrink-0 bg-primary/10 rounded-full flex items-center justify-center">
                  <span className="text-xl sm:text-2xl" role="img" aria-label="personalized">🎯</span>
                </div>
                <div>
                  <h3 className="text-lg font-semibold mb-1 sm:mb-2">Personalized Journey</h3>
                  <p className="text-sm text-text-light">
                    AI-powered recommendations just for you.
                  </p>
                </div>
              </div>
            </div>
            <div className="hidden lg:block">
              <div className="aspect-w-4 aspect-h-3 rounded-2xl overflow-hidden shadow-2xl">
                <img
                  src={getPersonalizedImages().feature}
                  alt="AI-powered nutrition analysis"
                  className="object-cover w-full h-full"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* FAQ Section */}
      <div className="bg-white py-16 sm:py-24">
        <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-8 sm:mb-12">
            <h2 className="text-2xl sm:text-4xl font-bold mb-3 sm:mb-4">Frequently Asked Questions</h2>
            <p className="text-sm sm:text-base text-text-light max-w-2xl mx-auto">
              Quick answers to common questions about Foodwise
            </p>
          </div>
          <div className="space-y-3">
            <FAQItem 
              question="How is Foodwise different from other nutrition apps?"
              answer="Foodwise combines cutting-edge AI technology with evidence-based nutrition science to provide personalized recommendations. Unlike traditional apps that just count calories, we focus on optimizing your nutrition for longevity, cognitive performance, and overall wellbeing. Our AI understands your unique needs and adapts recommendations based on your health goals, dietary preferences, and lifestyle."
              className="bg-primary/5 hover:bg-primary/10 transition-colors duration-300 rounded-xl"
            />
            <FAQItem 
              question="Will this work with my busy lifestyle?"
              answer="Absolutely! Foodwise is designed for busy professionals. Our AI-powered image recognition makes tracking meals effortless, and our recommendations adapt to your schedule and preferences. Whether you have 15 minutes or an hour to cook, we'll suggest recipes that fit your time constraints while maintaining optimal nutrition."
              className="bg-primary/5 hover:bg-primary/10 transition-colors duration-300 rounded-xl"
            />
            <FAQItem 
              question="How quickly will I see results?"
              answer="Many users report improved energy levels and mental clarity within the first week. Physical changes typically become noticeable within 2-4 weeks of consistent use, but the long-term benefits for longevity and health continue to accumulate over time. Our AI tracks your progress and provides personalized insights to help you stay motivated and achieve your goals."
              className="bg-primary/5 hover:bg-primary/10 transition-colors duration-300 rounded-xl"
            />
            <FAQItem 
              question="Is the nutrition advice based on scientific research?"
              answer="Yes! Our recommendations are based on peer-reviewed research in nutrition science and longevity studies. We continuously update our knowledge base with the latest scientific findings to ensure you're getting the most current, evidence-based guidance. Our team of nutritionists and researchers validates all recommendations to ensure they meet the highest standards of scientific accuracy."
              className="bg-primary/5 hover:bg-primary/10 transition-colors duration-300 rounded-xl"
            />
            <FAQItem 
              question="Can Foodwise accommodate dietary restrictions?"
              answer="Yes, Foodwise fully supports various dietary preferences and restrictions including vegetarian, vegan, keto, paleo, gluten-free, and more. Our AI takes into account your specific dietary needs, allergies, and preferences to generate appropriate recipes and recommendations. We also consider cultural preferences and seasonal availability of ingredients."
              className="bg-primary/5 hover:bg-primary/10 transition-colors duration-300 rounded-xl"
            />
          </div>
        </div>
        {/* Add bottom margin */}
        <div className="h-24"></div>
      </div>

      {/* Context Popup */}
      <div className={`fixed inset-0 z-50 ${showContextPopup ? 'pointer-events-auto' : 'pointer-events-none'}`}>
        <div 
          className={`absolute inset-0 bg-black transition-opacity duration-300 ease-in-out ${
            showContextPopup ? 'bg-opacity-50' : 'bg-opacity-0'
          }`}
          onClick={() => setShowContextPopup(false)}  
        />
        <div className="flex items-center justify-center p-4 h-full" onClick={(e) => e.stopPropagation()}>
          <div className={`bg-white rounded-lg p-6 w-full max-w-md transform transition-all duration-300 ease-in-out ${
            showContextPopup ? 'translate-y-0 opacity-100' : 'translate-y-4 opacity-0'
          }`}>
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-lg font-medium">Additional Context</h3>
              <button
                onClick={() => setShowContextPopup(false)}
                className="text-gray-400 hover:text-gray-500 transition-colors duration-200"
              >
                <XMarkIcon className="h-6 w-6" />
              </button>
            </div>
            <div className="mb-6">
              <input
                type="text"
                maxLength={50}
                placeholder="e.g., I'm feeling sick, need energy for workout..."
                value={additionalContext}
                onChange={(e) => setAdditionalContext(e.target.value)}
                className="w-full p-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-shadow duration-200"
              />
              <p className="text-sm text-gray-500 mt-1">
                {additionalContext.length}/50 characters
              </p>
            </div>
            <div className="flex justify-center">
              <button 
                onClick={() => {
                  handleRecipeGeneration('quick', additionalContext);
                  setShowContextPopup(false);
                }}
                className="
                  relative
                  w-32 h-10
                  text-gray-700
                  rounded-full 
                  shadow-lg 
                  flex items-center justify-center 
                  transition-all duration-300
                  hover:scale-105 
                  focus:outline-none 
                  select-none
                  before:absolute
                  before:inset-[-3px]
                  before:-z-10
                  before:rounded-full
                  before:bg-gradient-to-r
                  before:from-[#ff6b00]
                  before:via-[#ffdd00]
                  before:to-[#ff4400]
                  after:absolute
                  after:inset-[-3px]
                  after:-z-20
                  after:rounded-full
                  after:blur-md
                  after:bg-gradient-to-r
                  after:from-[#ff6b0080]
                  after:via-[#ffdd0080]
                  after:to-[#ff440080]
                  [&>*]:z-10
                  active:scale-100
                "
              >
                <div className="absolute inset-[0] bg-white rounded-full z-[1]" />
                <div className="flex items-center gap-1 text-sm z-10">
                  <span className="text-lg rotate-90">↻</span>
                  <span>Generate</span>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Meal Popup */}
      {showMealPopup && selectedMeal && (
        <HomeMealPopup
          meal={selectedMeal}
          onClose={() => {
            setShowMealPopup(false);
            setSelectedMeal(null);
          }}
        />
      )}

      {/* Audio Recording Popup */}
      {showAudioPopup && (
        <AudioRecordingPopup onClose={() => setShowAudioPopup(false)} />
      )}

      {/* Camera Popup */}
      {showCameraPopup && (
        <CameraPopup onClose={() => setShowCameraPopup(false)} />
      )}
    </div>
  );
};

export default Home;
import React, { useState, useRef, useEffect } from 'react';
import { FaTimes } from 'react-icons/fa';
import { ChatHeader } from './ChatHeader';
import { MessageList } from './MessageList';
import { ChatInput } from './ChatInput';
import * as chatService from '../../services/chatService';
import * as memoryService from '../../services/memoryService';
import { useAuth } from '../../contexts/AuthContext';
import { db } from '../../firebase';
import { doc, getDoc } from 'firebase/firestore';

const NutritionistChatPopup = ({ isOpen, onClose, onGenerateMealPlan, hideMealPlanButton = false, onMessagesUpdate }) => {
  const { user } = useAuth();
  const [messages, setMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [inputMessage, setInputMessage] = useState('');
  const [error, setError] = useState(null);
  const [showWelcome, setShowWelcome] = useState(true);
  const [isGeneratingMealPlan] = useState(false);
  const messagesEndRef = useRef(null);
  const [userProfile, setUserProfile] = useState(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  // Add overflow control to body when popup is open
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isOpen]);

  // Load existing thread and messages
  useEffect(() => {
    const loadExistingChat = async () => {
      if (!user || !isOpen) return;

      try {
        console.log('🔍 Looking for chat history for user:', user.uid);
        const chatHistory = await chatService.loadChatHistory(user.uid);
        
        if (chatHistory) {
          console.log('📚 Found existing chat history');
          setMessages(chatHistory.messages || []);
          // If we have chat history, don't show welcome popup
          if (chatHistory.messages?.length > 0) {
            setShowWelcome(false);
          }
        } else {
          console.log('📝 Fresh start! Creating new chat history');
          const welcomeMessage = {
            role: 'assistant',
            content: "Hi! I'm your Foodwise nutritionist. I'll help understand your preferences and goals to create the perfect meal plan for you. What would you like to tell me about your dietary preferences?",
            timestamp: new Date().toISOString(),
            userId: user.uid
          };
          setMessages([welcomeMessage]);
          await chatService.updateChatHistory(user.uid, [welcomeMessage]);
        }
      } catch (error) {
        console.error('💥 Chat loading error:', error);
        setError('Failed to load chat history');
        setMessages([]);
      }
    };

    loadExistingChat();
  }, [user, isOpen]);

  // Load user profile and memory
  useEffect(() => {
    const loadUserData = async () => {
      if (!user || !isOpen) return;
      try {
        const [memory, timedPrefs, userDoc] = await Promise.all([
          memoryService.loadMemory(user.uid),
          memoryService.loadTimedPreferences(user.uid),
          getDoc(doc(db, 'users', user.uid))
        ]);
        
        // Combine user data with memory
        setUserProfile({
          ...userDoc.data(),
          memory,
          timedPreferences: timedPrefs
        });
      } catch (error) {
        console.error('Failed to load user data:', error);
      }
    };
    loadUserData();
  }, [user, isOpen]);

  // Update parent component when messages change
  useEffect(() => {
    if (onMessagesUpdate) {
      onMessagesUpdate(messages);
    }
  }, [messages, onMessagesUpdate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!inputMessage.trim() || !user) return;

    const userMessage = {
      role: 'user',
      content: inputMessage,
      timestamp: new Date().toISOString(),
      userId: user.uid
    };

    try {
      setIsLoading(true);
      // Add user message to the UI immediately
      const newMessages = [...messages, userMessage];
      setMessages(newMessages);
      setInputMessage(''); // Clear input right after showing the message

      // Send message and get AI response
      const data = await chatService.sendChatMessage(newMessages, userProfile);
      
      let functionResponse = null;
      // Check if there are tool calls in the response
      if (data.response.tool_calls && data.response.tool_calls.length > 0) {
        const toolCall = data.response.tool_calls[0];
        if (toolCall.function) {
          console.log('🔧 Function call received:', {
            name: toolCall.function.name,
            args: JSON.parse(toolCall.function.arguments)
          });
          
          const args = JSON.parse(toolCall.function.arguments);
          
          if (toolCall.function.name === 'update_timed_preference') {
            console.log('⏱️ Updating timed preference:', args);
            await memoryService.updateTimedPreference(user.uid, args.category, args.content, args.duration, args.endDate);
            functionResponse = `I'll remember that preference and help you stay on track with your goal!`;
          } else if (toolCall.function.name === 'update_memory') {
            console.log('📝 Updating memory:', args);
            functionResponse = "I've noted down your preference. Is there anything specific you'd like to know about your diet?";
          }
        }
      }
      
      // Get the response content
      const content = functionResponse || (data.response.content?.[0]?.text || data.response.content || "I couldn't process that message.");
      
      const assistantMessage = {
        role: 'assistant',
        content: content,
        timestamp: new Date().toISOString(),
        userId: user.uid
      };

      const updatedMessages = [...newMessages, assistantMessage];
      setMessages(updatedMessages);
      await chatService.updateChatHistory(user.uid, updatedMessages);
      
    } catch (error) {
      console.error('Error sending message:', error);
      setError('Failed to send message');
      setMessages(prev => [...prev, {
        role: 'assistant',
        content: 'Sorry, I encountered an error. Please try again.',
        timestamp: new Date().toISOString(),
        userId: user.uid
      }]);
    } finally {
      setIsLoading(false);
    }
  };
/*
  const handleProceedToMealPlan = async () => {
    try {
      setIsGeneratingMealPlan(true);
      
      // Generate chat synthesis first
      console.log('🔄 Generating chat synthesis...');
      const synthesis = await chatService.synthesizeChat(messages);
      await chatService.saveChatSynthesis(user.uid, synthesis);
      
      // Add a message from the assistant about applying preferences
      const systemMessage = {
        role: 'assistant',
        content: "Great! I've gathered enough information about your preferences. I'll apply these to your existing meal plan.",
        timestamp: new Date().toISOString(),
        userId: user.uid
      };
      
      const updatedMessages = [...messages, systemMessage];
      setMessages(updatedMessages);
      await chatService.updateChatHistory(user.uid, updatedMessages);
      
      // Wait a moment for the message to be visible
      await new Promise(resolve => setTimeout(resolve, 1500));
      
      // Close the chat and pass the synthesis
      onClose();
      onGenerateMealPlan(synthesis);
      
    } catch (error) {
      console.error('Error proceeding to meal plan:', error);
      setError('Failed to proceed to meal plan');
    } finally {
      setIsGeneratingMealPlan(false);
    }
  };
*/
  if (!isOpen) return null;

  if (showWelcome) {
    return (
      <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
        <div className="relative w-full max-w-md mx-4 p-6 bg-white rounded-lg shadow-xl md:mx-0">
          <button
            onClick={onClose}
            className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
          >
            <FaTimes className="w-5 h-5" />
          </button>

          <div className="mt-2 text-center">
            <div className="mb-6">
              <span className="text-4xl">👩‍⚕️</span>
            </div>
            <h2 className="text-lg font-medium text-gray-900 mb-3">
              Chat with our AI Nutritionist
            </h2>
            <p className="text-gray-600 mb-6 text-sm">
              Let's understand your unique situation, preferences, and goals before creating your personalized meal plan. Our AI nutritionist will help tailor meals that perfectly match your needs.
            </p>

            <div className="flex justify-end space-x-3">
              <button
                onClick={onClose}
                className="px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 rounded-lg hover:bg-gray-200"
              >
                Maybe Later
              </button>
              <button
                onClick={() => setShowWelcome(false)}
                className="px-6 py-2 text-sm font-medium text-white bg-primary rounded-lg hover:bg-primary-dark"
              >
                Start Chat
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50" onClick={(e) => e.stopPropagation()}>
      {isGeneratingMealPlan ? (
        <div className="bg-white rounded-lg p-8 max-w-sm w-full mx-4 text-center">
          <div className="flex flex-col items-center justify-center space-y-6">
            <div className="relative w-20 h-20">
              <div className="absolute inset-0 rounded-full border-4 border-t-primary border-r-primary/30 border-b-primary/10 border-l-primary/70 animate-spin"></div>
              <div className="absolute inset-3 bg-gradient-to-br from-primary to-primary-dark rounded-full animate-pulse"></div>
              <div className="absolute inset-0 flex items-center justify-center text-2xl">
                👩‍🍳
              </div>
            </div>
            <p className="text-lg font-medium text-gray-800 animate-pulse">
              Applying your preferences to meal plan...
            </p>
          </div>
        </div>
      ) : (
        <div className="relative w-full h-full md:h-[80vh] md:w-[600px] bg-white rounded-lg shadow-xl flex flex-col">
          {/* Close button */}
          <button
            onClick={onClose}
            className="absolute top-4 right-4 z-10 text-gray-500 hover:text-gray-700"
          >
            <FaTimes className="w-5 h-5" />
          </button>

          <div className="flex flex-col h-full overflow-hidden">
            <ChatHeader />
            
            <div className="flex-1 overflow-y-auto">
              <MessageList 
                messages={messages} 
                isLoading={isLoading}
                error={error}
              />
              <div ref={messagesEndRef} />
            </div>

            <div className="flex-shrink-0">
              <div className="p-4">
                <ChatInput 
                  inputMessage={inputMessage}
                  setInputMessage={setInputMessage}
                  onSubmit={handleSubmit}
                  isLoading={isLoading}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default NutritionistChatPopup; 
import React from 'react';

export const ChatHeader = () => {
  return (
    <div className="flex items-center justify-between p-4 border-b">
      <div className="flex items-center">
        <span className="text-lg font-medium text-gray-900">Chat with AI Nutritionist</span>
      </div>
    </div>
  );
};

import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { useAnalysisHistory } from '../hooks/useAnalysisHistory';
import { FaUtensils, FaSpinner, FaFileDownload } from 'react-icons/fa';
import MealCalendar from '../components/calendar/MealCalendar';
import { PDFDownloadLink } from '@react-pdf/renderer';
import MealPlanPDF from '../components/pdf/MealPlanPDF';
import { getAuth } from 'firebase/auth';
import { getMealPlan } from '../services/mealPlanService';
import { Link } from 'react-router-dom';
import NutritionistChatPopup from '../components/chat/NutritionistChatPopup';

const MyMeals = () => {
  const [editingMealId, setEditingMealId] = useState(null);
  const [editingValues, setEditingValues] = useState({
    lastTappedIndex: null,
    lastTappedField: null,
    values: {}
  });
  const { history, loading, error, deleteMeal, updateMeal } = useAnalysisHistory(20);
  const [isFrequencyExpanded, setIsFrequencyExpanded] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedIngredient, setSelectedIngredient] = useState(null);
  const [groupedMeals, setGroupedMeals] = useState({});
  const [isChatPopupOpen, setIsChatPopupOpen] = useState(false);

  const handleEdit = useCallback(async (meal, ingredientIndex, newValue, field = 'grams') => {
    try {
      if (field === 'grams' && (!newValue || isNaN(newValue))) {
        return;
      }
      
      const updatedIngredients = [...meal.ingredients];
      updatedIngredients[ingredientIndex] = {
        ...updatedIngredients[ingredientIndex],
        [field === 'grams' ? 'estimated_grams' : 'name']: field === 'grams' ? parseInt(newValue) : newValue
      };
      
      await updateMeal(meal.id, { ingredients: updatedIngredients });
      setEditingMealId(null);
      setEditingValues({
        lastTappedIndex: null,
        lastTappedField: null,
        values: {}
      });
    } catch (error) {
      console.error('Failed to update meal:', error);
      alert('Failed to save changes. Please try again.');
    }
  }, [updateMeal]);

  const handleClickOutside = useCallback((event) => {
    if (editingMealId) {
      const isInput = event.target.tagName.toLowerCase() === 'input';
      const isEditButton = event.target.closest('[aria-label="Edit meal"]');
      
      if (!isInput && !isEditButton) {
        const meal = history.find(m => m.id === editingMealId);
        if (meal) {
          Object.entries(editingValues).forEach(([key, value]) => {
            if (key.startsWith(editingMealId)) {
              const index = parseInt(key.split('-')[1]);
              handleEdit(meal, index, value);
            }
          });
        }
        setEditingMealId(null);
        setEditingValues({
          lastTappedIndex: null,
          lastTappedField: null,
          values: {}
        });
      }
    }
  }, [editingMealId, editingValues, history, handleEdit]);

  // Add styles effect
  useEffect(() => {
    const styleSheet = document.createElement("style");
    styleSheet.innerText = styles;
    document.head.appendChild(styleSheet);
    return () => {
      document.head.removeChild(styleSheet);
    };
  }, []);

  // Click outside effect
  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [handleClickOutside]);

  // Group meals effect
  useEffect(() => {
    const processLoggedMeals = () => {
      const meals = history.reduce((acc, meal) => {
        const mealDate = meal.timestamp.toDate();
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        const now = new Date();
        
        // Only include meals from now onwards, up to 2 days in the future
        const twoDaysFromNow = new Date(today);
        twoDaysFromNow.setDate(today.getDate() + 2);
        twoDaysFromNow.setHours(23, 59, 59, 999);
        
        // For today, only include future meals. For other days, include all meals
        const isToday = mealDate.getDate() === today.getDate() &&
                       mealDate.getMonth() === today.getMonth() &&
                       mealDate.getFullYear() === today.getFullYear();

        if ((isToday && mealDate >= now) || // Future meals today
            (mealDate > today && mealDate <= twoDaysFromNow)) { // Or meals in next 2 days
          const date = mealDate.toISOString().split('T')[0];
          if (!acc[date]) {
            acc[date] = [];
          }
          acc[date].push({
            time: mealDate.toLocaleTimeString('en-US', { 
              hour: '2-digit', 
              minute: '2-digit',
              hour12: false 
            }),
            name: meal.description || meal.title || 'Untitled Meal',
            ingredients: meal.ingredients.map(ing => ({
              name: ing.name,
              quantity: ing.estimated_grams || 0,
              unit: 'g'
            }))
          });
        }
        return acc;
      }, {});
      return meals;
    };

    const addPlannedMeals = async (loggedMeals) => {
      const auth = getAuth();
      if (!auth.currentUser) return loggedMeals;

      try {
        const mealPlan = await getMealPlan(auth.currentUser.uid);
        const meals = { ...loggedMeals };
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        const twoDaysFromNow = new Date(today);
        twoDaysFromNow.setDate(today.getDate() + 2);
        twoDaysFromNow.setHours(23, 59, 59, 999);

        if (mealPlan && mealPlan.days) {
          mealPlan.days.forEach(planDay => {
            const planDate = new Date(planDay.date);
            // Only process if the day is today or in the next 2 days
            if (planDate >= today && planDate <= twoDaysFromNow) {
              const date = planDay.date;
              if (!meals[date]) {
                meals[date] = [];
              }

              planDay.meals.forEach(meal => {
                const [hours, minutes] = meal.time.split(':').map(Number);
                const mealDateTime = new Date(planDate);
                mealDateTime.setHours(hours, minutes);
                const now = new Date();

                // Skip if it's a past meal from today
                const isToday = planDate.getDate() === today.getDate() &&
                              planDate.getMonth() === today.getMonth() &&
                              planDate.getFullYear() === today.getFullYear();
                              
                if (!isToday || mealDateTime >= now) {
                  // Only add if it's not already logged (to avoid duplicates)
                  const existingMeal = meals[date].find(m => 
                    m.time === meal.time && m.name === (meal.title || meal.description)
                  );

                  if (!existingMeal) {
                    meals[date].push({
                      time: meal.time,
                      name: meal.title || meal.description || 'Planned Meal',
                      ingredients: meal.ingredients?.map(ing => ({
                        name: ing.name,
                        quantity: ing.amount || 0,
                        unit: ing.unit || 'g'
                      })) || []
                    });
                  }
                }
              });
            }
          });
        }
        return meals;
      } catch (error) {
        console.error('Error fetching planned meals:', error);
        return loggedMeals;
      }
    };

    const updateMeals = async () => {
      const loggedMeals = processLoggedMeals();
      const allMeals = await addPlannedMeals(loggedMeals);
      setGroupedMeals(allMeals);
    };

    updateMeals();
  }, [history]);

  if (loading) {
    return (
      <div className="min-h-screen pt-16 bg-background flex items-center justify-center">
        <FaSpinner className="w-8 h-8 text-gray-600 animate-spin" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen pt-16 bg-background flex items-center justify-center">
        <div className="text-center p-4 bg-red-50 rounded-lg">
          <p className="text-red-600">Error loading meals: {error}</p>
        </div>
      </div>
    );
  }

  const handleDelete = async (mealId) => {
    if (window.confirm('Are you sure you want to delete this meal?')) {
      await deleteMeal(mealId);
    }
  };

  const getIngredientWithCategory = (meals) => {
    const sevenDaysAgo = new Date();
    sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);

    return meals
      .filter(meal => meal.timestamp?.toDate() >= sevenDaysAgo)
      .reduce((acc, meal) => {
        meal.ingredients.forEach(ing => {
          const normalizedName = ing.name.charAt(0).toUpperCase() + ing.name.slice(1).toLowerCase();
          if (!acc[normalizedName]) {
            acc[normalizedName] = {
              count: 1,
              category: ing.category
            };
          } else {
            acc[normalizedName].count += 1;
          }
        });
        return acc;
      }, {});
  };

  const getIngredientSummary = (meals) => {
    const sevenDaysAgo = new Date();
    sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);

    const ingredients = meals
      .filter(meal => meal.timestamp?.toDate() >= sevenDaysAgo)
      .reduce((acc, meal) => {
        meal.ingredients.forEach(ing => {
          const normalizedName = ing.name.charAt(0).toUpperCase() + ing.name.slice(1).toLowerCase();
          if (!acc[normalizedName]) {
            acc[normalizedName] = {
              count: 1,
              category: ing.category
            };
          } else {
            acc[normalizedName].count += 1;
          }
        });
        return acc;
      }, {});

    const totalCount = Object.keys(ingredients).length;
    const produceCount = Object.values(ingredients).filter(
      ing => ing.category?.toLowerCase().includes('vegetable') || 
             ing.category?.toLowerCase().includes('fruit')
    ).length;

    return { totalCount, produceCount };
  };

  const getCategoryCount = (meals, category) => {
    return meals
      .flatMap(meal => meal.ingredients)
      .filter(ing => ing.category === category)
      .length;
  };

  const MealTimeline = ({ meals }) => {
    const timelineRef = useRef(null);
    const [activeDate, setActiveDate] = useState(new Date().toDateString());
    const [expandedMeals, setExpandedMeals] = useState(new Set());

    // Add toggle function for ingredients
    const toggleIngredients = (mealId) => {
      setExpandedMeals(prev => {
        const newSet = new Set(prev);
        if (newSet.has(mealId)) {
          newSet.delete(mealId);
        } else {
          newSet.add(mealId);
        }
        return newSet;
      });
    };

    // Group meals by date and time of day
    const groupedMeals = useMemo(() => {
      return meals.reduce((acc, meal) => {
        const date = meal.timestamp?.toDate().toDateString();
        const hour = meal.timestamp?.toDate().getHours();
        
        // Determine time of day
        let timeOfDay = 'Other';
        if (hour >= 5 && hour < 11) timeOfDay = 'Breakfast';
        else if (hour >= 11 && hour < 15) timeOfDay = 'Lunch';
        else if (hour >= 15 && hour < 18) timeOfDay = 'Snack';
        else if (hour >= 18 && hour < 22) timeOfDay = 'Dinner';
        
        if (!acc[date]) {
          acc[date] = {
            Breakfast: [],
            Lunch: [],
            Snack: [],
            Dinner: [],
            Other: []
          };
        }
        
        acc[date][timeOfDay].push(meal);
        return acc;
      }, {});
    }, [meals]);

    // Get unique dates
    const dates = Object.keys(groupedMeals).sort((a, b) => 
      new Date(b) - new Date(a)
    );

    return (
      <div className="relative">
        {/* Date Selector */}
        <div className="flex overflow-x-auto scrollbar-hide -mx-4 px-4 sm:mx-0 sm:px-0 mb-6 sticky top-0 bg-background z-10 pb-2">
          <div className="flex space-x-2">
            {dates.map((date) => (
              <button
                key={date}
                onClick={() => setActiveDate(date)}
                className={`
                  flex-shrink-0 px-4 py-2 rounded-full text-sm font-medium
                  transition-all duration-200
                  ${activeDate === date 
                    ? 'bg-emerald-100 text-emerald-800' 
                    : 'bg-white text-gray-600 hover:bg-gray-50'}
                `}
              >
                {isToday(date) ? 'Today' : formatDateShort(date)}
              </button>
            ))}
          </div>
        </div>

        {/* Timeline View */}
        <div ref={timelineRef} className="relative">
          {['Breakfast', 'Lunch', 'Snack', 'Dinner', 'Other'].map((timeOfDay) => {
            const mealsInTime = groupedMeals[activeDate]?.[timeOfDay] || [];
            if (mealsInTime.length === 0) return null;

            return (
              <div key={timeOfDay} className="mb-6 last:mb-0">
                <div className="flex items-center mb-3">
                  <div className="w-2 h-2 rounded-full bg-emerald-500 mr-3" />
                  <h3 className="text-sm font-medium text-gray-500">{timeOfDay}</h3>
                </div>
                
                <div className="space-y-3">
                  {mealsInTime.map((meal) => (
                    <div
                      key={meal.id}
                      className="bg-white rounded-lg shadow-sm p-4 transition-all duration-200 hover:shadow-md"
                    >
                      <div className="flex items-start justify-between mb-2">
                        <div>
                          <h4 className="font-medium text-gray-800">
                            {meal.description || 'Untitled Meal'}
                          </h4>
                          <p className="text-sm text-gray-500">
                            {formatTime(meal.timestamp)}
                          </p>
                        </div>
                        
                        {/* Quick Stats */}
                        <div className="flex items-center space-x-2">
                          <span className="text-sm px-2 py-1 bg-gray-50 rounded-full text-gray-600">
                            {meal.ingredients.length} items
                          </span>
                        </div>
                      </div>

                      {/* Ingredients Preview with Expansion */}
                      <div className="flex flex-wrap gap-2">
                        {(expandedMeals.has(meal.id) ? meal.ingredients : meal.ingredients.slice(0, 3)).map((ing, idx) => (
                          <span
                            key={idx}
                            className="text-sm px-2 py-1 bg-gray-50 rounded-full text-gray-600"
                          >
                            {ing.name}
                            {ing.estimated_grams && <span className="text-gray-400 ml-1">{ing.estimated_grams}g</span>}
                          </span>
                        ))}
                        {meal.ingredients.length > 3 && !expandedMeals.has(meal.id) && (
                          <button
                            onClick={() => toggleIngredients(meal.id)}
                            className="text-sm px-2 py-1 bg-gray-50 rounded-full text-gray-500 hover:bg-gray-100 transition-colors"
                          >
                            +{meal.ingredients.length - 3} more
                          </button>
                        )}
                        {expandedMeals.has(meal.id) && meal.ingredients.length > 3 && (
                          <button
                            onClick={() => toggleIngredients(meal.id)}
                            className="text-sm px-2 py-1 bg-gray-50 rounded-full text-gray-500 hover:bg-gray-100 transition-colors"
                          >
                            Show less
                          </button>
                        )}
                      </div>

                      {/* Quick Actions */}
                      <div className="mt-3 pt-3 border-t border-gray-100 flex justify-end">
                        <button
                          onClick={() => handleDelete(meal.id)}
                          className="text-sm text-gray-500 hover:text-red-600 transition-colors"
                        >
                          Delete
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  return (
    <div className="min-h-screen pt-page-top bg-background pb-36 mt-0 lg:mt-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h1 className="text-2xl sm:text-3xl font-thin text-text-dark mb-6">
          Your Meal Planner
        </h1>
        <p className="text-lg text-text-light max-w-2xl mb-8">
          Chat with our <Link to="/chat" className="text-primary hover:text-primary/80 font-medium">AI Nutritionist</Link> to tailor your meal plan. Update preferences anytime.
        </p>
      </div>

      <div className="px-4 sm:px-6 lg:px-8 max-w-7xl mx-auto">
        <div className="flex justify-between items-center mb-6">
          <div className="relative group hidden md:block">
            <PDFDownloadLink
              document={<MealPlanPDF meals={groupedMeals} />}
              fileName="meal-plan.pdf"
              className="inline-flex items-center justify-center w-11 h-11 rounded-full bg-white shadow-sm text-gray-400 hover:text-emerald-500 hover:shadow-md hover:scale-105 transform transition-all duration-200 border border-gray-100"
            >
              {({ blob, url, loading, error }) => {
                // Show loading state while either the PDF is generating or meals are being fetched
                const isLoading = loading || !groupedMeals;
                return isLoading ? (
                  <FaSpinner className="w-4 h-4 animate-spin text-emerald-500" />
                ) : (
                  <FaFileDownload className="w-4 h-4" />
                );
              }}
            </PDFDownloadLink>
            <div className="absolute right-0 top-full mt-2 px-3 py-1.5 bg-gray-900 text-white text-xs font-medium rounded-lg opacity-0 invisible group-hover:opacity-100 group-hover:visible transform group-hover:translate-y-0 translate-y-1 transition-all duration-200 whitespace-nowrap shadow-lg z-50">
              Export as PDF
              <div className="absolute -top-1 right-4 w-2 h-2 bg-gray-900 transform rotate-45"></div>
            </div>
          </div>
        </div>

        {/* Meal Calendar Section */}
        <div className="mb-8">
          <MealCalendar />
        </div>
        <h2 className="text-h2 font-thin text-text-dark mb-6">
          Past Meals
        </h2>

        {/* Weekly Summary Section */}
        {history.length > 0 && (
          <div className="bg-white rounded-xl shadow-sm p-6 mb-8">
            <button 
              onClick={() => setIsFrequencyExpanded(prev => !prev)}
              className="w-full text-left"
            >
              <div className="text-xl font-semibold text-gray-800">
                Last 7 Days Summary
              </div>
              <div 
                className={`text-sm text-gray-400 mt-1 transition-all duration-300 ease-in-out ${
                  isFrequencyExpanded 
                    ? 'opacity-0 transform -translate-y-2 h-0' 
                    : 'opacity-100 transform translate-y-0 h-auto'
                }`}
              >
                {(() => {
                  const { totalCount, produceCount } = getIngredientSummary(history);
                  return `${totalCount} ingredients, ${produceCount} vegetables and fruits`;
                })()}
              </div>
            </button>
            {isFrequencyExpanded && selectedCategory && (
              <div 
                className="text-sm text-gray-500 mb-3 transition-all duration-300 ease-in-out opacity-0 animate-fade-in"
                style={{
                  animation: 'fadeIn 0.3s ease-in-out forwards'
                }}
              >
                {getCategoryCount(history, selectedCategory)} {selectedCategory.toLowerCase()}
              </div>
            )}
            <div className={`flex flex-wrap gap-2 transition-all duration-300 ease-in-out origin-top ${
              isFrequencyExpanded 
                ? 'opacity-100 max-h-[500px] scale-y-100 mt-4' 
                : 'opacity-0 max-h-0 scale-y-95 overflow-hidden'
            }`}>
              {Object.entries(getIngredientWithCategory(history))
                .sort(([, a], [, b]) => b.count - a.count)
                .map(([ingredient, data]) => (
                  <button
                    key={ingredient}
                    onClick={() => {
                      setSelectedCategory(selectedCategory === data.category ? null : data.category);
                      setSelectedIngredient(selectedIngredient === ingredient ? null : ingredient);
                    }}
                    className={`rounded-full px-3 py-1 text-sm transition-all duration-200 ${
                      selectedCategory === data.category 
                        ? 'bg-emerald-100 text-emerald-800 font-medium' 
                        : selectedCategory 
                          ? 'bg-gray-50 text-gray-400' 
                          : 'text-gray-600 hover:bg-gray-100'
                    }`}
                  >
                    {data.count}× {ingredient}
                  </button>
                ))}
            </div>
          </div>
        )}

        {history.length === 0 ? (
          <div className="text-center p-8 bg-white rounded-xl shadow-sm">
            <FaUtensils className="w-12 h-12 text-gray-400 mx-auto mb-4" />
            <h3 className="text-xl font-semibold text-gray-600">No meals yet</h3>
            <p className="text-gray-500 mt-2">Take a photo of your meal to get started!</p>
          </div>
        ) : (
          <div>
            <MealTimeline meals={history} />
          </div>
        )}

        {/* Add the chat popup */}
        <NutritionistChatPopup
          isOpen={isChatPopupOpen}
          onClose={() => setIsChatPopupOpen(false)}
        />
      </div>
    </div>
  );
};


const isToday = (dateString) => {
  return dateString === new Date().toDateString();
};

// Add new helper function
const formatDateShort = (dateString) => {
  const date = new Date(dateString);
  return new Intl.DateTimeFormat('en-US', {
    month: 'short',
    day: 'numeric'
  }).format(date);
};

const formatTime = (timestamp) => {
  if (!timestamp) return '';
  const date = timestamp.toDate();
  return new Intl.DateTimeFormat('en-US', {
    hour: 'numeric',
    minute: '2-digit'
  }).format(date);
};

// Move the styles constant outside the component
const styles = `
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-5px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

export default MyMeals;
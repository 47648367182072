import { useState } from 'react';
import { getAuth } from 'firebase/auth';
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '../firebase';
import { useNavigate } from 'react-router-dom';

export const useRecipeActions = (userPreferences) => {
  const [isLogged, setIsLogged] = useState(false);
  const [showLogSuccess, setShowLogSuccess] = useState(false);
  const navigate = useNavigate();

  const processIngredients = (recipe) => {
    if (!recipe) return [];
    
    // Handle array of ingredient objects from meal calendar
    if (Array.isArray(recipe.ingredients)) {
      // If ingredients is an array of objects with name, quantity, unit
      if (recipe.ingredients[0]?.name) {
        return recipe.ingredients.map(ing => {
          const quantity = ing.quantity || '';
          const unit = ing.unit || '';
          const name = ing.name || '';
          return `${quantity} ${unit} ${name}`.trim();
        }).filter(Boolean);
      }
      // If ingredients is an array of strings
      return recipe.ingredients.filter(Boolean);
    }
    
    // If recipe itself is the ingredients array
    if (Array.isArray(recipe)) {
      return recipe.map(ing => {
        if (typeof ing === 'object') {
          const quantity = ing.quantity || '';
          const unit = ing.unit || '';
          const name = ing.name || '';
          return `${quantity} ${unit} ${name}`.trim();
        }
        return ing;
      }).filter(Boolean);
    }
    
    return [];
  };

  const addToShoppingList = async (recipe) => {
    const auth = getAuth();
    const user = auth.currentUser;
    if (!user || !recipe) return;

    try {
      const ingredients = processIngredients(recipe);
      console.log('Processing ingredients for shopping list:', ingredients);
      const batch = [];
      
      for (const ingredient of ingredients) {
        const match = ingredient.match(/^([\d.]+\s*[a-zA-Z]+)?\s*(.+)$/);
        const amount = match[1] || '';
        const name = match[2] || ingredient;

        batch.push({
          userId: user.uid,
          name: name.trim(),
          amount: amount.trim(),
          isActive: true,
          createdAt: serverTimestamp(),
          updatedAt: serverTimestamp()
        });
      }

      const shoppingListRef = collection(db, 'shopping_lists');
      const promises = batch.map(item => addDoc(shoppingListRef, item));
      const results = await Promise.all(promises);
      console.log('Added items to shopping list with IDs:', results.map(doc => doc.id));
      
      navigate('/shopping-list');
    } catch (error) {
      console.error('Error adding to shopping list:', error);
    }
  };

  const logMeal = async (recipe) => {
    try {
      const auth = getAuth();
      const user = auth.currentUser;
      if (!user || !recipe) {
        console.error('No user logged in or no recipe provided');
        return;
      }

      const ingredients = processIngredients(recipe);
      console.log('Processing ingredients for meal log:', ingredients);

      if (ingredients.length === 0) {
        console.error('No valid ingredients found in recipe:', recipe);
        return;
      }

      // Use GPT to analyze ingredients
      const ingredientResponse = await fetch('https://generateairesponse-boc5emstrq-uc.a.run.app/chat', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          messages: [
            {
              role: 'system',
              content: 'You are a nutritionist that analyzes ingredients. Respond ONLY with a JSON object in this exact format, nothing else: {"type": "meal", "ingredients": [{"name": "ingredient", "estimated_grams": number, "category": "category"}]}. For category, use only: meat, fish, vegetables, fruits, grains, dairy, legumes, nuts, processed_food, beverages, condiments.'
            },
            {
              role: 'user',
              content: `Analyze these ingredients: ${ingredients.join(', ')}`
            }
          ]
        })
      });

      const data = await ingredientResponse.json();
      console.log('AI Response:', data);

      let analyzedIngredients;
      try {
        if (typeof data.response === 'string') {
          // Try to extract JSON if it's wrapped in markdown code blocks
          const jsonMatch = data.response.match(/```json\n?(.*)\n?```/s) || 
                          data.response.match(/{.*}/s);
          
          const jsonStr = jsonMatch ? jsonMatch[1] || jsonMatch[0] : data.response;
          console.log('Extracted JSON string:', jsonStr);
          
          const parsed = JSON.parse(jsonStr);
          analyzedIngredients = parsed.ingredients;
        } else {
          analyzedIngredients = data.response.ingredients;
        }

        if (!analyzedIngredients || !Array.isArray(analyzedIngredients) || analyzedIngredients.length === 0) {
          throw new Error('Invalid ingredients structure');
        }
      } catch (error) {
        console.error('Failed to parse ingredients', error);
        // Fallback: create a simple structure for the ingredients
        analyzedIngredients = ingredients.map(ing => ({
          name: ing,
          estimated_grams: 100,
          category: 'unknown'
        }));
      }

      const mealData = {
        userId: user.uid,
        timestamp: serverTimestamp(),
        analyzedAt: new Date().toISOString(),
        ingredients: analyzedIngredients,
        transcribedText: ingredients.join(', '),
        title: recipe.title || recipe.description || '',
        mealType: recipe.mealType || 'lunch',
        date: recipe.date || recipe.time || new Date().toISOString().split('T')[0],
        originalIngredients: ingredients
      };

      console.log('Saving meal data:', mealData);

      const mealsRef = collection(db, 'meals');
      const docRef = await addDoc(mealsRef, mealData);
      console.log('Meal logged with document ID:', docRef.id);
      
      setIsLogged(true);
      setShowLogSuccess(true);
      setTimeout(() => setShowLogSuccess(false), 3000);
    } catch (error) {
      console.error('Error logging meal:', error);
    }
  };

  return {
    addToShoppingList,
    logMeal,
    isLogged,
    showLogSuccess,
    userPreferences
  };
};

import React, { useState } from 'react';
import { FiTarget, FiCalendar, FiMoon, FiClock } from 'react-icons/fi';

const Goals = ({ userData, updateUserData, onNext, onBack }) => {
  const [errors, setErrors] = useState({});

  const healthFocusOptions = [
    {
      id: 'gut-microbiome',
      title: 'Gut Health',
      description: 'Focus on foods that promote digestive health and a balanced microbiome',
      icon: '🦠'
    },
    {
      id: 'longevity',
      title: 'Longevity',
      description: 'Optimize your diet for healthy aging and cellular health',
      icon: '🌱'
    },
    {
      id: 'fitness',
      title: 'Fitness',
      description: 'Nutrition tailored for athletic performance and muscle recovery',
      icon: '💪'
    }
  ];

  const goalOptions = [
    { value: 'weight-loss', label: 'Weight Loss', icon: '⬇️' },
    { value: 'muscle-gain', label: 'Muscle Gain', icon: '💪' },
    { value: 'maintenance', label: 'Maintenance', icon: '⚖️' },
    { value: 'general-health', label: 'General Health', icon: '❤️' }
  ];

  const validateForm = () => {
    const newErrors = {};
    if (!userData.goal) newErrors.goal = 'Please select a primary goal';
    if (!userData.healthFocus) newErrors.healthFocus = 'Please select a health focus';
    
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      onNext();
    }
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === 'checkbox' ? checked : value;
    updateUserData({ [name]: newValue });
    if (errors[name]) {
      setErrors(prev => ({ ...prev, [name]: undefined }));
    }
  };

  return (
    <div className="space-y-6">
      <div className="text-center">
        <h2 className="text-2xl font-bold text-gray-900">Your Health Goals</h2>
        <p className="mt-2 text-sm text-gray-600">
          Let's understand what you want to achieve with Foodwise. This will help us
          create a personalized nutrition plan for you.
        </p>
      </div>

      <form onSubmit={handleSubmit} className="space-y-8">
        {/* Primary Goal Selection */}
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-4">
            What's your primary goal?
          </label>
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
            {goalOptions.map((goal) => (
              <label
                key={goal.value}
                className={`relative flex cursor-pointer rounded-lg border p-4 shadow-sm focus:outline-none ${
                  userData.goal === goal.value
                    ? 'border-primary-500 ring-2 ring-primary-500'
                    : 'border-gray-300'
                }`}
              >
                <input
                  type="radio"
                  name="goal"
                  value={goal.value}
                  checked={userData.goal === goal.value}
                  onChange={handleChange}
                  className="sr-only"
                />
                <div className="flex w-full items-center justify-between">
                  <div className="flex items-center">
                    <div className="text-sm">
                      <p className="font-medium text-gray-900">
                        {goal.icon} {goal.label}
                      </p>
                    </div>
                  </div>
                  {userData.goal === goal.value && (
                    <div className="shrink-0 text-primary-600">
                      <FiTarget className="h-6 w-6" />
                    </div>
                  )}
                </div>
              </label>
            ))}
          </div>
          {errors.goal && (
            <p className="mt-2 text-sm text-red-600">{errors.goal}</p>
          )}
        </div>

        {/* Health Focus Selection */}
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-4">
            What's your primary health focus?
          </label>
          <div className="grid grid-cols-1 gap-4">
            {healthFocusOptions.map((focus) => (
              <label
                key={focus.id}
                className={`relative flex cursor-pointer rounded-lg border p-4 shadow-sm focus:outline-none ${
                  userData.healthFocus === focus.id
                    ? 'border-primary-500 ring-2 ring-primary-500'
                    : 'border-gray-300'
                }`}
              >
                <input
                  type="radio"
                  name="healthFocus"
                  value={focus.id}
                  checked={userData.healthFocus === focus.id}
                  onChange={handleChange}
                  className="sr-only"
                />
                <div className="flex w-full items-center">
                  <div className="text-2xl mr-4">{focus.icon}</div>
                  <div>
                    <p className="font-medium text-gray-900">{focus.title}</p>
                    <p className="text-sm text-gray-500">{focus.description}</p>
                  </div>
                  {userData.healthFocus === focus.id && (
                    <div className="shrink-0 text-primary-600 ml-auto">
                      <FiTarget className="h-6 w-6" />
                    </div>
                  )}
                </div>
              </label>
            ))}
          </div>
          {errors.healthFocus && (
            <p className="mt-2 text-sm text-red-600">{errors.healthFocus}</p>
          )}
        </div>

        {/* Menstrual Cycle Tracking (only for female users) */}
        {userData.gender === 'female' && (
          <div className="border-t pt-6">
            <div className="bg-gradient-to-r from-pink-50 to-purple-50 rounded-lg p-6">
              <div className="flex items-start space-x-4">
                <div className="flex-shrink-0">
                  <div className="p-2 bg-pink-100 rounded-full">
                    <FiMoon className="h-6 w-6 text-pink-600" />
                  </div>
                </div>
                <div className="flex-grow">
                  <div className="flex items-center justify-between">
                    <div>
                      <h3 className="text-lg font-medium text-gray-900">Menstrual Cycle Nutrition Adaptation</h3>
                      <p className="mt-1 text-sm text-gray-600">
                        Get personalized nutrition recommendations based on your cycle phases
                      </p>
                    </div>
                    <div className="flex items-center">
                      <div className="relative inline-block w-12 mr-2 align-middle select-none">
                        <input
                          type="checkbox"
                          name="menstrualCycleTracking"
                          checked={userData.menstrualCycleTracking}
                          onChange={handleChange}
                          className="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer transition-transform duration-200 ease-in-out checked:translate-x-full checked:border-pink-500"
                        />
                        <label className="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"></label>
                      </div>
                      <span className="text-sm font-medium text-gray-700">
                        {userData.menstrualCycleTracking ? 'Enabled' : 'Disabled'}
                      </span>
                    </div>
                  </div>

                  {userData.menstrualCycleTracking && (
                    <div className="mt-6 grid grid-cols-1 md:grid-cols-2 gap-6">
                      <div className="bg-white rounded-lg p-4 shadow-sm border border-pink-100">
                        <div className="flex items-center space-x-3 mb-3">
                          <FiCalendar className="h-5 w-5 text-pink-500" />
                          <label className="block text-sm font-medium text-gray-700">
                            Last cycle start date
                          </label>
                        </div>
                        <input
                          type="date"
                          name="cycleStartDate"
                          value={userData.cycleStartDate}
                          onChange={handleChange}
                          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-pink-500 focus:ring-pink-500 sm:text-sm"
                          required={userData.menstrualCycleTracking}
                        />
                      </div>

                      <div className="bg-white rounded-lg p-4 shadow-sm border border-pink-100">
                        <div className="flex items-center space-x-3 mb-3">
                          <FiClock className="h-5 w-5 text-pink-500" />
                          <label className="block text-sm font-medium text-gray-700">
                            Average cycle length
                          </label>
                        </div>
                        <div className="relative">
                          <select
                            name="cycleDuration"
                            value={userData.cycleDuration}
                            onChange={handleChange}
                            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-pink-500 focus:ring-pink-500 sm:text-sm appearance-none"
                            required={userData.menstrualCycleTracking}
                          >
                            {[...Array(15)].map((_, i) => (
                              <option key={i + 21} value={i + 21}>
                                {i + 21} days
                              </option>
                            ))}
                          </select>
                          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                            <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                              <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/>
                            </svg>
                          </div>
                        </div>
                        <p className="mt-2 text-xs text-gray-500">
                          Most cycles are between 21 and 35 days
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Navigation Buttons */}
        <div className="flex justify-between px-4 py-2 bg-white border-t border-gray-100">
          <button
            type="button"
            onClick={onBack}
            className="text-sm text-gray-600 hover:text-gray-900"
          >
            Back
          </button>
          <button
            type="submit"
            className="text-sm text-primary hover:text-primary-dark"
          >
            Continue
          </button>
        </div>
      </form>
    </div>
  );
};

export default Goals; 
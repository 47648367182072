import React from 'react';
import { usePWA } from '../../contexts/PWAContext';
import PWANavBar from './PWANavBar';
import Navbar from './Navbar';

const NavigationManager = () => {
  const { isPWA } = usePWA();

  // If it's a PWA, show the mobile navigation
  if (isPWA) {
    return <PWANavBar />;
  }

  // For all other cases, show the regular navbar
  return <Navbar />;
};

export default NavigationManager; 
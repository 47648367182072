import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { IoFlaskOutline, IoAnalyticsOutline, IoMedicalOutline, IoBeakerOutline } from 'react-icons/io5';
import { useAuth } from '../contexts/AuthContext';
import { toast } from 'react-hot-toast';
import { db } from '../firebase';
import { collection, addDoc, serverTimestamp, query, where, getDocs } from 'firebase/firestore';

const Landing3 = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const { currentUser } = useAuth();

  useEffect(() => {
    if (currentUser) {
      navigate('/home');
    }
  }, [currentUser, navigate]);

  const handleWaitlistSubmit = async (e) => {
    e.preventDefault();
    
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      toast.error('Please enter a valid email address');
      return;
    }

    try {
      const waitlistQuery = query(
        collection(db, "waitlist"),
        where("email", "==", email)
      );

      const querySnapshot = await getDocs(waitlistQuery);
      if (!querySnapshot.empty) {
        toast.error('This email is already on the waitlist');
        return;
      }

      await addDoc(collection(db, "waitlist"), {
        email,
        timestamp: serverTimestamp(),
        source: 'longevity-nutrition',
        status: 'pending'
      });

      toast.success('Successfully joined the waitlist!');
      setEmail('');
    } catch (error) {
      console.error('Error:', error);
      toast.error('Failed to join waitlist. Please try again.');
    }
  };

  return (
    <div className="w-full bg-gray-50">
      {/* Hero Section */}
      <div className="bg-gradient-to-br from-gray-50 to-white py-24 relative overflow-hidden">
        {/* Abstract molecular background pattern */}
        <div className="absolute inset-0 opacity-5">
          <div className="absolute inset-0" style={{
            backgroundImage: `url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%2399F6E4' fill-opacity='0.4'%3E%3Cpath d='M36 34v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zm0-30V0h-2v4h-4v2h4v4h2V6h4V4h-4zM6 34v-4H4v4H0v2h4v4h2v-4h4v-2H6zM6 4V0H4v4H0v2h4v4h2V6h4V4H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`,
          }} />
        </div>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative z-10">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-12 items-center">
            <div className="text-left">
              <h1 className="text-5xl sm:text-6xl font-extrabold text-gray-900 mb-8 leading-tight">
                Longevity Nutrition<br/>
                <span className="text-transparent bg-clip-text bg-gradient-to-r from-teal-600 to-teal-400">Backed by Science</span>
              </h1>
              <p className="text-xl text-gray-600 mb-10 leading-relaxed">
                Advanced nutritional analysis using molecular biomarkers and AI-driven insights for optimal cellular health.
              </p>
              <form onSubmit={handleWaitlistSubmit} className="max-w-lg">
                <div className="flex flex-col sm:flex-row gap-4">
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Enter your email"
                    className="flex-1 px-6 py-4 rounded-lg border-2 border-gray-200 focus:border-teal-400 focus:ring-2 focus:ring-teal-200 focus:outline-none transition-all duration-300"
                  />
                  <button
                    type="submit"
                    className="px-8 py-4 bg-teal-500 text-white font-medium rounded-lg hover:bg-teal-600 transition-all duration-300 shadow-lg hover:shadow-xl"
                  >
                    Join Waitlist
                  </button>
                </div>
              </form>
            </div>
            <div className="relative">
              <img
                src="https://images.unsplash.com/photo-1532187863486-abf9dbad1b69?ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80"
                alt="Scientific Analysis"
                className="rounded-2xl shadow-xl w-full"
              />
              <div className="absolute inset-0 rounded-2xl bg-gradient-to-br from-teal-500/10 to-transparent"></div>
            </div>
          </div>
        </div>
      </div>

      {/* Scientific Method Section */}
      <section className="py-20 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-16">
            <h2 className="text-3xl font-bold text-gray-900 mb-4">The Science of Nutrition</h2>
            <p className="text-xl text-gray-600">Molecular-level analysis for precise nutritional optimization</p>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            <div className="bg-gray-50 rounded-xl p-8 border-t-4 border-teal-500">
              <div className="w-12 h-12 bg-teal-100 rounded-lg flex items-center justify-center mb-6">
                <IoMedicalOutline className="w-6 h-6 text-teal-600" />
              </div>
              <h3 className="text-xl font-semibold text-gray-900 mb-4">Molecular Analysis</h3>
              <p className="text-gray-600">Advanced biomarker tracking at the molecular level for precise nutritional needs.</p>
            </div>
            <div className="bg-gray-50 rounded-xl p-8 border-t-4 border-teal-500">
              <div className="w-12 h-12 bg-teal-100 rounded-lg flex items-center justify-center mb-6">
                <IoBeakerOutline className="w-6 h-6 text-teal-600" />
              </div>
              <h3 className="text-xl font-semibold text-gray-900 mb-4">Cellular Optimization</h3>
              <p className="text-gray-600">Target specific cellular pathways with precision-based nutrition protocols.</p>
            </div>
            <div className="bg-gray-50 rounded-xl p-8 border-t-4 border-teal-500">
              <div className="w-12 h-12 bg-teal-100 rounded-lg flex items-center justify-center mb-6">
                <IoFlaskOutline className="w-6 h-6 text-teal-600" />
              </div>
              <h3 className="text-xl font-semibold text-gray-900 mb-4">Clinical Research</h3>
              <p className="text-gray-600">Evidence-based protocols validated through rigorous clinical studies.</p>
            </div>
            <div className="bg-gray-50 rounded-xl p-8 border-t-4 border-teal-500">
              <div className="w-12 h-12 bg-teal-100 rounded-lg flex items-center justify-center mb-6">
                <IoAnalyticsOutline className="w-6 h-6 text-teal-600" />
              </div>
              <h3 className="text-xl font-semibold text-gray-900 mb-4">AI Analytics</h3>
              <p className="text-gray-600">Machine learning algorithms process biomarker data for personalized insights.</p>
            </div>
          </div>
        </div>
      </section>

      {/* Research Stats */}
      <section className="py-16 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-16">
            <div className="relative">
              <img
                src="https://images.unsplash.com/photo-1576086213369-97a306d36557?ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80"
                alt="Laboratory Analysis"
                className="rounded-xl shadow-lg"
              />
              <div className="absolute inset-0 bg-gradient-to-t from-black/50 to-transparent rounded-xl"></div>
            </div>
            <div className="relative">
              <img
                src="https://images.unsplash.com/photo-1581093588401-fbb62a02f120?ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80"
                alt="Molecular Analysis"
                className="rounded-xl shadow-lg"
              />
              <div className="absolute inset-0 bg-gradient-to-t from-black/50 to-transparent rounded-xl"></div>
            </div>
          </div>
          <div className="grid grid-cols-2 lg:grid-cols-4 gap-8">
            <div className="bg-white p-8 rounded-xl shadow-sm">
              <div className="text-4xl font-bold text-teal-600 mb-2">500+</div>
              <div className="text-gray-600">Biomarkers Analyzed</div>
            </div>
            <div className="bg-white p-8 rounded-xl shadow-sm">
              <div className="text-4xl font-bold text-teal-600 mb-2">50+</div>
              <div className="text-gray-600">Clinical Studies</div>
            </div>
            <div className="bg-white p-8 rounded-xl shadow-sm">
              <div className="text-4xl font-bold text-teal-600 mb-2">99%</div>
              <div className="text-gray-600">Analysis Accuracy</div>
            </div>
            <div className="bg-white p-8 rounded-xl shadow-sm">
              <div className="text-4xl font-bold text-teal-600 mb-2">24/7</div>
              <div className="text-gray-600">Real-time Monitoring</div>
            </div>
          </div>
        </div>
      </section>

      {/* Clinical Process */}
      <section className="py-20 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-16">
            <div className="mb-8">
              <img
                src="https://images.unsplash.com/photo-1579165466741-7f35e4755660?ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80"
                alt="Clinical Process"
                className="rounded-2xl shadow-xl mx-auto w-full max-w-2xl"
              />
            </div>
            <h2 className="text-3xl font-bold text-gray-900 mb-4">Clinical-Grade Process</h2>
            <p className="text-xl text-gray-600">Rigorous scientific methodology meets cutting-edge technology</p>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="bg-gray-50 rounded-xl p-8">
              <div className="flex items-center mb-6">
                <div className="w-12 h-12 bg-teal-100 rounded-full flex items-center justify-center mr-4">
                  <span className="text-2xl font-bold text-teal-600">1</span>
                </div>
                <h3 className="text-xl font-semibold text-gray-900">Sample Analysis</h3>
              </div>
              <p className="text-gray-600">Advanced laboratory testing of blood, genetic, and metabolic markers.</p>
            </div>
            <div className="bg-gray-50 rounded-xl p-8">
              <div className="flex items-center mb-6">
                <div className="w-12 h-12 bg-teal-100 rounded-full flex items-center justify-center mr-4">
                  <span className="text-2xl font-bold text-teal-600">2</span>
                </div>
                <h3 className="text-xl font-semibold text-gray-900">Data Processing</h3>
              </div>
              <p className="text-gray-600">AI algorithms analyze molecular data to identify nutritional needs.</p>
            </div>
            <div className="bg-gray-50 rounded-xl p-8">
              <div className="flex items-center mb-6">
                <div className="w-12 h-12 bg-teal-100 rounded-full flex items-center justify-center mr-4">
                  <span className="text-2xl font-bold text-teal-600">3</span>
                </div>
                <h3 className="text-xl font-semibold text-gray-900">Protocol Creation</h3>
              </div>
              <p className="text-gray-600">Development of personalized nutrition protocols based on scientific evidence.</p>
            </div>
          </div>
        </div>
      </section>

      {/* Scientific Publications */}
      <section className="py-20 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-16">
            <div className="relative overflow-hidden rounded-xl shadow-lg aspect-[4/3]">
              <img
                src="https://images.unsplash.com/photo-1614935151651-0bea6508db6b?ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80"
                alt="Research Paper"
                className="object-cover w-full h-full"
              />
              <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent"></div>
            </div>
            <div className="relative overflow-hidden rounded-xl shadow-lg aspect-[4/3]">
              <img
                src="https://images.unsplash.com/photo-1576086213369-97a306d36557?ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80"
                alt="Laboratory Research"
                className="object-cover w-full h-full"
              />
              <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent"></div>
            </div>
            <div className="relative overflow-hidden rounded-xl shadow-lg aspect-[4/3]">
              <img
                src="https://images.unsplash.com/photo-1532187863486-abf9dbad1b69?ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80"
                alt="Scientific Analysis"
                className="object-cover w-full h-full"
              />
              <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent"></div>
            </div>
          </div>
          <div className="text-center mb-16">
            <h2 className="text-3xl font-bold text-gray-900 mb-4">Research Validation</h2>
            <p className="text-xl text-gray-600">Backed by peer-reviewed scientific research</p>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            <div className="bg-white p-8 rounded-xl shadow-sm">
              <div className="text-sm text-teal-600 font-medium mb-2">Journal of Nutritional Science</div>
              <h3 className="text-lg font-semibold text-gray-900 mb-4">Molecular Nutrition & Food Research</h3>
              <p className="text-gray-600">Breakthrough findings in molecular nutrition and personalized dietary protocols.</p>
            </div>
            <div className="bg-white p-8 rounded-xl shadow-sm">
              <div className="text-sm text-teal-600 font-medium mb-2">Clinical Nutrition Journal</div>
              <h3 className="text-lg font-semibold text-gray-900 mb-4">Biomarker-Based Nutrition Analysis</h3>
              <p className="text-gray-600">Advanced methodologies in nutritional biomarker analysis and interpretation.</p>
            </div>
            <div className="bg-white p-8 rounded-xl shadow-sm">
              <div className="text-sm text-teal-600 font-medium mb-2">Nature Metabolism</div>
              <h3 className="text-lg font-semibold text-gray-900 mb-4">AI in Nutritional Science</h3>
              <p className="text-gray-600">Implementation of machine learning in precision nutrition protocols.</p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Landing3;

import React, { useRef } from 'react';
import { IoSendSharp, IoImageOutline } from 'react-icons/io5';

export const ChatInput = ({ inputMessage, setInputMessage, onSubmit, isLoading }) => {
  const inputRef = useRef(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!inputMessage.trim() || isLoading) return;
    onSubmit(e);
  };

  return (
    <div className="border-t border-border p-3 mb-4">
      <form 
        onSubmit={handleSubmit} 
        className="flex gap-2 items-center"
      >
        <button
          type="button"
          onClick={() => console.log('Photo upload clicked - currently disabled')}
          className="text-gray-400 hover:text-gray-600 transition-colors disabled:opacity-50"
          disabled={true}
        >
          <IoImageOutline className="w-5 h-5" />
        </button>

        <input
          ref={inputRef}
          type="text"
          id="chat-input"
          name="chat-input"
          value={inputMessage}
          onChange={(e) => setInputMessage(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter' && !e.shiftKey) {
              e.preventDefault();
              handleSubmit(e);
            }
          }}
          placeholder="Type your message..."
          className="flex-1 bg-background rounded-xl px-4 py-2 focus:outline-none focus:ring-2 focus:ring-red-500 text-[16px]"
        />

        <button
          type="submit"
          disabled={!inputMessage.trim() || isLoading}
          className="bg-red-500 text-white rounded-xl p-2 hover:bg-red-600 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
        >
          <IoSendSharp className="w-5 h-5" />
        </button>
      </form>
    </div>
  );
};

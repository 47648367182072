import React, { useState } from 'react';
import { ChevronDownIcon } from '@heroicons/react/24/outline';

const FAQItem = ({ question, answer, className = '' }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div className={`group relative ${className}`}>
      {/* Card with hover effect and shadow */}
      <div 
        className={`
          relative bg-white rounded-2xl 
          transition-all duration-300 ease-in-out
          shadow-sm hover:shadow-md
          ${isExpanded ? 'bg-primary/5' : 'hover:bg-gray-50'}
        `}
      >
        <button
          className="w-full px-4 sm:px-6 py-3 sm:py-4 text-left focus:outline-none"
          onClick={() => setIsExpanded(!isExpanded)}
          aria-expanded={isExpanded}
        >
          <div className="flex justify-between items-center gap-4">
            <h3 className={`
              text-base leading-relaxed
              transition-colors duration-300
              ${isExpanded ? 'text-primary' : 'text-gray-900 group-hover:text-gray-700'}
            `}>
              {question}
            </h3>
            <ChevronDownIcon 
              className={`
                w-4 h-4 flex-shrink-0
                transition-all duration-300
                ${isExpanded 
                  ? 'transform rotate-180 text-primary' 
                  : 'text-gray-400 group-hover:text-gray-500'
                }
              `}
            />
          </div>
        </button>

        {/* Answer section with smooth animation */}
        <div
          className={`
            overflow-hidden
            transition-all duration-300 ease-in-out
            ${isExpanded ? 'max-h-[500px] opacity-100' : 'max-h-0 opacity-0'}
          `}
        >
          <div className="px-4 sm:px-6 pb-4">
            <div className={`
              prose prose-gray max-w-none
              transition-all duration-300 delay-100
              ${isExpanded ? 'opacity-100 transform translate-y-0' : 'opacity-0 transform -translate-y-2'}
            `}>
              <p className="text-sm text-text-light leading-relaxed">{answer}</p>
            </div>
          </div>
        </div>
      </div>

      {/* Decorative elements */}
      <div className={`
        absolute inset-0 rounded-2xl
        transition-opacity duration-300
        pointer-events-none
        ${isExpanded 
          ? 'opacity-100' 
          : 'opacity-0 group-hover:opacity-100'
        }
      `}>
        <div className="absolute inset-0 rounded-2xl bg-gradient-to-r from-primary/5 to-transparent" />
      </div>
    </div>
  );
};

export default FAQItem; 
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { IoFlaskOutline, IoNutritionOutline, IoAnalyticsOutline } from 'react-icons/io5';
import { useAuth } from '../contexts/AuthContext';
import { toast } from 'react-hot-toast';
import { db } from '../firebase';
import { collection, addDoc, serverTimestamp, query, where, getDocs } from 'firebase/firestore';

const Landing2 = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const { currentUser } = useAuth();

  useEffect(() => {
    if (currentUser) {
      navigate('/home');
    }
  }, [currentUser, navigate]);

  const handleWaitlistSubmit = async (e) => {
    e.preventDefault();
    
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      toast.error('Please enter a valid email address');
      return;
    }

    try {
      const waitlistQuery = query(
        collection(db, "waitlist"),
        where("email", "==", email)
      );

      const querySnapshot = await getDocs(waitlistQuery);
      if (!querySnapshot.empty) {
        toast.error('This email is already on the waitlist');
        return;
      }

      await addDoc(collection(db, "waitlist"), {
        email,
        timestamp: serverTimestamp(),
        source: 'microbiome',
        status: 'pending'
      });

      toast.success('Successfully joined the waitlist!');
      setEmail('');
    } catch (error) {
      console.error('Error:', error);
      toast.error('Failed to join waitlist. Please try again.');
    }
  };

  return (
    <div className="w-full bg-white">
      {/* Hero Section */}
      <section className="pt-20 pb-16 sm:pt-28 sm:pb-20">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
            <div className="text-left">
              <h1 className="text-4xl sm:text-5xl font-bold text-gray-900 mb-6">
                Transform Your Gut Health in 8 Weeks
              </h1>
              <p className="text-xl text-gray-600 mb-8">
                Join thousands who've optimized their gut microbiome for better digestion, 
                enhanced immunity, and improved overall wellbeing.
              </p>
              <form onSubmit={handleWaitlistSubmit} className="flex flex-col sm:flex-row gap-4">
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Enter your email"
                  className="flex-1 px-6 py-4 text-lg rounded-lg border-2 border-gray-200 focus:border-teal-400 focus:ring-2 focus:ring-teal-200 focus:outline-none transition-all duration-300"
                />
                <button
                  type="submit"
                  className="px-8 py-4 bg-teal-500 text-white font-medium rounded-lg hover:bg-teal-600 transition-all duration-300 text-lg shadow-md hover:shadow-lg"
                >
                  Start Your Journey
                </button>
              </form>
            </div>
            <div className="lg:pl-12">
              {/* Hero Image: Happy person enjoying a colorful, gut-healthy meal */}
              <div className="aspect-w-4 aspect-h-3 rounded-2xl overflow-hidden shadow-2xl">
                <img
                  src="/images/landing2/hero-meal.jpg"
                  alt="Person enjoying a healthy, colorful meal"
                  className="w-full h-full object-cover"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Results Timeline Section */}
      <section className="py-20 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-3xl sm:text-4xl font-bold text-center text-gray-900 mb-16">
            Your Journey to Better Gut Health
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="bg-white p-8 rounded-xl shadow-lg">
              <div className="text-teal-500 font-bold text-lg mb-2">Week 1-2</div>
              <h3 className="text-xl font-bold text-gray-900 mb-4">Reduced Bloating</h3>
              <p className="text-gray-600 mb-6">Experience noticeable reduction in bloating and digestive discomfort.</p>
              {/* Timeline Image 1: Before/After stomach comparison */}
              <div className="aspect-w-16 aspect-h-9 rounded-lg overflow-hidden">
                <img
                  src="/images/landing2/timeline-week2.jpg"
                  alt="Before and after comparison showing reduced bloating"
                  className="w-full h-full object-cover"
                />
              </div>
            </div>
            <div className="bg-white p-8 rounded-xl shadow-lg">
              <div className="text-teal-500 font-bold text-lg mb-2">Week 3-5</div>
              <h3 className="text-xl font-bold text-gray-900 mb-4">Enhanced Energy</h3>
              <p className="text-gray-600 mb-6">Feel more energized throughout the day with improved nutrient absorption.</p>
              {/* Timeline Image 2: Person with visibly more energy */}
              <div className="aspect-w-16 aspect-h-9 rounded-lg overflow-hidden">
                <img
                  src="/images/landing2/timeline-week5.jpg"
                  alt="Person showing increased energy levels"
                  className="w-full h-full object-cover"
                />
              </div>
            </div>
            <div className="bg-white p-8 rounded-xl shadow-lg">
              <div className="text-teal-500 font-bold text-lg mb-2">Week 6-8</div>
              <h3 className="text-xl font-bold text-gray-900 mb-4">Total Transformation</h3>
              <p className="text-gray-600 mb-6">Experience improved mood, clearer skin, and optimal digestive health.</p>
              {/* Timeline Image 3: Radiant, healthy person */}
              <div className="aspect-w-16 aspect-h-9 rounded-lg overflow-hidden">
                <img
                  src="/images/landing2/timeline-week8.jpg"
                  alt="Person showing overall health improvement"
                  className="w-full h-full object-cover"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Benefits Section */}
      <section className="py-20">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-3xl sm:text-4xl font-bold text-center text-gray-900 mb-16">
            The Science of Better Health
          </h2>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-16 items-center">
            <div>
              {/* Science Illustration: Modern, minimalist gut-brain connection diagram */}
              <div className="aspect-w-4 aspect-h-3 rounded-xl overflow-hidden shadow-lg">
                <img
                  src="/images/landing2/gut-brain-diagram.jpg"
                  alt="Gut-brain connection diagram"
                  className="w-full h-full object-cover"
                />
              </div>
            </div>
            <div className="space-y-8">
              <div className="flex gap-4 items-start">
                <div className="w-12 h-12 rounded-lg bg-teal-100 flex items-center justify-center flex-shrink-0">
                  <IoFlaskOutline className="w-6 h-6 text-teal-600" />
                </div>
                <div>
                  <h3 className="text-xl font-bold text-gray-900 mb-2">Research-Backed Approach</h3>
                  <p className="text-gray-600">Our methods are based on the latest microbiome research and clinical studies.</p>
                </div>
              </div>
              <div className="flex gap-4 items-start">
                <div className="w-12 h-12 rounded-lg bg-teal-100 flex items-center justify-center flex-shrink-0">
                  <IoNutritionOutline className="w-6 h-6 text-teal-600" />
                </div>
                <div>
                  <h3 className="text-xl font-bold text-gray-900 mb-2">Personalized Nutrition</h3>
                  <p className="text-gray-600">Get customized dietary recommendations based on your unique gut profile.</p>
                </div>
              </div>
              <div className="flex gap-4 items-start">
                <div className="w-12 h-12 rounded-lg bg-teal-100 flex items-center justify-center flex-shrink-0">
                  <IoAnalyticsOutline className="w-6 h-6 text-teal-600" />
                </div>
                <div>
                  <h3 className="text-xl font-bold text-gray-900 mb-2">Progress Tracking</h3>
                  <p className="text-gray-600">Monitor your improvements with detailed analytics and insights.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Success Stories Section */}
      <section className="py-20 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-3xl sm:text-4xl font-bold text-center text-gray-900 mb-16">
            Success Stories
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            <div className="bg-white p-8 rounded-xl shadow-lg">
              {/* Success Story Image 1 */}
              <div className="w-20 h-20 rounded-full overflow-hidden mx-auto mb-6">
                <img
                  src="/images/landing2/success-sarah.jpg"
                  alt="Sarah's transformation"
                  className="w-full h-full object-cover"
                />
              </div>
              <blockquote className="text-gray-600 text-center mb-4">
                "I saw improvements in just 2 weeks. My energy levels are through the roof!"
              </blockquote>
              <p className="text-gray-900 font-medium text-center">Sarah, 34</p>
              <p className="text-gray-500 text-sm text-center">Energy levels improved 85%</p>
            </div>
            <div className="bg-white p-8 rounded-xl shadow-lg">
              {/* Success Story Image 2 */}
              <div className="w-20 h-20 rounded-full overflow-hidden mx-auto mb-6">
                <img
                  src="/images/landing2/success-michael.jpg"
                  alt="Michael's transformation"
                  className="w-full h-full object-cover"
                />
              </div>
              <blockquote className="text-gray-600 text-center mb-4">
                "The personalized approach made all the difference in my gut health journey."
              </blockquote>
              <p className="text-gray-900 font-medium text-center">Michael, 42</p>
              <p className="text-gray-500 text-sm text-center">Digestive issues reduced 90%</p>
            </div>
            <div className="bg-white p-8 rounded-xl shadow-lg">
              {/* Success Story Image 3 */}
              <div className="w-20 h-20 rounded-full overflow-hidden mx-auto mb-6">
                <img
                  src="/images/landing2/success-emma.jpg"
                  alt="Emma's transformation"
                  className="w-full h-full object-cover"
                />
              </div>
              <blockquote className="text-gray-600 text-center mb-4">
                "Not only did my gut health improve, but my skin cleared up too!"
              </blockquote>
              <p className="text-gray-900 font-medium text-center">Emma, 29</p>
              <p className="text-gray-500 text-sm text-center">Skin clarity improved 75%</p>
            </div>
          </div>
        </div>
      </section>

      {/* Final CTA Section */}
      <section className="py-20">
        <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <h2 className="text-3xl sm:text-4xl font-bold text-gray-900 mb-6">
            Start Your Transformation Today
          </h2>
          <p className="text-xl text-gray-600 mb-8">
            Join thousands of others who've already transformed their gut health and overall wellbeing.
          </p>
          <form onSubmit={handleWaitlistSubmit} className="max-w-lg mx-auto flex flex-col sm:flex-row gap-4">
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter your email"
              className="flex-1 px-6 py-4 text-lg rounded-lg border-2 border-gray-200 focus:border-teal-400 focus:ring-2 focus:ring-teal-200 focus:outline-none transition-all duration-300"
            />
            <button
              type="submit"
              className="px-8 py-4 bg-teal-500 text-white font-medium rounded-lg hover:bg-teal-600 transition-all duration-300 text-lg shadow-md hover:shadow-lg"
            >
              Transform Your Health
            </button>
          </form>
        </div>
      </section>
    </div>
  );
};

export default Landing2;
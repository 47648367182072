export const formatMessage = (content) => {
  if (!content) return '';
  
  // If content is an array of message parts
  if (Array.isArray(content)) {
    content = content.map(part => part.text || '').join('\n');
  }
  
  // If content is an object with text property
  if (typeof content === 'object' && content.text) {
    content = content.text;
  }

  // Now content should be a string, proceed with formatting
  let text = content.toString();

  // Format headers (###)
  text = text.replace(/^### (.*$)/gm, '<h3 class="text-xl font-semibold mt-6 mb-3">$1</h3>');
  text = text.replace(/^## (.*$)/gm, '<h2 class="text-2xl font-semibold mt-6 mb-3">$1</h2>');
  text = text.replace(/^# (.*$)/gm, '<h1 class="text-3xl font-bold mt-6 mb-4">$1</h1>');
  
  // Format bold and italic
  text = text.replace(/\*\*(.*?)\*\*/g, '<strong class="font-semibold">$1</strong>');
  text = text.replace(/\*(.*?)\*/g, '<em class="italic">$1</em>');
  
  // Format bullet points and wrap in ul if needed
  const hasBullets = text.includes('• ') || text.includes('- ');
  text = text.replace(/^[•-] (.*$)/gm, '<li class="ml-4 mb-2">$1</li>');
  
  if (hasBullets) {
    text = text.replace(/<li.*?<\/li>/gs, match => '<ul class="list-disc my-3 space-y-1">' + match + '</ul>');
  }
  
  // Format code or important terms with backticks
  text = text.replace(/`(.*?)`/g, '<code class="bg-gray-100 px-1.5 py-0.5 rounded text-sm font-mono">$1</code>');
  
  // Convert newlines to paragraphs for better spacing
  text = text.split('\n\n').map(para => 
    para.trim() ? `<p class="mb-3">${para}</p>` : ''
  ).join('');
  
  return text;
};

import React from 'react';

const Footer = ({ className = '' }) => {
  return (
    <footer className={`w-full py-4 bg-background border-t border-border ${className}`}>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <p className="text-small text-text-light text-center">
          {new Date().getFullYear()} Foodwise. All rights reserved | Contact: <a href="mailto:contact@foodwise.me" className="hover:text-primary">contact@foodwise.me</a>
        </p>
      </div>
    </footer>
  );
};

export default Footer; 
// PAL (Physical Activity Level) values
export const PAL_VALUES = {
  SEDENTARY: 1.2, // wenig aktiv
  LIGHTLY_ACTIVE: 1.4, // etwas aktiv
  MODERATELY_ACTIVE: 1.6, // aktiv
  VERY_ACTIVE: 1.8, // sehr aktiv
};

/**
 * Calculate Basal Metabolic Rate (BMR) using the Harris-Benedict equation
 * @param {Object} params
 * @param {number} params.weight - Weight in kg
 * @param {number} params.height - Height in cm
 * @param {number} params.age - Age in years
 * @param {string} params.gender - 'male' or 'female'
 * @returns {number} BMR in kcal/day
 */
export const calculateBMR = ({ weight, height, age, gender }) => {
  if (!weight || !height || !age || !gender) {
    return 0;
  }

  if (gender === 'male') {
    return 66.47 + (13.7 * weight) + (5.0 * height) - (6.8 * age);
  } else {
    return 655.1 + (9.6 * weight) + (1.8 * height) - (4.7 * age);
  }
};

/**
 * Get PAL value based on activity level
 * @param {string} activityLevel - One of: 'sedentary', 'lightly_active', 'moderately_active', 'very_active'
 * @returns {number} PAL value
 */
export const getPALValue = (activityLevel) => {
  switch (activityLevel) {
    case 'sedentary':
      return PAL_VALUES.SEDENTARY;
    case 'lightly_active':
      return PAL_VALUES.LIGHTLY_ACTIVE;
    case 'moderately_active':
      return PAL_VALUES.MODERATELY_ACTIVE;
    case 'very_active':
      return PAL_VALUES.VERY_ACTIVE;
    default:
      return PAL_VALUES.SEDENTARY;
  }
};

/**
 * Calculate BMI (Body Mass Index)
 * @param {number} weight - Weight in kilograms
 * @param {number} height - Height in centimeters
 * @returns {number} BMI value
 */
export const calculateBMI = (weight, height) => {
  // Convert height to meters
  const heightInMeters = height / 100;
  // Calculate BMI
  return weight / (heightInMeters * heightInMeters);
};

/**
 * Get BMI category
 * @param {number} bmi - BMI value
 * @returns {string} BMI category
 */
export const getBMICategory = (bmi) => {
  if (bmi < 18.5) return 'Underweight';
  if (bmi < 25) return 'Normal weight';
  if (bmi < 30) return 'Overweight';
  return 'Obese';
};

/**
 * Calculate daily calorie needs using the Mifflin-St Jeor Equation
 * @param {Object} params
 * @param {number} params.weight - Weight in kilograms
 * @param {number} params.height - Height in centimeters
 * @param {number} params.age - Age in years
 * @param {string} params.gender - 'male' or 'female'
 * @param {string} params.activityLevel - Activity level
 * @param {number|null} params.customCalories - Custom calorie goal (optional)
 * @returns {Object} Calculated BMR and TDEE
 */
export const calculateCalories = ({ weight, height, age, gender, activityLevel, customCalories = null }) => {
  // Calculate BMR using Mifflin-St Jeor Equation
  let bmr;
  if (gender === 'male') {
    bmr = 10 * weight + 6.25 * height - 5 * age + 5;
  } else {
    bmr = 10 * weight + 6.25 * height - 5 * age - 161;
  }

  // Activity multipliers
  const activityMultipliers = {
    'sedentary': 1.2, // Little or no exercise
    'lightly-active': 1.375, // Light exercise/sports 1-3 days/week
    'moderately-active': 1.55, // Moderate exercise/sports 3-5 days/week
    'very-active': 1.725, // Hard exercise/sports 6-7 days/week
    'super-active': 1.9 // Very hard exercise/sports & physical job or training twice per day
  };

  // Calculate TDEE (Total Daily Energy Expenditure)
  const tdee = Math.round(bmr * activityMultipliers[activityLevel]);

  return {
    bmr: Math.round(bmr),
    tdee: customCalories || tdee
  };
};
